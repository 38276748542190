<template>
  <div v-frag>
    <label class="mb-0"
      ><b>{{ $t("sectionMode") }}:</b></label
    >
    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
      <template #button-content>
        <tl-mode-icon :mode="params.mode" :showName="true"></tl-mode-icon>
      </template>
      <b-dropdown-item @click="setMode('stay')" v-if="params.mode !== 'stay'"
        ><tl-mode-icon mode="stay" :showName="true"></tl-mode-icon
      ></b-dropdown-item>
      <b-dropdown-item @click="setMode('walk')" v-if="params.mode !== 'walk'"
        ><tl-mode-icon mode="walk" :showName="true"></tl-mode-icon
      ></b-dropdown-item>
      <b-dropdown-item @click="setMode('bike')" v-if="params.mode !== 'bike'"
        ><tl-mode-icon mode="bike" :showName="true"></tl-mode-icon
      ></b-dropdown-item>
      <b-dropdown-item @click="setMode('car')" v-if="params.mode !== 'car'"
        ><tl-mode-icon mode="car" :showName="true"></tl-mode-icon
      ></b-dropdown-item>
      <b-dropdown-item @click="setMode('pt')" v-if="params.mode !== 'pt'"
        ><tl-mode-icon mode="pt" :showName="true"></tl-mode-icon
      ></b-dropdown-item>
      <b-dropdown-item @click="setMode('ptm')" v-if="params.mode !== 'ptm'"
        ><tl-mode-icon mode="ptm" :showName="true"></tl-mode-icon
      ></b-dropdown-item>
      <b-dropdown-item
        @click="setMode('flight')"
        v-if="params.mode !== 'flight'"
        ><tl-mode-icon mode="flight" :showName="true"></tl-mode-icon
      ></b-dropdown-item>
      <b-dropdown-item @click="setMode('boat')" v-if="params.mode !== 'boat'"
        ><tl-mode-icon mode="boat" :showName="true"></tl-mode-icon
      ></b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import tlModeIcon from "../components/tlModeIcon";

export default {
  name: "tl-mode-switcher",
  components: {
    "tl-mode-icon": tlModeIcon,
  },
  computed: {
    params() {
      return this.$store.state.params.section;
    },
  },
  methods: {
    setMode(mode) {
      this.$store.dispatch("params/setMode", mode);
    },
  },
};
</script>

<style></style>
