<template>
  <div class="d-flex flex-column h-100">
    <div class="content">
      <b-container fluid>
        <b-row class="my-2 px-1 align-items-center">
          <slot name="button-sidebar"></slot>
          <h3 class="mb-1">{{ $t("store") }}</h3>
        </b-row>
      </b-container>
    </div>
    <!-- <div class="footer align-items-center pt-1">
    </div> -->
  </div>
</template>

<script>
export default {
  components: {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
.content {
  flex: auto;
  overflow-y: auto;
}

.footer {
  width: 100%;
  flex-basis: 30px;
  display: flex;
}
</style>
