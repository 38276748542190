<template>
  <div v-frag>
    <tl-datetime
      class="px-1"
      :value="params.starttime"
      @input="setParam('starttime', $event)"
      ><label>{{ $t("starttime") }}</label></tl-datetime
    >
    <tl-datetime
      class="px-1"
      :value="params.endtime"
      @input="setParam('endtime', $event)"
      ><label>{{ $t("endtime") }}</label></tl-datetime
    >
    <template v-if="params.mode != 'stay'">
      <b-row class="my-1 px-1">
        <label>{{ $t("sectionStartpointName") }}</label>
        <b-form-input v-model="startpoint_name"></b-form-input>
      </b-row>
      <b-row class="my-1 px-1">
        <label>{{ $t("sectionEndpointName") }}</label>
        <b-form-input v-model="endpoint_name"></b-form-input>
      </b-row>
    </template>
    <b-row class="my-1 px-1">
      <label>{{ $t("sectionDescription") }}</label>
      <b-form-input
        :value="params.description"
        @change="setParam('description', $event)"
      ></b-form-input>
    </b-row>
    <tl-with-tickets></tl-with-tickets>
    <div id="addSectionPriceFinder">
      <tl-price-finder @scroll="scrollToPriceFinder"></tl-price-finder>
    </div>
  </div>
</template>

<script>
import tlDatetime from "../components/tlDatetime";
import tlPriceFinder from "../components/tlPriceFinder";
import tlWithTickets from "../components/tlWithTickets";
import VueScrollTo from "vue-scrollto";

export default {
  components: {
    "tl-datetime": tlDatetime,
    "tl-price-finder": tlPriceFinder,
    "tl-with-tickets": tlWithTickets,
  },
  computed: {
    params() {
      return this.$store.state.params.section;
    },
    startpoint_name: {
      get() {
        return this.params.startpoint.name;
      },
      set(value) {
        this.setParam("startpoint", { name: value });
      },
    },
    endpoint_name: {
      get() {
        return this.params.endpoint.name;
      },
      set(value) {
        this.setParam("endpoint", { name: value });
      },
    },
  },
  methods: {
    setParam(key, value) {
      this.$store.commit("params/setSectionProp", { key: key, value: value });
    },
    scrollToPriceFinder() {
      VueScrollTo.scrollTo("#addSectionPriceFinder", 500, {
        container: "#addSectionContainer",
      });
    },
  },
};
</script>

<style scoped>
label {
  margin: 0;
}
.my-1 {
  align-items: center;
}
::v-deep .input-group:not(.tl-datetime) > .form-control:not(:last-child) {
  border-radius: 0.25rem !important;
}
</style>
