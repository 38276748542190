<template>
  <b-row class="my-1">
    <b-col sm="4" class="d-flex align-items-center word-break">
      <label class="m-0" for="input">{{ label }}:</label>
    </b-col>
    <b-col sm="8" class="position-relative">
      <div
        class="position-absolute d-flex align-items-center word-break"
        style="inset: 0; left: 15px"
        v-if="!showEditing"
      >
        <label class="m-0" id="input">{{ value }}</label
        ><b-link v-if="!readOnly" class="ml-2" @click="isEditing = true"
          ><i class="fas fa-pen"></i
        ></b-link>
      </div>
      <div
        class="w-100 d-flex align-items-center"
        :style="{ visibility: showEditing ? 'visible' : 'hidden' }"
      >
        <div class="flex-grow-1">
          <b-form-input
            v-model="inputValue"
            id="input"
            :formatter="formatter"
            :state="!!error || !!formatterError ? false : null"
            aria-describedby="input-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            {{ error || formatterError }}
          </b-form-invalid-feedback>
        </div>
        <b-spinner
          v-if="isLoading"
          class="ml-2"
          small
          variant="primary"
        ></b-spinner>
        <b-link class="ml-2 text-danger" @click="cancel"
          ><i class="fas fa-times fa-fw"></i
        ></b-link>
        <b-link class="ml-2" @click="save" :disabled="!!formatterError"
          ><i class="fas fa-check fa-fw" key="link-save"></i
        ></b-link>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "tl-text-edit",
  data() {
    return {
      isEditing: false,
      inputValue: null,
      formatterError: null,
    };
  },
  props: {
    label: String,
    value: null,
    username: Boolean,
    error: String,
    isLoading: Boolean,
    readOnly: Boolean,
  },
  computed: {
    showEditing() {
      return this.isEditing || this.error || this.isLoading;
    },
  },
  methods: {
    save() {
      if (this.formatterError) return;
      this.$emit("input", this.inputValue);
      this.isEditing = false;
    },
    cancel() {
      this.$emit("cancel");
      this.isEditing = false;
      this.inputValue = this.value;
      this.formatter(this.value);
    },
    formatter(value) {
      if (!this.username) {
        this.formatterError = null;
        return value;
      }

      if (value.length == 0) {
        this.formatterError = this.$t("usernameIsEmpty");
        return value;
      }
      if (value.length > 20) {
        this.formatterError = this.$t("usernameMaxLength");
        return value;
      }

      if (value.match(/[^a-z0-9]/g)) {
        this.formatterError = this.$t("usernameAllowedChars");
        return value;
      }

      this.formatterError = null;
      return value;
    },
  },
  mounted() {
    this.inputValue = this.value;
  },
  watch: {
    value() {
      this.inputValue = this.value;
      this.formatter(this.value);
    },
  },
};
</script>

<style scoped lang="scss">
a.disabled {
  pointer-events: none;
  color: var(--secondary);
}
</style>
