/* eslint-disable no-async-promise-executor */
// import Vue from "vue";
import firebase from "firebase/app";
import "firebase/functions";
import { DateTime } from "luxon";
import i18n from "../i18n";
// import "firebase/firestore";
// import "firebase/storage";

export default {
  namespaced: true,
  state: () => ({
    selectedJourney: null,
    via: { name: "" },
    arrivalDeparture: "departure",
    startpointStops: [],
    viaStops: [],
    endpointStops: [],
    isLoadingStartpoint: false,
    cancelFindStartpoint: null,
    isLoadingVia: false,
    cancelFindVia: null,
    isLoadingEndpoint: false,
    cancelFindEndpoint: null,
    isLoadingJourneys: false,
    journeys: null,
    earlierRef: null,
    laterRef: null,
    datetime: "",
    overrideDate: "",
    addPrice: false,
    isLoadingPrices: false,
    cancelFindPrices: null,
    prices: null,
    selectedPrice: null,
    isGettingVehicleComposition: false,
    cancelGetVehicleComposition: null,
  }),
  mutations: {
    setSelectedJourney(state, selectedJourney) {
      state.selectedJourney = selectedJourney;
    },
    setVia(state, via) {
      state.via = via;
    },
    setArrivalDeparture(state, arrivalDeparture) {
      state.arrivalDeparture = arrivalDeparture;
    },
    setStartpointStops(state, startpointStops) {
      state.startpointStops = startpointStops;
    },
    setViaStops(state, viaStops) {
      state.viaStops = viaStops;
    },
    setEndpointStops(state, endpointStops) {
      state.endpointStops = endpointStops;
    },
    setIsLoadingStartpoint(state, isLoadingStartpoint) {
      state.isLoadingStartpoint = isLoadingStartpoint;
    },
    setCancelFindStartpoint(state, cancelFindStartpoint) {
      state.cancelFindStartpoint = cancelFindStartpoint;
    },
    setIsLoadingVia(state, isLoadingVia) {
      state.isLoadingVia = isLoadingVia;
    },
    setCancelFindVia(state, cancelFindVia) {
      state.cancelFindVia = cancelFindVia;
    },
    setIsLoadingEndpoint(state, isLoadingEndpoint) {
      state.isLoadingEndpoint = isLoadingEndpoint;
    },
    setCancelFindEndpoint(state, cancelFindEndpoint) {
      state.cancelFindEndpoint = cancelFindEndpoint;
    },
    setIsLoadingJourneys(state, isLoadingJourneys) {
      state.isLoadingJourneys = isLoadingJourneys;
    },
    setJourneys(state, journeys) {
      state.journeys = journeys;
    },
    setEarlierRef(state, earlierRef) {
      state.earlierRef = earlierRef;
    },
    setLaterRef(state, laterRef) {
      state.laterRef = laterRef;
    },
    setDatetime(state, datetime) {
      state.datetime = datetime;
    },
    setOverrideDate(state, overrideDate) {
      state.overrideDate = overrideDate;
    },
    setAddPrice(state, addPrice) {
      state.addPrice = addPrice;
    },
    setIsLoadingPrices(state, isLoadingPrices) {
      state.isLoadingPrices = isLoadingPrices;
    },
    setCancelFindPrices(state, cancelFindPrices) {
      state.cancelFindPrices = cancelFindPrices;
    },
    setPrices(state, prices) {
      state.prices = prices;
    },
    setSelectedPrice(state, selectedPrice) {
      state.selectedPrice = selectedPrice;
    },
    setIsGettingVehicleComposition(state, isGettingVehicleComposition) {
      state.isGettingVehicleComposition = isGettingVehicleComposition;
    },
    setCancelGetVehicleComposition(state, cancelGetVehicleComposition) {
      state.cancelGetVehicleComposition = cancelGetVehicleComposition;
    },
    disablePrice(state) {
      state.addPrice = false;
      state.prices = null;
      state.selectedPrice = null;
    },
    clear(state) {
      state.journeys = null;
      state.selectedJourney = null;
      state.earlierRef = null;
      state.laterRef = null;
      state.startpointStops = [];
      state.viaStops = [];
      state.endpointStops = [];
      state.overrideDate = "";
      state.isGettingVehicleComposition = false;
    },
  },
  getters: {
    findPricesEnabled(state, getters, rootState) {
      const mode = rootState.params.section?.mode;
      if (mode === "ptm" || (rootState.params.isEditing && mode === "pt")) {
        const markers = rootState.router.markers;
        return markers.length >= 2;
      } else if (mode === "stay") return false;
      else if (mode !== "pt") {
        return (
          rootState.params.section?.startpoint?.name &&
          rootState.params.section?.endpoint?.name
        );
      }
      return true;
    },
    getVehicleCompositionEnabled(state, getters, rootState) {
      const mode = rootState.params.section?.mode;
      if (mode === "ptm" || (rootState.params.isEditing && mode === "pt")) {
        const markers = rootState.router.markers;
        return (
          markers.length > 0 &&
          markers[0].isStation &&
          (markers[0].station.plannedDeparture ||
            markers[0].station.departure) &&
          rootState.params.section?.ptm?.number
        );
      } else if (mode === "pt") {
        const selectedJourney = state.selectedJourney;
        return (
          selectedJourney &&
          selectedJourney.legs.length === 1 &&
          (selectedJourney.legs[0].plannedDeparture ||
            selectedJourney.legs[0].departure) &&
          selectedJourney.legs[0].line?.fahrtNr
        );
      }
      return false;
    },
  },
  actions: {
    findStartpointStops: async function (context, payload) {
      const ignoreSame = payload.ignoreSame ?? false;
      if (
        payload.coords ||
        (payload.name != "" &&
          (payload.name != context.rootState.params.section.startpoint.name ||
            ignoreSame))
      ) {
        context.commit("setIsLoadingStartpoint", true);
        const stops = await new Promise(async (res) => {
          context.commit("setCancelFindStartpoint", res);
          let stops;
          try {
            if (payload.name) {
              stops = await context.dispatch("findStops", {
                name: payload.name,
                hafas_profile: context.rootState.params.section.hafas_profile,
                results: 5,
              });
            } else if (payload.coords) {
              stops = await context.dispatch("findStopsNearby", {
                coords: payload.coords,
                hafas_profile: context.rootState.params.section.hafas_profile,
                results: 5,
              });
            }
          } catch (error) {
            console.error(error);
            context.dispatch("showError", error.toString(), { root: true });
          }
          context.commit("setCancelFindStartpoint", null);
          res(stops);
        });
        if (stops) {
          context.commit("setStartpointStops", stops != undefined ? stops : []);
          if (context.state.startpointStops.length != 0) {
            context.commit(
              "params/setSectionProp",
              {
                key: "startpoint",
                value: context.state.startpointStops[0],
              },
              { root: true }
            );
          }
        }
        context.commit("setIsLoadingStartpoint", false);
      }
    },
    cancelFindStartpoint: function (context) {
      const cancelFindStartpoint = context.state.cancelFindStartpoint;
      if (cancelFindStartpoint) {
        cancelFindStartpoint();
        context.commit("setCancelFindStartpoint", null);
      }
    },
    findViaStops: async function (context, payload) {
      const ignoreSame = payload.ignoreSame ?? false;
      if (
        payload.coords ||
        (payload.name != "" &&
          (payload.name != context.state.via.name || ignoreSame))
      ) {
        context.commit("setIsLoadingVia", true);

        const stops = await new Promise(async (res) => {
          context.commit("setCancelFindVia", res);
          let stops;
          try {
            if (payload.name) {
              stops = await context.dispatch("findStops", {
                name: payload.name,
                hafas_profile: context.rootState.params.section.hafas_profile,
                results: 5,
              });
            } else if (payload.coords) {
              stops = await context.dispatch("findStopsNearby", {
                coords: payload.coords,
                hafas_profile: context.rootState.params.section.hafas_profile,
                results: 5,
              });
            }
          } catch (error) {
            console.error(error);
            context.dispatch("showError", error.toString(), { root: true });
          }
          context.commit("setCancelFindVia", null);
          res(stops);
        });
        if (stops) {
          context.commit("setViaStops", stops != undefined ? stops : []);
          if (context.state.viaStops.length != 0) {
            context.commit("setVia", context.state.viaStops[0]);
          }
        }
        context.commit("setIsLoadingVia", false);
      }
    },
    cancelFindVia: function (context) {
      const cancelFindVia = context.state.cancelFindVia;
      if (cancelFindVia) {
        cancelFindVia();
        context.commit("setCancelFindVia", null);
      }
    },
    findEndpointStops: async function (context, payload) {
      const ignoreSame = payload.ignoreSame ?? false;
      if (
        payload.coords ||
        (payload.name != "" &&
          (payload.name != context.rootState.params.section.endpoint.name ||
            ignoreSame))
      ) {
        context.commit("setIsLoadingEndpoint", true);

        const stops = await new Promise(async (res) => {
          context.commit("setCancelFindEndpoint", res);
          let stops;
          try {
            if (payload.name) {
              stops = await context.dispatch("findStops", {
                name: payload.name,
                hafas_profile: context.rootState.params.section.hafas_profile,
                results: 5,
              });
            } else if (payload.coords) {
              stops = await context.dispatch("findStopsNearby", {
                coords: payload.coords,
                hafas_profile: context.rootState.params.section.hafas_profile,
                results: 5,
              });
            }
          } catch (error) {
            console.error(error);
            context.dispatch("showError", error.toString(), { root: true });
          }
          context.commit("setCancelFindEndpoint", null);
          res(stops);
        });
        if (stops) {
          context.commit("setEndpointStops", stops != undefined ? stops : []);
          if (context.state.endpointStops.length != 0) {
            context.commit(
              "params/setSectionProp",
              {
                key: "endpoint",
                value: context.state.endpointStops[0],
              },
              { root: true }
            );
          }
        }
        context.commit("setIsLoadingEndpoint", false);
      }
    },
    cancelFindEndpoint: function (context) {
      const cancelFindEndpoint = context.state.cancelFindEndpoint;
      if (cancelFindEndpoint) {
        cancelFindEndpoint();
        context.commit("setCancelFindEndpoint", null);
      }
    },
    findStops: async function (context, payload) {
      let profile;
      if (payload.hafas_profile === "gmaps") {
        profile = "gmaps";
      } else {
        profile = "hafas";
      }
      var findStops = firebase.functions().httpsCallable("findStops");
      var result = await findStops({
        name: payload.name,
        profile: profile,
        hafas_profile: payload.hafas_profile,
        results: payload.results,
        language: i18n.locale,
      });
      if (result.data.status == 200) {
        if (!result.data.stops) {
          context.dispatch("showError", i18n.t("noStationsFound"), {
            root: true,
          });
        }

        return result.data.stops;
      }
    },
    async findStopsNearby(context, payload) {
      let profile;
      if (payload.hafas_profile === "gmaps") {
        profile = "gmaps";
      } else {
        profile = "hafas";
      }
      var findStopsNearby = firebase
        .functions()
        .httpsCallable("findStopsNearby");
      var result = await findStopsNearby({
        coords: payload.coords,
        profile: profile,
        hafas_profile: payload.hafas_profile,
        results: payload.results,
        language: i18n.locale,
      });
      if (result.data.status == 200) {
        return result.data.stops;
      }
    },
    findJourneys: async function (context) {
      const params = context.rootState.params.section;
      context.commit("setIsLoadingJourneys", true);
      context.commit("setJourneys", null);
      context.commit("setSelectedJourney", null);
      context.commit("setEarlierRef", null);
      context.commit("setLaterRef", null);
      context.dispatch("disablePrice");
      var findJourneys = firebase.functions().httpsCallable("findJourneys");
      try {
        let profile;
        if (params.hafas_profile === "gmaps") {
          profile = "gmaps";
        } else {
          profile = "hafas";
        }
        var result = await findJourneys({
          from: params.startpoint,
          via: context.state.via.name != "" ? context.state.via : null,
          to: params.endpoint,
          profile: profile,
          hafas_profile: params.hafas_profile,
          starttime:
            context.state.arrivalDeparture === "departure"
              ? context.state.datetime
              : "",
          endtime:
            context.state.arrivalDeparture === "arrival"
              ? context.state.datetime
              : "",
          language: i18n.locale,
        });
        if (result.data.status == 200) {
          const response = result.data.result;
          context.commit("setJourneys", response.journeys);
          context.commit("setEarlierRef", response.earlierRef);
          context.commit("setLaterRef", response.laterRef);
        }
      } catch (error) {
        console.error(error);
        context.dispatch("showError", error.toString(), { root: true });
      }

      context.commit("setIsLoadingJourneys", false);
    },
    findPrices: async function (context) {
      await context.dispatch("disablePrice");
      context.commit("setAddPrice", true);
      context.commit("setIsLoadingPrices", true);
      const prices = await new Promise(async (res) => {
        context.commit("setCancelFindPrices", res);
        let prices;
        try {
          let departure, from, to;
          const mode = context.rootState.params.section?.mode;
          if (!context.getters.findPricesEnabled)
            throw new Error("Error: findPricesEnabled is false!");
          if (
            mode === "ptm" ||
            (context.rootState.params.isEditing && mode === "pt")
          ) {
            const markers = context.rootState.router.markers;
            departure =
              markers[0].station.departure ||
              markers[0].station.plannedDeparture;
            from = {
              lat: markers[0].lat,
              lng: markers[0].lng,
            };
            to = {
              lat: markers[markers.length - 1].lat,
              lng: markers[markers.length - 1].lng,
            };
          } else if (mode === "pt") {
            if (!context.state.selectedJourney)
              throw new Error("Error: no selectedJourney!");
            departure = context.state.selectedJourney.legs[0].plannedDeparture;
            from = {
              lat: context.state.selectedJourney.legs[0].origin.location
                .latitude,
              lng: context.state.selectedJourney.legs[0].origin.location
                .longitude,
            };
            to = {
              lat: context.state.selectedJourney.legs[
                context.state.selectedJourney.legs.length - 1
              ].destination.location.latitude,
              lng: context.state.selectedJourney.legs[
                context.state.selectedJourney.legs.length - 1
              ].destination.location.longitude,
            };
          } else if (mode !== "stay") {
            const params = context.rootState.params.section;
            departure = params.starttime;
            from = { name: params.startpoint.name };
            to = { name: params.endpoint.name };
          }
          if (!from && !to) throw new Error("Error: from or to empty!");

          const isInFuture =
            departure && DateTime.fromISO(departure) > DateTime.now();
          var result = await firebase
            .functions()
            .httpsCallable("getOEBBPrices")({
            from,
            to,
            when: isInFuture ? departure : null,
            results: isInFuture ? 1 : null,
            withVC: true,
          });
          if (result.data.status == 200) {
            const tickets = [];
            for (const journey of result.data.journeys) {
              if (journey.tickets?.offers) {
                for (const offer of journey.tickets.offers) {
                  const title = offer.titleRaw?.de;
                  const price = offer.price?.total;
                  if (
                    !tickets.find(
                      (ticket) => ticket.title == title && ticket.price == price
                    )
                  ) {
                    tickets.push({
                      title,
                      price,
                      connection: journey.tickets.connection.sections.filter(
                        (section) => section.type == "journey"
                      ),
                    });
                  }
                }
              }
            }
            prices = tickets;
          }
        } catch (error) {
          console.error(error);
          context.dispatch("showError", error.toString(), { root: true });
        }
        context.commit("setCancelFindPrices", null);
        res(prices);
      });
      if (prices && prices.length > 0) {
        context.commit("setPrices", prices);
        context.commit("setSelectedPrice", 0);
        context.commit(
          "params/setSectionProp",
          {
            key: "ticketPrice",
            value: prices[0].price,
          },
          { root: true }
        );
        if (context.rootState.params.section.withTickets) {
          context.dispatch("params/setTicketPriceSaved", prices[0].price, {
            root: true,
          });
        }
      } else {
        context.commit("setSelectedPrice", "manual");
        context.commit(
          "params/setSectionProp",
          {
            key: "ticketPrice",
            value: 0,
          },
          { root: true }
        );
        if (context.rootState.params.section.withTickets) {
          context.dispatch("params/setTicketPriceSaved", 0, {
            root: true,
          });
        }
        context.dispatch("showError", i18n.t("noPricesFound"), {
          root: true,
        });
      }
      context.commit("setIsLoadingPrices", false);
    },
    cancelFindPrices: function (context) {
      const cancelFindPrices = context.state.cancelFindPrices;
      if (cancelFindPrices) {
        cancelFindPrices();
        context.commit("setCancelFindPrices", null);
      }
    },
    disablePrice(context) {
      context.dispatch("cancelFindPrices");
      context.commit("disablePrice");
      context.commit(
        "params/setSectionProp",
        {
          key: "ticketPrice",
          value: null,
        },
        { root: true }
      );
    },
    clear(context) {
      context.commit("clear");
      context.dispatch("disablePrice");
      context.dispatch("cancelFindStartpoint");
      context.dispatch("cancelFindVia");
      context.dispatch("cancelFindEndpoint");
      context.dispatch("cancelGetVehicleComposition");
    },
    getVehicleComposition: async function (context, payload) {
      const source = payload?.source ?? "oebb-live";
      if (source !== "oebb-live") throw new Error("Error: source invalid!");
      if (!context.getters.getVehicleCompositionEnabled)
        throw new Error("Error: getVehicleCompositionEnabled is false!");

      context.commit("setIsGettingVehicleComposition", true);

      const vehicleComposition = await new Promise(async (res) => {
        context.commit("setCancelGetVehicleComposition", res);
        let vehicleComposition;
        try {
          if (source === "oebb-live") {
            if (!payload.oebbStation)
              throw new Error("Error: oebbStation missing!");
            let station = payload.oebbStation;
            let date, trainNr;
            const mode = context.rootState.params.section.mode;
            if (
              mode === "ptm" ||
              (context.rootState.params.isEditing && mode === "pt")
            ) {
              const markers = context.rootState.router.markers;
              date = DateTime.fromISO(
                markers[0].station.plannedDeparture ||
                  markers[0].station.departure
              ).toISODate();
              trainNr = context.rootState.params.section.ptm.number;
            } else if (mode === "pt") {
              const selectedJourney = context.state.selectedJourney;
              date = DateTime.fromISO(
                selectedJourney.legs[0].plannedDeparture ||
                  selectedJourney.legs[0].departure
              ).toISODate();
              trainNr = selectedJourney.legs[0].line?.fahrtNr;
            }

            if (!date && !trainNr)
              throw new Error("Error: date or trainNr empty!");

            const result = await firebase
              .functions()
              .httpsCallable("getOEBBComposition")({
              date,
              trainNr,
              station,
            });
            if (result.data.status == 200) {
              vehicleComposition = result.data.vehicleComposition;
            } else if (result.data.status === 404) {
              vehicleComposition = [];
            }
          }
        } catch (error) {
          console.error(error);
          context.dispatch("showError", error.toString(), { root: true });
        }
        context.commit("setCancelGetVehicleComposition", null);
        res(vehicleComposition);
      });
      if (vehicleComposition && vehicleComposition.length > 0) {
        context.commit(
          "params/setSectionProp",
          {
            key: "vehicleComposition",
            value: vehicleComposition,
          },
          { root: true }
        );
      } else if (vehicleComposition && vehicleComposition.length === 0) {
        context.dispatch("showError", "TODO: Handle not reported", {
          root: true,
        });
      }
      context.commit("setIsGettingVehicleComposition", false);
    },
    cancelGetVehicleComposition: function (context) {
      const cancelGetVehicleComposition =
        context.state.cancelGetVehicleComposition;
      if (cancelGetVehicleComposition) {
        cancelGetVehicleComposition();
        context.commit("setCancelGetVehicleComposition", null);
      }
    },
  },
};
