<template>
  <b-row>
    <b-card
      v-if="profile"
      no-body
      class="overflow-hidden mx-1 mb-1 shadow-sm flex-grow-1"
    >
      <b-row no-gutters>
        <b-col
          v-if="profile.picture"
          cols="3"
          class="pl-2 py-2 align-self-center"
        >
          <b-card-img-lazy
            style="aspect-ratio: 1/1; object-fit: cover"
            :src="profile.pictureUrl"
            :alt="$t('profilePicture')"
            class="rounded-circle shadow-sm"
          ></b-card-img-lazy>
        </b-col>
        <b-col :cols="profile.picture ? 9 : 12">
          <b-card-body
            :title="
              $t('helloX', {
                name: profile.firstName,
              })
            "
          >
            <h6 class="card-subtitle text-muted mb-2">
              <i class="fas fa-ruler fa-fw mr-2"></i>{{ lengthText }}
            </h6>
            <div class="w-100 my-1">
              <b-link :to="{ name: 'Settings' }" class="card-link"
                ><i class="fas fa-cog fa-fw mr-2"></i
                >{{ $t("settings") }}</b-link
              >
            </div>
            <div class="w-100">
              <b-link :to="{ name: 'StoreMeta' }" class="card-link"
                ><i class="fas fa-database fa-fw mr-2"></i
                >{{ $t("store") }}</b-link
              >
            </div>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card></b-row
  >
</template>

<script>
import { formatLength } from "../lib/helpers";
export default {
  name: "tl-profile",
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    profile() {
      return this.$store.state.profile.data;
    },
    lengthText() {
      return formatLength(this.profile.length);
    },
  },
};
</script>

<style lang="scss" scoped></style>
