<template>
  <b-row class="my-1">
    <label>{{ $t("tracks") }}</label>
    <div class="w-100" v-if="tracks">
      <b-form-group>
        <div
          v-for="(track, i) in tracks"
          class="d-flex align-items-center"
          :class="i !== 0 ? 'mt-1' : null"
          :key="i"
        >
          <b-form-checkbox
            class="flex-grow-1"
            :key="track.track_id"
            :value="track.track_id"
            v-model="selectedTracks"
          >
            {{ track.name }}
          </b-form-checkbox>
          <b-button variant="outline-danger" @click="remove(i)"
            ><i class="fas fa-times"></i
          ></b-button>
        </div>
      </b-form-group>
    </div>
    <div v-if="isUploadingTrack" class="w-100 mb-1">
      <b-skeleton></b-skeleton>
    </div>
    <div class="d-flex">
      <b-form-file
        :value="trackerFile"
        @input="$store.commit('params/setTrackerFile', $event)"
        :placeholder="$t('trackerUploadPlaceholder')"
        :drop-placeholder="$t('dropFilePlaceholder')"
        :browse-text="$t('browse')"
        accept=".nmea"
      ></b-form-file>
      <b-button :disabled="!trackerFile" @click="uploadTrack" class="ml-1">
        <i class="fas fa-upload"></i>
      </b-button>
    </div>
  </b-row>
</template>

<script>
export default {
  name: "tl-tracks",
  props: {},
  computed: {
    trackerFile() {
      return this.$store.state.params.trackerFile;
    },
    isUploadingTrack() {
      return this.$store.state.params.isUploadingTrack;
    },
    tracks() {
      return this.$store.state.sections.tracks;
    },
    selectedTracks: {
      get() {
        return this.$store.state.sections.selectedTracks;
      },
      set(value) {
        this.$store.commit("sections/setSelectedTracks", value);
      },
    },
  },
  methods: {
    uploadTrack() {
      this.$store.dispatch("params/uploadTrack");
    },
  },
};
</script>

<style scoped></style>
