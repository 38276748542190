import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

//import OneDrive from "../plugins/OneDrive.cjs";
import msalConfig from "../plugins/msalConfig";
import { DateTime } from "luxon";
import tz_lookup from "tz-lookup";

export default {
  namespaced: true,
  state: () => ({
    uploadFiles: [],
    allow_read: "none",
    isUploading: false,
    isSettingAsTripImage: false,
    list: null,
    listener: null,
    isDeleting: null,
    showGalleryOnMap: false,
  }),
  mutations: {
    setUploadFiles(state, uploadFiles) {
      state.uploadFiles = uploadFiles;
    },
    setAllowRead(state, allow_read) {
      state.allow_read = allow_read;
    },
    setIsUploading(state, isUploading) {
      state.isUploading = isUploading;
    },
    setIsDeleting(state, isDeleting) {
      state.isDeleting = isDeleting;
    },
    setList(state, list) {
      state.list = list;
    },
    replaceListItem(state, item) {
      const list = state.list;
      if (list != null) {
        const index = list.findIndex(
          (element) => element.media_id === item.media_id
        );
        if (index !== -1) {
          Vue.set(state.list, index, item);
          return true;
        } else {
          return false;
        }
      }
    },
    setListener(state, listener) {
      state.listener = listener;
    },
    detachListener(state) {
      if (state.listener != null) {
        state.listener();
      }
    },
    setIsSettingAsTripImage(state, isSettingAsTripImage) {
      state.isSettingAsTripImage = isSettingAsTripImage;
    },
    setShowGalleryOnMap(state, showGalleryOnMap) {
      state.showGalleryOnMap = showGalleryOnMap;
    },
  },
  actions: {
    async load(context) {
      if (context.rootState.trips.selected != null) {
        const uid = context.rootGetters.getUser.uid;
        const trip_id = context.rootState.trips.selected.trip_id;

        const response = await firebase
          .functions()
          .httpsCallable("getTripMedia")({ uid, trip_id });

        const list = response.data;
        context.commit("setList", list);
      } else {
        context.commit("setList", null);
      }
    },
    async setAsTripImage(context, image) {
      if (
        image &&
        context.rootGetters.isLoggedIn &&
        context.rootGetters.isMyTrip &&
        context.rootState.trips.selected != null
      ) {
        context.commit("setIsSettingAsTripImage", true);

        const db = firebase.firestore();
        const uid = context.rootState.user.uid;
        const trip_id = context.rootState.trips.selected.trip_id;

        await db
          .collection("users")
          .doc(uid)
          .collection("trips")
          .doc(trip_id)
          .update({
            image: {
              media_id: image.media_id,
            },
          });
        await context.dispatch(
          "sections/load",
          { clearTracks: false },
          { root: true }
        );
        context.commit(
          "setToast",
          {
            title: "info",
            tTitle: true,
            text: "tripImageSet",
            tText: true,
            variant: "primary",
          },
          { root: true }
        );
        context.commit("setIsSettingAsTripImage", false);
      }
    },
    async relocate(context, payload) {
      if (
        context.rootGetters.isLoggedIn &&
        context.rootGetters.isMyTrip &&
        payload.item &&
        payload.location
      ) {
        try {
          const db = firebase.firestore();
          const uid = context.rootState.user.uid;
          const trip_id = context.rootState.trips.selected.trip_id;
          const item = payload.item;
          await db
            .collection("users")
            .doc(uid)
            .collection("trips")
            .doc(trip_id)
            .collection("media")
            .doc(item.media_id)
            .update({
              location: payload.location,
            });
          item.location = payload.location;
          context.commit("replaceListItem", item);
        } catch (error) {
          console.error(error);
          context.dispatch("showError", error.toString(), { root: true });
        }
      }
    },
    async resetLocation(context, item) {
      if (item && item.originalLocation) {
        await context.dispatch("relocate", {
          item: item,
          location: item.originalLocation,
        });
      }
    },
    async delete(context, items) {
      const trip = context.rootState.trips.selected;
      if (
        context.rootGetters.isLoggedIn &&
        items &&
        trip &&
        context.rootGetters.isMyTrip
      ) {
        context.commit("setIsDeleting", true);

        const db = firebase.firestore();
        const uid = context.rootState.user.uid;
        const trip_id = context.rootState.trips.selected.trip_id;
        const tripRef = db
          .collection("users")
          .doc(uid)
          .collection("trips")
          .doc(trip_id);

        const promises = [];

        for (const item of items) {
          promises.push(
            (async function () {
              await tripRef.collection("media").doc(item.media_id).delete();
            })()
          );
        }

        await Promise.all(promises);

        if (
          trip.image &&
          items.findIndex((value) => value.media_id == trip.image.media_id) !==
            -1
        ) {
          await tripRef.update({
            image: firebase.firestore.FieldValue.delete(),
          });
          context.dispatch(
            "sections/load",
            {
              clearTracks: false,
            },
            { root: true }
          );
        } else {
          context.dispatch("load", false);
        }

        context.commit(
          "setToast",
          {
            title: "info",
            tTitle: true,
            text: "mediaItemDeleted",
            tText: true,
            variant: "primary",
          },
          { root: true }
        );
        context.commit("setIsDeleting", false);
      }
    },
    async setPermissions(context, payload) {
      if (
        context.rootGetters.isLoggedIn &&
        context.rootState.trips.selected != null &&
        payload &&
        payload.items &&
        payload.allow_read
      ) {
        try {
          const db = firebase.firestore();
          const uid = context.rootState.user.uid;
          const trip_id = context.rootState.trips.selected.trip_id;
          const items = payload.items;
          const allow_read = payload.allow_read;

          const promises = [];
          for (const item of items) {
            if (allow_read !== item.allow_read) {
              promises.push(
                (async function () {
                  const ref = db
                    .collection("users")
                    .doc(uid)
                    .collection("trips")
                    .doc(trip_id)
                    .collection("media")
                    .doc(item.media_id);

                  await ref.update({
                    allow_read: allow_read,
                  });

                  item.allow_read = allow_read;

                  context.commit("replaceListItem", item);
                  context.commit(
                    "setToast",
                    {
                      title: "info",
                      tTitle: true,
                      text: "allowReadChanged",
                      tText: true,
                      variant: "primary",
                    },
                    { root: true }
                  );
                })()
              );
            }
          }

          await Promise.all(promises);
        } catch (error) {
          console.error(error);
          context.dispatch("showError", error.toString(), { root: true });
        }
      }
    },
    async pick(context) {
      if (
        context.rootGetters.isLoggedIn &&
        context.rootGetters.isMyTrip &&
        context.rootState.trips.selected != null
      ) {
        context.commit("setIsUploading", true);
        const allow_read = context.state.allow_read;
        const odOptions = {
          clientId: msalConfig.auth.clientId,
          action: "query",
          multiSelect: true,
          viewType: "files",
          advanced: {
            redirectUri: msalConfig.auth.odRedirectUri,
            endpointHint: "api.onedrive.com",
            queryParameters: "expand=thumbnails",
            loginHint: context.rootState.msal.username,
            isConsumerAccount: true,
            filter: "photo,video,.heic,.mov,.jpg,.jpeg,.png,.mp4",
          },
          success: async function (response) {
            const db = firebase.firestore();
            const uid = context.rootState.user.uid;
            const trip_id = context.rootState.trips.selected.trip_id;

            const promises = [];
            for (const file of response.value) {
              promises.push(
                (async function () {
                  const ref = db
                    .collection("users")
                    .doc(uid)
                    .collection("trips")
                    .doc(trip_id)
                    .collection("media")
                    .doc();
                  const item = {
                    allow_read: allow_read,
                    created: file.image
                      ? file.photo.takenDateTime
                      : DateTime.fromISO(file.fileSystemInfo.createdDateTime)
                          .setZone(
                            file.location
                              ? tz_lookup(
                                  file.location.latitude,
                                  file.location.longitude
                                )
                              : "Europe/Vienna"
                          )
                          .toISO({ includeOffset: false }),
                    fileName: file.name,
                    mimeType: file.file.mimeType,
                    fileType: file.image ? "photo" : "video",
                    location: file.location
                      ? {
                          lat: file.location.latitude,
                          lng: file.location.longitude,
                        }
                      : {
                          lat: 0,
                          lng: 0,
                        },
                    originalLocation: file.location
                      ? {
                          lat: file.location.latitude,
                          lng: file.location.longitude,
                        }
                      : {
                          lat: 0,
                          lng: 0,
                        },
                    timestamp: DateTime.now().toISO(),
                    size: file.image
                      ? {
                          height: file.image.height,
                          width: file.image.width,
                        }
                      : null,
                    odId: file.id,
                  };

                  if (!item.created) {
                    item.created = file.createdDateTime;
                  }
                  await ref.set(item);
                })()
              );
            }

            await Promise.all(promises);
            await context.dispatch("load", false);
            context.commit("setIsUploading", false);
          },
          cancel: function () {
            context.commit("setIsUploading", false);
          },
          error: function () {
            context.commit("setIsUploading", false);
          },
        };
        window.OneDrive.open(odOptions);
      }
    },
  },
};
