<template>
  <b-list-group class="accordion">
    <b-list-group-item
      button
      v-for="journey in journeys"
      @click="$emit('selected', journey)"
      :active="isSelected(journey)"
    >
      <span
        >{{ getTime(journey.legs[0].plannedDeparture) }}
        {{
          getDelay(journey.legs[0].plannedDeparture, journey.legs[0].departure)
        }}
        {{ journey.legs[0].origin.name }}</span
      >
      <span
        v-show="!isSelected(journey) && getChanges(journey.legs) != '0x'"
        class="info small"
      >
        <i class="far fa-clock"></i>
        {{
          getDuration(
            journey.legs[0].plannedDeparture,
            journey.legs[journey.legs.length - 1].plannedArrival
          )
        }}
        <i class="fas fa-exchange-alt margin-left"></i>
        {{ getChanges(journey.legs) }}
      </span>
      <span class="info small" v-show="getChanges(journey.legs) == '0x'"
        ><b>{{ journey.legs[0].line.name }}</b> <i class="fa">&#xf061;</i>
        {{ journey.legs[0].direction }}</span
      >
      <b-collapse
        :visible="isExpanded(journey)"
        accordion="my-accordion"
        role="tabpanel"
      >
        <div
          v-for="(leg, i) in journey.legs"
          :class="i != journey.legs.length - 1 ? ['mb-2'] : []"
        >
          <!--<br v-if="i != 0" />-->
          <span v-show="i != 0" class="small"
            >{{ getTime(leg.plannedDeparture) }}
            {{ getDelay(leg.plannedDeparture, leg.departure) }}
            {{ leg.origin.name }}
          </span>
          <span class="info small"
            ><b>{{ leg.line.name }}</b> <i class="fa">&#xf061;</i>
            {{ leg.direction }}</span
          >
          <span v-show="i != journey.legs.length - 1" class="small"
            >{{ getTime(leg.plannedArrival) }}
            {{ getDelay(leg.plannedArrival, leg.arrival) }}
            {{ leg.destination.name }}</span
          >
        </div>
      </b-collapse>
      <span
        >{{ getTime(journey.legs[journey.legs.length - 1].plannedArrival) }}
        {{
          getDelay(
            journey.legs[journey.legs.length - 1].plannedArrival,
            journey.legs[journey.legs.length - 1].arrival
          )
        }}
        {{ journey.legs[journey.legs.length - 1].destination.name }}</span
      >
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "tl-journey-picker",
  props: {
    journeys: Array,
    selectedJourney: Object,
  },
  methods: {
    getTime(time) {
      return DateTime.fromISO(time, { setZone: true }).toFormat("HH:mm");
    },
    getDelay(planned, real) {
      if (this.$store.state.params.section?.hafas_profile === "gmaps") {
        return "";
      }

      if (!real) {
        return "❌";
      }

      const diff = DateTime.fromISO(real)
        .diff(DateTime.fromISO(planned))
        .shiftTo("minutes");

      if (diff.minutes == 0) {
        return "";
      }

      if (diff.minutes < 0) {
        return diff.toFormat("m");
      }
      return "+" + diff.toFormat("m");
    },
    getDuration(time1, time2) {
      var diff = DateTime.fromISO(time2)
        .diff(DateTime.fromISO(time1))
        .shiftTo("hours", "minutes");
      if (diff.hours >= 1) {
        return diff.toFormat("h:mm") + "h";
      } else {
        return diff.toFormat("m") + "min";
      }
    },
    getChanges(legs) {
      return parseInt(legs.length - 1) + "x";
    },
    isSelected(journey) {
      return this.selectedJourney != undefined
        ? this.selectedJourney.refreshToken == journey.refreshToken
        : false;
    },
    isExpanded(journey) {
      return this.isSelected(journey) && this.getChanges(journey.legs) != "0x";
    },
  },
};
</script>

<style scoped>
.list-group {
  width: 100% !important;
}

span {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.info {
  padding-left: 15px;
}

.margin-left {
  margin-left: 5px;
}

hr {
  border-top-color: white !important;
}
</style>
