<template>
  <div id="app">
    <router-view v-if="!$store.state.loading"></router-view>
    <b-spinner
      v-else
      label="Spinning"
      class="spinner"
      variant="primary"
    ></b-spinner>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/functions";

export default {
  name: "app",
  mounted() {
    this.$nextTick(function () {
      resetHeight();
      //vm.$refs.lmap.refreshMap();
      window.addEventListener("resize", function () {
        resetHeight();
      });
    });
    if (this.$cookies.isKey("locale")) {
      this.$i18n.locale = this.$cookies.get("locale");
    }
  },
  watch: {
    "$i18n.locale": function (newLocale) {
      this.$cookies.set("locale", newLocale);
    },
  },
  methods: {
    async setTripAccess() {
      var setTripAccess = firebase.functions().httpsCallable("setTripAccess");
      var result = await setTripAccess({
        trip_id: "QCSk4keTrllObGt2JmKz",
        access_type: "all",
      });
      console.log(result);
    },
    // async fetchCreationDates() {
    //   var fetchCreationDates = firebase
    //     .functions()
    //     .httpsCallable("fetchCreationDates");
    //   var result = await fetchCreationDates();
    //   console.log(result);
    // },
    // async createPolylines() {
    //   var createPolylines = firebase
    //     .functions()
    //     .httpsCallable("createPolylines");
    //   var result = await createPolylines();
    //   console.log(result);
    // },
  },
  metaInfo() {
    return {
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} | travellog` : "travellog";
      },
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
};

function resetHeight() {
  document.getElementById("app").style.height = window.innerHeight + "px";
}
</script>

<style lang="scss">
@import "./style/bootstrap.scss";
// ::-webkit-scrollbar {
//   width: 0px;
//   background: transparent;
// }

img.emoji {
  height: 1em;
  width: 1em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
}

@media only screen and (min-device-width: 736px) {
  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .tl-typeahead .list-group {
    &::-webkit-scrollbar-track {
      background-color: white !important;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: $gray-300;
    border-radius: 12px;
    border: 2px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: $gray-500;
  }
}

.draggable:hover {
  cursor: move;
}

.spinner {
  position: absolute;
  top: calc(50% - 2rem);
  left: calc(50% - 2rem);
  width: 4rem !important;
  height: 4rem !important;
}
</style>
