<template>
  <div style="height: 100%">
    <div class="panel-left pr-1 panel-flex" v-if="isDesktop">
      <div class="mb-1">
        <b-button
          variant="link"
          @click="$store.dispatch(!fromStore ? 'toTrip' : 'toUser')"
          class="text-decoration-none"
          ><i class="fas fa-arrow-left mr-1"></i>
          {{ $t(!fromStore ? "trip" : "overview") }}</b-button
        >
      </div>
      <div class="section-picker" ref="section_picker_div">
        <portal-target name="portal-desktop" slim></portal-target>
      </div>
    </div>

    <b-sidebar
      v-else
      id="sidebar"
      backdrop
      shadow
      width="200px"
      @shown="sidebarShown"
    >
      <b-button
        slot="title"
        variant="link"
        @click="$store.dispatch(!fromStore ? 'toTrip' : 'toUser')"
        class="text-decoration-none"
        ><i class="fas fa-arrow-left mr-1"></i>
        {{ $t(!fromStore ? "trip" : "overview") }}</b-button
      >
      <div class="p-1">
        <portal-target name="portal-mobile" slim></portal-target>
      </div>
    </b-sidebar>

    <portal :to="portalName">
      <tl-section-picker
        v-if="sections != null"
        :selected_section_id="$route.params.section_id"
        :selected_add="
          !fromStore
            ? $route.name == 'AddSection'
            : $route.name == 'AddSectionStore'
        "
        @add="
          $router.push({ name: !fromStore ? 'AddSection' : 'AddSectionStore' })
        "
        @selected="selectedSection"
        @loadNext="loadNext"
        :isLoadingNext="isLoadingNext"
        :showLoadNext="!!startAfter"
        :dragDisabled="fromStore"
      ></tl-section-picker>
    </portal>

    <div
      class="edit-content pl-1"
      :style="{ width: isDesktop ? '65%' : '100%' }"
    >
      <router-view>
        <b-button
          v-if="!isDesktop"
          v-b-toggle.sidebar
          slot="button-sidebar"
          variant="link"
          class="text-decoration-none mr-1"
          style="color: black; font-size: 20px"
          ><i class="fas fa-bars" key="icon-bars"></i
        ></b-button>
      </router-view>
    </div>
  </div>
</template>

<script>
import tlSectionPicker from "../components/tlSectionPicker";
import { Portal, PortalTarget } from "portal-vue";

export default {
  components: {
    "tl-section-picker": tlSectionPicker,
    Portal,
    PortalTarget,
  },
  computed: {
    sections() {
      return this.$store.state.sections.list;
    },
    isDesktop() {
      return this.$store.state.isDesktop;
    },
    portalName() {
      return this.isDesktop ? "portal-desktop" : "portal-mobile";
    },
    fromStore() {
      return this.$store.state.sections.fromStore;
    },
    isLoadingNext() {
      return this.$store.state.sections.isLoadingNext;
    },
    startAfter() {
      return this.$store.state.sections.startAfter;
    },
  },
  methods: {
    selectedSection(section) {
      this.$router.push({
        name: !this.fromStore ? "EditSection" : "EditSectionStore",
        params: { section_id: section.section_id },
      });
    },
    loadNext() {
      this.$store.dispatch("sections/loadNext");
    },
    sidebarShown() {
      // const scrollContainer = document.getElementsByClassName("b-sidebar-body");
      // if (scrollContainer != undefined) {
      //   scrollContainer[0].scrollTop = scrollContainer[0].scrollHeight;
      // }
    },
  },
  watch: {
    sections: {
      handler: function (newS) {
        if (newS != null && !this.fromStore) {
          this.$nextTick(function () {
            if (this.$refs.section_picker_div != undefined) {
              this.$refs.section_picker_div.scrollTop =
                this.$refs.section_picker_div.scrollHeight;
            }
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.sections != null) {
      this.$nextTick(function () {
        if (this.$refs.section_picker_div != undefined) {
          this.$refs.section_picker_div.scrollTop =
            this.$refs.section_picker_div.scrollHeight;
        }
      });
    }
  },
};
</script>

<style scoped>
.section-picker {
  overflow-y: auto;
  flex-grow: 1;
}

.panel-left {
  width: 35%;
  float: left;
  height: 100%;
}

.panel-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.edit-content {
  float: left;
  height: 100%;
}

::v-deep .b-sidebar {
  height: 100% !important;
}
</style>
