<template>
  <b-row v-if="mode === 'stay'" class="my-1 justify-content-center">
    <b-button
      @click="setStayCoords"
      variant="outline-secondary"
      key="stay-button"
    >
      <i class="fas fa-map-marker-alt" key="stay"></i>
    </b-button>
  </b-row>
  <b-row v-else-if="mode === 'pt'" class="my-1 justify-content-center">
    <b-button
      @click="findStartpointStops"
      class="mb-1 mt-2 w-100"
      variant="secondary"
      size="sm"
      key="origin-button"
    >
      {{ $t("sectionStartpointName") }}
    </b-button>
    <b-button
      v-if="hafas_profile !== 'gmaps'"
      @click="findViaStops"
      class="mb-1 w-100"
      variant="secondary"
      size="sm"
      key="via-button"
    >
      {{ $t("via") }}
    </b-button>
    <b-button
      @click="findEndpointStops"
      class="w-100"
      variant="secondary"
      size="sm"
      key="destination-button"
    >
      {{ $t("sectionEndpointName") }}
    </b-button>
    <b-button
      v-if="marker"
      @click="$emit('remove')"
      class="mb-1 mt-2 w-100"
      variant="secondary"
      size="sm"
      key="remove-button"
    >
      {{ $t("remove") }}
    </b-button>
  </b-row>
  <b-row v-else class="justify-content-center">
    <b-button
      class="mt-2"
      @click="routerAddFirstMarker"
      variant="outline-secondary"
      size="sm"
      key="router-first-button"
    >
      <i class="fas fa-step-backward" key="router-first"></i>
    </b-button>
    <b-button
      @click="routerAddLastMarker"
      class="ml-1 mt-2"
      variant="outline-secondary"
      size="sm"
      key="router-last-button"
    >
      <i class="fas fa-step-forward" key="router-last"></i>
    </b-button>
  </b-row>
</template>

<script>
export default {
  name: "tl-track-popup",
  computed: {
    mode() {
      return this.$store.state.params.section.mode;
    },
    hafas_profile() {
      return this.$store.state.params.section.hafas_profile;
    },
  },
  data() {
    return {
      properties: null,
      coords: null,
    };
  },
  emits: ["remove"],
  props: ["marker"],
  methods: {
    setStayCoords() {
      this.$store.commit("params/setSectionProp", {
        key: "coords",
        value: this.coords,
      });
      this.$store.commit("params/setSectionProp", {
        key: "starttime",
        value: this.properties.localTime,
      });
      this.$store.commit("params/setSectionProp", {
        key: "endtime",
        value: this.properties.localTime,
      });
    },
    routerAddFirstMarker() {
      this.$store.dispatch("router/addMarkerAt", {
        index: 0,
        marker: this.coords,
        station: {
          name: "",
          arrival: this.properties.localTime,
          plannedArrival: "",
          platformArrival: "",
          departure: this.properties.localTime,
          plannedDeparture: "",
          platformDeparture: "",
        },
      });
      this.$store.commit("params/setSectionProp", {
        key: "starttime",
        value: this.properties.localTime,
      });
    },
    routerAddLastMarker() {
      this.$store.dispatch("router/addMarker", {
        marker: this.coords,
        station: {
          name: "",
          arrival: this.properties.localTime,
          plannedArrival: "",
          platformArrival: "",
          departure: this.properties.localTime,
          plannedDeparture: "",
          platformDeparture: "",
        },
      });
      this.$store.commit("params/setSectionProp", {
        key: "endtime",
        value: this.properties.localTime,
      });
    },
    async findStartpointStops() {
      if (!this.marker)
        this.$store.commit("params/pt/setArrivalDeparture", "departure");
      this.$nextTick(() => {
        if (!this.marker)
          this.$store.commit("params/setSectionProp", {
            key: "starttime",
            value: this.properties.localTime,
          });
        if (!this.marker)
          this.$store.commit(
            "params/pt/setDatetime",
            this.properties.localTime
          );
        this.$store.dispatch("params/pt/findStartpointStops", {
          coords: this.coords,
        });
      });
    },
    async findViaStops() {
      this.$store.dispatch("params/pt/findViaStops", {
        coords: this.coords,
      });
    },
    async findEndpointStops() {
      this.$store.dispatch("params/pt/findEndpointStops", {
        coords: this.coords,
      });
    },
  },
};
</script>

<style></style>
