<template>
  <div
    v-if="sections != null"
    style="height: 100%; display: flex; flex-direction: column"
  >
    <div class="mb-1" v-if="$store.getters.isMyTrip">
      <b-button
        variant="link"
        :to="{ name: 'User' }"
        class="text-decoration-none"
        ><i class="fas fa-arrow-left mr-1"></i> {{ $t("overview") }}</b-button
      >
    </div>
    <div style="overflow-y: auto; flex: 1" id="sections_container">
      <div id="trip_top"></div>
      <b-card
        v-if="trip != null"
        no-body
        class="overflow-hidden mx-1 mb-1 shadow-sm"
      >
        <b-row no-gutters>
          <b-col v-if="trip.image != null" md="5">
            <b-card-img-lazy
              style="height: 100%; object-fit: cover"
              :src="trip.image.thumbnailLarge"
              blank-width="1000"
              blank-height="750"
              :alt="trip.name"
              class="rounded-0"
            ></b-card-img-lazy>
          </b-col>
          <b-col :md="trip.image != null ? 7 : 12">
            <b-card-body :title="trip.name">
              <h6 class="card-subtitle text-muted mb-1">
                {{ trip.dateText }}
              </h6>
              <h6 class="card-subtitle text-muted">{{ trip.lengthText }}</h6>
              <b-card-text
                v-if="showCountryFlags && trip.countries"
                class="mb-1"
                v-html="trip.countriesHtml"
              >
              </b-card-text>
              <b-card-text v-if="trip.description != undefined" class="mb-2">
                {{ trip.description }}
              </b-card-text>
              <b-card-text v-if="trip.ongoing" class="mb-2 text-danger">
                <i class="fas fa-person-walking-luggage mr-1"></i>
                {{ $t("ongoing") }}
              </b-card-text>
              <b-link
                v-if="$store.getters.isMyTrip"
                :to="{ name: 'EditTripMeta' }"
                class="card-link"
                ><i class="fas fa-pen mr-2"></i>{{ $t("edit") }}</b-link
              >
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      <tl-sections
        ref="tl_sections"
        :sections="sections"
        :trip="trip"
      ></tl-sections>
    </div>
  </div>

  <!--<div v-else style="display: flex; overflow: hidden;">
    <div style="flex: 2; padding-left: 10px; padding-right: 5px;">
      <b-skeleton id="skeleton-line"></b-skeleton>
    </div>
    <div style="flex: 28; padding-left: 5px; padding-right: 10px;">
      <template v-for="i in 4">
        <div style="height: 30px;">
          <b-skeleton></b-skeleton>
        </div>
        <div style="height: 63px;">
          <b-skeleton></b-skeleton>
        </div>
        <div v-if="i != 4" style="height: 30px; margin-bottom: 15px;">
          <b-skeleton></b-skeleton>
        </div>
        <div v-else style="height: 30px;">
          <b-skeleton></b-skeleton>
        </div>
      </template>
    </div>
  </div>-->
</template>

<script>
import tlSections from "../components/tlSections";
import VueScrollTo from "vue-scrollto";
import debounce from "debounce";
import { DateTime } from "luxon";

var shouldScroll = true;

export default {
  components: {
    "tl-sections": tlSections,
  },
  computed: {
    sections() {
      return this.$store.state.sections.list;
    },
    trip() {
      return this.$store.state.trips.selected;
    },
    day() {
      return this.$route.params.day;
    },
    showCountryFlags() {
      return this.$store.getters["profile/getShowCountryFlags"];
    },
  },
  methods: {
    scrollToDay(day) {
      if (day != undefined) {
        VueScrollTo.scrollTo("#day_" + day, 200, {
          container: "#sections_container",
        });
      } else {
        VueScrollTo.scrollTo("#trip_top", 200, {
          container: "#sections_container",
        });
      }
    },
    scrollToSection(section_id) {
      VueScrollTo.scrollTo("#section_" + section_id, 200, {
        container: "#sections_container",
      });
    },
    handleScroll: debounce(function () {
      if (this.trip != null && this.trip.days != undefined) {
        var currentDay = null;
        for (var day of this.trip.days) {
          if (document.getElementById("day_" + day.day + "_header") != null) {
            var offset =
              document
                .getElementById("day_" + day.day + "_header")
                .getBoundingClientRect().top -
              document
                .getElementById("sections_container")
                .getBoundingClientRect().top;
            if (offset < 10) {
              currentDay = day.day;
            }
          }
        }
        if (this.$route.params.day != currentDay) {
          shouldScroll = false;
          if (currentDay != null) {
            this.$router.replace({ params: { day: currentDay } });
          } else {
            this.$router.replace({
              path: `/user/${this.$store.getters.getUserParam}/trip/${this.$route.params.trip}`,
            });
          }
        }
      }
    }, 500),
  },
  watch: {
    sections: {
      handler: function (newVal) {
        if (newVal != null) {
          this.$nextTick(function () {
            if (this.$store.state.sections.shouldScroll) {
              this.scrollToDay(this.day);
            }
            this.$store.commit("sections/setShouldScroll", true);
            document
              .getElementById("sections_container")
              .addEventListener("scroll", this.handleScroll);
          });
        }
      },
      deep: true,
    },
    day(newDay) {
      if (shouldScroll) {
        this.$nextTick(function () {
          this.scrollToDay(newDay);
        });
      } else {
        shouldScroll = true;
      }
    },
  },
  mounted() {
    if (this.sections != null) {
      this.$nextTick(function () {
        document
          .getElementById("sections_container")
          .addEventListener("scroll", this.handleScroll);
        if (shouldScroll) {
          if (this.day != undefined) {
            this.$refs.tl_sections.expandDay(this.day);
            setTimeout(() => {
              this.scrollToDay(this.day);
            }, 1000);
          } else if (this.trip?.ongoing) {
            let ongoingSection;
            for (const section of this.sections) {
              const stop = section.stops[0];
              if (!stop.departure && !stop.plannedDeparture) continue;
              const departure = DateTime.fromISO(
                stop.departure || stop.plannedDeparture
              );
              if (departure <= DateTime.now()) {
                ongoingSection = section;
              } else {
                break;
              }
            }
            if (ongoingSection) {
              if (ongoingSection.days) {
                for (const day of ongoingSection.days) {
                  this.$refs.tl_sections.expandDay(day);
                }
                setTimeout(() => {
                  this.scrollToSection(ongoingSection.section_id);
                }, 1000);
              } else {
                this.scrollToSection(ongoingSection.section_id);
              }
            }
          }
        }
      });
    }
  },
  metaInfo() {
    const name = this.trip != null ? this.trip.name : this.$t("trip");
    const description =
      this.trip != null &&
      this.trip.description != null &&
      this.trip.description != ""
        ? this.trip.description
        : this.$t("tripOnTravellog");
    const thumbnail =
      this.trip != null && this.trip.image != null
        ? this.trip.image.thumbnailSmall
        : undefined;
    const image =
      this.trip != null && this.trip.image != null
        ? this.trip.image.thumbnailLarge
        : undefined;

    return {
      title: name,
      meta: [
        {
          name: "title",
          content: name,
        },
        {
          name: "description",
          content: description,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: "og:title",
          content: name,
        },
        {
          property: "og:image",
          content: thumbnail,
        },
        {
          property: "twitter:description",
          content: description,
        },
        {
          property: "twitter:title",
          content: name,
        },
        {
          property: "twitter:image",
          content: image,
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
      ],
    };
  },
};
</script>

<style scoped>
.skeleton {
  margin-top: 5px;
  margin-bottom: 5px;
}

/*.b-skeleton {
  height: 100%;
}*/

h3 {
  margin: 5px 10px;
}
</style>
