<template>
  <div v-if="!!tickets">
    <b-row class="my-1 px-1"
      ><b-form-checkbox v-model="withTickets" switch>
        {{ $t("withTicket") }}
      </b-form-checkbox>
    </b-row>
    <b-row v-if="withTickets" class="my-1 px-1"
      ><b-form-select
        :options="tickets"
        text-field="name"
        value-field="ticketId"
        v-model="ticketId"
      >
      </b-form-select>
    </b-row>
    <b-row v-if="withTickets" class="my-1 px-1">
      <label>{{ $t("moneySaved") }}</label>
      <b-input-group append="€">
        <b-form-input
          class="text-right"
          style="
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          "
          v-model="ticketPriceSaved"
          type="number"
          :step="0.01"
        ></b-form-input
      ></b-input-group>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "tl-with-tickets",
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    tickets() {
      return this.$store.state.tickets.data;
    },
    withTickets: {
      get() {
        return !!this.$store.state.params.section.withTickets;
      },
      set(value) {
        if (!value || !this.tickets) {
          this.$store.commit("params/setSectionProp", {
            key: "withTickets",
            value: null,
          });
        } else if (this.$store.state.params.section.ticketPrice != null) {
          this.$store.commit("params/setSectionProp", {
            key: "withTickets",
            value: [
              {
                ticketId: this.tickets[0].ticketId,
                ticketPriceSaved: this.$store.state.params.section.ticketPrice,
              },
            ],
          });
        } else {
          this.$store.commit("params/setSectionProp", {
            key: "withTickets",
            value: [
              {
                ticketId: this.tickets[0].ticketId,
                ticketPriceSaved: 0,
              },
            ],
          });
        }
      },
    },
    ticketPriceSaved: {
      get() {
        return this.$store.state.params.section.withTickets?.[0]
          .ticketPriceSaved;
      },
      set(value) {
        this.$store.dispatch("params/setTicketPriceSaved", value);
      },
    },
    ticketId: {
      get() {
        return this.$store.state.params.section.withTickets?.[0].ticketId;
      },
      set(value) {
        this.$store.dispatch("params/setTicketId", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
