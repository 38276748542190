<template>
  <div>
    <h1>travellog</h1>
    <template v-if="!user">
      <b-form-input
        v-model="email"
        type="email"
        placeholder="email"
      ></b-form-input>
      <b-form-input
        v-model="password"
        type="password"
        placeholder="password"
      ></b-form-input>
      <b-button @click="login" variant="primary">Login</b-button></template
    >
    <template v-else>
      <p>{{ user.email }}</p>
      <b-button @click="logout" variant="primary">Logout</b-button>
      <b-button @click="toUser" variant="primary">To user</b-button></template
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password,
      });
    },
    logout() {
      this.$store.dispatch("logout");
    },
    toUser() {
      this.$store.dispatch("toUser");
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style></style>
