<template>
  <div>
    <b-modal id="tlMediaViewer" size="xl" hide-header centered>
      <b-carousel
        controls
        :interval="0"
        background="transparent"
        style="text-shadow: 1px 1px 2px #333"
        v-model="index"
      >
        <b-carousel-slide v-for="item in list" :key="item.media_id">
          <template #img>
            <b-img-lazy
              v-if="item.fileType === 'photo'"
              class="w-100"
              style="object-fit: contain; max-height: 600px"
              :src="item.url"
              :alt="item.fileName"
            />
            <b-embed
              v-if="item.fileType === 'video'"
              type="video"
              controls
              autoplay
              :poster="item.thumbnailSmall"
            >
              <source :src="item.url" :type="item.mimeType" />
            </b-embed>
          </template>
        </b-carousel-slide>
      </b-carousel>
      <template #modal-footer>
        <label>{{ formatedDate }}</label>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DateTime from "luxon/src/datetime.js";

export default {
  name: "tlMediaViewer",
  computed: {
    list() {
      return this.$store.state.gallery.list;
    },
    formatedDate() {
      if (
        this.list &&
        this.list[this.index] &&
        this.list[this.index].timestamp
      ) {
        return DateTime.fromISO(this.list[this.index].created).toFormat(
          "dd.MM.yyyy HH:mm"
        );
      } else {
        return "";
      }
    },
  },
  methods: {
    open(item) {
      if (item && item.media_id && this.list) {
        this.index = this.list.findIndex((el) => el.media_id === item.media_id);
      }
      this.$bvModal.show("tlMediaViewer");
    },
  },
  data() {
    return {
      index: 0,
    };
  },
};
</script>

<style>
#tlMediaViewer .modal-dialog {
  max-width: 900px !important;
}

#tlMediaViewer .modal-dialog .modal-content {
  border: 0;
  border-radius: 0;
  background-color: transparent;
}

#tlMediaViewer .modal-dialog .modal-content .modal-body {
  padding: 0;
}

#tlMediaViewer .modal-dialog .modal-content .modal-footer {
  border: none;
  justify-content: center;
  color: white;
}

#tlMediaViewer
  .modal-dialog
  .modal-content
  .modal-body
  .slide
  .carousel-control-prev,
.carousel-control-next {
  top: 200px !important;
  bottom: 200px !important;
}
</style>
