<template>
  <b-container>
    <h1>tracker</h1>
    <p>{{ $t("trackerInfo") }}</p>
    <b-row class="my-2">
      <b-button @click="getStatus" variant="primary">{{
        $t("trackerCheckConnection")
      }}</b-button>
      <b-spinner
        v-if="isChecking"
        variant="primary"
        class="tracker-spinner"
      ></b-spinner>
    </b-row>
    <div v-if="isConnected">
      <b-row class="my-2">
        <hr class="solid" />
      </b-row>
      <b-row class="my-2">
        <b-button @click="startTracking" variant="primary">{{
          $t("trackerStart")
        }}</b-button>
        <b-spinner
          v-if="isStarting"
          variant="primary"
          class="tracker-spinner"
        ></b-spinner>
      </b-row>
      <b-row class="my-2">
        <b-button @click="stopTracking" variant="primary">{{
          $t("trackerStop")
        }}</b-button>
        <b-spinner
          v-if="isStopping"
          variant="primary"
          class="tracker-spinner"
        ></b-spinner>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isChecking: false,
      isConnected: false,
      isStarting: false,
      isStopping: false,
    };
  },
  computed: {
    trip() {
      return this.$store.state.trips.selected;
    },
  },
  methods: {
    async getStatus() {
      try {
        this.isChecking = true;
        var res = await axios({
          method: "post",
          url: `//192.168.4.1:3000/status`,
          data: {},
        });
        this.isChecking = false;
        if (res.status == 200) {
          console.log(res.data);
          this.isConnected = true;
        } else {
          throw new Error("Error in status");
        }
      } catch (error) {
        this.isChecking = false;
        this.isConnected = false;
        this.$bvToast.toast(this.$t("trackerCheckConnectionError"), {
          title: "tracker",
          variant: "danger",
          solid: true,
        });
      }
    },
    async startTracking() {
      try {
        this.isStarting = true;
        var res = await axios({
          method: "post",
          url: `//192.168.4.1:3000/starttracking`,
          data: {
            trip_id: this.trip.trip_id,
          },
        });
        this.isStarting = false;
        if (res.status == 200) {
          this.$bvToast.toast(this.$t("trackerStartSuccess"), {
            title: "tracker",
            variant: "primary",
            solid: true,
          });
        } else {
          throw new Error("Error in startTracking");
        }
      } catch (error) {
        this.isStarting = false;
        this.$bvToast.toast(this.$t("trackerStartError"), {
          title: "tracker",
          variant: "danger",
          solid: true,
        });
      }
    },
    async stopTracking() {
      try {
        this.isStopping = true;
        var res = await axios({
          method: "post",
          url: `//192.168.4.1:3000/stoptracking`,
          data: {},
        });
        this.isStopping = false;
        if (res.status == 200) {
          this.$bvToast.toast(this.$t("trackerStopSuccess"), {
            title: "tracker",
            variant: "primary",
            solid: true,
          });
        } else {
          throw new Error("Error in stopTracking");
        }
      } catch (error) {
        this.isStarting = false;
        this.$bvToast.toast(this.$t("trackerStopError"), {
          title: "tracker",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.row {
  margin-left: 0;
  margin-right: 0;
  align-items: center;
}

.tracker-spinner {
  margin-left: 10px;
}

hr.solid {
  background-color: #bbb;
  height: 1px;
  width: 100%;
}

.b-toast {
  z-index: 99999;
}
</style>
