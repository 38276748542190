<template>
  <div v-frag>
    <b-row class="my-1 px-1">
      <label>{{ $t("ptmCategory") }}</label>
      <b-form-input
        :value="params.ptm.category"
        @change="setPtmParam('category', $event)"
      ></b-form-input>
    </b-row>
    <b-row class="my-1 px-1">
      <label>{{ $t("ptmNumber") }}</label>
      <b-form-input
        :value="params.ptm.number"
        @change="setPtmParam('number', $event)"
      ></b-form-input>
    </b-row>
    <b-row class="my-1 px-1">
      <label>{{ $t("ptmDirection") }}</label>
      <b-form-input
        :value="params.ptm.direction"
        @change="setPtmParam('direction', $event)"
      ></b-form-input>
    </b-row>
    <b-row class="px-1">
      <label>{{ $t("ptmMode") }}</label>
      <b-form-select
        :value="params.ptm.mode"
        @change="setPtmParam('mode', $event)"
        :options="ptModes"
      ></b-form-select>
    </b-row>
    <b-row class="my-1 px-1">
      <label>{{ $t("ptmOperator") }}</label>
      <b-form-input
        :value="params.ptm.operator"
        @change="setPtmParam('operator', $event)"
      ></b-form-input>
    </b-row>
    <b-row class="my-1 px-1">
      <label>{{ $t("sectionDescription") }}</label>
      <b-form-input
        :value="params.description"
        @change="setParam('description', $event)"
      ></b-form-input>
    </b-row>
    <tl-with-tickets></tl-with-tickets>
    <div id="addSectionPriceFinder">
      <tl-price-finder @scroll="scrollToPriceFinder"></tl-price-finder>
    </div>
    <div id="vehicleCompositionEditor">
      <tl-vehicle-composition-editor
        @scroll="scrollTo('vehicleCompositionEditor')"
      ></tl-vehicle-composition-editor>
    </div>
  </div>
</template>

<script>
import tlPriceFinder from "../components/tlPriceFinder";
import tlWithTickets from "../components/tlWithTickets";
import tlVehicleCompositionEditor from "../components/tlVehicleCompositionEditor";
import VueScrollTo from "vue-scrollto";

export default {
  components: {
    "tl-price-finder": tlPriceFinder,
    "tl-with-tickets": tlWithTickets,
    "tl-vehicle-composition-editor": tlVehicleCompositionEditor,
  },
  computed: {
    params() {
      return this.$store.state.params.section;
    },
    ptModes() {
      let modes = [
        {
          value: { product: "intercity", mode: "train" },
          text: this.$t("intercity"),
        },
        {
          value: { product: "regional", mode: "train" },
          text: this.$t("regional"),
        },
        {
          value: { product: "suburban", mode: "train" },
          text: this.$t("suburban"),
        },
        { value: { product: "metro", mode: "train" }, text: this.$t("metro") },
        { value: { product: "tram", mode: "train" }, text: this.$t("tram") },
        { value: { product: "bus", mode: "bus" }, text: this.$t("bus") },
        {
          value: { product: "cablecar", mode: "gondola" },
          text: this.$t("cablecar"),
        },
        {
          value: { product: "cograilway", mode: "gondola" },
          text: this.$t("cograilway"),
        },
        {
          value: { product: "funicular", mode: "gondola" },
          text: this.$t("funicular"),
        },
      ];

      const mode = this.params?.ptm?.mode;

      if (mode) {
        if (
          !modes.find(
            (item) =>
              item.value.product === mode.product &&
              item.value.mode === mode.mode
          )
        ) {
          modes.push({
            value: mode,
            text: mode.product,
          });
        }
      }

      return modes;
    },
  },
  methods: {
    setParam(key, value) {
      this.$store.commit("params/setSectionProp", { key: key, value: value });
    },
    setPtmParam(key, value) {
      this.$store.commit("params/setSectionPtmProp", {
        key: key,
        value: value,
      });
    },
    scrollToPriceFinder() {
      VueScrollTo.scrollTo("#addSectionPriceFinder", 500, {
        container: "#addSectionContainer",
      });
    },
    scrollTo(id) {
      VueScrollTo.scrollTo("#" + id, 500, {
        container: "#addSectionContainer",
      });
    },
  },
};
</script>

<style scoped>
label {
  margin: 0;
}
.my-1 {
  align-items: center;
}
</style>
