<template>
  <div>
    <b-row class="my-1 px-1 align-items-center">
      <b-form-checkbox
        @input="toggleVehicleComposition"
        :checked="!!vehicleComposition"
        :disabled="!vehicleCompositionEnabled"
        switch
      >
        {{ $t("addVehicleComposition") }}
      </b-form-checkbox>
    </b-row>
    <template v-if="vehicleComposition"
      ><b-row class="my-1 px-1">
        <b-form-select
          v-model="oebbStation"
          :options="oebbStations"
          value-field="eva"
          text-field="name"
        ></b-form-select> </b-row
      ><b-row class="my-1 px-1 justify-content-end align-items-center">
        <b-spinner
          v-if="isGettingVehicleComposition"
          variant="secondary"
          label="Spinning"
        ></b-spinner>
        <b-button
          class="ml-2"
          :disabled="!oebbStation || isGettingVehicleComposition"
          @click="getVehicleComposition"
          >{{ $t("search") }}</b-button
        > </b-row
      ><b-row
        v-if="vehicleComposition && vehicleComposition.length > 0"
        class="my-1 px-1"
      >
        <tl-train-composition
          @loaded="$emit('scroll')"
          :composition="vehicleComposition"
        ></tl-train-composition> </b-row
    ></template>
  </div>
</template>

<script>
import tlTrainComposition from "../components/tlTrainComposition";

export default {
  name: "tl-vehicle-composition-editor",
  props: {},
  components: {
    "tl-train-composition": tlTrainComposition,
  },
  data() {
    return {
      oebbStations: oebbStations,
      oebbStation: "",
    };
  },
  methods: {
    async toggleVehicleComposition(value) {
      if (value && this.vehicleCompositionEnabled) {
        this.$store.commit("params/setSectionProp", {
          key: "vehicleComposition",
          value: [],
        });
        this.$emit("scroll");
        await this.queryOEBBStation();
        await this.getVehicleComposition();
      } else {
        this.$store.commit("params/setSectionProp", {
          key: "vehicleComposition",
          value: null,
        });
      }
    },
    async getVehicleComposition() {
      if (!this.oebbStation) throw Error("Error: oebbStation missing!");

      this.$store.commit("params/setSectionProp", {
        key: "vehicleComposition",
        value: [],
      });

      await this.$store.dispatch("params/pt/getVehicleComposition", {
        source: "oebb-live",
        oebbStation: this.oebbStation,
      });

      this.$emit("scroll");
    },
    async queryOEBBStation() {
      if (this.vehicleComposition && this.vehicleComposition.length === 0) {
        let location;
        if (
          this.$store.state.params.section.mode === "ptm" ||
          (this.$store.state.params.isEditing &&
            this.$store.state.params.section.mode === "pt")
        ) {
          location = {
            lat: this.$store.state.router.markers[0].lat,
            lng: this.$store.state.router.markers[0].lng,
          };
        } else if (this.$store.state.params.section.mode === "pt") {
          location = {
            lat: this.$store.state.params.pt.selectedJourney.legs[0].origin
              .location.latitude,
            lng: this.$store.state.params.pt.selectedJourney.legs[0].origin
              .location.longitude,
          };
        }
        if (!location) return;
        this.$store.commit("params/pt/setIsGettingVehicleComposition", true);
        try {
          const stops = await this.$store.dispatch(
            "params/pt/findStopsNearby",
            {
              coords: location,
              hafas_profile: "oebb",
              results: 8,
            }
          );
          const oebbStation = this.oebbStations.find((value) =>
            stops.find((stop) => stop.id === value.eva)
          );
          if (oebbStation) this.oebbStation = oebbStation.eva;
        } catch (error) {
          console.error(error);
        }
        this.$store.commit("params/pt/setIsGettingVehicleComposition", false);
      }
    },
  },
  computed: {
    /** @returns {any} */
    vehicleComposition() {
      return this.$store.state.params?.section?.vehicleComposition;
    },
    /** @returns {boolean} */
    vehicleCompositionEnabled() {
      return this.$store.getters["params/pt/getVehicleCompositionEnabled"];
    },
    /** @returns {boolean} */
    isGettingVehicleComposition() {
      return this.$store.state.params.pt.isGettingVehicleComposition;
    },
  },
};

const oebbStations = [
  {
    eva: "8400319",
    name: "'s-Hertogenbosch",
  },
  {
    eva: "8000001",
    name: "Aachen",
  },
  {
    eva: "8100204",
    name: "Abfaltersbach",
  },
  {
    eva: "8100202",
    name: "Absdorf-Hippersdorf",
  },
  {
    eva: "8100604",
    name: "Acharting Bahnhof",
  },
  {
    eva: "8100205",
    name: "Achau",
  },
  {
    eva: "8100602",
    name: "Achenlohe",
  },
  {
    eva: "8101955",
    name: "Achleitnersiedlung",
  },
  {
    eva: "8100161",
    name: "Admont",
  },
  {
    eva: "8100611",
    name: "Aich im Jauntal",
  },
  {
    eva: "8100597",
    name: "Aich-Assach",
  },
  {
    eva: "8100208",
    name: "Aigen-Schlägl",
  },
  {
    eva: "8100609",
    name: "Aisthofen",
  },
  {
    eva: "8100599",
    name: "Albersdorf b.Gleisdorf Bahnhof",
  },
  {
    eva: "8100209",
    name: "Alkoven Bahnhof",
  },
  {
    eva: "8100614",
    name: "Alkoven Schule Bahnhof",
  },
  {
    eva: "8100210",
    name: "Allentsteig",
  },
  {
    eva: "8100613",
    name: "Allerheiligen-Mürzhofen",
  },
  {
    eva: "8100548",
    name: "Allerheiligenhöfe",
  },
  {
    eva: "8100615",
    name: "Alling-Tobisegg",
  },
  {
    eva: "8100630",
    name: "Altach",
  },
  {
    eva: "8101863",
    name: "Altenhof",
  },
  {
    eva: "8100139",
    name: "Altenmarkt im Pongau",
  },
  {
    eva: "8100635",
    name: "Altenstadt",
  },
  {
    eva: "8100152",
    name: "Altmünster am Traunsee",
  },
  {
    eva: "7400005",
    name: "Alvesta",
  },
  {
    eva: "8400058",
    name: "Amsterdam",
  },
  {
    eva: "8100012",
    name: "Amstetten",
  },
  {
    eva: "8300186",
    name: "Ancona",
  },
  {
    eva: "8100211",
    name: "Andorf",
  },
  {
    eva: "8135785",
    name: "Angererbach-Ahrnbach Bahnhof",
  },
  {
    eva: "8100619",
    name: "Angern",
  },
  {
    eva: "8100212",
    name: "Annaberg-Reith",
  },
  {
    eva: "8100213",
    name: "Annenheim",
  },
  {
    eva: "8100626",
    name: "Ansfelden",
  },
  {
    eva: "8100214",
    name: "Anthering Bahnhof",
  },
  {
    eva: "8100215",
    name: "Antiesenhofen",
  },
  {
    eva: "5300420",
    name: "Apa",
  },
  {
    eva: "8100622",
    name: "Arbing",
  },
  {
    eva: "8100623",
    name: "Ardning",
  },
  {
    eva: "8300179",
    name: "Arezzo",
  },
  {
    eva: "8400071",
    name: "Arnhem",
  },
  {
    eva: "8100056",
    name: "Arnoldstein",
  },
  {
    eva: "8101913",
    name: "Arnsdorf b.Lamprechtshausen Bahnhof",
  },
  {
    eva: "8100390",
    name: "Aschau im Zillertal Bahnhof",
  },
  {
    eva: "8100600",
    name: "Aschbach",
  },
  {
    eva: "8100188",
    name: "Aspang",
  },
  {
    eva: "8102046",
    name: "Asten Fisching",
  },
  {
    eva: "8100454",
    name: "Attersee Bahnhof",
  },
  {
    eva: "8100017",
    name: "Attnang-Puchheim",
  },
  {
    eva: "8100637",
    name: "Atzenbrugg",
  },
  {
    eva: "8100563",
    name: "Au b.Bad Wimsbach Bahnhof",
  },
  {
    eva: "8000013",
    name: "Augsburg",
  },
  {
    eva: "8100639",
    name: "Aurachkirchen",
  },
  {
    eva: "8100638",
    name: "Aurolzmünster",
  },
  {
    eva: "8100642",
    name: "Ausschlag-Zöbern",
  },
  {
    eva: "8100164",
    name: "Bad Aussee",
  },
  {
    eva: "8100663",
    name: "Bad Blumau",
  },
  {
    eva: "8100538",
    name: "Bad Deutsch-Altenburg",
  },
  {
    eva: "8001787",
    name: "Bad Endorf",
  },
  {
    eva: "8100192",
    name: "Bad Erlach",
  },
  {
    eva: "8100655",
    name: "Bad Fischau",
  },
  {
    eva: "8100653",
    name: "Bad Fischau-Brunn",
  },
  {
    eva: "8100095",
    name: "Bad Gastein",
  },
  {
    eva: "8100174",
    name: "Bad Gleichenberg Bahnhof",
  },
  {
    eva: "8100160",
    name: "Bad Goisern",
  },
  {
    eva: "8100097",
    name: "Bad Hofgastein",
  },
  {
    eva: "8100157",
    name: "Bad Ischl",
  },
  {
    eva: "8100165",
    name: "Bad Mitterndorf",
  },
  {
    eva: "8100672",
    name: "Bad Mitterndorf-Heilbrunn",
  },
  {
    eva: "8100453",
    name: "Bad Neusiedl am See",
  },
  {
    eva: "8100677",
    name: "Bad Radkersburg",
  },
  {
    eva: "8100678",
    name: "Bad Ried",
  },
  {
    eva: "8100481",
    name: "Bad Sauerbrunn",
  },
  {
    eva: "8100021",
    name: "Bad Schallerbach-Wallern",
  },
  {
    eva: "8101628",
    name: "Bad Schwanberg",
  },
  {
    eva: "8101718",
    name: "Bad Vigaun",
  },
  {
    eva: "8100708",
    name: "Bad Vöslau",
  },
  {
    eva: "8100455",
    name: "Bad Waltersdorf",
  },
  {
    eva: "8101849",
    name: "Bad Wimsbach Steinfeld Bahnhof",
  },
  {
    eva: "8100711",
    name: "Bad Wimsbach-Neydharting Bahnhof",
  },
  {
    eva: "8000774",
    name: "Baden Baden",
  },
  {
    eva: "8100025",
    name: "Baden b.Wien Bahnhof",
  },
  {
    eva: "8503504",
    name: "Baden(CH)",
  },
  {
    eva: "5300087",
    name: "Baia Mare",
  },
  {
    eva: "8300293",
    name: "Bari",
  },
  {
    eva: "8500010",
    name: "Basel",
  },
  {
    eva: "8000026",
    name: "Basel Bad",
  },
  {
    eva: "8100216",
    name: "Baumgarten-Schattendorf",
  },
  {
    eva: "8100656",
    name: "Baumgartenberg",
  },
  {
    eva: "7200080",
    name: "Beograd",
  },
  {
    eva: "8100657",
    name: "Berg im Drautal",
  },
  {
    eva: "8100681",
    name: "Bergern",
  },
  {
    eva: "8100606",
    name: "Bergham b.Linz Am Dürrweg Bahnhst",
  },
  {
    eva: "8100565",
    name: "Bergham b.Linz Bahnhof",
  },
  {
    eva: "8100456",
    name: "Bergheim b.Salzburg Bahnhst",
  },
  {
    eva: "8101519",
    name: "Bergheim b.Salzburg Schlachthof Bahnhof",
  },
  {
    eva: "8010403",
    name: "Berlin Charlottenburg",
  },
  {
    eva: "8011102",
    name: "Berlin Gesundbrunnen",
  },
  {
    eva: "8011160",
    name: "Berlin Hbf",
  },
  {
    eva: "8098160",
    name: "Berlin Hbf (tief)",
  },
  {
    eva: "8010255",
    name: "Berlin Ostbf",
  },
  {
    eva: "8011113",
    name: "Berlin Südkreuz",
  },
  {
    eva: "8010405",
    name: "Berlin Wannsee",
  },
  {
    eva: "8010406",
    name: "Berlin Zoo",
  },
  {
    eva: "8010036",
    name: "Berlin-Lichtenberg",
  },
  {
    eva: "8100650",
    name: "Berndorf Fabrik",
  },
  {
    eva: "8100652",
    name: "Berndorf Stadt",
  },
  {
    eva: "8100457",
    name: "Bernhardsthal",
  },
  {
    eva: "8100703",
    name: "Bichl im Zillertal Bahnhof",
  },
  {
    eva: "8100178",
    name: "Bierbaum",
  },
  {
    eva: "8100689",
    name: "Bisamberg",
  },
  {
    eva: "8100042",
    name: "Bischofshofen",
  },
  {
    eva: "8100666",
    name: "Blankenberg b.Vorchdorf Bahnhof",
  },
  {
    eva: "5400489",
    name: "Blansko",
  },
  {
    eva: "8100393",
    name: "Bleiburg",
  },
  {
    eva: "8101942",
    name: "Bleiburg Staatsgrenze (Tarifpunkt)",
  },
  {
    eva: "8100668",
    name: "Bleiburg Stadt",
  },
  {
    eva: "8100669",
    name: "Blindenmarkt",
  },
  {
    eva: "8100067",
    name: "Bludenz",
  },
  {
    eva: "8100667",
    name: "Bludenz-Moos",
  },
  {
    eva: "5100227",
    name: "Bochnia",
  },
  {
    eva: "8100651",
    name: "Boding",
  },
  {
    eva: "5400038",
    name: "Bohumin",
  },
  {
    eva: "8300217",
    name: "Bologna",
  },
  {
    eva: "8300084",
    name: "Bolzano/Bozen",
  },
  {
    eva: "8000044",
    name: "Bonn Hbf",
  },
  {
    eva: "8001083",
    name: "Bonn-Beuel",
  },
  {
    eva: "7900080",
    name: "Borovnica",
  },
  {
    eva: "8100458",
    name: "Bramberg",
  },
  {
    eva: "8010060",
    name: "Brandenburg Hbf",
  },
  {
    eva: "5600209",
    name: "Bratislava  Nove Mesto",
  },
  {
    eva: "5600207",
    name: "Bratislava hl. st.",
  },
  {
    eva: "5600582",
    name: "Bratislava-Petrzalka",
  },
  {
    eva: "8100365",
    name: "Braunau am Inn",
  },
  {
    eva: "8000049",
    name: "Braunschweig",
  },
  {
    eva: "5400202",
    name: "Breclav",
  },
  {
    eva: "5403739",
    name: "Breclav(Gr)",
  },
  {
    eva: "8100090",
    name: "Bregenz",
  },
  {
    eva: "8101950",
    name: "Bregenz Hafen",
  },
  {
    eva: "8100397",
    name: "Breitenbrunn",
  },
  {
    eva: "8100682",
    name: "Breiteneich bei Horn",
  },
  {
    eva: "8100695",
    name: "Breitenschützing",
  },
  {
    eva: "8100693",
    name: "Breitensee NÖ",
  },
  {
    eva: "8100692",
    name: "Breitenstein",
  },
  {
    eva: "8100710",
    name: "Breitenwaida",
  },
  {
    eva: "8000050",
    name: "Bremen",
  },
  {
    eva: "8300092",
    name: "Brennero/Brenner",
  },
  {
    eva: "8300048",
    name: "Brescia",
  },
  {
    eva: "8300076",
    name: "Bressanone/Brixen",
  },
  {
    eva: "8100366",
    name: "Brixen im Thale",
  },
  {
    eva: "8100101",
    name: "Brixlegg",
  },
  {
    eva: "5438015",
    name: "Brno dolni nadrazi",
  },
  {
    eva: "5400001",
    name: "Brno hl. n.",
  },
  {
    eva: "5400210",
    name: "Brno-Kralovo Pole",
  },
  {
    eva: "5400603",
    name: "Brno-Zidenice",
  },
  {
    eva: "8100032",
    name: "Bruck  a. d. Mur",
  },
  {
    eva: "8100198",
    name: "Bruck a. d. Leitha",
  },
  {
    eva: "8100047",
    name: "Bruck-Fusch",
  },
  {
    eva: "8100686",
    name: "Bruckberg",
  },
  {
    eva: "8101832",
    name: "Bruckberg Golfplatz",
  },
  {
    eva: "8500309",
    name: "Brugg",
  },
  {
    eva: "8100705",
    name: "Brunn a. d. Pitten",
  },
  {
    eva: "8100706",
    name: "Brunn an der Schneebergbahn",
  },
  {
    eva: "8100698",
    name: "Brunn-Maria Enzersdorf",
  },
  {
    eva: "8100699",
    name: "Brunnenfeld-Stallehr",
  },
  {
    eva: "8800004",
    name: "Bruxelles/Brüssel Midi",
  },
  {
    eva: "8800002",
    name: "Bruxelles/Brüssel Nord",
  },
  {
    eva: "5100003",
    name: "Brzeg",
  },
  {
    eva: "5100671",
    name: "Brzesko Okocim",
  },
  {
    eva: "8100700",
    name: "Buchberg",
  },
  {
    eva: "8000057",
    name: "Buchloe",
  },
  {
    eva: "8509404",
    name: "Buchs SG",
  },
  {
    eva: "5500003",
    name: "Budapest Keleti",
  },
  {
    eva: "8101909",
    name: "Burk",
  },
  {
    eva: "8100647",
    name: "Bärnbach",
  },
  {
    eva: "8100094",
    name: "Böckstein",
  },
  {
    eva: "8100661",
    name: "Böheimkirchen",
  },
  {
    eva: "8100713",
    name: "Böhlerwerk a. d. Ybbs",
  },
  {
    eva: "8100217",
    name: "Bürmoos Bahnhof",
  },
  {
    eva: "5300102",
    name: "Carei",
  },
  {
    eva: "8300307",
    name: "Catania Centrale",
  },
  {
    eva: "8300192",
    name: "Cattolica S. Giovanni Gabicce",
  },
  {
    eva: "7900005",
    name: "Celje",
  },
  {
    eva: "8300193",
    name: "Cesena",
  },
  {
    eva: "5400002",
    name: "Ceska Trebova",
  },
  {
    eva: "5400018",
    name: "Ceske Velenice",
  },
  {
    eva: "5100708",
    name: "Chalupki",
  },
  {
    eva: "8300148",
    name: "Chiusi-Chianciano Terme",
  },
  {
    eva: "5341779",
    name: "Cicarlau",
  },
  {
    eva: "8300086",
    name: "Conegliano",
  },
  {
    eva: "5100228",
    name: "Debica",
  },
  {
    eva: "5500058",
    name: "Debrecen",
  },
  {
    eva: "8100722",
    name: "Dechantskirchen",
  },
  {
    eva: "8100145",
    name: "Dellach im Drautal",
  },
  {
    eva: "8300106",
    name: "Desenzano del Garda-Sirmione",
  },
  {
    eva: "8100721",
    name: "Deutsch Wagram",
  },
  {
    eva: "8100720",
    name: "Deutschfeistritz Bahnhof",
  },
  {
    eva: "8100719",
    name: "Deutschkreutz",
  },
  {
    eva: "8100718",
    name: "Deutschlandsberg Stadt",
  },
  {
    eva: "5600193",
    name: "Devinska Nova Ves",
  },
  {
    eva: "8101833",
    name: "Diensthubersiedlung",
  },
  {
    eva: "8102065",
    name: "Diepoltsdorf Bahnhof",
  },
  {
    eva: "8101550",
    name: "Dietmannsdorf",
  },
  {
    eva: "8100732",
    name: "Dietrichshofen",
  },
  {
    eva: "7900017",
    name: "Dobova",
  },
  {
    eva: "8100219",
    name: "Donnerskirchen",
  },
  {
    eva: "8100730",
    name: "Dorf Paßthurn",
  },
  {
    eva: "8100716",
    name: "Dorf a. d. Enns",
  },
  {
    eva: "8100098",
    name: "Dorfgastein",
  },
  {
    eva: "8100727",
    name: "Dornach",
  },
  {
    eva: "8100122",
    name: "Dornbirn",
  },
  {
    eva: "8100728",
    name: "Dornbirn-Schoren",
  },
  {
    eva: "8000080",
    name: "Dortmund",
  },
  {
    eva: "8100220",
    name: "Draßburg",
  },
  {
    eva: "8100731",
    name: "Dreistetten",
  },
  {
    eva: "8010085",
    name: "Dresden",
  },
  {
    eva: "8100221",
    name: "Drösing",
  },
  {
    eva: "8000086",
    name: "Duisburg",
  },
  {
    eva: "8100143",
    name: "Dölsach",
  },
  {
    eva: "8100717",
    name: "Dörnbach-Hitzing Bahnhof",
  },
  {
    eva: "8100733",
    name: "Dürnbach",
  },
  {
    eva: "8100734",
    name: "Dürnberg",
  },
  {
    eva: "8100724",
    name: "Dürnkrut",
  },
  {
    eva: "8100735",
    name: "Dürrwien",
  },
  {
    eva: "8000085",
    name: "Düsseldorf",
  },
  {
    eva: "8100140",
    name: "Eben im Pongau",
  },
  {
    eva: "8100225",
    name: "Ebenfurth",
  },
  {
    eva: "8199504",
    name: "Ebenfurth Übergang (Tarifpunkt)",
  },
  {
    eva: "8100155",
    name: "Ebensee",
  },
  {
    eva: "8100766",
    name: "Ebensee Landungsplatz",
  },
  {
    eva: "8100740",
    name: "Eberschwang",
  },
  {
    eva: "8100226",
    name: "Ebreichsdorf",
  },
  {
    eva: "8100743",
    name: "Eching b.Bürmoos Bahnhof",
  },
  {
    eva: "8100189",
    name: "Edlitz-Grimmenstein",
  },
  {
    eva: "8100227",
    name: "Eferding",
  },
  {
    eva: "8100737",
    name: "Eferding Gewerbegebiet Bahnhof",
  },
  {
    eva: "8100201",
    name: "Eggenburg",
  },
  {
    eva: "8100763",
    name: "Ehrenhausen",
  },
  {
    eva: "8100736",
    name: "Eichberg",
  },
  {
    eva: "8100749",
    name: "Eichgraben-Altlengbach",
  },
  {
    eva: "8101834",
    name: "Eiferding Bahnhof",
  },
  {
    eva: "8100760",
    name: "Einach Bahnhof",
  },
  {
    eva: "8100231",
    name: "Eisenstadt",
  },
  {
    eva: "8100773",
    name: "Eisenstadt Schule",
  },
  {
    eva: "8100772",
    name: "Elsbethen",
  },
  {
    eva: "8100745",
    name: "Emmersdorf im Gailtal",
  },
  {
    eva: "8100011",
    name: "Enns",
  },
  {
    eva: "8100759",
    name: "Ennsdorf",
  },
  {
    eva: "8100775",
    name: "Enzersdorf bei Staatz",
  },
  {
    eva: "8100757",
    name: "Enzesfeld-Lindabrunn",
  },
  {
    eva: "8100755",
    name: "Erlach im Zillertal Bahnhof",
  },
  {
    eva: "8100764",
    name: "Erlauf",
  },
  {
    eva: "8100234",
    name: "Erlaufklause",
  },
  {
    eva: "8100747",
    name: "Ernsthofen",
  },
  {
    eva: "8100770",
    name: "Eschenau",
  },
  {
    eva: "8000098",
    name: "Essen",
  },
  {
    eva: "8100746",
    name: "Etsdorf-Straß",
  },
  {
    eva: "8100774",
    name: "Eugendorf",
  },
  {
    eva: "8100084",
    name: "Faak am See",
  },
  {
    eva: "8100777",
    name: "Falkendorf/Mur Bahnhof",
  },
  {
    eva: "8100176",
    name: "Fehring",
  },
  {
    eva: "8100175",
    name: "Feldbach",
  },
  {
    eva: "8100793",
    name: "Feldbach/Raab Landesbahn",
  },
  {
    eva: "8100785",
    name: "Feldham Bahnhof",
  },
  {
    eva: "8100197",
    name: "Feldkirch",
  },
  {
    eva: "8100791",
    name: "Feldkirch Amberg",
  },
  {
    eva: "8100039",
    name: "Feldkirchen  in Kärnten",
  },
  {
    eva: "8103174",
    name: "Feldkirchen-Seiersberg",
  },
  {
    eva: "8100780",
    name: "Felixdorf",
  },
  {
    eva: "8100568",
    name: "Fels",
  },
  {
    eva: "8100783",
    name: "Fentsch-St. Lorenzen",
  },
  {
    eva: "8100781",
    name: "Ferndorf",
  },
  {
    eva: "8300209",
    name: "Ferrara",
  },
  {
    eva: "8100784",
    name: "Feuerwerksanstalt",
  },
  {
    eva: "8100053",
    name: "Fieberbrunn",
  },
  {
    eva: "8100788",
    name: "Finkenstein",
  },
  {
    eva: "8300151",
    name: "Firenze SMN",
  },
  {
    eva: "8100790",
    name: "Fischa Bahnhst",
  },
  {
    eva: "8100235",
    name: "Fischamend",
  },
  {
    eva: "8100795",
    name: "Flaurling",
  },
  {
    eva: "8100601",
    name: "Flughafen Graz-Feldkirchen",
  },
  {
    eva: "8100353",
    name: "Flughafen Wien (VIE)",
  },
  {
    eva: "8101898",
    name: "Forst Hilti",
  },
  {
    eva: "8300089",
    name: "Fortezza/Franzensfeste",
  },
  {
    eva: "8100804",
    name: "Fraham b.Eferding Bahnhof",
  },
  {
    eva: "8100802",
    name: "Frankenfels",
  },
  {
    eva: "8100018",
    name: "Frankenmarkt",
  },
  {
    eva: "8070004",
    name: "Frankfurt (M) Flugh. Regiobf",
  },
  {
    eva: "8070003",
    name: "Frankfurt (M) Flughafen Fernbf",
  },
  {
    eva: "8000105",
    name: "Frankfurt (M) Hbf",
  },
  {
    eva: "8002041",
    name: "Frankfurt(M) Süd",
  },
  {
    eva: "8002042",
    name: "Frankfurt(Main)West",
  },
  {
    eva: "8010113",
    name: "Frankfurt(Oder)",
  },
  {
    eva: "8100069",
    name: "Frastanz",
  },
  {
    eva: "8100238",
    name: "Frauenkirchen",
  },
  {
    eva: "8100779",
    name: "Frauental-Bad Gams",
  },
  {
    eva: "8000107",
    name: "Freiburg (Breisgau)",
  },
  {
    eva: "8000108",
    name: "Freilassing",
  },
  {
    eva: "8100239",
    name: "Freistadt",
  },
  {
    eva: "8100185",
    name: "Friedberg",
  },
  {
    eva: "8100241",
    name: "Friedburg",
  },
  {
    eva: "8000112",
    name: "Friedrichshafen",
  },
  {
    eva: "8100076",
    name: "Friesach",
  },
  {
    eva: "8100104",
    name: "Fritzens-Wattens",
  },
  {
    eva: "8100033",
    name: "Frohnleiten",
  },
  {
    eva: "8101836",
    name: "Frojach Bahnhof",
  },
  {
    eva: "8100808",
    name: "Frättingsdorf",
  },
  {
    eva: "8000115",
    name: "Fulda",
  },
  {
    eva: "8100814",
    name: "Furth",
  },
  {
    eva: "8100244",
    name: "Furth-Göttweig",
  },
  {
    eva: "8100813",
    name: "Furth-Palt",
  },
  {
    eva: "8100797",
    name: "Föderlach",
  },
  {
    eva: "8100242",
    name: "Fügen-Hart im Zillertal Bahnhof",
  },
  {
    eva: "8100368",
    name: "Fürnitz",
  },
  {
    eva: "8100177",
    name: "Fürstenfeld",
  },
  {
    eva: "8100243",
    name: "Fürth-Kaprun",
  },
  {
    eva: "8100154",
    name: "Gaflenz",
  },
  {
    eva: "8100818",
    name: "Gagering Bahnhof",
  },
  {
    eva: "8100246",
    name: "Gaisbach-Wartberg",
  },
  {
    eva: "8100817",
    name: "Gaisfeld",
  },
  {
    eva: "8100819",
    name: "Gaishorn",
  },
  {
    eva: "8100823",
    name: "Gaisruck",
  },
  {
    eva: "8100247",
    name: "Gars-Thunau",
  },
  {
    eva: "8100248",
    name: "Garsten",
  },
  {
    eva: "8100332",
    name: "Gattendorf",
  },
  {
    eva: "8100828",
    name: "Gedersdorf",
  },
  {
    eva: "8100830",
    name: "Geinberg",
  },
  {
    eva: "8100831",
    name: "Gemeinlebarn",
  },
  {
    eva: "8300153",
    name: "Genova",
  },
  {
    eva: "8100869",
    name: "Gerasdorf",
  },
  {
    eva: "8100878",
    name: "Gerling OÖ",
  },
  {
    eva: "8100879",
    name: "Gerling im Pinzgau",
  },
  {
    eva: "8100834",
    name: "Getzersdorf",
  },
  {
    eva: "8100549",
    name: "Gießenbach",
  },
  {
    eva: "8100842",
    name: "Gisingen",
  },
  {
    eva: "8100849",
    name: "Glandorf",
  },
  {
    eva: "8100850",
    name: "Glanegg",
  },
  {
    eva: "8100195",
    name: "Gleisdorf",
  },
  {
    eva: "8100285",
    name: "Gleisdorf West",
  },
  {
    eva: "8100851",
    name: "Gleißenfeld",
  },
  {
    eva: "8100852",
    name: "Glinzendorf",
  },
  {
    eva: "5100011",
    name: "Gliwice",
  },
  {
    eva: "8100026",
    name: "Gloggnitz",
  },
  {
    eva: "5100012",
    name: "Glogow",
  },
  {
    eva: "8100151",
    name: "Gmunden",
  },
  {
    eva: "8100129",
    name: "Gmünd NÖ",
  },
  {
    eva: "8198129",
    name: "Gmünd NÖ Bahnhof (Waldviertelbahn)",
  },
  {
    eva: "8101921",
    name: "Gmünd NÖ Staatsgrenze (Tarifpunkt)",
  },
  {
    eva: "8100856",
    name: "Gnas Bahnhof",
  },
  {
    eva: "8100697",
    name: "Gnas Burgfried Bahnhst",
  },
  {
    eva: "8100857",
    name: "Gniebing",
  },
  {
    eva: "8100859",
    name: "Gobelsburg",
  },
  {
    eva: "8100863",
    name: "Goisern Jodschwefelbad",
  },
  {
    eva: "8100041",
    name: "Golling-Abtenau",
  },
  {
    eva: "8100255",
    name: "Gols",
  },
  {
    eva: "8100864",
    name: "Gopperding",
  },
  {
    eva: "8100886",
    name: "Gosdorf",
  },
  {
    eva: "8100182",
    name: "Grafendorf",
  },
  {
    eva: "8100256",
    name: "Grafenstein",
  },
  {
    eva: "8100369",
    name: "Gramatneusiedl",
  },
  {
    eva: "8100877",
    name: "Granitztal",
  },
  {
    eva: "8100370",
    name: "Gratwein-Gratkorn",
  },
  {
    eva: "8100173",
    name: "Graz",
  },
  {
    eva: "8103490",
    name: "Graz Don Bosco",
  },
  {
    eva: "8100843",
    name: "Graz Köflacherbf.",
  },
  {
    eva: "8103487",
    name: "Graz Liebenau-Murpark",
  },
  {
    eva: "8100371",
    name: "Graz Ostbf Messe",
  },
  {
    eva: "8101398",
    name: "Graz Puntigam",
  },
  {
    eva: "8102019",
    name: "Graz Stadion Liebenau",
  },
  {
    eva: "8101507",
    name: "Graz Straßgang",
  },
  {
    eva: "8100883",
    name: "Graz Webling",
  },
  {
    eva: "8100905",
    name: "Graz Wetzelsdorf",
  },
  {
    eva: "8100146",
    name: "Greifenburg-Weißensee",
  },
  {
    eva: "8100257",
    name: "Greifenstein-Altenberg",
  },
  {
    eva: "8100858",
    name: "Grein Stadt",
  },
  {
    eva: "8100826",
    name: "Grein-Bad Kreuzen",
  },
  {
    eva: "8100836",
    name: "Greinsfurth",
  },
  {
    eva: "8100258",
    name: "Gries",
  },
  {
    eva: "8100892",
    name: "Gries im Pinzgau",
  },
  {
    eva: "8100022",
    name: "Grieskirchen-Gallspach",
  },
  {
    eva: "8100907",
    name: "Grieswirt",
  },
  {
    eva: "8100890",
    name: "Groß Sierning",
  },
  {
    eva: "8100837",
    name: "Groß St. Florian",
  },
  {
    eva: "8100570",
    name: "Großendorf",
  },
  {
    eva: "8100876",
    name: "Großraming",
  },
  {
    eva: "8100906",
    name: "Großweikersdorf",
  },
  {
    eva: "8100135",
    name: "Gröbming",
  },
  {
    eva: "8100261",
    name: "Grünau im Almtal",
  },
  {
    eva: "8100847",
    name: "Grünbach Kohlenwerk",
  },
  {
    eva: "8101837",
    name: "Grünbach Schule",
  },
  {
    eva: "8100882",
    name: "Grünbach am Schneeberg",
  },
  {
    eva: "8100885",
    name: "Gschnarret Bahnhof",
  },
  {
    eva: "8100159",
    name: "Gstatterboden i. Nationalpark",
  },
  {
    eva: "8100891",
    name: "Gstocket Bahnhof",
  },
  {
    eva: "8100901",
    name: "Guggenbach Pulverwerksiedlung Bahnhof",
  },
  {
    eva: "8100898",
    name: "Guggenbach Warthkogelsiedlung Bahnhof",
  },
  {
    eva: "8100867",
    name: "Gumpoldskirchen",
  },
  {
    eva: "8100845",
    name: "Gunskirchen",
  },
  {
    eva: "8100894",
    name: "Guntersdorf",
  },
  {
    eva: "8100372",
    name: "Guntramsdorf-Kaiserau",
  },
  {
    eva: "8100846",
    name: "Guntramsdorf-Thallern",
  },
  {
    eva: "8100263",
    name: "Gurten",
  },
  {
    eva: "8100903",
    name: "Gussendorf",
  },
  {
    eva: "8100571",
    name: "Gutenstein",
  },
  {
    eva: "5500080",
    name: "Gyor",
  },
  {
    eva: "8100245",
    name: "Gänserndorf",
  },
  {
    eva: "8100861",
    name: "Gödersdorf",
  },
  {
    eva: "8100827",
    name: "Göllersdorf",
  },
  {
    eva: "8100200",
    name: "Göpfritz",
  },
  {
    eva: "8100862",
    name: "Görtschach-Förolach",
  },
  {
    eva: "8100252",
    name: "Gösing",
  },
  {
    eva: "8000128",
    name: "Göttingen",
  },
  {
    eva: "8100254",
    name: "Götzendorf",
  },
  {
    eva: "8100120",
    name: "Götzis",
  },
  {
    eva: "8000139",
    name: "Günzburg",
  },
  {
    eva: "8100910",
    name: "Habachtal-Weyerhof",
  },
  {
    eva: "8100264",
    name: "Hadersdorf  am Kamp",
  },
  {
    eva: "8100912",
    name: "Hagenau b.Salzburg Bahnhof",
  },
  {
    eva: "8100914",
    name: "Hagenau im Innkreis",
  },
  {
    eva: "8101839",
    name: "Hagendorf im Murtal Bahnhof",
  },
  {
    eva: "8100572",
    name: "Haid b.Vöcklamarkt Bahnhof",
  },
  {
    eva: "8100265",
    name: "Haiding",
  },
  {
    eva: "8100956",
    name: "Haiming",
  },
  {
    eva: "8100959",
    name: "Hainburg a.d. Donau",
  },
  {
    eva: "8100958",
    name: "Hainburg a.d. Donau Kulturfabrik",
  },
  {
    eva: "8100266",
    name: "Hainburg a.d. Donau Personenbf",
  },
  {
    eva: "8100957",
    name: "Hainfeld",
  },
  {
    eva: "5500057",
    name: "Hajduszoboszlo",
  },
  {
    eva: "8100916",
    name: "Halbenrain",
  },
  {
    eva: "8100105",
    name: "Hall in Tirol",
  },
  {
    eva: "8010159",
    name: "Halle (Saale)",
  },
  {
    eva: "8100040",
    name: "Hallein",
  },
  {
    eva: "8102049",
    name: "Hallein Burgfried",
  },
  {
    eva: "8100926",
    name: "Hallstatt",
  },
  {
    eva: "8100929",
    name: "Hallwang-Elixhausen",
  },
  {
    eva: "8002549",
    name: "Hamburg",
  },
  {
    eva: "8002553",
    name: "Hamburg Altona",
  },
  {
    eva: "8002548",
    name: "Hamburg Dammtor",
  },
  {
    eva: "8000147",
    name: "Hamburg Harburg",
  },
  {
    eva: "8000152",
    name: "Hannover",
  },
  {
    eva: "8003487",
    name: "Hannover Messe/Laatzen",
  },
  {
    eva: "8100267",
    name: "Hard-Fussach",
  },
  {
    eva: "8102050",
    name: "Hart bei Graz",
  },
  {
    eva: "8100948",
    name: "Hart im Innkreis",
  },
  {
    eva: "8101935",
    name: "Hart-Wörth",
  },
  {
    eva: "8100180",
    name: "Hartberg",
  },
  {
    eva: "8100981",
    name: "Haselstauden",
  },
  {
    eva: "8100976",
    name: "Haslach",
  },
  {
    eva: "8100982",
    name: "Haslau a.d.Donau",
  },
  {
    eva: "8100921",
    name: "Hatlerdorf",
  },
  {
    eva: "8100986",
    name: "Hatting",
  },
  {
    eva: "8100268",
    name: "Hatzendorf",
  },
  {
    eva: "8100136",
    name: "Haus",
  },
  {
    eva: "8100990",
    name: "Hausleiten",
  },
  {
    eva: "8100980",
    name: "Hausruck",
  },
  {
    eva: "8100984",
    name: "Hautzendorf",
  },
  {
    eva: "5500017",
    name: "Hegyeshalom",
  },
  {
    eva: "5501629",
    name: "Hegyeshalom(Gr)",
  },
  {
    eva: "8000156",
    name: "Heidelberg",
  },
  {
    eva: "8100924",
    name: "Heilbad Burgwies",
  },
  {
    eva: "8100928",
    name: "Helmahof",
  },
  {
    eva: "8102051",
    name: "Hengsberg",
  },
  {
    eva: "8100925",
    name: "Hennersdorf",
  },
  {
    eva: "8100270",
    name: "Hermagor",
  },
  {
    eva: "8100272",
    name: "Herzogenburg",
  },
  {
    eva: "8100996",
    name: "Herzogenburg Stadt",
  },
  {
    eva: "8100997",
    name: "Herzogenburg-Wielandsthal",
  },
  {
    eva: "8100995",
    name: "Herzograd",
  },
  {
    eva: "8100936",
    name: "Hetzmannsdorf-Wullersdorf",
  },
  {
    eva: "8100158",
    name: "Hieflau",
  },
  {
    eva: "8100949",
    name: "Hilm-Kematen",
  },
  {
    eva: "8100950",
    name: "Himberg",
  },
  {
    eva: "8100947",
    name: "Himberg b.Übelbach Bahnhof",
  },
  {
    eva: "8100954",
    name: "Hintering Bahnhof",
  },
  {
    eva: "8100172",
    name: "Hinterstoder",
  },
  {
    eva: "8100952",
    name: "Hipping Bahnhof",
  },
  {
    eva: "8100953",
    name: "Hirschbach",
  },
  {
    eva: "8100983",
    name: "Hirtenberg",
  },
  {
    eva: "8100052",
    name: "Hochfilzen",
  },
  {
    eva: "8100111",
    name: "Hochzirl",
  },
  {
    eva: "5400056",
    name: "Hodonin",
  },
  {
    eva: "8100937",
    name: "Hofstatt",
  },
  {
    eva: "8100940",
    name: "Hofstetten-Grünau",
  },
  {
    eva: "8100979",
    name: "Hofstätten b.Bad Gleichenberg Bahnhst",
  },
  {
    eva: "8100130",
    name: "Hohenau",
  },
  {
    eva: "8100945",
    name: "Hohenbrugg a. d. Raab",
  },
  {
    eva: "8100121",
    name: "Hohenems",
  },
  {
    eva: "8100274",
    name: "Hollabrunn",
  },
  {
    eva: "8100955",
    name: "Hollenegg",
  },
  {
    eva: "8100275",
    name: "Hollersbach",
  },
  {
    eva: "8167958",
    name: "Hollersbach Panoramabahn",
  },
  {
    eva: "8100276",
    name: "Holzleithen",
  },
  {
    eva: "8100059",
    name: "Hopfgarten",
  },
  {
    eva: "8100939",
    name: "Hopfgarten Berglift",
  },
  {
    eva: "8100277",
    name: "Horn",
  },
  {
    eva: "5400060",
    name: "Horni Dvoriste",
  },
  {
    eva: "7400006",
    name: "Hässleholm",
  },
  {
    eva: "8100962",
    name: "Höbersdorf",
  },
  {
    eva: "8100961",
    name: "Höflein a.d. Donau",
  },
  {
    eva: "8100967",
    name: "Hönigsberg",
  },
  {
    eva: "8100970",
    name: "Hörersdorf",
  },
  {
    eva: "8100974",
    name: "Hörsching",
  },
  {
    eva: "8100273",
    name: "Hötzelsdorf-Geras",
  },
  {
    eva: "8100993",
    name: "Hüttau",
  },
  {
    eva: "8100999",
    name: "Iglmühle",
  },
  {
    eva: "7900029",
    name: "Ilirska Bistrica",
  },
  {
    eva: "8100062",
    name: "Imst-Pitztal",
  },
  {
    eva: "8101000",
    name: "Imsterberg",
  },
  {
    eva: "8100108",
    name: "Innsbruck",
  },
  {
    eva: "8100109",
    name: "Innsbruck  Westbf",
  },
  {
    eva: "8100110",
    name: "Innsbruck Hötting",
  },
  {
    eva: "8101007",
    name: "Inzing",
  },
  {
    eva: "8100399",
    name: "Irnfritz",
  },
  {
    eva: "8101005",
    name: "Irschen",
  },
  {
    eva: "8101006",
    name: "Itzling OÖ Bahnhof",
  },
  {
    eva: "5100233",
    name: "Jaroslaw",
  },
  {
    eva: "5100213",
    name: "Jaworzno Szczakowa",
  },
  {
    eva: "8101012",
    name: "Jedenspeigen",
  },
  {
    eva: "8100102",
    name: "Jenbach",
  },
  {
    eva: "8100545",
    name: "Jenbach Bahnhof (Zillertalbahn)",
  },
  {
    eva: "8101010",
    name: "Jennersdorf",
  },
  {
    eva: "8102010",
    name: "Jesdorf-Bergfried",
  },
  {
    eva: "7900001",
    name: "Jesenice",
  },
  {
    eva: "7900043",
    name: "Jesenice(Gr)",
  },
  {
    eva: "8101014",
    name: "Johnsbach i. Nationalpark",
  },
  {
    eva: "8100280",
    name: "Jois",
  },
  {
    eva: "8100073",
    name: "Judenburg",
  },
  {
    eva: "8101011",
    name: "Judendorf-Straßengel",
  },
  {
    eva: "7800319",
    name: "Jurdani",
  },
  {
    eva: "7800321",
    name: "Jusici",
  },
  {
    eva: "8101918",
    name: "Kaindorf a. d. Sulm",
  },
  {
    eva: "8101023",
    name: "Kaindorf im Murtal Bahnhof",
  },
  {
    eva: "8101025",
    name: "Kainisch",
  },
  {
    eva: "8101019",
    name: "Kalsdorf",
  },
  {
    eva: "8100281",
    name: "Kaltenbach-Stumm im Zillertal Bahnhof",
  },
  {
    eva: "8100282",
    name: "Kaltenbrunnen",
  },
  {
    eva: "8100126",
    name: "Kalwang",
  },
  {
    eva: "8101024",
    name: "Kamegg",
  },
  {
    eva: "8100283",
    name: "Kammer-Schörfling",
  },
  {
    eva: "8101041",
    name: "Kammerhof",
  },
  {
    eva: "8101061",
    name: "Kammern",
  },
  {
    eva: "8101020",
    name: "Kapellerfeld",
  },
  {
    eva: "8100031",
    name: "Kapfenberg",
  },
  {
    eva: "8101032",
    name: "Kapfenberg Fachhochschule",
  },
  {
    eva: "8101021",
    name: "Kapfing im Zillertal Bahnhof",
  },
  {
    eva: "8101075",
    name: "Kappel am Krappfeld",
  },
  {
    eva: "8000191",
    name: "Karlsruhe",
  },
  {
    eva: "8003200",
    name: "Kassel Wilhelmshöhe",
  },
  {
    eva: "8101079",
    name: "Kastenreith",
  },
  {
    eva: "5100020",
    name: "Katowice",
  },
  {
    eva: "8101029",
    name: "Katsdorf",
  },
  {
    eva: "8100284",
    name: "Katzelsdorf",
  },
  {
    eva: "8101105",
    name: "Katzendorf in Stmk Bahnhof",
  },
  {
    eva: "5100119",
    name: "Kedzierzyn Kozle",
  },
  {
    eva: "8100536",
    name: "Kefermarkt",
  },
  {
    eva: "8101038",
    name: "Kematen in Tirol",
  },
  {
    eva: "8101036",
    name: "Kematen-Piberbach",
  },
  {
    eva: "8000197",
    name: "Kempten (Allgäu)",
  },
  {
    eva: "8101037",
    name: "Kendlbruck Bahnhof",
  },
  {
    eva: "8101045",
    name: "Kimpling",
  },
  {
    eva: "8100030",
    name: "Kindberg",
  },
  {
    eva: "8100289",
    name: "Kirchberg a. d. Pielach",
  },
  {
    eva: "8100288",
    name: "Kirchberg am Wagram",
  },
  {
    eva: "8100057",
    name: "Kirchberg in Tirol",
  },
  {
    eva: "8101049",
    name: "Kirchberg-Thürnau Bahnhof",
  },
  {
    eva: "8100290",
    name: "Kirchbichl",
  },
  {
    eva: "8100170",
    name: "Kirchdorf  a. d. Krems",
  },
  {
    eva: "8101048",
    name: "Kirchstetten",
  },
  {
    eva: "8100373",
    name: "Kittsee",
  },
  {
    eva: "8103001",
    name: "Kittsee Staatsgrenze (Tarifpunkt)",
  },
  {
    eva: "8100055",
    name: "Kitzbühel",
  },
  {
    eva: "8101044",
    name: "Kitzbühel Hahnenkamm",
  },
  {
    eva: "8100085",
    name: "Klagenfurt",
  },
  {
    eva: "8101090",
    name: "Klagenfurt Annabichl",
  },
  {
    eva: "8101091",
    name: "Klagenfurt Ebenthal",
  },
  {
    eva: "8101093",
    name: "Klagenfurt Lend",
  },
  {
    eva: "8101094",
    name: "Klagenfurt Ostbf",
  },
  {
    eva: "8103842",
    name: "Klagenfurt Süd",
  },
  {
    eva: "8103644",
    name: "Klagenfurt West",
  },
  {
    eva: "8101050",
    name: "Klamm-Schottwien",
  },
  {
    eva: "8101056",
    name: "Klangen",
  },
  {
    eva: "8100171",
    name: "Klaus",
  },
  {
    eva: "8101100",
    name: "Klaus in Vorarlberg",
  },
  {
    eva: "8101055",
    name: "Kleblach-Lind",
  },
  {
    eva: "8101051",
    name: "Kledering",
  },
  {
    eva: "8100156",
    name: "Kleinreifling",
  },
  {
    eva: "8101106",
    name: "Kleinzell",
  },
  {
    eva: "8100460",
    name: "Klosterneuburg-Kierling",
  },
  {
    eva: "8101101",
    name: "Klosterneuburg-Weidling",
  },
  {
    eva: "7800006",
    name: "Knin",
  },
  {
    eva: "8100072",
    name: "Knittelfeld",
  },
  {
    eva: "8601309",
    name: "Kobenhavn",
  },
  {
    eva: "8000206",
    name: "Koblenz",
  },
  {
    eva: "8601242",
    name: "Koebenhavns Lufthavn st",
  },
  {
    eva: "8100575",
    name: "Kogl im Attergau Bahnhof",
  },
  {
    eva: "8100091",
    name: "Kolbnitz",
  },
  {
    eva: "5400022",
    name: "Kolin",
  },
  {
    eva: "8100293",
    name: "Korneuburg",
  },
  {
    eva: "8101071",
    name: "Kothmühle",
  },
  {
    eva: "8101033",
    name: "Kottingbrunn",
  },
  {
    eva: "8101065",
    name: "Kottingneusiedl",
  },
  {
    eva: "5100028",
    name: "Krakow Glowny",
  },
  {
    eva: "8100551",
    name: "Kranebitten",
  },
  {
    eva: "7900014",
    name: "Kranj",
  },
  {
    eva: "8101076",
    name: "Kraubath",
  },
  {
    eva: "8101077",
    name: "Kreisbach",
  },
  {
    eva: "8172379",
    name: "Kreischberg Talstation Bahnhof",
  },
  {
    eva: "8100295",
    name: "Krems  a.d. Donau",
  },
  {
    eva: "8101087",
    name: "Krems in Steiermark",
  },
  {
    eva: "8100169",
    name: "Kremsmünster",
  },
  {
    eva: "8101085",
    name: "Krenstetten-Biberbach",
  },
  {
    eva: "8101080",
    name: "Krieglach",
  },
  {
    eva: "8100296",
    name: "Krimml",
  },
  {
    eva: "8101084",
    name: "Kritzendorf",
  },
  {
    eva: "7800339",
    name: "Krnjevo",
  },
  {
    eva: "8101053",
    name: "Krottendorf-Ligist",
  },
  {
    eva: "7900030",
    name: "Krsko",
  },
  {
    eva: "8101083",
    name: "Krummnußbaum",
  },
  {
    eva: "8100079",
    name: "Krumpendorf",
  },
  {
    eva: "5101863",
    name: "Krzeszowice",
  },
  {
    eva: "8101081",
    name: "Kröllendorf",
  },
  {
    eva: "8100546",
    name: "Kuchl",
  },
  {
    eva: "8102047",
    name: "Kuchl Garnei",
  },
  {
    eva: "8100001",
    name: "Kufstein",
  },
  {
    eva: "8101063",
    name: "Kumpfmühl",
  },
  {
    eva: "8100100",
    name: "Kundl",
  },
  {
    eva: "8101039",
    name: "Köflach",
  },
  {
    eva: "8000207",
    name: "Köln Hbf",
  },
  {
    eva: "8003368",
    name: "Köln Messe/Deutz",
  },
  {
    eva: "8073368",
    name: "Köln Messe/Deutz Gl.11-12",
  },
  {
    eva: "8101070",
    name: "Köppling",
  },
  {
    eva: "8100594",
    name: "Köstendorf Weng",
  },
  {
    eva: "8101072",
    name: "Kößlwang Bahnhof",
  },
  {
    eva: "8101097",
    name: "Küb",
  },
  {
    eva: "8101099",
    name: "Küpfern",
  },
  {
    eva: "8300156",
    name: "La Spezia",
  },
  {
    eva: "8101180",
    name: "Laa a. d. Thaya",
  },
  {
    eva: "8101110",
    name: "Lacken",
  },
  {
    eva: "8101111",
    name: "Ladendorf",
  },
  {
    eva: "8101113",
    name: "Lahnsiedlung",
  },
  {
    eva: "8101179",
    name: "Lahnstein",
  },
  {
    eva: "8101118",
    name: "Lahrndorf",
  },
  {
    eva: "8102044",
    name: "Laimach Regionalmuseum Bahnhof",
  },
  {
    eva: "8100015",
    name: "Lambach",
  },
  {
    eva: "8101223",
    name: "Lambach Markt",
  },
  {
    eva: "8100298",
    name: "Lamprechtshausen Bahnhof",
  },
  {
    eva: "5101932",
    name: "Lancut",
  },
  {
    eva: "8100063",
    name: "Landeck-Zams",
  },
  {
    eva: "8100065",
    name: "Langen am Arlberg",
  },
  {
    eva: "8101159",
    name: "Langenlebarn",
  },
  {
    eva: "8100299",
    name: "Langenlois",
  },
  {
    eva: "8101190",
    name: "Langenwang",
  },
  {
    eva: "8101191",
    name: "Langenzersdorf",
  },
  {
    eva: "8100300",
    name: "Langkampfen",
  },
  {
    eva: "8101189",
    name: "Langwies",
  },
  {
    eva: "8101160",
    name: "Lannach",
  },
  {
    eva: "8101173",
    name: "Lanzendorf-Rannersdorf",
  },
  {
    eva: "8101104",
    name: "Lanzenkirchen",
  },
  {
    eva: "8101178",
    name: "Lasberg-St. Oswald",
  },
  {
    eva: "7900031",
    name: "Lasko",
  },
  {
    eva: "8100301",
    name: "Laubenbachmühle",
  },
  {
    eva: "8101120",
    name: "Lauffen",
  },
  {
    eva: "8100077",
    name: "Launsdorf-Hochosterwitz",
  },
  {
    eva: "8100302",
    name: "Lauterach",
  },
  {
    eva: "8101122",
    name: "Laxenburg-Biedermannsdorf",
  },
  {
    eva: "8100194",
    name: "Laßnitzhöhe",
  },
  {
    eva: "8101161",
    name: "Laßnitzthal",
  },
  {
    eva: "8101137",
    name: "Lebring",
  },
  {
    eva: "5100035",
    name: "Legnica",
  },
  {
    eva: "8101119",
    name: "Lehen-Altensam",
  },
  {
    eva: "8100035",
    name: "Leibnitz",
  },
  {
    eva: "8010205",
    name: "Leipzig",
  },
  {
    eva: "8100554",
    name: "Leithen",
  },
  {
    eva: "8100045",
    name: "Lend",
  },
  {
    eva: "8101126",
    name: "Lendorf",
  },
  {
    eva: "8102009",
    name: "Lengau",
  },
  {
    eva: "8101145",
    name: "Lengdorf",
  },
  {
    eva: "8101141",
    name: "Lenzing",
  },
  {
    eva: "8101135",
    name: "Lenzing Ort",
  },
  {
    eva: "8100070",
    name: "Leoben",
  },
  {
    eva: "8101124",
    name: "Leobendorf-Burg Kreuzenstein",
  },
  {
    eva: "8101163",
    name: "Leobersdorf",
  },
  {
    eva: "8100051",
    name: "Leogang",
  },
  {
    eva: "8101146",
    name: "Leogang-Steinberge",
  },
  {
    eva: "8101165",
    name: "Leonding",
  },
  {
    eva: "8101167",
    name: "Leonding (Lokalbahn) Bahnhof",
  },
  {
    eva: "8101499",
    name: "Leonding Straßfeld Bahnhof",
  },
  {
    eva: "7900002",
    name: "Lesce-Bled",
  },
  {
    eva: "5400084",
    name: "Letovice",
  },
  {
    eva: "8101148",
    name: "Lichendorf",
  },
  {
    eva: "8100305",
    name: "Liebenfels",
  },
  {
    eva: "8101149",
    name: "Lieboch",
  },
  {
    eva: "8101158",
    name: "Lieboch Schadendorf",
  },
  {
    eva: "8800012",
    name: "Liege",
  },
  {
    eva: "8100141",
    name: "Lienz",
  },
  {
    eva: "8102042",
    name: "Lienz-Peggetz",
  },
  {
    eva: "8100131",
    name: "Liezen",
  },
  {
    eva: "8101153",
    name: "Lilienfeld",
  },
  {
    eva: "8101605",
    name: "Lilienfeld Krankenhaus",
  },
  {
    eva: "8101154",
    name: "Limberg-Maissau",
  },
  {
    eva: "8101151",
    name: "Lind b.Scheifling Bahnhst",
  },
  {
    eva: "8101157",
    name: "Lind-Rosegg",
  },
  {
    eva: "8000230",
    name: "Lindau-Insel",
  },
  {
    eva: "8003693",
    name: "Lindau-Reutin",
  },
  {
    eva: "7400008",
    name: "Linköping",
  },
  {
    eva: "8100013",
    name: "Linz",
  },
  {
    eva: "8101127",
    name: "Linz Ebelsberg",
  },
  {
    eva: "8100807",
    name: "Linz Franckstraße",
  },
  {
    eva: "8101193",
    name: "Linz Oed",
  },
  {
    eva: "8100396",
    name: "Linz Urfahr",
  },
  {
    eva: "8101195",
    name: "Linz Wegscheid",
  },
  {
    eva: "8101358",
    name: "Linz-Pichling",
  },
  {
    eva: "8101699",
    name: "Linz/Donau Untergaumberg Bahnhst",
  },
  {
    eva: "8300157",
    name: "Livorno",
  },
  {
    eva: "7900003",
    name: "Ljubljana",
  },
  {
    eva: "8100124",
    name: "Lochau-Hörbranz",
  },
  {
    eva: "8101166",
    name: "Loich",
  },
  {
    eva: "8100398",
    name: "Loipersbach-Schattendorf",
  },
  {
    eva: "8101169",
    name: "Loosdorf",
  },
  {
    eva: "8101175",
    name: "Lorüns",
  },
  {
    eva: "8100463",
    name: "Losenstein",
  },
  {
    eva: "5100308",
    name: "Lubin Gorniczy",
  },
  {
    eva: "5100237",
    name: "Lubliniec",
  },
  {
    eva: "8100066",
    name: "Ludesch",
  },
  {
    eva: "7400115",
    name: "Lund Central",
  },
  {
    eva: "8101185",
    name: "Lungitz",
  },
  {
    eva: "8100123",
    name: "Lustenau",
  },
  {
    eva: "8101186",
    name: "Lutzmannsdorf/Mur Bahnhst",
  },
  {
    eva: "8101164",
    name: "Lödersdorf",
  },
  {
    eva: "8000238",
    name: "Lüneburg",
  },
  {
    eva: "8101196",
    name: "Madling Bahnhof",
  },
  {
    eva: "8010224",
    name: "Magdeburg",
  },
  {
    eva: "8101199",
    name: "Maierdorf Bahnhof",
  },
  {
    eva: "8101198",
    name: "Mainburg",
  },
  {
    eva: "8000240",
    name: "Mainz",
  },
  {
    eva: "8100050",
    name: "Maishofen-Saalbach",
  },
  {
    eva: "8100093",
    name: "Mallnitz-Obervellach",
  },
  {
    eva: "7400004",
    name: "Malmö",
  },
  {
    eva: "8101202",
    name: "Mandling",
  },
  {
    eva: "8000244",
    name: "Mannheim",
  },
  {
    eva: "8101871",
    name: "Mannswörth",
  },
  {
    eva: "8101254",
    name: "Manzing-Prambach Bahnhof",
  },
  {
    eva: "8101460",
    name: "Marbach b.Murau Golfplatz Bahnhof",
  },
  {
    eva: "8100466",
    name: "Marchegg",
  },
  {
    eva: "8102900",
    name: "Marchegg Staatsgrenze (Tarifpunkt)",
  },
  {
    eva: "8101204",
    name: "Marchtrenk",
  },
  {
    eva: "8101197",
    name: "Maria Anzbach",
  },
  {
    eva: "8100537",
    name: "Maria Ellend a.d. Donau",
  },
  {
    eva: "8101224",
    name: "Maria Lanzendorf",
  },
  {
    eva: "8100470",
    name: "Maria Rain",
  },
  {
    eva: "8101239",
    name: "Maria Saal",
  },
  {
    eva: "8101244",
    name: "Mariahof-St. Lambrecht",
  },
  {
    eva: "8100471",
    name: "Mariazell",
  },
  {
    eva: "7900006",
    name: "Maribor",
  },
  {
    eva: "8101203",
    name: "Markersdorf a. d. Pielach",
  },
  {
    eva: "8101238",
    name: "Markt Paternion",
  },
  {
    eva: "8101241",
    name: "Markt Sachsenburg",
  },
  {
    eva: "8100576",
    name: "Marktl",
  },
  {
    eva: "8100472",
    name: "Marz-Rohrbach",
  },
  {
    eva: "8300162",
    name: "Massa Centro",
  },
  {
    eva: "8100106",
    name: "Matrei",
  },
  {
    eva: "8100408",
    name: "Mattersburg",
  },
  {
    eva: "8102015",
    name: "Mattersburg Nord",
  },
  {
    eva: "8100409",
    name: "Mattighofen",
  },
  {
    eva: "8101228",
    name: "Mauer-Öhling",
  },
  {
    eva: "8100410",
    name: "Mauerkirchen",
  },
  {
    eva: "8101205",
    name: "Mautbrücken",
  },
  {
    eva: "8100125",
    name: "Mautern",
  },
  {
    eva: "8100413",
    name: "Mauthausen",
  },
  {
    eva: "8100541",
    name: "Mayrhofen im Zillertal Bahnhof",
  },
  {
    eva: "5300259",
    name: "Mediesu Aurit",
  },
  {
    eva: "8100005",
    name: "Melk",
  },
  {
    eva: "8000249",
    name: "Memmingen",
  },
  {
    eva: "8100412",
    name: "Micheldorf",
  },
  {
    eva: "8100573",
    name: "Micheldorf-Hirt",
  },
  {
    eva: "8101217",
    name: "Michelhausen",
  },
  {
    eva: "8101218",
    name: "Miesenbach-Waidmannsfeld",
  },
  {
    eva: "8300046",
    name: "Milano",
  },
  {
    eva: "8300062",
    name: "Milano Lambrate",
  },
  {
    eva: "8300047",
    name: "Milano Porta Garibaldi",
  },
  {
    eva: "8100478",
    name: "Mining",
  },
  {
    eva: "8101245",
    name: "Mistelbach",
  },
  {
    eva: "8102007",
    name: "Mistelbach Stadt",
  },
  {
    eva: "8004043",
    name: "Mittenwald",
  },
  {
    eva: "8004044",
    name: "Mittenwald(Gr)",
  },
  {
    eva: "8100479",
    name: "Mitterbach",
  },
  {
    eva: "8101219",
    name: "Mitterberghütten",
  },
  {
    eva: "8101221",
    name: "Mitterdorf-Veitsch",
  },
  {
    eva: "8100480",
    name: "Mittersill",
  },
  {
    eva: "8100417",
    name: "Mittewald a. d. Drau",
  },
  {
    eva: "8101222",
    name: "Mittlere Au",
  },
  {
    eva: "8100418",
    name: "Mittlern",
  },
  {
    eva: "8101210",
    name: "Mixnitz-Bärenschützklamm",
  },
  {
    eva: "8101231",
    name: "Mogersdorf",
  },
  {
    eva: "8100376",
    name: "Moosbierbaum-Heiligeneich",
  },
  {
    eva: "5500016",
    name: "Mosonmagyarovar",
  },
  {
    eva: "8101247",
    name: "Muckendorf-Wipfing",
  },
  {
    eva: "8100489",
    name: "Munderfing",
  },
  {
    eva: "8100578",
    name: "Muntigl Bahnhof",
  },
  {
    eva: "8100490",
    name: "Murau Bahnhof",
  },
  {
    eva: "8101490",
    name: "Murau St.Egidi Bahnhof",
  },
  {
    eva: "8101842",
    name: "Murau St.Leonhard Bahnhst",
  },
  {
    eva: "8101843",
    name: "Murau West Bahnhst",
  },
  {
    eva: "8100491",
    name: "Mureck",
  },
  {
    eva: "8101230",
    name: "Mödling",
  },
  {
    eva: "8100419",
    name: "Möllbrücke-Sachsenburg",
  },
  {
    eva: "8101229",
    name: "Möllersdorf Aspangbahn",
  },
  {
    eva: "8100484",
    name: "Mönchhof-Halbturn",
  },
  {
    eva: "8101237",
    name: "Mötz",
  },
  {
    eva: "8100421",
    name: "Mühlbach im Pinzgau",
  },
  {
    eva: "8100087",
    name: "Mühldorf-Möllbrücke",
  },
  {
    eva: "8101248",
    name: "Mühlheim",
  },
  {
    eva: "8101225",
    name: "Mühling",
  },
  {
    eva: "8101841",
    name: "Mühling-Plaika",
  },
  {
    eva: "8100424",
    name: "Müllendorf",
  },
  {
    eva: "8000261",
    name: "München Hbf",
  },
  {
    eva: "8098261",
    name: "München Hbf Gl.27-36",
  },
  {
    eva: "8098262",
    name: "München Hbf Gl.5-10",
  },
  {
    eva: "8000262",
    name: "München Ost",
  },
  {
    eva: "8100423",
    name: "Münchendorf",
  },
  {
    eva: "8100488",
    name: "Münster-Wiesing",
  },
  {
    eva: "8100029",
    name: "Mürzzuschlag",
  },
  {
    eva: "8700018",
    name: "Nancy",
  },
  {
    eva: "8300239",
    name: "Napoli",
  },
  {
    eva: "8101262",
    name: "Nendeln",
  },
  {
    eva: "8100068",
    name: "Nenzing",
  },
  {
    eva: "8101265",
    name: "Nettingsdorf",
  },
  {
    eva: "8101261",
    name: "Neubau-Kreuzstetten",
  },
  {
    eva: "8100388",
    name: "Neudorf",
  },
  {
    eva: "8100494",
    name: "Neudörfl",
  },
  {
    eva: "8100495",
    name: "Neufeld a. d. Leitha",
  },
  {
    eva: "8100430",
    name: "Neufelden",
  },
  {
    eva: "8101267",
    name: "Neuhaus a. d. Gail",
  },
  {
    eva: "8101282",
    name: "Neuhaus-Niederwaldkirchen",
  },
  {
    eva: "8101255",
    name: "Neuhofen a. d. Krems",
  },
  {
    eva: "8100433",
    name: "Neukirchen am Großvenediger",
  },
  {
    eva: "8101278",
    name: "Neukirchen bei Lambach",
  },
  {
    eva: "8101260",
    name: "Neukirchen-Gampern",
  },
  {
    eva: "8101279",
    name: "Neulengbach",
  },
  {
    eva: "8101280",
    name: "Neulengbach Stadt",
  },
  {
    eva: "8101293",
    name: "Neumarkt a. d. Ybbs-Karlsbach",
  },
  {
    eva: "8100075",
    name: "Neumarkt in Steiermark",
  },
  {
    eva: "8100023",
    name: "Neumarkt-Kallham",
  },
  {
    eva: "8100134",
    name: "Neumarkt/Wallersee",
  },
  {
    eva: "8101277",
    name: "Neunkirchen NÖ",
  },
  {
    eva: "8101288",
    name: "Neuratting",
  },
  {
    eva: "8100434",
    name: "Neusiedl  am See",
  },
  {
    eva: "8000276",
    name: "Neuwied",
  },
  {
    eva: "8100499",
    name: "Nickelsdorf",
  },
  {
    eva: "8101272",
    name: "Niederkreuzstetten",
  },
  {
    eva: "8101287",
    name: "Niedernfritz-St. Martin",
  },
  {
    eva: "8100500",
    name: "Niedernsill",
  },
  {
    eva: "8100438",
    name: "Niederspaching Bahnhof",
  },
  {
    eva: "8101285",
    name: "Niederwölz Bahnhof",
  },
  {
    eva: "8101273",
    name: "Niederöblarn",
  },
  {
    eva: "8101275",
    name: "Niklasdorf",
  },
  {
    eva: "8101274",
    name: "Nikolsdorf",
  },
  {
    eva: "7400007",
    name: "Norrköping",
  },
  {
    eva: "8101292",
    name: "Nußbach",
  },
  {
    eva: "5500957",
    name: "Nyirabrany",
  },
  {
    eva: "7400017",
    name: "Nässjö",
  },
  {
    eva: "8101283",
    name: "Nöstlbach-St. Marien",
  },
  {
    eva: "8101284",
    name: "Nötsch",
  },
  {
    eva: "8000284",
    name: "Nürnberg",
  },
  {
    eva: "8101289",
    name: "Nüziders",
  },
  {
    eva: "8101315",
    name: "Ober Olberndorf",
  },
  {
    eva: "8101328",
    name: "Ober Waltersdorf",
  },
  {
    eva: "8100505",
    name: "Ober-Grafendorf",
  },
  {
    eva: "8102006",
    name: "Oberalm",
  },
  {
    eva: "8101294",
    name: "Oberaschach Bahnhof",
  },
  {
    eva: "8101295",
    name: "Oberbrunn",
  },
  {
    eva: "8100144",
    name: "Oberdrauburg",
  },
  {
    eva: "8100439",
    name: "Obereggendorf",
  },
  {
    eva: "8101299",
    name: "Oberhart",
  },
  {
    eva: "8102053",
    name: "Oberhofen im Inntal",
  },
  {
    eva: "8101332",
    name: "Oberhofen-Zell am Moos",
  },
  {
    eva: "8101313",
    name: "Oberland",
  },
  {
    eva: "8100442",
    name: "Obernberg-Altheim",
  },
  {
    eva: "8100509",
    name: "Oberndorf b.Salzburg Bahnhof",
  },
  {
    eva: "8101309",
    name: "Oberndorf b.Salzburg Oichtensiedlung Bahnhof",
  },
  {
    eva: "8101874",
    name: "Oberndorf b.Salzburg- Laufen Bahnhst",
  },
  {
    eva: "8101792",
    name: "Oberndorf in Tirol",
  },
  {
    eva: "8101316",
    name: "Oberpiesting",
  },
  {
    eva: "8101319",
    name: "Oberradlberg",
  },
  {
    eva: "8101317",
    name: "Obersdorf",
  },
  {
    eva: "8101322",
    name: "Obersee",
  },
  {
    eva: "8101324",
    name: "Oberthalheim-Timelkam",
  },
  {
    eva: "8101325",
    name: "Obertrattnach-Markt Hofkirchen",
  },
  {
    eva: "8101311",
    name: "Obertraun Koppenbrüllerhöhle",
  },
  {
    eva: "8100163",
    name: "Obertraun-Dachsteinhöhlen",
  },
  {
    eva: "8101329",
    name: "Oberweiden",
  },
  {
    eva: "8601770",
    name: "Odense",
  },
  {
    eva: "5341884",
    name: "Odoreu",
  },
  {
    eva: "8100579",
    name: "Oed",
  },
  {
    eva: "8101305",
    name: "Oedt b.Feldbach Bahnhst",
  },
  {
    eva: "8101307",
    name: "Oedt b.Feldbach Siedlung Bahnhst",
  },
  {
    eva: "8101306",
    name: "Oepping",
  },
  {
    eva: "8000290",
    name: "Offenburg",
  },
  {
    eva: "8101308",
    name: "Oftering",
  },
  {
    eva: "7800040",
    name: "Ogulin",
  },
  {
    eva: "8101323",
    name: "Oisnitz-St. Josef",
  },
  {
    eva: "5102560",
    name: "Olawa",
  },
  {
    eva: "8101312",
    name: "Ollersbach",
  },
  {
    eva: "7800002",
    name: "Opatija-Matulji",
  },
  {
    eva: "5100046",
    name: "Opole Glowne",
  },
  {
    eva: "8101320",
    name: "Ortmann",
  },
  {
    eva: "8300257",
    name: "Orvieto",
  },
  {
    eva: "8100037",
    name: "Ossiach-Bodensdorf",
  },
  {
    eva: "8102067",
    name: "Ostermiething Bahnhof",
  },
  {
    eva: "5400026",
    name: "Ostrava",
  },
  {
    eva: "8100513",
    name: "Ottensheim",
  },
  {
    eva: "8101327",
    name: "Ottnang-Wolfsegg",
  },
  {
    eva: "8101336",
    name: "Paasdorf",
  },
  {
    eva: "8135419",
    name: "Pabing am Haunsberg Bahnhst",
  },
  {
    eva: "8601899",
    name: "Padborg",
  },
  {
    eva: "8300098",
    name: "Padova",
  },
  {
    eva: "8300341",
    name: "Palermo",
  },
  {
    eva: "8101334",
    name: "Palmsdorf im Attergau Bahnhof",
  },
  {
    eva: "8100543",
    name: "Pama",
  },
  {
    eva: "8100448",
    name: "Pamhagen",
  },
  {
    eva: "5400161",
    name: "Pardubice hl.n.",
  },
  {
    eva: "8700011",
    name: "Paris",
  },
  {
    eva: "8101335",
    name: "Parndorf",
  },
  {
    eva: "8100515",
    name: "Parndorf Ort",
  },
  {
    eva: "8101389",
    name: "Pasching",
  },
  {
    eva: "8000298",
    name: "Passau",
  },
  {
    eva: "8101393",
    name: "Passering",
  },
  {
    eva: "8100086",
    name: "Paternion-Feistritz",
  },
  {
    eva: "8101337",
    name: "Patsch",
  },
  {
    eva: "8101338",
    name: "Paudorf",
  },
  {
    eva: "8100027",
    name: "Payerbach-Reichenau",
  },
  {
    eva: "8100034",
    name: "Peggau-Deutschfeistritz",
  },
  {
    eva: "8101346",
    name: "Perchtoldsdorf",
  },
  {
    eva: "8100580",
    name: "Perg",
  },
  {
    eva: "8102016",
    name: "Perg Schulzentrum",
  },
  {
    eva: "7800012",
    name: "Perkovic",
  },
  {
    eva: "7875607",
    name: "Permani",
  },
  {
    eva: "8101370",
    name: "Pernegg",
  },
  {
    eva: "8101975",
    name: "Pernitz Raimundviertel",
  },
  {
    eva: "8101858",
    name: "Pernitz Wipfelhofstraße",
  },
  {
    eva: "8101369",
    name: "Pernitz-Muggendorf",
  },
  {
    eva: "8300216",
    name: "Pesaro",
  },
  {
    eva: "8300124",
    name: "Peschiera del Garda",
  },
  {
    eva: "8101347",
    name: "Petersbaumgarten",
  },
  {
    eva: "8101399",
    name: "Peterskirchen",
  },
  {
    eva: "8100520",
    name: "Petronell-Carnuntum",
  },
  {
    eva: "8100521",
    name: "Pettenbach",
  },
  {
    eva: "8101414",
    name: "Petzenkirchen",
  },
  {
    eva: "8100522",
    name: "Peuerbach Bahnhof",
  },
  {
    eva: "8101351",
    name: "Pfaffenschwendt",
  },
  {
    eva: "8101352",
    name: "Pfaffstätten",
  },
  {
    eva: "8101353",
    name: "Pfarrwerfen",
  },
  {
    eva: "8101349",
    name: "Pfennigbach",
  },
  {
    eva: "8101356",
    name: "Pichl",
  },
  {
    eva: "8100523",
    name: "Piesendorf",
  },
  {
    eva: "8101848",
    name: "Piesendorf Bad",
  },
  {
    eva: "8101394",
    name: "Piesting",
  },
  {
    eva: "8101974",
    name: "Piesting Harzwerk",
  },
  {
    eva: "8100524",
    name: "Pill-Vomperbach",
  },
  {
    eva: "8100186",
    name: "Pinggau Markt",
  },
  {
    eva: "8101361",
    name: "Pinsdorf",
  },
  {
    eva: "8101397",
    name: "Pirtendorf",
  },
  {
    eva: "8300169",
    name: "Pisa",
  },
  {
    eva: "8100191",
    name: "Pitten",
  },
  {
    eva: "7900011",
    name: "Pivka",
  },
  {
    eva: "8101363",
    name: "Plank am Kamp",
  },
  {
    eva: "8101367",
    name: "Platt",
  },
  {
    eva: "8000301",
    name: "Plattling",
  },
  {
    eva: "8300110",
    name: "Pordenone",
  },
  {
    eva: "7900012",
    name: "Postojna",
  },
  {
    eva: "8012666",
    name: "Potsdam",
  },
  {
    eva: "8101396",
    name: "Pottenbrunn",
  },
  {
    eva: "8100525",
    name: "Pottendorf-Landegg",
  },
  {
    eva: "8101401",
    name: "Pottenstein a. d. Triesting",
  },
  {
    eva: "8101390",
    name: "Pottschach",
  },
  {
    eva: "7900007",
    name: "Pragersko",
  },
  {
    eva: "5400201",
    name: "Praha Holesovice",
  },
  {
    eva: "5400014",
    name: "Praha hl. n.",
  },
  {
    eva: "8100526",
    name: "Pram-Haag",
  },
  {
    eva: "8101844",
    name: "Prambachkirchen-Bad Weinberg Bahnhof",
  },
  {
    eva: "8101339",
    name: "Prambeckenhof Bahnhof",
  },
  {
    eva: "8101340",
    name: "Prattsdorf-Dachsberg Bahnhof",
  },
  {
    eva: "8101343",
    name: "Preding-Wieselsdorf",
  },
  {
    eva: "8101847",
    name: "Predlitz Pichl Bahnhof",
  },
  {
    eva: "8101385",
    name: "Predlitz Turrach Bahnhof",
  },
  {
    eva: "8100528",
    name: "Pregarten",
  },
  {
    eva: "8101382",
    name: "Premstätten-Tobelbad",
  },
  {
    eva: "8101371",
    name: "Prenning Bahnhof",
  },
  {
    eva: "8101372",
    name: "Prenning Viertler Bahnhof",
  },
  {
    eva: "8101381",
    name: "Pressbaum",
  },
  {
    eva: "8100529",
    name: "Pressegger See",
  },
  {
    eva: "8004885",
    name: "Prien am Chiemsee",
  },
  {
    eva: "8101387",
    name: "Prinzersdorf",
  },
  {
    eva: "8101395",
    name: "Pritschitz",
  },
  {
    eva: "8101386",
    name: "Pruggern",
  },
  {
    eva: "5100234",
    name: "Przemysl Gl.",
  },
  {
    eva: "5102825",
    name: "Przemysl Zasanie",
  },
  {
    eva: "5100195",
    name: "Przeworsk",
  },
  {
    eva: "8101380",
    name: "Prädiberg Bahnhof",
  },
  {
    eva: "8102054",
    name: "Puch Urstein",
  },
  {
    eva: "8101406",
    name: "Puch bei Hallein",
  },
  {
    eva: "8101877",
    name: "Puch bei Villach",
  },
  {
    eva: "8101403",
    name: "Puchberg  am Schneeberg",
  },
  {
    eva: "8101402",
    name: "Puchenau",
  },
  {
    eva: "8101409",
    name: "Puchenau West",
  },
  {
    eva: "8100530",
    name: "Puchenstuben",
  },
  {
    eva: "8101405",
    name: "Pulgarn",
  },
  {
    eva: "8100532",
    name: "Purbach am Neusiedlersee",
  },
  {
    eva: "8101391",
    name: "Purgstall",
  },
  {
    eva: "8101342",
    name: "Purkersdorf Sanatorium",
  },
  {
    eva: "8101404",
    name: "Purkersdorf Zentrum",
  },
  {
    eva: "8101408",
    name: "Purkla",
  },
  {
    eva: "8101373",
    name: "Pusarnitz",
  },
  {
    eva: "8100806",
    name: "Puxerboden Bahnhst",
  },
  {
    eva: "8100006",
    name: "Pöchlarn",
  },
  {
    eva: "8101375",
    name: "Pöckau",
  },
  {
    eva: "8101376",
    name: "Pöham",
  },
  {
    eva: "8101364",
    name: "Pölfing-Brunn",
  },
  {
    eva: "8101377",
    name: "Pöndorf",
  },
  {
    eva: "8100080",
    name: "Pörtschach/Wörther See",
  },
  {
    eva: "8101379",
    name: "Pötting im Hausruckkreis Bahnhof",
  },
  {
    eva: "8100531",
    name: "Pürbach-Schrems",
  },
  {
    eva: "8100582",
    name: "Pürgg",
  },
  {
    eva: "5500055",
    name: "Püspökladany",
  },
  {
    eva: "8100193",
    name: "Raaba",
  },
  {
    eva: "8101415",
    name: "Raasdorf",
  },
  {
    eva: "8101416",
    name: "Rabensburg",
  },
  {
    eva: "8101423",
    name: "Rabenstein a. d. Pielach",
  },
  {
    eva: "5100263",
    name: "Raciborz",
  },
  {
    eva: "8100138",
    name: "Radstadt",
  },
  {
    eva: "5103068",
    name: "Radymno",
  },
  {
    eva: "8101421",
    name: "Rainfeld-Kleinzell",
  },
  {
    eva: "8101419",
    name: "Ramingdorf-Haidershofen",
  },
  {
    eva: "8101443",
    name: "Ramingstein Bahnhof",
  },
  {
    eva: "8101444",
    name: "Ramingstein-Thomatal Bahnhof",
  },
  {
    eva: "8100400",
    name: "Ramsau im Zillertal-Hippach Bahnhof",
  },
  {
    eva: "8100119",
    name: "Rankweil",
  },
  {
    eva: "8100465",
    name: "Rattenberg-Kramsach",
  },
  {
    eva: "8101428",
    name: "Redl-Zipf",
  },
  {
    eva: "8101430",
    name: "Regelsbrunn",
  },
  {
    eva: "8000309",
    name: "Regensburg",
  },
  {
    eva: "8101431",
    name: "Reichraming",
  },
  {
    eva: "8100112",
    name: "Reith",
  },
  {
    eva: "8101432",
    name: "Rekawinkel",
  },
  {
    eva: "8101456",
    name: "Rettenbach",
  },
  {
    eva: "8100469",
    name: "Retz",
  },
  {
    eva: "8300225",
    name: "Riccione",
  },
  {
    eva: "8100405",
    name: "Ried  im Innkreis",
  },
  {
    eva: "8100406",
    name: "Ried im Zillertal Bahnhof",
  },
  {
    eva: "8100407",
    name: "Riedau",
  },
  {
    eva: "8100473",
    name: "Riedenburg",
  },
  {
    eva: "8101916",
    name: "Riedersbach Bahnhof",
  },
  {
    eva: "8101457",
    name: "Rietz",
  },
  {
    eva: "7800013",
    name: "Rijeka",
  },
  {
    eva: "8300221",
    name: "Rimini",
  },
  {
    eva: "8100167",
    name: "Rohr-Bad Hall",
  },
  {
    eva: "8101454",
    name: "Rohr/Raab",
  },
  {
    eva: "8101439",
    name: "Rohrbach a. d. Gölsen",
  },
  {
    eva: "8100474",
    name: "Rohrbach-Berg",
  },
  {
    eva: "8100183",
    name: "Rohrbach-Vorau",
  },
  {
    eva: "8101453",
    name: "Rohrendorf",
  },
  {
    eva: "8300263",
    name: "Roma",
  },
  {
    eva: "5103157",
    name: "Ropczyce",
  },
  {
    eva: "8101448",
    name: "Roppen",
  },
  {
    eva: "8101449",
    name: "Rosenau",
  },
  {
    eva: "8100083",
    name: "Rosenbach",
  },
  {
    eva: "8100411",
    name: "Rosenburg",
  },
  {
    eva: "8000320",
    name: "Rosenheim",
  },
  {
    eva: "8101440",
    name: "Rosental",
  },
  {
    eva: "8010304",
    name: "Rostock",
  },
  {
    eva: "8012221",
    name: "Rostock-Lichtenhagen",
  },
  {
    eva: "8101973",
    name: "Rothengrub",
  },
  {
    eva: "8101447",
    name: "Rothenthurn",
  },
  {
    eva: "8101451",
    name: "Rotholz Bahnhof",
  },
  {
    eva: "8100476",
    name: "Rottenegg",
  },
  {
    eva: "8300113",
    name: "Rovereto",
  },
  {
    eva: "8300238",
    name: "Rovigo",
  },
  {
    eva: "8101450",
    name: "Roßleithen",
  },
  {
    eva: "7800475",
    name: "Rukavac",
  },
  {
    eva: "8100477",
    name: "Rum",
  },
  {
    eva: "5402728",
    name: "Rybnik",
  },
  {
    eva: "5100262",
    name: "Rybnik (PL)",
  },
  {
    eva: "5100082",
    name: "Rzepin",
  },
  {
    eva: "5100229",
    name: "Rzeszow Glowny",
  },
  {
    eva: "5103245",
    name: "Rzeszow Zachodni",
  },
  {
    eva: "8100049",
    name: "Saalfelden",
  },
  {
    eva: "8101463",
    name: "Saffen",
  },
  {
    eva: "8100002",
    name: "Salzburg",
  },
  {
    eva: "8101470",
    name: "Salzburg Aigen",
  },
  {
    eva: "8102048",
    name: "Salzburg Aiglhof",
  },
  {
    eva: "8100559",
    name: "Salzburg Gnigl",
  },
  {
    eva: "8100414",
    name: "Salzburg Hbf (Bahnsteige 11-12)",
  },
  {
    eva: "8101476",
    name: "Salzburg Itzling Bahnhof",
  },
  {
    eva: "8101558",
    name: "Salzburg Kasern",
  },
  {
    eva: "8102063",
    name: "Salzburg Liefering",
  },
  {
    eva: "8100404",
    name: "Salzburg Maria Plain-Plainbrücke Bahnhof",
  },
  {
    eva: "8102052",
    name: "Salzburg Mülln-Altstadt",
  },
  {
    eva: "8101481",
    name: "Salzburg Parsch",
  },
  {
    eva: "8102055",
    name: "Salzburg Sam",
  },
  {
    eva: "8101917",
    name: "Salzburg Süd",
  },
  {
    eva: "8102045",
    name: "Salzburg Taxham Europark",
  },
  {
    eva: "8300114",
    name: "San Candido/Innichen",
  },
  {
    eva: "7800067",
    name: "Sapjane",
  },
  {
    eva: "8101467",
    name: "Sarasdorf",
  },
  {
    eva: "8509411",
    name: "Sargans",
  },
  {
    eva: "8100415",
    name: "Sarmingstein Bahnhof",
  },
  {
    eva: "5400228",
    name: "Satov",
  },
  {
    eva: "8100036",
    name: "Sattendorf",
  },
  {
    eva: "8100416",
    name: "Sattledt",
  },
  {
    eva: "5300090",
    name: "Satu Mare",
  },
  {
    eva: "8100583",
    name: "Saurau Bahnhof",
  },
  {
    eva: "8101611",
    name: "Sautern-Schiltern",
  },
  {
    eva: "8101469",
    name: "Saxen",
  },
  {
    eva: "8100482",
    name: "Schaan-Vaduz",
  },
  {
    eva: "8100483",
    name: "Schaftenau",
  },
  {
    eva: "8100088",
    name: "Scharnitz",
  },
  {
    eva: "8100420",
    name: "Scharnstein-Mühldorf",
  },
  {
    eva: "8101480",
    name: "Schauboden",
  },
  {
    eva: "8101852",
    name: "Schauersberg",
  },
  {
    eva: "8100485",
    name: "Scheibbs",
  },
  {
    eva: "8101546",
    name: "Scheiblingkirchen-Warth",
  },
  {
    eva: "8101538",
    name: "Scheifling",
  },
  {
    eva: "8100137",
    name: "Schladming",
  },
  {
    eva: "8101535",
    name: "Schleinbach",
  },
  {
    eva: "8101540",
    name: "Schlierbach",
  },
  {
    eva: "8101543",
    name: "Schlins-Beschling",
  },
  {
    eva: "8100486",
    name: "Schlitters-Bruck am Ziller Bahnhof",
  },
  {
    eva: "8100585",
    name: "Schloß Haus",
  },
  {
    eva: "8101516",
    name: "Schlägl",
  },
  {
    eva: "8100587",
    name: "Schlöglmühl",
  },
  {
    eva: "8100584",
    name: "Schlüßlberg",
  },
  {
    eva: "8101517",
    name: "Schmidham im Attergau Bahnhof",
  },
  {
    eva: "8101586",
    name: "Schrambach",
  },
  {
    eva: "8100117",
    name: "Schruns",
  },
  {
    eva: "8101581",
    name: "Schurrerprambach Bahnhof",
  },
  {
    eva: "8100016",
    name: "Schwanenstadt",
  },
  {
    eva: "8101636",
    name: "Schwarza",
  },
  {
    eva: "8101634",
    name: "Schwarzach in Vorarlberg",
  },
  {
    eva: "8100044",
    name: "Schwarzach-St. Veit",
  },
  {
    eva: "8100199",
    name: "Schwarzenau",
  },
  {
    eva: "8101632",
    name: "Schwarzenbach a. d. Pielach",
  },
  {
    eva: "8101593",
    name: "Schwarzsee",
  },
  {
    eva: "8100103",
    name: "Schwaz",
  },
  {
    eva: "8100259",
    name: "Schwechat",
  },
  {
    eva: "8101475",
    name: "Schwerbach",
  },
  {
    eva: "8101601",
    name: "Schwertberg",
  },
  {
    eva: "8100024",
    name: "Schärding",
  },
  {
    eva: "8100487",
    name: "Schönberg am Kamp",
  },
  {
    eva: "8101548",
    name: "Schönborn-Mallebarn",
  },
  {
    eva: "8101545",
    name: "Schönfeld-Lassee",
  },
  {
    eva: "8101629",
    name: "Schönwies",
  },
  {
    eva: "8102025",
    name: "Schüttdorf Areitbahn Bahnhst",
  },
  {
    eva: "8101840",
    name: "Schüttdorf Kitzsteinhornstraße",
  },
  {
    eva: "8101671",
    name: "Schüttdorf Tischlerhäusl",
  },
  {
    eva: "8101617",
    name: "Schützen Haltestelle",
  },
  {
    eva: "8100422",
    name: "Schützen am Gebirge",
  },
  {
    eva: "8100179",
    name: "Sebersdorf",
  },
  {
    eva: "5103349",
    name: "Sedziszow Malopolski",
  },
  {
    eva: "8100190",
    name: "Seebenstein",
  },
  {
    eva: "8100113",
    name: "Seefeld in Tirol",
  },
  {
    eva: "8100426",
    name: "Seekirchen am Wallersee",
  },
  {
    eva: "5300319",
    name: "Seini",
  },
  {
    eva: "8100150",
    name: "Selzthal",
  },
  {
    eva: "8100028",
    name: "Semmering",
  },
  {
    eva: "7900064",
    name: "Sentilj",
  },
  {
    eva: "7900018",
    name: "Sevnica",
  },
  {
    eva: "8101498",
    name: "Seyring",
  },
  {
    eva: "8101477",
    name: "Siebenbrunn-Leopoldsdorf",
  },
  {
    eva: "8101523",
    name: "Siebenhirten NÖ",
  },
  {
    eva: "8101478",
    name: "Siebenmühlen-Rosenau",
  },
  {
    eva: "8101522",
    name: "Sierndorf",
  },
  {
    eva: "8101524",
    name: "Sierndorf a. d. March",
  },
  {
    eva: "8101527",
    name: "Siggerwiesen Bahnhof",
  },
  {
    eva: "8100493",
    name: "Sigmundsherberg",
  },
  {
    eva: "8100591",
    name: "Silberwald",
  },
  {
    eva: "8100142",
    name: "Sillian",
  },
  {
    eva: "8100586",
    name: "Silz",
  },
  {
    eva: "8000072",
    name: "Simbach  (Inn)",
  },
  {
    eva: "8000073",
    name: "Singen",
  },
  {
    eva: "8101587",
    name: "Sitzenberg-Reidling",
  },
  {
    eva: "8101567",
    name: "Sollenau",
  },
  {
    eva: "8101573",
    name: "Sonntagberg",
  },
  {
    eva: "5501494",
    name: "Sopron",
  },
  {
    eva: "5100197",
    name: "Sosnowiec Pld",
  },
  {
    eva: "8101576",
    name: "Sperneck b.Eferding Bahnhof",
  },
  {
    eva: "8101922",
    name: "Spielfeld Staatsgrenze (Tarifpunkt)",
  },
  {
    eva: "8100082",
    name: "Spielfeld-Straß",
  },
  {
    eva: "8101578",
    name: "Spillern",
  },
  {
    eva: "8100306",
    name: "Spital am Pyhrn",
  },
  {
    eva: "8101596",
    name: "Spital am Semmering",
  },
  {
    eva: "8100092",
    name: "Spittal-Millstättersee",
  },
  {
    eva: "7800016",
    name: "Split",
  },
  {
    eva: "7800495",
    name: "Split Predgrade",
  },
  {
    eva: "8100431",
    name: "St. Andrä am Zicksee",
  },
  {
    eva: "8100496",
    name: "St. Andrä im Lavanttal",
  },
  {
    eva: "8100432",
    name: "St. Andrä-Wördern",
  },
  {
    eva: "8100064",
    name: "St. Anton am Arlberg",
  },
  {
    eva: "8100497",
    name: "St. Anton im Montafon",
  },
  {
    eva: "8101489",
    name: "St. Egyden",
  },
  {
    eva: "8506302",
    name: "St. Gallen",
  },
  {
    eva: "8101501",
    name: "St. Georgen a. d. Gusen",
  },
  {
    eva: "8101502",
    name: "St. Georgen a. d. Gusen Ort",
  },
  {
    eva: "8101506",
    name: "St. Georgen a. d. Mattig",
  },
  {
    eva: "8101505",
    name: "St. Georgen am Längsee",
  },
  {
    eva: "8101510",
    name: "St. Georgen am Steinfelde",
  },
  {
    eva: "8101504",
    name: "St. Georgen ob Judenburg",
  },
  {
    eva: "8101529",
    name: "St. Jodok",
  },
  {
    eva: "8100181",
    name: "St. Johann i. d. Haide",
  },
  {
    eva: "8100043",
    name: "St. Johann im Pongau",
  },
  {
    eva: "8100054",
    name: "St. Johann in Tirol",
  },
  {
    eva: "8101530",
    name: "St. Johann-Weistrach",
  },
  {
    eva: "8101243",
    name: "St. Marein-St. Lorenzen",
  },
  {
    eva: "8506314",
    name: "St. Margrethen SG",
  },
  {
    eva: "8101560",
    name: "St. Martin bei Traun",
  },
  {
    eva: "8100564",
    name: "St. Martin i.S. Bergla",
  },
  {
    eva: "8100437",
    name: "St. Martin im Innkreis",
  },
  {
    eva: "8100502",
    name: "St. Martin-Sittich",
  },
  {
    eva: "8100071",
    name: "St. Michael",
  },
  {
    eva: "8101471",
    name: "St. Michael ob Bleiburg",
  },
  {
    eva: "8100503",
    name: "St. Nikola-Struden",
  },
  {
    eva: "8101579",
    name: "St. Pantaleon",
  },
  {
    eva: "8100501",
    name: "St. Paul",
  },
  {
    eva: "8101575",
    name: "St. Paul Bad",
  },
  {
    eva: "8101582",
    name: "St. Peter im Sulmtal",
  },
  {
    eva: "8101597",
    name: "St. Peter-Seitenstetten",
  },
  {
    eva: "8100008",
    name: "St. Pölten",
  },
  {
    eva: "8101374",
    name: "St. Pölten  Alpenbahnhof-Kaiserwald",
  },
  {
    eva: "8101948",
    name: "St. Pölten Alpenbahnhof",
  },
  {
    eva: "8101845",
    name: "St. Pölten Porschestraße",
  },
  {
    eva: "8101990",
    name: "St. Pölten-Traisenpark",
  },
  {
    eva: "8100440",
    name: "St. Stefan im Lavanttal",
  },
  {
    eva: "8101598",
    name: "St. Stefan-Vorderberg",
  },
  {
    eva: "8100506",
    name: "St. Urban am Ossiachersee",
  },
  {
    eva: "8100009",
    name: "St. Valentin",
  },
  {
    eva: "8100078",
    name: "St. Veit  a. d. Glan",
  },
  {
    eva: "8101624",
    name: "St. Veit a. d. Glan Westbf",
  },
  {
    eva: "8101622",
    name: "St. Veit a. d. Gölsen",
  },
  {
    eva: "8101623",
    name: "St. Veit a. d. Triesting",
  },
  {
    eva: "8100498",
    name: "St.Georgen im Attergau Bahnhof",
  },
  {
    eva: "8101665",
    name: "St.Georgen im Attergau Thern Bahnhof",
  },
  {
    eva: "8101542",
    name: "St.Lorenzen ob Murau Bahnhof",
  },
  {
    eva: "8106399",
    name: "St.Lorenzen ob Murau Ost Bahnhst",
  },
  {
    eva: "8101583",
    name: "St.Pantaleon-Reith Bahnhof",
  },
  {
    eva: "8101589",
    name: "St.Ruprecht ob Murau Bahnhof",
  },
  {
    eva: "8101591",
    name: "St.Ruprecht/Raab Bahnhof",
  },
  {
    eva: "8101600",
    name: "Staatz",
  },
  {
    eva: "8100308",
    name: "Stadl-Paura",
  },
  {
    eva: "8100533",
    name: "Stadl/Mur Bahnhof",
  },
  {
    eva: "8101514",
    name: "Stadt Haag",
  },
  {
    eva: "8100309",
    name: "Stadt Rottenmann",
  },
  {
    eva: "8101635",
    name: "Stadt Waidhofen a. d. Ybbs",
  },
  {
    eva: "8100132",
    name: "Stainach-Irdning",
  },
  {
    eva: "8101870",
    name: "Stallegg",
  },
  {
    eva: "8100589",
    name: "Stams",
  },
  {
    eva: "8100310",
    name: "Stans",
  },
  {
    eva: "8100311",
    name: "Statzendorf",
  },
  {
    eva: "8100162",
    name: "Steeg-Gosau",
  },
  {
    eva: "8101486",
    name: "Stefansdorf b.Peuerbach Bahnhof",
  },
  {
    eva: "8101494",
    name: "Stein a. d. Enns",
  },
  {
    eva: "8102011",
    name: "Steinach",
  },
  {
    eva: "8100107",
    name: "Steinach in Tirol",
  },
  {
    eva: "8101612",
    name: "Steinbachbrücke",
  },
  {
    eva: "8100020",
    name: "Steindorf  bei Straßwalchen",
  },
  {
    eva: "8100038",
    name: "Steindorf am Ossiachersee",
  },
  {
    eva: "8100149",
    name: "Steinfeld im Drautal",
  },
  {
    eva: "8101520",
    name: "Steinhaus",
  },
  {
    eva: "8101521",
    name: "Steinhaus bei Wels",
  },
  {
    eva: "8101533",
    name: "Steinklamm",
  },
  {
    eva: "8101534",
    name: "Steinkogel",
  },
  {
    eva: "8101679",
    name: "Steinschal-Tradigist",
  },
  {
    eva: "8100010",
    name: "Steyr",
  },
  {
    eva: "8101609",
    name: "Steyr-Münichholz",
  },
  {
    eva: "8101614",
    name: "Steyregg",
  },
  {
    eva: "8101638",
    name: "Steyrling",
  },
  {
    eva: "8101500",
    name: "Stiefern",
  },
  {
    eva: "8101536",
    name: "Stillfried",
  },
  {
    eva: "8100534",
    name: "Stockerau",
  },
  {
    eva: "7400002",
    name: "Stockholm",
  },
  {
    eva: "8700023",
    name: "Strasbourg",
  },
  {
    eva: "8100313",
    name: "Strass im Zillertal Bahnhof",
  },
  {
    eva: "8101595",
    name: "Strasshof",
  },
  {
    eva: "8000095",
    name: "Straubing",
  },
  {
    eva: "8101493",
    name: "Straß-Emling Bahnhof",
  },
  {
    eva: "8101906",
    name: "Straßham Wehrgasse Bahnhst",
  },
  {
    eva: "8101594",
    name: "Straßham-Schönering Bahnhof",
  },
  {
    eva: "8101515",
    name: "Straßhof/Aschach Bahnhof",
  },
  {
    eva: "8100019",
    name: "Straßwalchen",
  },
  {
    eva: "8102062",
    name: "Straßwalchen West",
  },
  {
    eva: "8100196",
    name: "Studenzen-Fladnitz",
  },
  {
    eva: "8100314",
    name: "Stuhlfelden",
  },
  {
    eva: "8000096",
    name: "Stuttgart",
  },
  {
    eva: "8101606",
    name: "Stöttham im Attergau Bahnhof",
  },
  {
    eva: "8100590",
    name: "Stübing",
  },
  {
    eva: "8100315",
    name: "Suben",
  },
  {
    eva: "8101585",
    name: "Sulz-Röthis",
  },
  {
    eva: "8101620",
    name: "Sulzbachtäler",
  },
  {
    eva: "8100316",
    name: "Summerau",
  },
  {
    eva: "5400007",
    name: "Summerau(Gr)",
  },
  {
    eva: "5400097",
    name: "Svitavy",
  },
  {
    eva: "5500029",
    name: "Szentgotthard",
  },
  {
    eva: "5501636",
    name: "Szentgotthárd(Gr)",
  },
  {
    eva: "8101468",
    name: "Säusenstein",
  },
  {
    eva: "8100429",
    name: "Söchau",
  },
  {
    eva: "8101557",
    name: "Söding-Mooskirchen",
  },
  {
    eva: "8101851",
    name: "Sölling",
  },
  {
    eva: "8100317",
    name: "Tainach-Stein",
  },
  {
    eva: "8100318",
    name: "Takern-St. Margarethen",
  },
  {
    eva: "8101644",
    name: "Tallesbrunn",
  },
  {
    eva: "8100319",
    name: "Tamsweg Bahnhof",
  },
  {
    eva: "8101854",
    name: "Tamsweg St.Leonhard Bahnhof",
  },
  {
    eva: "5100230",
    name: "Tarnow",
  },
  {
    eva: "8303915",
    name: "Tarvisio Boscoverde",
  },
  {
    eva: "8101646",
    name: "Tassenbach",
  },
  {
    eva: "8101647",
    name: "Tattendorf",
  },
  {
    eva: "8100187",
    name: "Tauchen-Schaueregg",
  },
  {
    eva: "8101652",
    name: "Tauchendorf-Haidensee",
  },
  {
    eva: "8100320",
    name: "Taufkirchen a. d. Pram",
  },
  {
    eva: "8100166",
    name: "Tauplitz",
  },
  {
    eva: "8100046",
    name: "Taxenbach-Rauris",
  },
  {
    eva: "8101656",
    name: "Teesdorf",
  },
  {
    eva: "8101658",
    name: "Teichstätt",
  },
  {
    eva: "8100060",
    name: "Telfs-Pfaffenhofen",
  },
  {
    eva: "8101660",
    name: "Tenneck",
  },
  {
    eva: "8100323",
    name: "Terfens-Weer",
  },
  {
    eva: "8100322",
    name: "Ternberg",
  },
  {
    eva: "8101661",
    name: "Ternitz",
  },
  {
    eva: "8101655",
    name: "Teufenbach/Mur Bahnhof",
  },
  {
    eva: "8100324",
    name: "Thal",
  },
  {
    eva: "8101678",
    name: "Thalheim-Pöls",
  },
  {
    eva: "8101664",
    name: "Theresienfeld",
  },
  {
    eva: "8101666",
    name: "Thurnharting Bahnhof",
  },
  {
    eva: "8101675",
    name: "Thörl-Maglern",
  },
  {
    eva: "8101669",
    name: "Tiffen",
  },
  {
    eva: "8101670",
    name: "Timelkam",
  },
  {
    eva: "8101685",
    name: "Tisis",
  },
  {
    eva: "8300001",
    name: "Torino",
  },
  {
    eva: "8101643",
    name: "Traisen",
  },
  {
    eva: "8101645",
    name: "Traisen Markt",
  },
  {
    eva: "8101640",
    name: "Traiskirchen Aspangbahn",
  },
  {
    eva: "8100325",
    name: "Traismauer",
  },
  {
    eva: "8101651",
    name: "Trasdorf",
  },
  {
    eva: "8101686",
    name: "Trattenbach",
  },
  {
    eva: "8101690",
    name: "Traun",
  },
  {
    eva: "8100153",
    name: "Traunkirchen",
  },
  {
    eva: "8101674",
    name: "Traunkirchen Ort",
  },
  {
    eva: "8000116",
    name: "Traunstein",
  },
  {
    eva: "8101653",
    name: "Trautmannsdorf a. d. Leitha",
  },
  {
    eva: "8101654",
    name: "Trautmannsdorf in Oststmk Bahnhof",
  },
  {
    eva: "8101684",
    name: "Traxenbichl",
  },
  {
    eva: "7900037",
    name: "Trbovlje",
  },
  {
    eva: "8100096",
    name: "Treibach-Althofen",
  },
  {
    eva: "8300101",
    name: "Trento",
  },
  {
    eva: "8300130",
    name: "Treviso",
  },
  {
    eva: "8100128",
    name: "Trieben",
  },
  {
    eva: "8101680",
    name: "Triebendorf Bahnhof",
  },
  {
    eva: "8300107",
    name: "Trieste",
  },
  {
    eva: "8319009",
    name: "Trieste Autostazione",
  },
  {
    eva: "8100379",
    name: "Trimmelkam Bahnhof",
  },
  {
    eva: "8101682",
    name: "Trumau",
  },
  {
    eva: "5100072",
    name: "Trzebinia",
  },
  {
    eva: "8100118",
    name: "Tschagguns",
  },
  {
    eva: "8101692",
    name: "Tulln  Stadt",
  },
  {
    eva: "8100203",
    name: "Tulln a.d.Donau",
  },
  {
    eva: "8101650",
    name: "Tullnerbach-Pressbaum",
  },
  {
    eva: "8102059",
    name: "Tullnerfeld",
  },
  {
    eva: "8101676",
    name: "Töschling",
  },
  {
    eva: "8100329",
    name: "Uderns im Zillertal Bahnhof",
  },
  {
    eva: "8300115",
    name: "Udine",
  },
  {
    eva: "8346393",
    name: "Udine stazione (Viale Europa Unita)",
  },
  {
    eva: "8102029",
    name: "Uggl-Schwarzenbach Bahnhof",
  },
  {
    eva: "8000170",
    name: "Ulm",
  },
  {
    eva: "8101695",
    name: "Ulmerfeld-Hausmening",
  },
  {
    eva: "8101697",
    name: "Ulrichskirchen",
  },
  {
    eva: "8101696",
    name: "Unter Höflein",
  },
  {
    eva: "8101702",
    name: "Unter Kritzendorf",
  },
  {
    eva: "8101704",
    name: "Unter Oberndorf",
  },
  {
    eva: "8101709",
    name: "Unter Purkersdorf",
  },
  {
    eva: "8101712",
    name: "Unterberg-Stefansbrücke",
  },
  {
    eva: "8100331",
    name: "Untereggendorf",
  },
  {
    eva: "8100794",
    name: "Unterfladnitz Bahnhof",
  },
  {
    eva: "8101700",
    name: "Unterhart",
  },
  {
    eva: "8101855",
    name: "Unterhillinglah Bahnhof",
  },
  {
    eva: "8101706",
    name: "Unterradlberg",
  },
  {
    eva: "8102056",
    name: "Unterretzbach",
  },
  {
    eva: "8101952",
    name: "Unterretzbach Staatsgrenze (Tarifpunkt)",
  },
  {
    eva: "8101701",
    name: "Untersiebenbrunn",
  },
  {
    eva: "8101713",
    name: "Untertullnerbach",
  },
  {
    eva: "8100074",
    name: "Unzmarkt",
  },
  {
    eva: "8101711",
    name: "Urschendorf",
  },
  {
    eva: "8400621",
    name: "Utrecht",
  },
  {
    eva: "8102030",
    name: "Uttendorf im Pinzgau Manlitzbrücke Bahnhst",
  },
  {
    eva: "8100333",
    name: "Uttendorf-Helpfau",
  },
  {
    eva: "8100334",
    name: "Uttendorf-Stubachtal",
  },
  {
    eva: "5300364",
    name: "Valea lui Mihai",
  },
  {
    eva: "5501423",
    name: "Vamospercs",
  },
  {
    eva: "8100380",
    name: "Vandans",
  },
  {
    eva: "8100081",
    name: "Velden am Wörther See",
  },
  {
    eva: "8101720",
    name: "Vellach-Khünburg",
  },
  {
    eva: "8300093",
    name: "Venezia Mestre",
  },
  {
    eva: "8324253",
    name: "Venezia Mestre stazione (Viale Stazione)",
  },
  {
    eva: "8300094",
    name: "Venezia S. Lucia",
  },
  {
    eva: "8385217",
    name: "Venezia Tronchetto",
  },
  {
    eva: "8300120",
    name: "Verona",
  },
  {
    eva: "8381962",
    name: "Versciaco-Elmo/Vierschach-Helm staz./Bf. (Ss49)",
  },
  {
    eva: "8300126",
    name: "Vicenza",
  },
  {
    eva: "8101716",
    name: "Viechtwang",
  },
  {
    eva: "8101717",
    name: "Viehofen",
  },
  {
    eva: "8100147",
    name: "Villach",
  },
  {
    eva: "8100148",
    name: "Villach  Westbf",
  },
  {
    eva: "0292022",
    name: "Villach Hbf (Busbahnhof)",
  },
  {
    eva: "8101496",
    name: "Villach Seebach",
  },
  {
    eva: "8101592",
    name: "Villach St. Ruprecht",
  },
  {
    eva: "8100382",
    name: "Villach Warmbad",
  },
  {
    eva: "7800018",
    name: "Vinkovci",
  },
  {
    eva: "8300121",
    name: "Vipiteno/Sterzing",
  },
  {
    eva: "8100335",
    name: "Vitis",
  },
  {
    eva: "8101725",
    name: "Voitsberg",
  },
  {
    eva: "8101723",
    name: "Voitsdorf",
  },
  {
    eva: "8100337",
    name: "Volders-Baumkirchen",
  },
  {
    eva: "8102043",
    name: "Vorchdorf Gewerbegebiet Bahnhof",
  },
  {
    eva: "8101856",
    name: "Vorchdorf Schule (Bahnhst)",
  },
  {
    eva: "8100338",
    name: "Vorchdorf-Eggenberg Bahnhof",
  },
  {
    eva: "8102012",
    name: "Vorstadl",
  },
  {
    eva: "8100507",
    name: "Vöcklabruck",
  },
  {
    eva: "8100508",
    name: "Vöcklamarkt",
  },
  {
    eva: "8100336",
    name: "Völkermarkt-Kühnsdorf",
  },
  {
    eva: "8101728",
    name: "Völs",
  },
  {
    eva: "8101730",
    name: "Wackersbach Bahnhof",
  },
  {
    eva: "8101764",
    name: "Wagram-Grafenegg",
  },
  {
    eva: "8100445",
    name: "Waidhofen/Ybbs Bahnhof",
  },
  {
    eva: "8100339",
    name: "Waizenkirchen Bahnhof",
  },
  {
    eva: "8100960",
    name: "Waizenkirchen Hochscharten Bahnhof",
  },
  {
    eva: "8101737",
    name: "Walchen im Attergau Bahnhof",
  },
  {
    eva: "8101738",
    name: "Walchen im Pinzgau",
  },
  {
    eva: "8100127",
    name: "Wald am Schoberpaß",
  },
  {
    eva: "8100340",
    name: "Wald im Pinzgau",
  },
  {
    eva: "8101745",
    name: "Waldegg",
  },
  {
    eva: "8101988",
    name: "Waldegg-Dürnbach",
  },
  {
    eva: "8101747",
    name: "Walding",
  },
  {
    eva: "8101807",
    name: "Waldstein Bahnhof",
  },
  {
    eva: "8100341",
    name: "Wallern im Burgenland",
  },
  {
    eva: "8101939",
    name: "Wallersbach b.Unzmarkt Bahnhof",
  },
  {
    eva: "8101803",
    name: "Wallersee",
  },
  {
    eva: "8101757",
    name: "Walsberg Bahnhof",
  },
  {
    eva: "8100342",
    name: "Wampersdorf",
  },
  {
    eva: "8101938",
    name: "Wandritsch Bahnhof",
  },
  {
    eva: "8101736",
    name: "Wankham",
  },
  {
    eva: "5100065",
    name: "Warszawa Centralna",
  },
  {
    eva: "5100172",
    name: "Warszawa Gdanska",
  },
  {
    eva: "5100066",
    name: "Warszawa Wschodnia",
  },
  {
    eva: "5100067",
    name: "Warszawa Zachodnia",
  },
  {
    eva: "8101742",
    name: "Wartberg a. d. Krems",
  },
  {
    eva: "8100383",
    name: "Wartberg im Mürztal",
  },
  {
    eva: "8100343",
    name: "Weiden am See",
  },
  {
    eva: "8100344",
    name: "Weigelsdorf",
  },
  {
    eva: "8101748",
    name: "Weikendorf-Dörfles",
  },
  {
    eva: "8101908",
    name: "Weinburg",
  },
  {
    eva: "8101755",
    name: "Weissenbach-Neuhaus",
  },
  {
    eva: "8101752",
    name: "Weitersfeld a. d. Mur",
  },
  {
    eva: "8101784",
    name: "Weitlanbrunn",
  },
  {
    eva: "8101811",
    name: "Weitwörth-Nußdorf Bahnhof",
  },
  {
    eva: "8101813",
    name: "Weiz Bahnhof",
  },
  {
    eva: "8117345",
    name: "Weiz Interspar Bahnhst",
  },
  {
    eva: "8101341",
    name: "Weiz Preding Bahnhst",
  },
  {
    eva: "8101814",
    name: "Weiz Zentrum (Bahnhst)",
  },
  {
    eva: "8100347",
    name: "Weizelsdorf",
  },
  {
    eva: "8101806",
    name: "Weißenbach-St. Gallen",
  },
  {
    eva: "8101783",
    name: "Weißenstein-Kellerberg",
  },
  {
    eva: "8100014",
    name: "Wels",
  },
  {
    eva: "8101754",
    name: "Wels Lokalbahn",
  },
  {
    eva: "8101946",
    name: "Wels Messe",
  },
  {
    eva: "8101785",
    name: "Wendling",
  },
  {
    eva: "8100595",
    name: "Wenns",
  },
  {
    eva: "8100539",
    name: "Werfen",
  },
  {
    eva: "8100593",
    name: "Werndorf",
  },
  {
    eva: "8100544",
    name: "Wernstein",
  },
  {
    eva: "8100058",
    name: "Westendorf",
  },
  {
    eva: "8101809",
    name: "Wettmannstätten",
  },
  {
    eva: "8100511",
    name: "Weyer",
  },
  {
    eva: "8102888",
    name: "Wien Aspern Nord",
  },
  {
    eva: "8100634",
    name: "Wien Atzgersdorf",
  },
  {
    eva: "8101003",
    name: "Wien Blumental",
  },
  {
    eva: "8100395",
    name: "Wien Breitensee",
  },
  {
    eva: "8100691",
    name: "Wien Brünner Straße",
  },
  {
    eva: "8100756",
    name: "Wien Erzherzog Karl Straße",
  },
  {
    eva: "8100236",
    name: "Wien Floridsdorf",
  },
  {
    eva: "8100446",
    name: "Wien Franz Josefs Bahnhof",
  },
  {
    eva: "8101555",
    name: "Wien Geiselbergstraße",
  },
  {
    eva: "8100249",
    name: "Wien Gersthof",
  },
  {
    eva: "8101556",
    name: "Wien Grillgasse",
  },
  {
    eva: "8100922",
    name: "Wien Hadersdorf",
  },
  {
    eva: "8199501",
    name: "Wien Hadersdorf Stadtgrenze (Tarifpunkt)",
  },
  {
    eva: "8100920",
    name: "Wien Haidestraße",
  },
  {
    eva: "8101934",
    name: "Wien Handelskai (Bahnsteige 1-2)",
  },
  {
    eva: "8101947",
    name: "Wien Handelskai (Bahnsteige 11-12)",
  },
  {
    eva: "8103000",
    name: "Wien Hbf",
  },
  {
    eva: "8101590",
    name: "Wien Hbf (Bahnsteige 1-2)",
  },
  {
    eva: "8100004",
    name: "Wien Hbf Autoreisezug",
  },
  {
    eva: "8100269",
    name: "Wien Heiligenstadt",
  },
  {
    eva: "8100271",
    name: "Wien Hernals",
  },
  {
    eva: "8100935",
    name: "Wien Hetzendorf",
  },
  {
    eva: "8100918",
    name: "Wien Hirschstetten",
  },
  {
    eva: "8100447",
    name: "Wien Hütteldorf",
  },
  {
    eva: "8101013",
    name: "Wien Jedlersdorf",
  },
  {
    eva: "8101022",
    name: "Wien Kahlenbergerdorf",
  },
  {
    eva: "8100374",
    name: "Wien Kaiserebersdorf",
  },
  {
    eva: "8100297",
    name: "Wien Krottenbachstraße",
  },
  {
    eva: "8101170",
    name: "Wien Leopoldau",
  },
  {
    eva: "8101150",
    name: "Wien Liesing",
  },
  {
    eva: "8101227",
    name: "Wien Matzleinsdorfer Platz",
  },
  {
    eva: "8100514",
    name: "Wien Meidling",
  },
  {
    eva: "8100449",
    name: "Wien Mitte",
  },
  {
    eva: "8101290",
    name: "Wien Nußdorf",
  },
  {
    eva: "8100441",
    name: "Wien Oberdöbling",
  },
  {
    eva: "8100512",
    name: "Wien Ottakring",
  },
  {
    eva: "8100450",
    name: "Wien Penzing",
  },
  {
    eva: "8101539",
    name: "Wien Praterkai",
  },
  {
    eva: "8100349",
    name: "Wien Praterstern",
  },
  {
    eva: "8101473",
    name: "Wien Quartier Belvedere",
  },
  {
    eva: "8101433",
    name: "Wien Rennweg",
  },
  {
    eva: "8100796",
    name: "Wien Siemensstraße",
  },
  {
    eva: "8101553",
    name: "Wien Simmering",
  },
  {
    eva: "8101853",
    name: "Wien Speising",
  },
  {
    eva: "8101937",
    name: "Wien Spittelau",
  },
  {
    eva: "8101818",
    name: "Wien St. Marx",
  },
  {
    eva: "8199503",
    name: "Wien St.Veit (Tarifpunkt)",
  },
  {
    eva: "8104229",
    name: "Wien Stadlau",
  },
  {
    eva: "8101588",
    name: "Wien Strebersdorf",
  },
  {
    eva: "8101619",
    name: "Wien Süßenbrunn",
  },
  {
    eva: "8101641",
    name: "Wien Traisengasse",
  },
  {
    eva: "8101760",
    name: "Wien Weidlingau",
  },
  {
    eva: "8100003",
    name: "Wien Westbahnhof",
  },
  {
    eva: "8102057",
    name: "Wien Wolf in der Au",
  },
  {
    eva: "8101820",
    name: "Wien Zentralfriedhof",
  },
  {
    eva: "8100516",
    name: "Wiener Neustadt",
  },
  {
    eva: "8102017",
    name: "Wiener Neustadt Anemonensee",
  },
  {
    eva: "8101789",
    name: "Wiener Neustadt Civitas Nova",
  },
  {
    eva: "8101791",
    name: "Wiener Neustadt Nord",
  },
  {
    eva: "8100354",
    name: "Wienerbruck-Josefsberg",
  },
  {
    eva: "8101775",
    name: "Wies Markt",
  },
  {
    eva: "8101750",
    name: "Wies-Eibiswald",
  },
  {
    eva: "8100355",
    name: "Wieselburg  a. d. Erlauf",
  },
  {
    eva: "8100356",
    name: "Wiesen-Sigleß",
  },
  {
    eva: "8101804",
    name: "Wiesenfeld-Schwarzenbach",
  },
  {
    eva: "8101778",
    name: "Wiesmühle",
  },
  {
    eva: "8101774",
    name: "Wildon",
  },
  {
    eva: "8162092",
    name: "Wildshut Gut Wildshut Bahnhof",
  },
  {
    eva: "8101773",
    name: "Wildshut Kirchberg Bahnhof",
  },
  {
    eva: "8101787",
    name: "Wildungsmauer",
  },
  {
    eva: "8101771",
    name: "Wilfleinsdorf",
  },
  {
    eva: "8101857",
    name: "Wilfling",
  },
  {
    eva: "8101768",
    name: "Wilhelmsburg a. d. Traisen",
  },
  {
    eva: "8101769",
    name: "Willendorf",
  },
  {
    eva: "8101767",
    name: "Willersdorf/Aschach Bahnhof",
  },
  {
    eva: "8101776",
    name: "Windau",
  },
  {
    eva: "8100357",
    name: "Winden",
  },
  {
    eva: "8100517",
    name: "Windischgarsten",
  },
  {
    eva: "8100358",
    name: "Winterbach",
  },
  {
    eva: "8506000",
    name: "Winterthur",
  },
  {
    eva: "8101781",
    name: "Winzendorf",
  },
  {
    eva: "8101788",
    name: "Wittmannsdorf",
  },
  {
    eva: "5104095",
    name: "Wodzislaw Slaski",
  },
  {
    eva: "8100359",
    name: "Wolfsberg",
  },
  {
    eva: "8101383",
    name: "Wolfsberg Reding",
  },
  {
    eva: "8101795",
    name: "Wolfsbergkogel",
  },
  {
    eva: "8101794",
    name: "Wolfshütte",
  },
  {
    eva: "8100360",
    name: "Wolfsthal",
  },
  {
    eva: "8100389",
    name: "Wolfurt",
  },
  {
    eva: "8101793",
    name: "Wolkersdorf",
  },
  {
    eva: "8101786",
    name: "Wollsdorf/Raab Bahnhof",
  },
  {
    eva: "8101797",
    name: "Wopfing",
  },
  {
    eva: "5100069",
    name: "Wroclaw Gl.",
  },
  {
    eva: "8100361",
    name: "Wulkaprodersdorf",
  },
  {
    eva: "8101810",
    name: "Wulkaprodersdorf Haltestelle",
  },
  {
    eva: "8101796",
    name: "Wöllersdorf",
  },
  {
    eva: "8102058",
    name: "Wöllersdorf Marchgraben",
  },
  {
    eva: "8100099",
    name: "Wörgl",
  },
  {
    eva: "8101571",
    name: "Wörgl Süd-Bruckhäusl",
  },
  {
    eva: "8100425",
    name: "Wörschach Schwefelbad",
  },
  {
    eva: "8000260",
    name: "Würzburg",
  },
  {
    eva: "8100007",
    name: "Ybbs a. d. Donau",
  },
  {
    eva: "5100070",
    name: "Zabrze",
  },
  {
    eva: "7900252",
    name: "Zagorje",
  },
  {
    eva: "7800020",
    name: "Zagreb",
  },
  {
    eva: "5100264",
    name: "Zebrzydowice",
  },
  {
    eva: "8101817",
    name: "Zehmemoos Bahnhof",
  },
  {
    eva: "8101825",
    name: "Zeiselmauer-Königstetten",
  },
  {
    eva: "8100048",
    name: "Zell  am See",
  },
  {
    eva: "8101819",
    name: "Zell a. d. Pram",
  },
  {
    eva: "8100547",
    name: "Zell am Ziller Bahnhof",
  },
  {
    eva: "8101861",
    name: "Zellermoos",
  },
  {
    eva: "8100363",
    name: "Zellerndorf",
  },
  {
    eva: "8100518",
    name: "Zeltweg",
  },
  {
    eva: "7900004",
    name: "Zidani Most",
  },
  {
    eva: "8101821",
    name: "Ziegelhaiden Bahnhof",
  },
  {
    eva: "5100222",
    name: "Zielona Gora",
  },
  {
    eva: "8101822",
    name: "Ziersdorf",
  },
  {
    eva: "8100451",
    name: "Zirl",
  },
  {
    eva: "8101828",
    name: "Zitoll Bahnhof",
  },
  {
    eva: "8101829",
    name: "Zurndorf",
  },
  {
    eva: "8101826",
    name: "Zöbing",
  },
  {
    eva: "8503000",
    name: "Zürich",
  },
  {
    eva: "8503016",
    name: "Zürich Flughafen",
  },
  {
    eva: "8100133",
    name: "Öblarn",
  },
  {
    eva: "8100061",
    name: "Ötztal",
  },
  {
    eva: "8101693",
    name: "Übelbach Bahnhof",
  },
  {
    eva: "8101714",
    name: "Übelbach Vormarkt Bahnhof",
  },
  {
    eva: "8101694",
    name: "Übersbach",
  },
];
</script>

<style lang="scss" scoped>
</style>
