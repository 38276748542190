<template>
  <div>
    <l-map
      :zoom="zoom"
      :center="center"
      :options="{ zoomControl: false }"
      :fadeAnimation="false"
      ref="lmap"
      @click="addMarker"
      @update:zoom="zoom = $event"
      :style="{ cursor: routerIsActive ? 'crosshair' : 'grab' }"
    >
      <l-control
        class="leaflet-control-layers leaflet-control"
        ref="mapOptions"
        position="topright"
        v-if="controlLayer"
      >
        <div
          v-if="!showOptions"
          class="leaflet-control-layers-toggle options-icon"
          @mouseover="showOptions = true"
          @click="showOptions = true"
        ></div>
        <div v-else @mouseleave="showOptions = false" class="m-2">
          <tl-map-options
            ><b-row class="my-1">
              <b-form-select
                text-field="name"
                value-field="value"
                :value="tileProvider"
                :options="tileProviders"
                @change="changeBaselayer"
              ></b-form-select> </b-row
            ><b-row class="my-1">
              <b-form-checkbox
                @input="openRailwayMapTileProvider.visible = $event"
                :checked="openRailwayMapTileProvider.visible"
                switch
              >
                OpenRailwayMap
              </b-form-checkbox>
            </b-row></tl-map-options
          >
        </div>
      </l-control>

      <template
        v-if="
          ($route.name === 'AddSection' || $route.name === 'AddSectionStore') &&
          mode === 'pt'
        "
      >
        <l-geo-json
          v-for="polyline in polylines"
          :geojson="polyline"
          :options="polylineOptions()"
          name="polyline"
        >
        </l-geo-json
      ></template>
      <l-feature-group
        ref="sectionPolylines"
        v-if="($route.params.trip || fromStore) && sections && !hideSections"
      >
        <template v-for="section in sections">
          <l-polyline
            v-if="
              !(
                routerIsActive &&
                $route.params.section_id === section.section_id
              ) && section.polyline2
            "
            :lat-lngs="section.polyline2"
            :color="section.options.color"
            :dashArray="section.options.dashArray"
            :opacity="fullSectionOpacity ? 1 : 0.25"
            :key="section.section_id + '_2'"
          >
            <l-tooltip :options="{ sticky: true }">{{
              section.display_name
            }}</l-tooltip></l-polyline
          >
          <l-polyline
            v-if="
              !(
                routerIsActive &&
                $route.params.section_id === section.section_id
              )
            "
            :lat-lngs="section.polyline1 || section.polyline"
            :color="section.options.color"
            :dashArray="section.options.dashArray"
            :opacity="fullSectionOpacity ? 1 : section.options.opacity"
            :key="section.section_id + '_1'"
          >
            <l-tooltip :options="{ sticky: true }">{{
              section.display_name
            }}</l-tooltip></l-polyline
          >
        </template>
        <l-polyline
          v-if="sections && sections.length > 0 && zoom > 10"
          :lat-lngs="getConnectingLines(sections)"
          :options="connectingLineOptions"
          :key="'connecting_lines'"
        >
        </l-polyline>
      </l-feature-group>
      <l-feature-group
        ref="stopMarkers"
        v-if="
          ($route.params.trip || fromStore) &&
          sections &&
          zoom > 8 &&
          !hideSections
        "
      >
        <template v-for="section in sections">
          <l-circle-marker
            v-if="
              !(
                routerIsActive &&
                $route.params.section_id === section.section_id
              )
            "
            v-for="(stop, stopIndex) in section.stops"
            :key="section.section_id + '_' + stopIndex"
            :latLng="getStopCoords(stop, section.polyline)"
            :opacity="
              fullSectionOpacity
                ? 1
                : getStopOpacity(
                    section.options.opacity,
                    section.splitPoint,
                    stop.point
                  )
            "
            :options="stopOptions"
            :color="section.color"
            ><l-tooltip>{{ getStopText(stop) }}</l-tooltip></l-circle-marker
          >
        </template>
      </l-feature-group>
      <l-feature-group
        ref="tripPolylines"
        v-if="
          !$route.params.trip &&
          !fromStore &&
          trips &&
          !hideSections &&
          userSectionsLoaded
        "
      >
        <l-layer-group v-for="trip in trips"
          ><l-polyline
            v-for="section in trip.sections"
            :lat-lngs="section.polyline"
            :color="sectionOptions(section).color"
            :dashArray="sectionOptions(section).dashArray"
            :opacity="fullSectionOpacity ? 1 : sectionOptions(section).opacity"
            @click="$store.dispatch('toTrip', trip)"
          >
            <l-tooltip :options="{ sticky: true }">{{
              trip.name
            }}</l-tooltip></l-polyline
          ><l-polyline
            v-if="trip.sections && trip.sections.length > 0 && zoom > 10"
            :lat-lngs="getConnectingLines(trip.sections)"
            :options="connectingLineOptions"
            :key="'connecting_lines_trip'"
          >
          </l-polyline
        ></l-layer-group>
      </l-feature-group>

      <l-freedraw
        v-model="trackFilterPolygon"
        :mode="freedrawMode"
        :options="{
          maximumPolygons: 1,
        }"
      />

      <!-- show tracks -->
      <template v-if="selectedTracksPolyline">
        <l-polyline
          v-for="trackPolyline in selectedTracksPolyline"
          :lat-lngs="trackPolyline.coords"
          @ready="setPolylineArrows"
          :key="trackPolyline.track_id + '_polyline'"
          v-if="!redrawTracks"
        ></l-polyline>
      </template>

      <template v-if="selectedTracks">
        <l-marker-cluster
          :options="{ spiderfyOnMaxZoom: false, disableClusteringAtZoom: 18 }"
          v-for="track in selectedTracks"
          :key="track.track_id"
          v-if="!redrawTracks"
          ><l-geo-json
            :geojson="track.geojson"
            name="track"
            :options="trackOptions()"
            :key="track.track_id + '_geojson'"
          ></l-geo-json
        ></l-marker-cluster>

        <div v-show="false">
          <tl-track-popup ref="trackPopup"></tl-track-popup>
        </div>
      </template>

      <l-marker v-if="isStay && stayMarker != null" :lat-lng="stayMarker">
        <l-icon
          :icon-size="[24, 32]"
          :icon-anchor="[12, 32]"
          :popupAnchor="[0, -20]"
          :className="lineColor === '#17a2b8' ? 'text-info' : 'text-white'"
          ><i
            class="fas fa-map-marker-alt"
            style="height: 100%; width: 100%"
          ></i
        ></l-icon>
      </l-marker>

      <l-marker
        v-if="mode === 'pt' && ptSearchMarker"
        :lat-lng="ptSearchMarker"
        @add="setPtSearchMarker"
        @move="setPtSearchMarker"
      >
        <l-icon
          :icon-size="[24, 32]"
          :icon-anchor="[12, 32]"
          :popupAnchor="[0, -20]"
          :className="lineColor === '#17a2b8' ? 'text-info' : 'text-white'"
          ><i
            class="fas fa-map-marker-alt"
            style="height: 100%; width: 100%"
          ></i
        ></l-icon>
      </l-marker>

      <div v-show="false">
        <tl-track-popup
          :marker="true"
          ref="ptSearchPopup"
          @remove="ptSearchMarker = null"
        ></tl-track-popup>
      </div>

      <l-marker
        v-if="routerIsActive && routeMarkers.length != 0"
        v-for="(marker, index) in routeMarkers"
        @dragend="moveRouteMarker($event, index)"
        :lat-lng="marker"
        :draggable="marker.mode !== 'inherit' || index == 0"
      >
        <l-icon
          :icon-size="[24, 32]"
          :icon-anchor="[12, 32]"
          :popupAnchor="[0, -20]"
          :className="
            index === selectedRouteMarker
              ? 'text-primary'
              : lineColor === '#17a2b8'
              ? 'text-info'
              : 'text-white'
          "
          ><i
            class="fas fa-map-marker-alt"
            style="height: 100%; width: 100%"
          ></i
        ></l-icon>
        <l-popup>
          <b-row class="justify-content-center">
            <b-button
              class="mt-2"
              @click="selectRouteMarker(index)"
              variant="outline-secondary"
              size="sm"
            >
              <i class="fas fa-pen"></i>
            </b-button>
            <b-button
              @click="removeRouteMarker(index)"
              variant="outline-secondary"
              size="sm"
              class="ml-1 mt-2"
            >
              <i class="fas fa-trash"></i>
            </b-button>
          </b-row>
        </l-popup>
      </l-marker>

      <l-marker
        v-if="mediaItems != null && showGalleryOnMap"
        v-for="item in mediaItems"
        :lat-lng="item.location"
        @click="openGalleryItem(item)"
        @dragend="relocateMediaItem($event, item)"
        :draggable="isMyTrip && $route.name != 'Trip'"
        :zIndexOffset="2000"
      >
        <l-icon :icon-size="[40, 40]" :popupAnchor="[0, -20]">
          <div class="image-marker">
            <b-img
              fluid
              class="image-marker-inner"
              :src="item.thumbnailSmall"
              :alt="item.name"
            ></b-img></div
        ></l-icon>
      </l-marker>

      <tl-media-viewer ref="tlMediaViewer"></tl-media-viewer>

      <template v-if="routerIsActive && route !== null">
        <l-polyline
          v-for="routeSection in route"
          :lat-lngs="routeSection.coords"
          :color="lineColor"
          :options="routeOptions"
          @click="onRouteClick($event, routeSection)"
          @mouseover="routerMouseover($event, routeSection)"
          @mouseout="routeHoverMarker = null"
        ></l-polyline>
        <l-marker
          v-if="routeHoverMarker"
          :lat-lng="routeHoverMarker"
          :options="{ interactive: false }"
        >
          <l-icon
            :icon-size="[24, 32]"
            :icon-anchor="[12, 32]"
            :popupAnchor="[0, -20]"
            :className="lineColor === '#17a2b8' ? 'text-info' : 'text-white'"
            ><i
              class="fas fa-map-marker-alt"
              style="height: 100%; width: 100%"
            ></i
          ></l-icon>
        </l-marker>
      </template>

      <l-moving-marker
        v-if="(showLastLocation || isLive) && lastLocation"
        :lat-lng="lastLocation"
        :icon="isLive ? pulsingIconRed : pulsingIconGray"
        key="tracker-marker"
        :duration="500"
      >
        <l-tooltip v-if="lastSeen">
          {{ $t("lastKnownLocation") + ": " + lastSeen }}
        </l-tooltip>
      </l-moving-marker>

      <!-- <l-geo-json
        v-if="routerIsActive && route !== null"
        :geojson="route"
        :options="routeOptions()"
        @click="onRouteClick"
        name="route"
      ></l-geo-json> -->
      <!--<l-geo-json v-for="layer in layers" :geojson="layer" :options="options()"></l-geo-json>
    
    
     
    <l-marker v-for="marker, index in curveMarkers" :lat-lng="marker"></l-marker>
    @click="selectMarker(index)"
    <l-marker v-for="coord, index in coords" :lat-lng="coord"></l-marker>-->
      <l-tile-layer
        v-if="openRailwayMapPaneCreated"
        :options="openRailwayMapTileProvider"
        layer-type="base"
        pane="tilePaneORM"
        :key="openRailwayMapTileProvider.name"
        :name="openRailwayMapTileProvider.name"
        :visible="openRailwayMapTileProvider.visible"
        :url="openRailwayMapTileProvider.url"
        :attribution="openRailwayMapTileProvider.attribution"
      />
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        v-if="tileProvider.visible && tileProvider.value != 'noBackgroundMap'"
        :key="tileProvider.value"
        :name="tileProvider.value"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"
        pane="tilePane"
        :tileLayerClass="tileProvider.tileLayerClass || leafletTileLayer"
      />
    </l-map>
  </div>
</template>

<script>
import L from "leaflet";
// import { Icon } from "leaflet";
// delete Icon.Default.prototype._getIconUrl;
// Icon.Default.mergeOptions({
//   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
//   iconUrl: require("leaflet/dist/images/marker-icon.png"),
//   shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
// });
import {
  LMap,
  LTileLayer,
  LMarker,
  LGeoJson,
  LPopup,
  LIcon,
  LControl,
  LPolyline,
  LTooltip,
  LFeatureGroup,
  LLayerGroup,
  LCircleMarker,
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen";
import "leaflet-geometryutil";
import "leaflet-textpath";
import "@ansur/leaflet-pulse-icon";
import "@ansur/leaflet-pulse-icon/dist/L.Icon.Pulse.css";
import "../plugins/TileLayer.Grayscale";
import LMovingMarker from "../plugins/LMovingMarker.vue";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import LFreeDraw from "vue2-leaflet-freedraw";
import { ALL, NONE } from "leaflet-freedraw";
import { lineString } from "@turf/helpers";
import tlMediaViewer from "../components/tlMediaViewer";
import tlTrackPopup from "../components/tlTrackPopup";
import tlMapOptions from "../components/tlMapOptions";
import { DateTime } from "luxon";
import { getSectionColor } from "../store/sections";
import debounce from "debounce";

export default {
  name: "tl-map",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson,
    LPopup,
    LIcon,
    LControl,
    LPolyline,
    LTooltip,
    LFeatureGroup,
    LLayerGroup,
    LCircleMarker,
    "l-freedraw": LFreeDraw,
    "l-marker-cluster": Vue2LeafletMarkerCluster,
    "l-moving-marker": LMovingMarker,
    "tl-media-viewer": tlMediaViewer,
    "tl-track-popup": tlTrackPopup,
    "tl-map-options": tlMapOptions,
  },
  props: {},
  mounted() {
    var vm = this;
    this.$nextTick(function () {
      vm.refreshMap();
      window.addEventListener("resize", function () {
        //vm.refreshMap();
      });
      const map = vm.$refs.lmap.mapObject;
      map.addControl(
        new window.L.Control.Fullscreen({
          position: "topright",
        })
      );
      vm.controlLayer = true;
      this.$nextTick(function () {
        L.DomEvent.disableScrollPropagation(vm.$refs.mapOptions.$el);
      });
      map.attributionControl.setPrefix("");
      map.createPane("tilePaneORM");
      map.getPane("tilePaneORM").style.zIndex = 210;
      vm.openRailwayMapPaneCreated = true;
      map.on("baselayerchange", function (tileProvider) {
        vm.tileProvider = tileProvider.name;
        if (
          tileProvider.name == "OpenStreetMap" ||
          tileProvider.name == "OpenStreetMap Greyscale" ||
          tileProvider.name === "Mapbox Light"
        ) {
          vm.lineColor = "#17a2b8";
        } else if (
          tileProvider.name == "Esri" ||
          tileProvider.name == "Mapbox" ||
          tileProvider.name == "Mapbox Dark"
        ) {
          vm.lineColor = "#FFFFFF";
        }
      });
      if (this.curve != null) {
        map.fitBounds(L.geoJson(this.curve).getBounds(), {
          paddingTopLeft: [this.mainSidebar ? 520 : 0, 0],
        });
      }
    });
  },
  updated() {
    //var vm = this;
    this.$nextTick(function () {
      //vm.refreshMap();
    });
  },
  methods: {
    refreshMap: function () {
      this.$refs.lmap.mapObject.invalidateSize();
    },
    fitCurve(padding = true) {
      var layer = this.$refs.lmap.$children.find((obj) => {
        return obj.name == "curve";
      });
      if (layer != undefined) {
        var bounds = layer.getBounds();
        this.$refs.lmap.mapObject.fitBounds(bounds, {
          paddingTopLeft: [this.mainSidebar && padding ? 520 : 0, 0],
        });
      }
    },
    flyToCurve: function () {
      var layer = this.$refs.lmap.$children.find((obj) => {
        return obj.name == "curve";
      });
      if (layer != undefined) {
        var bounds = layer.getBounds();
        this.$refs.lmap.mapObject.flyToBounds(bounds);
      }
    },
    flyToCenter: function () {
      this.$refs.lmap.mapObject.flyTo(this.center, this.zoom);
    },
    onLayerClick(event) {
      if (event.layer.feature.properties.section_id != undefined) {
        const section_id = event.layer.feature.properties.section_id;
        var selected_section = this.sections.find((obj) => {
          return obj.section_id == section_id;
        });

        this.$store.dispatch(
          "toSection",
          selected_trip,
          selected_section.section_id
        );
      } else if (event.layer.feature.properties.trip_id != undefined) {
        const trip_id = event.layer.feature.properties.trip_id;
        var selected_trip = this.trips.find((obj) => {
          return obj.trip_id == trip_id;
        });
        this.$store.dispatch("toTrip", selected_trip);
      }
    },
    onRouteClick(event, routeSection) {
      L.DomEvent.stopPropagation(event);
      if (this.routerIsActive) {
        this.$store.dispatch("router/addMarkerAt", {
          marker: {
            lat: event.latlng["lat"],
            lng: event.latlng["lng"],
          },
          closestPoint: this.getClosestPoint(routeSection.coords, event.latlng),
          index: routeSection.marker_id,
        });
      }
    },
    routerMouseover(event, routeSection) {
      if (this.routeMarkers[routeSection.marker_id].mode === "inherit") {
        const closestPoint =
          routeSection.coords[
            this.getClosestPoint(routeSection.coords, event.latlng)
          ];
        this.routeHoverMarker = closestPoint;
      }
    },
    getClosestPoint(coords, latlng) {
      const closestPoint = L.GeometryUtil.closest(
        this.$refs.lmap.mapObject,
        coords,
        latlng,
        true
      );
      const index = coords.findIndex((item) => {
        return item[0] === closestPoint.lat && item[1] === closestPoint.lng;
      });
      if (coords.length < 3) {
        return null;
      }
      if (index == 0) {
        return 1;
      }

      if (index == coords.length - 1) {
        return index - 1;
      }

      return index;
    },
    addMarker(event) {
      this.showOptions = false;
      if (this.routerIsActive) {
        this.$store.dispatch("router/addMarker", {
          marker: {
            lat: event.latlng["lat"],
            lng: event.latlng["lng"],
          },
        });
      } else if (this.isStay) {
        this.$store.commit("params/setSectionProp", {
          key: "coords",
          value: event.latlng,
        });
      } else if (this.mode === "pt") {
        this.ptSearchMarker = event.latlng;
      }
    },
    removeRouteMarker(index) {
      this.$store.dispatch("router/removeMarker", index);
    },
    moveRouteMarker(event, index) {
      var latlng = event.target.getLatLng();
      this.$store.dispatch("router/moveMarker", {
        index: index,
        lat: latlng.lat,
        lng: latlng.lng,
      });
    },
    selectRouteMarker(index) {
      this.$store.commit("router/setSelectedMarker", index);
    },
    openGalleryItem(item) {
      this.$refs.tlMediaViewer.open(item);
    },
    relocateMediaItem(event, item) {
      var latlng = event.target.getLatLng();
      this.$store.dispatch("gallery/relocate", {
        item: item,
        location: {
          lat: latlng.lat,
          lng: latlng.lng,
        },
      });
    },
    sectionOptions(section) {
      const options = {};

      if (
        this.tileProvider == "OpenStreetMap" ||
        this.tileProvider == "Mapbox Light" ||
        this.tileProvider == "OpenStreetMap Greyscale"
      ) {
        options.color = "#17a2b8";
      } else if (
        this.tileProvider == "Esri" ||
        this.tileProvider == "Mapbox Satellite" ||
        this.tileProvider == "Mapbox Dark"
      ) {
        options.color = "#FFFFFF";
      }

      if (section.mode == "flight") {
        options.dashArray = "10 10";
      }
      if (
        this.tileProvider == "Mapbox Light" ||
        this.tileProvider == "OpenStreetMap"
      ) {
        options.color = getSectionColor(section);
      }
      options.opacity = 1;
      // if (section.days != null) {
      //   if (this.day != undefined) {
      //     if (section.days.includes(parseInt(this.day))) {
      //       options.opacity = 1;
      //     } else {
      //       options.opacity = 0.25;
      //     }
      //   }
      // }
      if (
        (this.hoverSection != null &&
          section.section_id != this.hoverSection) ||
        ((section.stops[0].departure || section.stops[0].plannedDeparture) &&
          DateTime.fromISO(
            section.stops[0].departure || section.stops[0].plannedDeparture
          ) > this.now)
      ) {
        options.opacity = 0.25;
      } else if (
        this.hoverSection != null &&
        section.section_id == this.hoverSection
      ) {
        options.opacity = 1;
      }

      return options;
    },
    trackOptions() {
      const vm = this;
      const geojsonMarkerOptions = {
        radius: 5,
        color: "orange",
      };

      return {
        pointToLayer: function (feature, latlng) {
          return L.circleMarker(latlng, geojsonMarkerOptions);
        },
        onEachFeature: function (feature, layer) {
          layer.bindTooltip(
            DateTime.fromISO(feature?.properties?.time, {
              zone: feature?.properties?.timezone,
            }).toLocaleString(DateTime.DATETIME_FULL)
          );
          layer.bindPopup(vm.$refs.trackPopup.$el);
          layer.on("click", function () {
            vm.$refs.trackPopup.properties = {
              localTime: DateTime.fromISO(feature?.properties?.time, {
                zone: feature?.properties?.timezone,
              }).toISO(),
              ...feature?.properties,
            };
            vm.$refs.trackPopup.coords = {
              lat: feature?.geometry?.coordinates?.[1],
              lng: feature?.geometry?.coordinates?.[0],
            };
            vm.$nextTick(() => {
              if (!vm.routerIsActive && vm.mode !== "pt") {
                layer._popup?._close?.();
              }
              layer._popup?.update?.();
            });
          });
          layer.bringToFront();
        },
      };
    },
    setPtSearchMarker(layer) {
      const vm = this;
      layer.target.bindPopup(vm.$refs.ptSearchPopup.$el);
      layer.target.on("click", function () {
        vm.$refs.ptSearchPopup.coords = vm.ptSearchMarker;
        vm.$nextTick(() => {
          layer.target._popup?.update?.();
        });
      });
    },
    setPolylineArrows(layer, color = "#3388ff") {
      layer.setText("  ►  ", {
        repeat: true,
        center: true,
        below: true,
        offset: 11,
        attributes: { "font-size": "32", fill: color },
      });
    },
    splitPolyline(section) {
      if (section.mode !== "pt" && section.mode !== "ptm") {
        return false;
      }

      if (
        (!section.stops[0].departure && !section.stops[0].plannedDeparture) ||
        (!section.stops[section.stops.length - 1].arrival &&
          !section.stops[section.stops.length - 1].plannedArrival)
      )
        return false;

      if (
        ((section.stops[0].departure || section.stops[0].plannedDeparture) &&
          DateTime.fromISO(
            section.stops[0].departure || section.stops[0].plannedDeparture
          ) > this.now) ||
        ((section.stops[section.stops.length - 1].arrival ||
          section.stops[section.stops.length - 1].plannedArrival) &&
          DateTime.fromISO(
            section.stops[section.stops.length - 1].arrival ||
              section.stops[section.stops.length - 1].plannedArrival
          ) <= this.now)
      ) {
        return false;
      }
      let index = 0;

      for (const stop of section.stops) {
        if (stop.point == 0) {
          continue;
        }

        if (!stop.arrival && !stop.plannedArrival) {
          break;
        }
        if (DateTime.fromISO(stop.arrival || stop.plannedArrival) > this.now) {
          break;
        }

        index = stop.point;
      }

      return index;
    },
    getStopOpacity(opacity = 1, splitPoint, stopPoint) {
      this.now;
      if (splitPoint || splitPoint === 0) {
        opacity = splitPoint >= stopPoint ? opacity : 0.25;
      }

      return opacity;
    },
    getStopText(stop) {
      let text = stop.name;
      const arrival = stop.arrival || stop.plannedArrival;
      const departure = stop.departure || stop.plannedDeparture;

      if (!arrival && !departure) {
        return text;
      }

      text += " | ";

      if (arrival) {
        text += DateTime.fromISO(arrival, { setZone: true }).toFormat("HH:mm");

        if (departure && arrival != departure) {
          text += " - ";
        }
      }

      if (departure && arrival != departure) {
        text += DateTime.fromISO(departure, { setZone: true }).toFormat(
          "HH:mm"
        );
      }
      return text;
    },
    getStopCoords(stop, polyline) {
      if (stop.point == null) {
        return stop.location;
      }

      if (!polyline[stop.point]) {
        return stop.location;
      }

      return {
        lat: polyline[stop.point][0],
        lng: polyline[stop.point][1],
      };
    },
    changeBaselayer(name) {
      const layers = JSON.parse(JSON.stringify(this.tileProviders));
      for (const layer of layers) {
        layer.visible = false;
        if (layer.value === name) {
          layer.visible = true;
        }
      }
      this.tileProviders = layers;
      this.tileProvider = name;
      if (
        name == "OpenStreetMap" ||
        name == "OpenStreetMap Greyscale" ||
        name === "Mapbox Light"
      ) {
        this.lineColor = "#17a2b8";
      } else if (name == "Esri" || name == "Mapbox" || name == "Mapbox Dark") {
        this.lineColor = "#FFFFFF";
      }
    },
    setRedrawTracks: debounce(function () {
      this.redrawTracks = false;
    }, 100),
    getConnectingLines(sections) {
      if (!sections || sections.length === 0) return null;

      const connectingLines = [];
      let currentLine = [];
      for (const i in sections) {
        const section = sections[i];
        let point1, point2;

        if (section.mode === "stay") {
          if (!section.coords) continue;
          point1 = [section.coords.lat, section.coords.lng];
          point2 = point1;
        } else {
          point1 = section.polyline[0];
          point2 = section.polyline[section.polyline.length - 1];
        }

        if (currentLine.length == 1) {
          currentLine.push(point1);
          connectingLines.push(currentLine);
          currentLine = [];
        }

        currentLine.push(point2);
      }

      return connectingLines;
    },
  },
  computed: {
    isMyTrip() {
      return this.$store.getters.isMyTrip;
    },
    refresh() {
      return this.$store.state.refreshMap;
    },
    isDesktop() {
      return this.$store.state.isDesktop;
    },
    curve_user() {
      return this.$store.state.trips.curve_user;
    },
    curve_trip() {
      return this.$store.state.sections.curve_trip;
    },
    trips() {
      return this.$store.state.trips.list;
    },
    sections() {
      if (this.$store.state.sections.list) {
        const sections = JSON.parse(
          JSON.stringify(this.$store.state.sections.list)
        );
        this.now;

        for (const i in sections) {
          const section = { ...sections[i] };
          const splitPoint = this.splitPolyline(section);
          const options = this.sectionOptions(section);
          section.options = options;
          if (splitPoint !== false) {
            section.splitPoint = splitPoint;
            section.polyline1 = section.polyline.slice(0, splitPoint + 1);
            section.polyline2 = section.polyline.slice(splitPoint);
          }
          sections.splice(i, 1, section);
        }
        return sections;
      } else {
        return null;
      }
    },
    day() {
      return this.$route.params.day;
    },
    mediaItems() {
      if (
        this.$store.state.trips.selected != null &&
        this.$store.state.gallery.list != null
      ) {
        return this.$store.state.gallery.list.filter(
          (item) => item.location != null
        );
      } else {
        return null;
      }
    },
    showGalleryOnMap() {
      return this.$store.state.gallery.showGalleryOnMap;
    },
    isStay() {
      return this.$store.state.router.isStay;
    },
    stayMarker() {
      return this.$store.state.params.section.coords;
    },
    mode() {
      return this.$store.state.params.section.mode;
    },
    curve() {
      if (this.$store.state.trips.selected != null) {
        return this.curve_trip;
      } else {
        return this.curve_user;
      }
    },
    mainSidebar() {
      return this.$store.state.mainSidebar;
    },
    route() {
      return this.$store.getters["router/getMappedRoute"];
    },
    routerIsActive() {
      return this.$store.state.router.isActive;
    },
    routeMarkers() {
      return this.$store.state.router.markers;
    },
    routeHoverMarker: {
      get() {
        return this.$store.state.router.hoverMarker;
      },
      set(value) {
        this.$store.commit("router/setHoverMarker", value);
      },
    },
    selectedRouteMarker() {
      return this.$store.state.router.selectedMarker;
    },
    selectedJourney() {
      return this.$store.state.params.pt.selectedJourney;
    },
    hoverSection() {
      return this.$store.state.sections.hover;
    },
    onEachFeatureFunction() {
      const sections = JSON.parse(JSON.stringify(this.sections));
      //const day = this.day;
      const tileProvider = this.tileProvider;
      const hoverSection = this.hoverSection;
      return (feature, layer) => {
        let color;
        let dashArray = null;
        let opacity = 1;

        if (
          tileProvider == "OpenStreetMap" ||
          tileProvider == "Mapbox Light" ||
          tileProvider == "OpenStreetMap Greyscale"
        ) {
          color = "#17a2b8";
        } else if (
          tileProvider == "Esri" ||
          tileProvider == "Mapbox Satellite" ||
          tileProvider == "Mapbox Dark"
        ) {
          color = "#FFFFFF";
        }

        if (feature.properties != undefined) {
          layer.bindTooltip(feature.properties.name, {
            permanent: false,
            sticky: true,
          });
          if (sections != null) {
            const section = sections.find(
              (element) => element.section_id == feature.properties.section_id
            );
            if (section.mode == "flight") {
              dashArray = "10 10";
            }
            if (tileProvider == "Mapbox Light") {
              if (section.mode == "pt" || section.mode == "ptm") {
                const product = section.timetable_section?.line?.product;
                const mode = section.timetable_section?.line?.mode;
                if (product == "tram") {
                  color = "tomato";
                } else if (
                  product == "subway" ||
                  product == "metro" ||
                  product == "u-bahn"
                ) {
                  color = "royalblue";
                } else if (mode == "train") {
                  color = "dimgrey";
                } else if (mode == "bus") {
                  color = "mediumseagreen";
                }
              } else if (section.mode == "walk") {
                color = "gold";
              } else if (section.mode == "bike") {
                color = "orange";
              } else if (section.mode == "car") {
                color = "brown";
              } else if (section.mode == "flight") {
                color = "silver";
              }
            }
            // if (section.days != null) {
            //   if (day != undefined) {
            //     if (section.days.includes(parseInt(day))) {
            //       opacity = 1;
            //     } else {
            //       opacity = 0.25;
            //     }
            //   }
            // }
            if (
              hoverSection != null &&
              feature.properties.section_id != hoverSection
            ) {
              opacity = 0.25;
            } else if (
              hoverSection != null &&
              feature.properties.section_id == hoverSection
            ) {
              opacity = 1;
            }
          }
        }
        layer.setStyle({
          color: color,
          opacity: opacity,
          dashArray: dashArray,
        });
      };
    },
    routeOptionsFunction() {
      const color = this.lineColor;
      return (feature, layer) => {
        layer.setStyle({
          color: color,
          opacity: 1,
        });
      };
    },
    polylineOptionsFunction() {
      return (feature, layer) => {
        layer.setStyle({
          color: "#DE541E",
          opacity: 1,
        });
        setTimeout(() => {
          layer.bringToFront();
        }, 0);
      };
    },
    selectedTracks() {
      let selectedTracks = JSON.parse(
        JSON.stringify(this.$store.getters["sections/getSelectedTracks"])
      );
      const trackFilterRange = this.$store.state.sections.trackFilterRange;

      if (
        selectedTracks &&
        selectedTracks.length == 1 &&
        trackFilterRange &&
        selectedTracks[0].geojson?.features
      ) {
        this.$set(
          selectedTracks[0].geojson,
          "features",
          selectedTracks[0].geojson.features.filter((feature) => {
            const datetime = DateTime.fromISO(feature.properties?.time, {
              zone: feature.properties?.timezone,
            });
            return (
              trackFilterRange[0] <= datetime && trackFilterRange[1] >= datetime
            );
          })
        );
      }

      return selectedTracks;
    },
    selectedTracksPolyline() {
      if (!this.selectedTracks) {
        return null;
      }

      return this.selectedTracks.map((track) => {
        return {
          ...track,
          geojson: null,
          coords: track.geojson.features.map((feature) => [
            feature.geometry.coordinates[1],
            feature.geometry.coordinates[0],
          ]),
        };
      });
    },
    isLive() {
      return this.$store.state.tracker.isLive;
    },
    showLastLocation() {
      return this.$store.state.tracker.showLastLocation;
    },
    lastSeen() {
      const lastSeen = this.$store.state.tracker.lastSeen;
      if (!lastSeen) {
        return null;
      }

      const datetime = DateTime.fromISO(lastSeen);

      const diff = this.now.diff(datetime, "minutes").minutes;

      if (diff >= 0 && diff < 60) {
        return this.$tc("xMinutesAgo", Math.floor(diff).toString());
      }

      if (datetime.startOf("day").equals(this.now.startOf("day"))) {
        return datetime.toLocaleString(DateTime.TIME_24_SIMPLE);
      }

      return datetime.toLocaleString(DateTime.DATETIME_SHORT);
    },
    lastLocation() {
      return this.$store.state.tracker.lastLocation;
    },
    trackFilterPolygonActive() {
      return this.$store.state.sections.trackFilterPolygonActive;
    },
    trackFilterPolygon() {
      return this.$store.state.sections.trackFilterPolygon;
    },
    freedrawMode() {
      return this.trackFilterPolygonActive ? ALL : NONE;
    },
    hideSections() {
      return this.$store.state.hideSections;
    },
    fullSectionOpacity() {
      return this.$store.state.fullSectionOpacity;
    },
    fromStore() {
      return this.$store.state.sections.fromStore;
    },
    connectingLineOptions() {
      return {
        dashArray: "1 6",
        color: "var(--gray)",
      };
    },
    userSectionsLoaded() {
      return this.$store.state.trips.sectionsLoaded;
    },
  },
  watch: {
    "$store.state.sections.fitBounds": function (fitBounds) {
      if (fitBounds) {
        this.$nextTick(() => {
          if (
            this.$refs.lmap &&
            this.$refs.sectionPolylines &&
            this.$store.state.sections.list?.length > 0
          ) {
            this.$refs.lmap.mapObject.fitBounds(
              this.$refs.sectionPolylines.mapObject.getBounds(),
              {
                paddingTopLeft: [
                  this.mainSidebar && this.isDesktop ? 520 : 0,
                  0,
                ],
              }
            );
          }
        });
        this.$store.commit("sections/setFitBounds", false);
      }
    },
    "$store.state.sections.fitSection": function (fitSection) {
      if (fitSection) {
        const section = this.$store.state.sections.list?.find(
          (section) => section.section_id === fitSection
        );
        this.$nextTick(() => {
          if (this.$refs.lmap && section) {
            this.$refs.lmap.mapObject.fitBounds(section.polyline, {
              paddingTopLeft: [this.mainSidebar && this.isDesktop ? 520 : 0, 0],
            });
          }
        });
        this.$store.commit("sections/setFitSection", false);
      }
    },
    "$store.state.trips.fitBounds": function (fitBounds) {
      if (fitBounds) {
        this.$nextTick(() => {
          if (this.$refs.lmap) {
            const map = this.$refs.lmap.mapObject;
            if (this.mainSidebar && this.isDesktop) {
              var targetPoint = map
                  .project(this.center, this.zoom)
                  .subtract([520 / 2, 0]),
                targetLatLng = map.unproject(targetPoint, this.zoom);

              map.setView(targetLatLng, this.zoom);
            } else {
              map.setView(this.center, this.zoom);
            }
          }
        });
        this.$store.commit("trips/setFitBounds", false);
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.refreshMap();
      });
    },
    curve: {
      handler: function (newC, oldC) {
        if (newC != null && newC != oldC && newC.fitBounds) {
          this.$refs.lmap.mapObject.fitBounds(L.geoJson(newC).getBounds(), {
            paddingTopLeft: [this.mainSidebar && this.isDesktop ? 520 : 0, 0],
          });
        }
      },
      deep: true,
    },
    selectedJourney: {
      handler: function (selectedJourney) {
        if (selectedJourney != null) {
          const polylines = [];
          for (const leg of selectedJourney.legs) {
            const polyline = leg.polyline;
            const points = [];

            for (const i in polyline.features) {
              const pointJson = polyline.features[i];
              const coord = pointJson.geometry.coordinates;
              points.push([coord[0], coord[1]]);
            }

            polylines.push(lineString(points));
          }
          this.polylines = polylines;
          this.$refs.lmap.mapObject.fitBounds(
            L.geoJson(polylines).getBounds(),
            {
              paddingTopLeft: [this.mainSidebar ? 520 : 0, 0],
            }
          );
        } else {
          this.polylines = [];
        }
      },
      deep: true,
    },
    now: {
      handler: function (now) {
        if (this.sections) {
          for (const section of this.sections) {
            if (section.mode === "pt" && section.hafasTripId) {
              const departure = section.stops[0].plannedDeparture;
              const arrival =
                section.stops[section.stops.length - 1].arrival ||
                section.stops[section.stops.length - 1].plannedArrival;

              if (!departure || !arrival) {
                continue;
              }

              if (
                DateTime.fromISO(departure) > now ||
                DateTime.fromISO(arrival) <= now
              ) {
                continue;
              }
              this.$store.dispatch("sections/update", {
                section_id: section.section_id,
              });
            }
          }
        }
      },
      deep: true,
    },
    selectedTracks: {
      handler: function (selectedTracks) {
        if (selectedTracks) {
          this.redrawTracks = true;
          this.setRedrawTracks();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      zoom: 7,
      center: [47.482352, 13.928678],
      lineColor: "#17a2b8",
      tileProvider: "Mapbox Light",
      controlLayer: false,
      ptSearchMarker: null,
      options() {
        return {
          onEachFeature: this.onEachFeatureFunction,
        };
      },
      routeOptions: {},
      polylineOptions() {
        return {
          onEachFeature: this.polylineOptionsFunction,
        };
      },
      polylines: [],
      tileProviders: [
        {
          name: "OpenStreetMap",
          value: "OpenStreetMap",
          visible: false,
          attribution:
            '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
        },
        {
          name: "Mapbox Light",
          value: "Mapbox Light",
          visible: true,
          url: "https://api.mapbox.com/styles/v1/jonasgaiswinkler/cl7ca4dpb000q14qip0cunr35/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9uYXNnYWlzd2lua2xlciIsImEiOiJja2F6cTVkd20wMGxmMnNvM2IwY2Jmc3Q1In0.RpGAwZ4eqsBoPzK6pu6-AA",
          attribution:
            '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          tileSize: 512,
          zoomOffset: -1,
        },
        {
          name: "Mapbox Dark",
          value: "Mapbox Dark",
          visible: false,
          url: "https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9uYXNnYWlzd2lua2xlciIsImEiOiJja2F6cTVkd20wMGxmMnNvM2IwY2Jmc3Q1In0.RpGAwZ4eqsBoPzK6pu6-AA",
          attribution:
            '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          tileSize: 512,
          zoomOffset: -1,
        },
        {
          name: "Mapbox Satellite",
          value: "Mapbox Satellite",
          visible: false,
          url: "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9uYXNnYWlzd2lua2xlciIsImEiOiJja2F6cTVkd20wMGxmMnNvM2IwY2Jmc3Q1In0.RpGAwZ4eqsBoPzK6pu6-AA",
          attribution:
            '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          tileSize: 512,
          zoomOffset: -1,
        },
        {
          name: "Esri",
          value: "Esri",
          visible: false,
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          attribution: '&copy; <a href="http://www.esri.com/">Esri</a>',
        },
        {
          name: "OpenStreetMap Greyscale",
          value: "OpenStreetMap Greyscale",
          visible: false,
          attribution:
            '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
          tileLayerClass: L.tileLayer.grayscale,
        },
        {
          name: this.$t("noBackgroundMap"),
          value: "noBackgroundMap",
          visible: false,
        },
      ],
      openRailwayMapTileProvider: {
        name: "OpenRailwayMap",
        value: "OpenRailwayMap",
        visible: false,
        url: "http://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png",
        attribution:
          '<a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>, Style: <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA 2.0</a> <a href="http://www.openrailwaymap.org/">OpenRailwayMap</a> and OpenStreetMap',
        minZoom: 2,
        maxZoom: 19,
        tileSize: 256,
      },
      openRailwayMapPaneCreated: false,
      pulsingIconRed: L.icon.pulse({
        iconSize: [12, 12],
        fillColor: "red",
        color: "red",
        heartbeat: 2,
      }),
      pulsingIconGray: L.icon.pulse({
        iconSize: [12, 12],
        fillColor: "var(--gray)",
        animate: false,
      }),
      now: DateTime.now(),
      stopOptions: {
        fillColor: "white",
        weight: 2,
        radius: 3,
        fillOpacity: 1,
      },
      showOptions: false,
      leafletTileLayer: L.tileLayer,
      redrawTracks: false,
    };
  },
  created() {
    setInterval(() => {
      this.now = DateTime.now();
    }, 15000);
  },
};
</script>
<style scoped lang="scss">
::v-deep .leaflet-control-layers-toggle {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-size: 16px 16px;
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='layer-group' class='svg-inline--fa fa-layer-group fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23404040' d='M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z'%3E%3C/path%3E%3C/svg%3E");
}

.options-icon {
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22layer-group%22%20class%3D%22svg-inline--fa%20fa-layer-group%20fa-w-16%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M12.41%20148.02l232.94%20105.67c6.8%203.09%2014.49%203.09%2021.29%200l232.94-105.67c16.55-7.51%2016.55-32.52%200-40.03L266.65%202.31a25.607%2025.607%200%200%200-21.29%200L12.41%20107.98c-16.55%207.51-16.55%2032.53%200%2040.04zm487.18%2088.28l-58.09-26.33-161.64%2073.27c-7.56%203.43-15.59%205.17-23.86%205.17s-16.29-1.74-23.86-5.17L70.51%20209.97l-58.1%2026.33c-16.55%207.5-16.55%2032.5%200%2040l232.94%20105.59c6.8%203.08%2014.49%203.08%2021.29%200L499.59%20276.3c16.55-7.5%2016.55-32.5%200-40zm0%20127.8l-57.87-26.23-161.86%2073.37c-7.56%203.43-15.59%205.17-23.86%205.17s-16.29-1.74-23.86-5.17L70.29%20337.87%2012.41%20364.1c-16.55%207.5-16.55%2032.5%200%2040l232.94%20105.59c6.8%203.08%2014.49%203.08%2021.29%200L499.59%20404.1c16.55-7.5%2016.55-32.5%200-40z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E") !important;
  background-size: 18px 18px !important;
}

::v-deep .leaflet-container {
  font: unset;
}

::v-deep .leaflet-top {
  z-index: $zMapControl !important;
}

.fa-trash,
.fa-pen {
  width: 1em !important;
}

.image-marker {
  border-radius: 0.25rem;
  border: 2px solid white;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.image-marker-inner {
  width: 36px !important;
  height: 36px !important;
  object-fit: cover;
}

/* .media-toggle {
  padding: 3px 8px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0px 0px 6px 0px #00000033;
} */
</style>
