<template>
  <b-row v-if="trips != null">
    <b-list-group class="shadow-sm">
      <b-list-group-item
        v-for="trip in trips"
        class="p-0 trip"
        @click="$store.dispatch('toTrip', trip)"
        style="cursor: pointer"
        ><b-row no-gutters>
          <b-col v-if="trip.image !== undefined" cols="5">
            <b-card-img-lazy
              v-if="trip.image"
              style="height: 100%; object-fit: cover; aspect-ratio: 4/3"
              :src="trip.image.thumbnailLarge"
              :alt="trip.name"
              class="rounded-0"
            ></b-card-img-lazy>
          </b-col>
          <b-col :cols="trip.image === undefined ? 12 : 7" class="p-3"
            ><h4>{{ trip.name }}</h4>
            <h6 class="m-0 text-muted" v-if="trip.dateText != null">
              {{ trip.dateText }}
            </h6>
            <h6 class="m-0 text-muted" v-if="trip.lengthText != null">
              {{ trip.lengthText }}
            </h6>
            <p
              class="m-0"
              v-if="showCountryFlags && trip.countriesHtml"
              v-html="trip.countriesHtml"
            ></p>
          </b-col> </b-row
      ></b-list-group-item>
    </b-list-group>
    <!-- <b-row v-for="trip in trips" class="my-1 px-2" :key="trip.trip_id">
      <b-card
        @click="$store.dispatch('toTrip', trip)"
        no-body
        class="overflow-hidden w-100"
        style="cursor: pointer"
      >
        <b-row no-gutters>
          <b-col md="5">
            <b-card-img-lazy
              v-if="trip.image != null"
              style="height: 100%; object-fit: cover"
              :src="trip.image.thumbnailMedium"
              blank-width="1000"
              blank-height="750"
              :alt="trip.name"
              class="rounded-0"
            ></b-card-img-lazy>
          </b-col>
          <b-col :md="7">
            <b-card-body :title="trip.name">
              <b-card-sub-title v-if="trip.dateText != null" class="mb-1">
                {{ trip.dateText }}
              </b-card-sub-title>
              <b-card-sub-title v-if="trip.lengthText != null">
                {{ trip.lengthText }}
              </b-card-sub-title>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-row> -->
  </b-row>
</template>

<script>
export default {
  name: "tl-trips",
  props: {
    trips: Array,
  },
  computed: {
    showCountryFlags() {
      return this.$store.getters["profile/getShowCountryFlags"];
    },
  },
  methods: {
    getDate(trip) {
      if (trip.starttime != undefined && trip.starttime != "") {
        let result = "";
        const startdate = new Date(trip.starttime).toLocaleDateString("de-DE");
        result += startdate;
        if (trip.endtime != undefined && trip.endtime != "") {
          const enddate = new Date(trip.endtime).toLocaleDateString("de-DE");
          if (enddate != startdate) {
            result += "-<br>" + enddate;
          }
        }
        return result;
      } else if (trip.endtime != undefined && trip.endtime != "") {
        return new Date(trip.endtime).toLocaleDateString("de-DE");
      } else {
        return null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
h4 {
  word-break: break-word;
  hyphens: auto;
}

.trip:hover {
  background-color: var(--light);
}
</style>
