<template>
  <div class="d-flex flex-column h-100">
    <div class="content" id="addSectionContainer">
      <b-container fluid>
        <b-row class="my-2 px-1 align-items-center">
          <slot name="button-sidebar"></slot>
          <h3 class="mb-1">{{ $t("newSection") }}</h3>
        </b-row>
        <div v-show="params.mode == ''">
          <b-row class="my-1 px-1">
            <h4 class="no-margin">
              <small class="text-muted">{{ $t("selectMode") }}</small>
            </h4>
          </b-row>
          <b-row class="my-1 px-1">
            <tl-mode-picker></tl-mode-picker>
          </b-row>
        </div>

        <div v-show="params.mode != ''">
          <form spellcheck="false">
            <b-row class="my-1 px-1">
              <tl-mode-switcher></tl-mode-switcher>
            </b-row>
            <b-row v-if="days != null" class="my-1 px-1">
              <label>{{ $t("days") }}</label>
              <b-form-select
                :value="params.days"
                @input="setParam('days', $event)"
                :options="days"
                value-field="day"
                text-field="text"
                multiple
                :select-size="3"
              ></b-form-select>
              <b-button
                @click="setParam('days', [])"
                variant="link"
                size="sm"
                class="text-decoration-none mt-1"
                ><i class="fas fa-times mr-1"></i>{{ $t("clear") }}</b-button
              >
            </b-row>
            <tl-section-params-pt
              v-if="params.mode === 'pt'"
            ></tl-section-params-pt>
            <tl-section-params-ptm
              v-else-if="params.mode === 'ptm'"
            ></tl-section-params-ptm>
            <tl-section-params-simple v-else></tl-section-params-simple>
          </form>
        </div>
      </b-container>
    </div>
    <div class="footer align-items-center pt-1">
      <b-button @click="goBack" variant="link" class="text-decoration-none">
        {{ $t("cancel") }}
      </b-button>
      <div class="flex-grow-1"></div>
      <b-spinner
        v-if="isLoadingSaving"
        variant="primary"
        class="mr-2 ml-1"
      ></b-spinner>
      <b-button
        @click="add"
        variant="primary"
        v-show="params.mode != ''"
        :disabled="!saveSectionEnabled || isLoadingSaving"
        >{{ $t("add") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import tlModeSwitcher from "../components/tlModeSwitcher";
import tlModePicker from "../components/tlModePicker";
import tlSectionParamsPt from "../components/tlSectionParamsPt.vue";
import tlSectionParamsPtm from "../components/tlSectionParamsPtm.vue";
import tlSectionParamsSimple from "../components/tlSectionParamsSimple.vue";

export default {
  components: {
    "tl-mode-switcher": tlModeSwitcher,
    "tl-mode-picker": tlModePicker,
    "tl-section-params-pt": tlSectionParamsPt,
    "tl-section-params-ptm": tlSectionParamsPtm,
    "tl-section-params-simple": tlSectionParamsSimple,
  },
  computed: {
    params() {
      return this.$store.state.params.section;
    },
    selectedJourney() {
      return this.$store.state.params.pt.selectedJourney;
    },
    isLoadingSaving() {
      return this.$store.state.params.isLoading;
    },
    saveSectionEnabled() {
      return this.$store.getters["params/saveSectionEnabled"];
    },
    fromStore() {
      return this.$store.state.sections.fromStore;
    },
    days() {
      if (this.fromStore) return null;
      const days = this.$store.state.trips.selected.days;

      return days != null
        ? days.map(function (el) {
            var day = Object.assign({}, el);
            day.text = day.name
              ? day.day + ": " + day.name
              : day.day.toString();
            return day;
          })
        : null;
    },
  },
  methods: {
    setParam(key, value) {
      this.$store.commit("params/setSectionProp", { key: key, value: value });
    },
    goBack() {
      this.$router.push({
        name: !this.fromStore ? "EditTripMeta" : "StoreMeta",
      });
      this.clear();
    },
    clear() {
      this.$store.dispatch("params/resetSection");
    },
    async add() {
      await this.$store.dispatch("params/saveSection");
    },
  },
  mounted() {
    if (
      this.params.mode !== "stay" &&
      this.params.mode !== "pt" &&
      this.params.mode !== ""
    ) {
      this.$store.commit("router/setIsActive", true);
    }
    if (this.params.mode === "stay") {
      this.$store.commit("router/setIsStay", true);
    }
  },
};
</script>

<style scoped>
label {
  margin: 0;
}

.align-end {
  display: flex;
  justify-content: flex-end;
}

.append-spinner {
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.typeahead-spinner {
  height: 25px;
  width: 25px;
}

.my-1 {
  align-items: center;
}

.no-margin {
  margin: 0;
}

.content {
  flex: auto;
  overflow-y: auto;
}

.footer {
  width: 100%;
  flex-basis: 30px;
  display: flex;
}

::v-deep .input-group:not(.tl-datetime) > .form-control:not(:last-child) {
  border-radius: 0.25rem !important;
}
</style>
