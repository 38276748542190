var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"role":"tablist","id":"tablist"}},[_vm._l((_vm.sectionsByDays),function(day,j){return (_vm.sectionsByDays)?[(day.day !== null)?[_c('div',{attrs:{"id":'day_' + day.day}}),_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-' + j),expression:"'collapse-' + j"}],staticClass:"day",attrs:{"id":'day_' + day.day + '_header'}},[_c('div',{staticClass:"center-h center-v",staticStyle:{"width":"100%","padding":"10px 15px","overflow":"initial"}},[_c('span',[_c('i',[_vm._v(_vm._s(_vm.$t("day"))+" "+_vm._s(day.day)),(
                  _vm.trip &&
                  _vm.trip.days.find(function (tripDay) { return tripDay.day == day.day; }).name
                )?_c('span',[_vm._v(": "+_vm._s(_vm.trip.days.find(function (tripDay) { return tripDay.day == day.day; }).name))]):_vm._e()])]),(day.sections && day.sections.length > 0)?_c('div',{staticClass:"day-arrow center-h center-v pr-2"},[_c('i',{staticClass:"fas fa-chevron-down arrow-icon"})]):_vm._e()])])]:_vm._e(),(day.sections)?_c('b-collapse',{attrs:{"id":'collapse-' + j,"visible":day.day === null || _vm.singleSection}},[_vm._l((day.sections),function(section,i){return [_c('div',{attrs:{"id":'section_' + section.section_id}}),_c('div',{key:_vm.getSectionKey(section),on:{"mouseover":function($event){return _vm.hoverSection(section.section_id)},"mouseleave":function($event){return _vm.hoverSection(null)}}},[(!section.onlyShow || section.onlyShow === 'top')?[_c('div',{staticClass:"origindestination"},[_c('div',{staticClass:"center-h center-v",staticStyle:{"width":"8%"}},[(i != 0)?_c('div',{staticClass:"graph-line dotted"}):_vm._e(),_c('div',{staticClass:"graph-dot",style:(_vm.getDotClass(section.stops[0], section.color))}),_c('div',{staticClass:"graph-line half bottom",style:(_vm.getLineClass(section.stops[0], section.color))})]),_c('div',{staticClass:"center-h",style:({ width: !_vm.singleSection ? '18%' : '25%' })},[_c('span',{attrs:{"title":_vm.getOriginTime(section, true)}},[_vm._v(_vm._s(_vm.getOriginTime(section)))]),_c('span',{staticClass:"ml-1 old-time",class:{
                    'line-through':
                      _vm.getOriginTime(section, false, true) !== '❌',
                  },attrs:{"title":_vm.getOriginTime(section, true, true)}},[_vm._v(_vm._s(_vm.getOriginTime(section, false, true)))])]),_c('div',{staticClass:"center-h",style:({ width: !_vm.singleSection ? '60%' : '53%' })},[_c('span',[_vm._v(_vm._s(_vm.getOriginText(section)))])]),_c('div',{staticClass:"center-h end-v",staticStyle:{"width":"10%"}},[_c('span',{attrs:{"title":_vm.getOriginPlatform(section)}},[_vm._v(_vm._s(_vm.getOriginPlatform(section)))])]),_c('div',{staticClass:"center-h",staticStyle:{"width":"4%"}})]),_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('accordion-' + j + '_' + i),expression:"'accordion-' + j + '_' + i"}],staticClass:"tab",attrs:{"role":"tab"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"center-h center-v",staticStyle:{"flex":"0 0 8%","height":"auto"}},[_c('div',{staticClass:"graph-line",style:(_vm.getLineClass(section.stops[0], section.color))})]),_c('div',{staticStyle:{"flex-grow":"1","min-width":"0"}},[_c('div',{staticClass:"w-100",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"center-h",staticStyle:{"flex-grow":"1","overflow":"hidden"}},[_c('div',{staticStyle:{"padding-left":"10px","overflow":"hidden"}},[(_vm.showModeIcon(section))?[_c('div',[_c('tl-mode-icon',{key:section.mode,attrs:{"mode":section.mode}}),_c('span',{staticClass:"ml-2",staticStyle:{"font-weight":"bold","margin":"0","white-space":"break-spaces"}},[_vm._v(_vm._s(section.description))])],1)]:_vm._e(),(section.mode != 'stay')?_c('span',{staticStyle:{"white-space":"break-spaces","word-wrap":"break-word"},domProps:{"innerHTML":_vm._s(_vm.getDetailText(section))}}):_vm._e()],2)]),(
                        _vm.getIntermediateStops(section).length > 0 ||
                        (section.description &&
                          (section.mode === 'pt' || section.mode === 'ptm'))
                      )?_c('div',{staticClass:"center-h center-v h-auto px-2"},[_c('i',{staticClass:"fas fa-chevron-down arrow-icon"})]):_vm._e()]),(section.vehicleComposition)?_c('div',{staticClass:"w-100 mt-1"},[_c('tl-train-composition',{attrs:{"composition":section.vehicleComposition,"loadingPadding":""}})],1):_vm._e()])])]),_c('b-collapse',{staticStyle:{"position":"relative"},attrs:{"id":'accordion-' + j + '_' + i,"accordion":"my-accordion","role":"tabpanel","visible":_vm.singleSection}},[_c('div',{staticClass:"w-100 d-flex"},[_c('div',{staticClass:"center-h center-v",staticStyle:{"flex":"0 0 8%","height":"auto"}},[_c('div',{staticClass:"graph-line",style:(_vm.getLineClass(section.stops[0], section.color))})]),_c('div',{staticClass:"center-h",staticStyle:{"flex-grow":"1","overflow":"hidden"}},[_c('div',{staticClass:"w-100",staticStyle:{"padding-left":"10px"}},[(section.mode === 'pt' || section.mode === 'ptm')?_c('div',{staticClass:"w-100"},[(section.description)?_c('span',{staticStyle:{"white-space":"normal"}},[_c('em',[_vm._v("\""+_vm._s(section.description)+"\"")])]):_vm._e()]):_vm._e()])])]),_vm._l((_vm.getIntermediateStops(section)),function(stop){return _c('div',{staticClass:"intermediate"},[_c('div',{staticClass:"center-h center-v",staticStyle:{"width":"8%"}},[_c('div',{staticClass:"graph-dot",style:(_vm.getDotClass(stop, section.color))}),_c('div',{staticClass:"graph-line",style:(_vm.getLineClass(stop, section.color, true))})]),_c('div',{staticClass:"time",style:({ width: !_vm.singleSection ? '18%' : '25%' })},[_c('div',[_c('span',{attrs:{"title":_vm.getSOArrivalTime(stop, true)}},[_vm._v(_vm._s(_vm.getSOArrivalTime(stop)))]),_c('span',{staticClass:"ml-1 old-time",class:{
                        'line-through':
                          _vm.getSOArrivalTime(stop, false, true) !== '❌',
                      },attrs:{"title":_vm.getSOArrivalTime(stop, true, true)}},[_vm._v(_vm._s(_vm.getSOArrivalTime(stop, false, true)))])]),(
                      _vm.getSOArrivalTime(stop) != _vm.getSODepartureTime(stop) ||
                      _vm.getSODepartureTime(stop, false, true) !=
                        _vm.getSOArrivalTime(stop, false, true)
                    )?_c('div',[_c('span',{attrs:{"title":_vm.getSODepartureTime(stop, true)}},[_vm._v(_vm._s(_vm.getSODepartureTime(stop)))]),_c('span',{staticClass:"ml-1 old-time",class:{
                        'line-through':
                          _vm.getSODepartureTime(stop, false, true) !== '❌',
                      },attrs:{"title":_vm.getSODepartureTime(stop, true, true)}},[_vm._v(_vm._s(_vm.getSODepartureTime(stop, false, true)))])]):_vm._e()]),_c('div',{staticClass:"center-h",style:({ width: !_vm.singleSection ? '60%' : '53%' })},[_c('span',[_vm._v(_vm._s(stop.name))])]),_c('div',{staticClass:"center-h center-v",staticStyle:{"width":"10%"}}),_c('div',{staticClass:"center-h",staticStyle:{"width":"4%"}})])})],2)]:_vm._e(),(section.onlyShow === 'top')?_c('div',{staticStyle:{"width":"100%","height":"10px"}},[_c('div',{staticClass:"center-h center-v",staticStyle:{"width":"8%"}},[_c('div',{staticClass:"graph-line",style:(_vm.getDaysLineClass(section.stops, section.color, 'top'))})])]):_vm._e(),(section.onlyShow === 'bottom')?_c('div',{staticStyle:{"width":"100%","height":"10px"}},[_c('div',{staticClass:"center-h center-v",staticStyle:{"width":"8%"}},[_c('div',{staticClass:"graph-line",style:(_vm.getDaysLineClass(section.stops, section.color, 'bottom'))})])]):_vm._e(),(!section.onlyShow || section.onlyShow === 'bottom')?[_c('div',{staticClass:"origindestination"},[_c('div',{staticClass:"center-h center-v",staticStyle:{"width":"8%"}},[_c('div',{staticClass:"graph-line half top",style:(_vm.getLineClass(
                      section.stops[section.stops.length - 1],
                      section.color
                    ))}),_c('div',{staticClass:"graph-dot",style:(_vm.getDotClass(
                      section.stops[section.stops.length - 1],
                      section.color
                    ))})]),_c('div',{staticClass:"center-h",style:({ width: !_vm.singleSection ? '18%' : '25%' })},[_c('span',{attrs:{"title":_vm.getDestinationTime(section, true)}},[_vm._v(_vm._s(_vm.getDestinationTime(section)))]),_c('span',{staticClass:"ml-1 old-time",class:{
                    'line-through':
                      _vm.getDestinationTime(section, false, true) !== '❌',
                  },attrs:{"title":_vm.getDestinationTime(section, true, true)}},[_vm._v(_vm._s(_vm.getDestinationTime(section, false, true)))])]),_c('div',{staticClass:"center-h",style:({ width: !_vm.singleSection ? '60%' : '53%' })},[_c('span',[_vm._v(_vm._s(_vm.getDestinationText(section)))])]),_c('div',{staticClass:"center-h end-v",staticStyle:{"width":"10%"}},[_c('span',{attrs:{"title":_vm.getDestinationPlatform(section)}},[_vm._v(_vm._s(_vm.getDestinationPlatform(section)))])]),_c('div',{staticClass:"center-h",staticStyle:{"width":"4%"}})])]:_vm._e()],2)]})],2):_vm._e()]:_vm._e()})],2)}
var staticRenderFns = []

export { render, staticRenderFns }