<template>
  <div class="d-flex flex-column h-100">
    <div class="content" id="addSectionContainer">
      <b-container fluid>
        <b-row class="my-2 px-1 align-items-center">
          <slot name="button-sidebar"></slot>
          <h3 class="mb-1">{{ $t("sectionDetails") }}</h3>
        </b-row>
        <template v-if="!isEditing">
          <b-row class="px-2">
            <b-link
              v-if="$store.getters.isMyTrip || fromStore"
              class="card-link"
              @click="editSection"
              ><i class="fas fa-pen mr-2"></i>{{ $t("edit") }}</b-link
            >
          </b-row>
          <b-row v-if="section != null" class="my-1">
            <tl-sections
              :sections="section"
              :trip="trip"
              :singleSection="true"
              class="w-100"
            ></tl-sections>
          </b-row>
          <template v-if="!fromStore">
            <b-row class="d-block px-1">
              <hr />
            </b-row>
            <b-row class="my-2 px-3 justify-content-center">
              <b-button class="w-100" @click="addSectionAfter"
                ><i class="fas fa-plus mr-2"></i
                >{{ $t("newSection") }}</b-button
              >
            </b-row></template
          >
        </template>
        <template v-else>
          <b-row v-if="days != null" class="my-1 px-1">
            <label>{{ $t("days") }}</label>
            <b-form-select
              :value="params.days"
              @input="setParam('days', $event)"
              :options="days"
              value-field="day"
              text-field="text"
              multiple
              :select-size="3"
            ></b-form-select>
          </b-row>
          <tl-section-params-ptm
            v-if="params.mode === 'pt' || params.mode === 'ptm'"
          ></tl-section-params-ptm>
          <tl-section-params-simple v-else></tl-section-params-simple>
        </template>
      </b-container>
    </div>
    <div class="footer align-items-center pt-1">
      <b-button @click="goBack" variant="link" class="text-decoration-none">
        {{ $t("cancel") }}
      </b-button>
      <div class="flex-grow-1"></div>
      <template v-if="!isEditing">
        <b-button
          key="delete-section"
          :disabled="isLoadingDeleting"
          v-b-modal.deleteSection
          variant="danger"
          ><i class="fas fa-trash"></i
        ></b-button>
        <b-modal id="deleteSection" :title="$t('deleteSection')">
          <b-container fluid>
            <b-row class="mb-2 px-2">
              <span
                v-html="
                  $t('deleteDialogText', {
                    name: $t('section') + ' ' + $route.params.section_id,
                  })
                "
              ></span>
            </b-row>
          </b-container>

          <template #modal-footer>
            <b-spinner
              v-if="isLoadingDeleting"
              variant="danger"
              class="mr-2 ml-1"
            ></b-spinner>
            <b-button
              variant="danger"
              @click="deleteSection"
              :disabled="isLoadingDeleting"
            >
              {{ $t("confirm") }}
            </b-button>
          </template>
        </b-modal>
      </template>
      <template v-else
        ><b-spinner
          v-if="isLoadingSaving"
          variant="primary"
          class="mr-2 ml-1"
        ></b-spinner>
        <b-button
          :disabled="!saveSectionEnabled || isLoadingSaving"
          variant="primary"
          key="save-section"
          @click="saveSection"
          >{{ $t("save") }}</b-button
        ></template
      >
    </div>
  </div>
</template>

<script>
import tlSections from "../components/tlSections";
import tlSectionParamsPtm from "../components/tlSectionParamsPtm.vue";
import tlSectionParamsSimple from "../components/tlSectionParamsSimple.vue";

export default {
  components: {
    "tl-sections": tlSections,
    "tl-section-params-ptm": tlSectionParamsPtm,
    "tl-section-params-simple": tlSectionParamsSimple,
  },
  computed: {
    section_id() {
      return this.$route.params.section_id;
    },
    section() {
      return this.sections != null
        ? this.sections.filter(
            (section) => section.section_id == this.section_id
          )
        : null;
    },
    sections() {
      return this.$store.state.sections.list;
    },
    trip() {
      return this.$store.state.trips.selected;
    },
    isLoadingDeleting() {
      return this.$store.state.sections.isDeleting;
    },
    isEditing() {
      return this.$store.state.params.isEditing;
    },
    params() {
      return this.$store.state.params.section;
    },
    saveSectionEnabled() {
      return this.$store.getters["params/saveSectionEnabled"];
    },
    days() {
      if (this.fromStore) return null;
      const days = this.$store.state.trips.selected.days;

      return days != null
        ? days.map(function (el) {
            var day = Object.assign({}, el);
            day.text = day.name
              ? day.day + ": " + day.name
              : day.day.toString();
            return day;
          })
        : null;
    },
    isLoadingSaving() {
      return this.$store.state.params.isLoading;
    },
    fromStore() {
      return this.$store.state.sections.fromStore;
    },
  },
  methods: {
    setParam(key, value) {
      this.$store.commit("params/setSectionProp", { key: key, value: value });
    },
    goBack() {
      if (!this.isEditing) {
        this.$router.push({
          name: !this.fromStore ? "EditTripMeta" : "Store",
        });
      } else {
        this.$store.dispatch("params/resetSection");
      }
    },
    async deleteSection() {
      await this.$store.dispatch(
        "sections/delete",
        this.$route.params.section_id
      );
    },
    async editSection() {
      await this.$store.dispatch("sections/edit");
    },
    async saveSection() {
      await this.$store.dispatch("params/saveSection");
    },
    addSectionAfter() {
      this.$store.dispatch("params/resetSection", {
        lastSectionId: this.$route.params.section_id,
      });
      this.$router.push({
        name: !this.fromStore ? "AddSection" : "AddSectionStore",
      });
    },
  },
};
</script>

<style scoped>
.content {
  flex: auto;
  overflow-y: auto;
}

.footer {
  width: 100%;
  flex-basis: 30px;
  display: flex;
}
</style>
