import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import i18n from "../i18n";

export default {
  namespaced: true,
  state: () => ({
    data: null,
    dataOther: null,
  }),
  mutations: {
    setData(state, data) {
      state.data = data;
    },
    setDataOther(state, dataOther) {
      state.dataOther = dataOther;
    },
    setProfileProp(state, payload) {
      if (!state.data) return;
      Vue.set(state.data, payload.key, payload.value);
    },
  },
  getters: {
    getShowCountryFlags(state) {
      return state.data?.showCountryFlags ?? true;
    },
  },
  actions: {
    async load(context) {
      if (!context.rootGetters["getUser"]) {
        context.commit("setData", null);
        context.commit("setDataOther", null);
        return;
      }

      const data = context.rootGetters["isLoggedIn"]
        ? await getProfile(context.rootState.user.uid)
        : null;

      i18n.locale = data?.locale ?? "en";

      context.commit("setData", data);

      const dataOther =
        context.rootGetters["getUser"] &&
        context.rootState.user &&
        context.rootGetters["getUser"].uid !== context.rootState.user?.uid
          ? await getProfile(context.rootGetters["getUser"].uid)
          : null;

      context.commit("setDataOther", dataOther);
    },
    async editParam(context, { key, value }) {
      if (!context.rootGetters["isLoggedIn"]) return;

      const oldValue = context.state.data?.[key] ?? null;
      context.commit("setProfileProp", { key, value });
      const uid = context.rootGetters["getUser"].uid;
      const db = firebase.firestore();
      try {
        await db
          .collection("users")
          .doc(uid)
          .collection("data")
          .doc("profile")
          .set(
            {
              [key]: value,
            },
            { merge: true }
          );
        context.commit(
          "setToast",
          {
            title: "info",
            tTitle: true,
            text: "settingSaved",
            tText: true,
            variant: "primary",
          },
          { root: true }
        );
      } catch (error) {
        console.error(error);
        context.commit("setProfileProp", { key, oldValue });
        context.dispatch("showError", error.toString(), { root: true });
      }
    },
  },
};

async function getProfile(uid) {
  const db = firebase.firestore();
  let profileDoc;
  try {
    profileDoc = await db
      .collection("users")
      .doc(uid)
      .collection("data")
      .doc("profile")
      .get();
  } catch (error) {
    return;
  }
  const data = profileDoc.data();
  if (data.picture) {
    data.pictureUrl = await firebase
      .storage()
      .ref(`users/${uid}/data/profile/${data.picture}`)
      .getDownloadURL();
  }

  data.showCountryFlags = data.showCountryFlags ?? true;

  return data;
}
