<template>
  <div>
    <div v-if="showLoadNext || isLoadingNext" class="position-relative">
      <b-button
        :style="{ visibility: !isLoadingNext ? 'visible' : 'hidden' }"
        @click="$emit('loadNext')"
        variant="link"
        size="sm"
        class="text-decoration-none w-100"
        ><i class="fas fa-arrow-up mr-1"></i> {{ $t("loadMore") }}</b-button
      >
      <div
        v-if="isLoadingNext"
        class="
          position-absolute
          d-flex
          justify-content-center
          align-items-center
        "
        style="inset: 0"
      >
        <b-spinner small variant="primary"></b-spinner>
      </div>
    </div>
    <b-list-group>
      <b-list-group-item
        v-if="sections != undefined && sections != null && sections.length != 0"
        key="origin"
        class="list-item"
      >
        <div class="section-icon">
          <i class="fas fa-home list-icon"></i>
        </div>
        <div class="section-info">{{ sections[0].stops[0].name }}</div>
      </b-list-group-item>
      <draggable
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
        v-model="sectionsModel"
        multi-drag
        selected-class="selected-section"
        :disabled="dragDisabled"
      >
        <b-list-group-item
          button
          v-for="section in sections"
          :key="section.section_id"
          :style="getSectionStyle(section)"
          @click="$emit('selected', section)"
          class="list-item"
        >
          <div class="section-icon overflow-hidden">
            <i
              v-if="section.mode != 'pt' && section.mode != 'ptm'"
              class="fas list-icon"
              :class="modeIcon(section)"
              :key="section.section_id + '_icon'"
            ></i>
            <span style="font-size: 14px; text-align: center" v-else>
              {{ getPTCategory(section) }}
            </span>
          </div>

          <div class="section-info">
            <i
              v-if="section.mode !== 'stay'"
              class="fas fa-arrow-right"
              :key="section.section_id + '_arrow_right'"
            ></i>
            <span style="margin-left: 4px">{{
              section.mode === "stay"
                ? section.description
                : section.stops[section.stops.length - 1].name
            }}</span>
          </div>
        </b-list-group-item>
      </draggable>
      <b-list-group-item
        button
        @click="$emit('add')"
        :active="selected_add"
        class="list-item"
      >
        <div class="section-icon">
          <i class="fas fa-plus list-icon"></i>
        </div>
        <div class="section-info" style="font-size: 15px !important">
          {{ $t("newSection") }}
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "tl-section-picker",
  components: {
    draggable,
  },
  props: {
    selected_section_id: String,
    selected_add: Boolean,
    isLoadingNext: Boolean,
    showLoadNext: Boolean,
    dragDisabled: Boolean,
  },
  data() {
    return {
      drag: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 100,
        disabled: false,
        delay: 400,
        delayOnTouchOnly: true,
        multiDragKey: "CTRL",
      };
    },
    sections() {
      return this.$store.state.sections.list;
    },
    sectionsModel: {
      get() {
        return this.sections;
      },
      set(value) {
        this.$store.dispatch("sections/reorder", value);
      },
    },
  },
  methods: {
    modeIcon(section) {
      if (section.mode == "pt" || section.mode == "ptm") {
        return "fa-subway";
      } else if (section.mode == "walk") {
        return "fa-walking";
      } else if (section.mode == "car") {
        return "fa-car";
      } else if (section.mode == "bike") {
        return "fa-bicycle";
      } else if (section.mode == "stay") {
        return "fa-map-marker-alt";
      } else if (section.mode == "flight") {
        return "fa-plane";
      } else if (section.mode == "boat") {
        return "fa-ship";
      }
    },
    getPTCategory(section) {
      const line_name = section.line_name;
      try {
        if (line_name.startsWith("SEV") || line_name.startsWith("SV")) {
          return "SEV";
        } else if (
          line_name.startsWith("STR") ||
          line_name.startsWith("Straßenbahn") ||
          line_name.startsWith("Tram") ||
          line_name.startsWith("Bus") ||
          line_name.startsWith("Stadtbus")
        ) {
          return line_name.match(/[0-9]+/)[0];
        } else if (line_name.charAt(0) == "U" || line_name.charAt(0) == "S") {
          return line_name.charAt(0) + line_name.match(/[0-9]+/)[0];
        } else if (line_name.split(/[0-9 ]+/)[0]) {
          return line_name.split(/[0-9 ]+/)[0];
        } else {
          return line_name.match(/[0-9]+/)[0];
        }
      } catch (error) {
        if (line_name.startsWith("Stadtbus")) {
          return line_name.split("Stadtbus ")[1];
        } else {
          return line_name;
        }
      }
    },
    getSectionStyle(section) {
      if (
        this.selected_section_id != undefined &&
        section.section_id == this.selected_section_id
      ) {
        return {
          "z-index": 2,
          color: "#fff",
          "background-color": "#74bc00",
          "border-color": "#74bc00",
        };
      }
      return {};
    },
  },
};
</script>

<style scoped>
::v-deep .list-group-item {
  padding: 5px 8px 5px 5px !important;
}

.section-icon {
  width: 15%;
  float: left;
  text-align: center;
  font-size: 14px;
}

.section-info {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 9px;
  padding-left: 4px;
  width: 85%;
  float: left;
  white-space: nowrap;
}

.list-item {
  display: flex;
  align-items: center;
}

.list-icon {
  margin-right: 5px;
  width: 100% !important;
}

span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.selected-section {
  z-index: 2 !important;
  color: #fff !important;
  background-color: #74bc00 !important;
  border-color: #74bc00 !important;
}
</style>
