import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
// import { DateTime, Duration } from "luxon";
import buildUrl from "build-url";
import router from "@/router";

import { PublicClientApplication } from "@azure/msal-browser";
import msalConfig from "../plugins/msalConfig";
const loginRequest = {
  scopes: ["User.Read", "Files.Read.All", "offline_access"],
  redirectUri: msalConfig.auth.redirectUri,
};

const client = new PublicClientApplication(msalConfig);

export default {
  namespaced: true,
  state: () => ({
    accessToken: null,
    expiresOn: null,
    username: null,
  }),
  mutations: {
    set(state, response) {
      state.accessToken = response.accessToken;
      state.expiresOn = response.expiresOn;
    },
    reset(state) {
      state.accessToken = null;
      state.expiresOn = null;
    },
    setUsername(state, username) {
      state.username = username;
    },
  },
  actions: {
    async authorize(context) {
      if (context.rootGetters.isLoggedIn) {
        const db = firebase.firestore();
        const uid = context.rootState.user.uid;
        const path = router.currentRoute?.path;

        const state = Math.floor(100000 + Math.random() * 900000);
        const url = buildUrl("https://login.microsoftonline.com", {
          path: "consumers/oauth2/v2.0/authorize",
          queryParams: {
            client_id: msalConfig.auth.clientId,
            response_type: "code",
            redirect_uri: msalConfig.auth.redirectUri,
            response_mode: "query",
            scope: loginRequest.scopes,
            state: state,
          },
        });

        await db
          .collection("users")
          .doc(uid)
          .collection("data")
          .doc("msal")
          .update({
            state: state,
            path: path,
          });

        window.location.href = url;
      }
    },
    async getRefreshToken(context, { query }) {
      if (context.rootGetters.isLoggedIn) {
        const { code, state } = query;
        const uid = context.rootGetters.getUser.uid;

        if (!code || !state) {
          // TODO: Throw error when no query params
          return;
        }

        const db = firebase.firestore();
        const msalRef = db
          .collection("users")
          .doc(uid)
          .collection("data")
          .doc("msal");
        const msalData = (await msalRef.get()).data();

        // if (state != msalData.state) {
        //   // TODO: Throw error when codes don't match
        //   return;
        // }

        msalRef.update({
          state: firebase.firestore.FieldValue.delete(),
          path: firebase.firestore.FieldValue.delete(),
        });

        let result;

        try {
          result = await firebase
            .functions()
            .httpsCallable("msalGetRefreshToken")({
            code: code,
            redirect_uri: msalConfig.auth.redirectUri,
          });
        } catch (error) {
          // TODO: Throw error when function throws error
          return;
        }

        if (result?.data?.status !== 200) {
          // TODO: Throw error when status is not 200
          return;
        }
        return msalData.path;
      } else {
        // TODO: Throw error when not logged in
        return;
      }
    },
    async getAccessToken(context) {
      if (context.rootGetters.isLoggedIn) {
        let result;
        try {
          result = await firebase
            .functions()
            .httpsCallable("msalGetAccessToken")();
        } catch (error) {
          // TODO: Throw error when function throws error
          return;
        }

        if (result?.data?.status !== 200) {
          // TODO: Throw error when status is not 200
          return;
        }

        return result.data.accessToken;
      }
    },
    async login(context) {
      if (context.rootGetters.isLoggedIn) {
        try {
          const db = firebase.firestore();
          const msalRef = db
            .collection("users")
            .doc(context.rootState.user.uid)
            .collection("data")
            .doc("msal");
          const msalDoc = await msalRef.get();
          let response = null;
          if (msalDoc.exists) {
            context.commit("setUsername", msalDoc.data().username);
            const account = client.getAccountByUsername(
              msalDoc.data().username
            );
            if (account) {
              response = await client.acquireTokenSilent(
                Object.assign(loginRequest, {
                  account: account,
                })
              );
            }
          }
          if (!msalDoc.exists || !response) {
            let request = loginRequest;
            if (msalDoc.exists) {
              request = Object.assign(loginRequest, {
                loginHint: msalDoc.data().username,
              });
            }
            response = await client.loginPopup(request);
            await msalRef.set({
              username: response.account.username,
            });
          }

          context.commit("set", response);
        } catch (error) {
          console.error(error);
          context.commit("reset");
        }
      } else {
        context.commit("reset");
      }
    },
    // async getAccessToken(context) {
    //   if (context.rootGetters.isLoggedIn) {
    //     if (!context.state.expiresOn) {
    //       await context.dispatch("login");
    //     }
    //     const now = DateTime.now().plus(
    //       Duration.fromObject({
    //         minutes: 5,
    //       })
    //     );
    //     const expiresOn = DateTime.fromJSDate(context.state.expiresOn);
    //     if (now > expiresOn) {
    //       await context.dispatch("login");
    //     }
    //     return context.state.accessToken;
    //   } else {
    //     return null;
    //   }
    // },
    async logout() {
      await client.logoutPopup(loginRequest);
    },
  },
};
