<template>
  <div class="d-flex flex-column h-100">
    <div class="content">
      <b-container fluid v-if="params != null">
        <b-row class="my-2 px-1 align-items-center">
          <slot name="button-sidebar"></slot>
          <h3 class="mb-1">{{ $t("tripDetails") }}</h3>
        </b-row>
        <b-row class="my-1 px-1">
          <label>{{ $t("tripName") }}</label>
          <b-form-input
            :value="params.name"
            @input="setParam('name', $event)"
          ></b-form-input>
        </b-row>
        <b-row class="my-1 px-1">
          <label>{{ $t("tripDescription") }}</label>
          <b-form-textarea
            :value="params.description"
            @input="setParam('description', $event)"
            rows="2"
            max-rows="5"
          ></b-form-textarea>
        </b-row>
        <b-row class="my-1 px-1">
          <label>{{ $t("tripUrlname") }}</label>
          <b-form-input
            :value="params.urlname"
            @input="setParam('urlname', $event)"
            :state="validUrlname"
            aria-describedby="input-urlname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-urlname-feedback">
            {{ $t("tripUrlnameChars") }}
          </b-form-invalid-feedback>
        </b-row>
        <b-row class="my-1 px-1">
          <b-form-group :label="$t('allowRead')" class="mb-0">
            <b-form-radio-group
              :options="allowReadOptions"
              :checked="params.allow_read"
              @input="setParam('allow_read', $event)"
            ></b-form-radio-group>
          </b-form-group>
        </b-row>
        <b-row class="d-block px-1">
          <hr />
        </b-row>
        <tl-days
          class="px-1"
          :days="params.days"
          @input="setParam('days', $event)"
        >
        </tl-days>
        <b-row class="d-block px-1">
          <hr />
        </b-row>
        <b-row class="my-2 px-3 justify-content-center">
          <b-button class="w-100" @click="$store.state.galleryRef.open()"
            ><i class="fas fa-images mr-2"></i>{{ $t("gallery") }}</b-button
          >
        </b-row>
        <b-row class="d-block px-1">
          <hr />
        </b-row>
        <tl-tracks class="px-1"></tl-tracks>
      </b-container>
    </div>
    <div class="footer align-items-center pt-1">
      <b-button @click="reset" variant="link" class="text-decoration-none">
        {{ $t("reset") }}
      </b-button>
      <div class="flex-grow-1"></div>
      <b-spinner
        v-if="isSavingMeta"
        variant="primary"
        class="mr-2 ml-1"
      ></b-spinner>
      <b-button
        @click="save"
        :disabled="saveMetaDisabled || isSavingMeta"
        variant="primary"
        >{{ $t("save") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import tlDays from "../components/tlDays";
import tlTracks from "../components/tlTracks";

export default {
  components: {
    "tl-days": tlDays,
    "tl-tracks": tlTracks,
  },
  computed: {
    params() {
      return this.$store.state.params.trip;
    },
    validUrlname() {
      return this.params == null || /^[a-z0-9-]*$/.test(this.params.urlname)
        ? null
        : false;
    },
    allowReadOptions() {
      return [
        { value: "none", text: this.$t("private") },
        { value: "all", text: this.$t("public") },
      ];
    },
    saveMetaDisabled() {
      return (
        this.params.name == "" ||
        this.params.urlname == "" ||
        this.validUrlname === false
      );
    },
    isSavingMeta() {
      return this.$store.state.params.isSavingMeta;
    },
  },
  methods: {
    setParam(key, value) {
      this.$store.commit("params/setTripProp", { key: key, value: value });
    },
    save() {
      this.$store.dispatch("params/saveMeta");
    },
    reset() {
      this.$store.dispatch("params/setTripMerge");
    },
  },
};
</script>

<style scoped>
.content {
  flex: auto;
  overflow-y: auto;
}

.footer {
  width: 100%;
  flex-basis: 30px;
  display: flex;
}
</style>
