<template>
  <b-container fluid style="overflow-y: auto; height: 100%">
    <b-row class="mb-1">
      <b-button
        variant="link"
        :to="{ name: 'User' }"
        class="text-decoration-none"
        ><i class="fas fa-arrow-left mr-1"></i> {{ $t("overview") }}</b-button
      >
    </b-row>
    <b-row class="px-3 mb-1">
      <h3>{{ $t("settings") }}</h3>
    </b-row>
    <tl-text-edit readOnly :label="$t('email')" :value="email"></tl-text-edit>
    <tl-text-edit
      :label="$t('username')"
      :value="username"
      username
      :error="usernameError"
      :isLoading="isChangingUsername"
      @cancel="usernameError = null"
      @input="changeUsername"
    ></tl-text-edit>
    <tl-text-edit
      :label="$t('firstName')"
      :value="profile.firstName"
      @input="editParam('firstName', $event)"
    ></tl-text-edit>
    <tl-text-edit
      :label="$t('lastName')"
      :value="profile.lastName"
      @input="editParam('lastName', $event)"
    ></tl-text-edit>
    <b-row class="my-1">
      <b-col sm="4" class="d-flex align-items-center word-break">
        <label class="m-0" for="input">{{ $t("defaultLanguage") }}:</label>
      </b-col>
      <b-col sm="8" class="d-flex align-items-center"
        ><b-form-select
          :options="$i18n.availableLocales"
          :value="profile.locale || $i18n.fallbackLocale"
          @input="setLocale"
        ></b-form-select></b-col
    ></b-row>
    <b-row class="my-1">
      <b-col sm="4" class="d-flex align-items-center word-break">
        <label class="m-0" for="input">{{ $t("showCountryFlags") }}:</label>
      </b-col>
      <b-col sm="8" class="d-flex align-items-center"
        ><b-form-checkbox
          switch
          :checked="profile.showCountryFlags"
          @input="editParam('showCountryFlags', $event)"
        ></b-form-checkbox></b-col
    ></b-row>
  </b-container>
</template>

<script>
import tlTextEdit from "../components/tlTextEdit";

export default {
  components: {
    "tl-text-edit": tlTextEdit,
  },
  data() {
    return {
      usernameError: null,
      isChangingUsername: false,
    };
  },
  computed: {
    profile() {
      return this.$store.state.profile.data;
    },
    username() {
      return this.$store.state.username;
    },
    email() {
      return this.$store.state.user?.email;
    },
  },
  methods: {
    editParam(key, value) {
      this.$store.dispatch("profile/editParam", {
        key,
        value,
      });
    },
    async changeUsername(newUsername) {
      this.isChangingUsername = true;
      this.usernameError = null;

      const result = await this.$store.dispatch("changeUsername", newUsername);
      this.isChangingUsername = false;

      if (result.status === 409) {
        this.usernameError = this.$t("usernameTaken");
        return;
      }
    },
    async setLocale(value) {
      await this.editParam("locale", value);
      this.$i18n.locale = value;
    },
  },
  watch: {},
};
</script>

<style></style>
