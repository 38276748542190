import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import trips from "./trips";
import sections from "./sections";
import params from "./params";
import tlRouter from "./router";
import gallery from "./gallery";
import msal from "./msal";
import tracker from "./tracker";
import profile from "./profile";
import tickets from "./tickets";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    trips: trips,
    sections: sections,
    params: params,
    router: tlRouter,
    gallery: gallery,
    msal: msal,
    tracker: tracker,
    profile: profile,
    tickets,
  },
  state: {
    user: null,
    otherUser: null,
    username: null,
    access_token: null,
    mainSidebar: true,
    loading: true,
    toast: null,
    isDesktop: true,
    refreshMap: 0,
    resetMap: true,
    isLoading: false,
    curveUserNeedsUpdate: false,
    isCompletingUser: false,
    galleryRef: null,
    hideSections: false,
    fullSectionOpacity: false,
  },
  getters: {
    isLoggedIn(state) {
      return state.user != null;
    },
    isMyTrip(state) {
      return state.user != null && state.otherUser == null;
    },
    getUser(state) {
      return state.otherUser == null ? state.user : state.otherUser;
    },
    getUserParam(state, getters) {
      return state.username == null ? getters.getUser.uid : state.username;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setOtherUser(state, otherUser) {
      state.otherUser = otherUser;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setAccessToken(state, access_token) {
      state.access_token = access_token;
    },
    setMainSidebar(state, mainSidebar) {
      state.mainSidebar = mainSidebar;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setToast(state, toast) {
      state.toast = toast;
    },
    setIsDesktop(state, isDesktop) {
      state.isDesktop = isDesktop;
    },
    refreshMap(state) {
      state.refreshMap++;
    },
    setResetMap(state, resetMap) {
      state.resetMap = resetMap;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setCurveUserNeedsUpdate(state, curveUserNeedsUpdate) {
      state.curveUserNeedsUpdate = curveUserNeedsUpdate;
    },
    setIsCompletingUser(state, isCompletingUser) {
      state.isCompletingUser = isCompletingUser;
    },
    setGalleryRef(state, galleryRef) {
      state.galleryRef = galleryRef;
    },
    setHideSections(state, hideSections) {
      state.hideSections = hideSections;
    },
    setFullSectionOpacity(state, fullSectionOpacity) {
      state.fullSectionOpacity = fullSectionOpacity;
    },
  },
  actions: {
    login(context, payload) {
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then((user) => {
          firebase
            .firestore()
            .collection("users")
            .doc(user.user.uid)
            .get()
            .then((userDoc) => {
              if (userDoc.exists) {
                context.dispatch("toUser", userDoc.data().username);
              }
            });
        })
        .catch(function () {
          // Handle Errors here.
          // var errorCode = error.code;
          // var errorMessage = error.message;
          // ...
        });
    },
    logout() {
      firebase.auth().signOut();
      router.push("/");
    },
    async completeUser(context) {
      if (context.getters.isLoggedIn) {
        context.commit("setIsCompletingUser", true);
        try {
          var completeObject = firebase
            .functions()
            .httpsCallable("completeObject");
          const result = await completeObject({
            type: "user",
          });
          if (result.data.status == 200) {
            context.commit("setCurveUserNeedsUpdate", false);
            await context.dispatch("trips/load");
            context.commit(
              "setToast",
              {
                title: "info",
                tTitle: true,
                text: "userCompleted",
                tText: true,
                variant: "primary",
              },
              { root: true }
            );
          }
        } catch (error) {
          console.error(error);
          context.dispatch("showError", error.toString(), { root: true });
        }
        context.commit("setIsCompletingUser", false);
      }
    },
    async toUser(context, username) {
      const uname = username || context.state.username;
      if (uname != null) {
        router.push(`/user/${uname}`);
      } else if (context.state.user) {
        const userDoc = await firebase
          .firestore()
          .collection("users")
          .doc(context.state.user.uid)
          .get();
        if (userDoc.exists && userDoc.data().username) {
          router.push(`/user/${userDoc.data().username}`);
        }
      }
    },
    toTrip(context, toTrip) {
      const trip =
        toTrip != undefined &&
        (toTrip.urlname != undefined || toTrip.trip_id != undefined)
          ? toTrip
          : context.state.trips.selected;
      const tripParam = trip.urlname == undefined ? trip.trip_id : trip.urlname;
      let path = `/user/${context.getters.getUserParam}/trip/${tripParam}`;
      if (toTrip != undefined && toTrip.toEdit) {
        path += "/edit";
      }
      router.push(path);
    },
    toSection(context, trip, section_id) {
      const tripParam = trip.urlname == undefined ? trip.trip_id : trip.urlname;
      router.push(
        `/user/${context.getters.getUserParam}/trip/${tripParam}/section/${section_id}`
      );
    },
    showError(context, text) {
      context.commit("setToast", {
        title: "error",
        tTitle: true,
        text: text,
        variant: "danger",
      });
    },
    async changeUsername(context, newUsername) {
      if (!context.getters["isLoggedIn"]) return { status: 401 };
      if (typeof newUsername !== "string") return { status: 400 };
      if (
        newUsername.match(/[^a-z0-9]/g) ||
        newUsername.length == 0 ||
        newUsername.length > 20
      )
        return { status: 400 };
      const db = firebase.firestore();
      const usernameQuery = await db
        .collection("users")
        .where("username", "==", newUsername)
        .get();
      if (!usernameQuery.empty) return { status: 409 };

      try {
        await firebase.functions().httpsCallable("changeUsername")({
          newUsername,
        });
      } catch (error) {
        console.error(error);
        context.dispatch("showError", error.toString(), { root: true });
        return { status: 500 };
      }

      context.commit(
        "setToast",
        {
          title: "info",
          tTitle: true,
          text: "usernameChanged",
          tText: true,
          variant: "primary",
        },
        { root: true }
      );

      router.replace({ params: { user: newUsername } });

      return { status: 200 };
    },
  },
});
