import firebase from "firebase/app";
import { DateTime } from "luxon";
import "firebase/firestore";

export default {
  namespaced: true,
  state: () => ({
    data: null,
  }),
  mutations: {
    setData(state, data) {
      state.data = data;
    },
  },
  actions: {
    async load(context) {
      if (!context.rootGetters["getUser"]) return;

      const uid = context.rootGetters["getUser"].uid;
      const db = firebase.firestore();

      let ticketsSnap;
      try {
        let ticketsRef = db
          .collection("users")
          .doc(uid)
          .collection("tickets")
          .where("validUntil", ">=", DateTime.now().toISO());
        if (!context.rootGetters["isLoggedIn"]) {
          ticketsRef = ticketsRef.where("allowRead", "==", "all");
        }
        ticketsSnap = await ticketsRef.get();
      } catch (error) {
        console.error(error);
        return;
      }

      const tickets = [];

      for (const ticketDoc of ticketsSnap.docs) {
        tickets.push({ ticketId: ticketDoc.id, ...ticketDoc.data() });
      }

      context.commit("setData", tickets.length !== 0 ? tickets : null);
    },
  },
};
