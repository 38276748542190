export function formatLength(length) {
  if (isNaN(length)) return null;
  return (
    (Math.round((length + Number.EPSILON) * 100) / 100).toLocaleString(
      "de-DE"
    ) + " km"
  );
}

export function formatEuros(value) {
  if (isNaN(value)) return null;
  return (
    (Math.round((value + Number.EPSILON) * 100) / 100).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
    }) + " €"
  );
}
