<template>
  <b-container fluid>
    <slot></slot>
    <b-row class="my-2">
      <b-form-checkbox v-model="hideSections" switch>
        {{ $t("hideSections") }}
      </b-form-checkbox>
    </b-row>
    <b-row class="my-2">
      <b-form-checkbox v-model="fullSectionOpacity" switch>
        {{ $t("fullSectionOpacity") }}
      </b-form-checkbox>
    </b-row>
    <b-row class="my-2" v-show="$route.params.trip != undefined">
      <b-form-checkbox
        @input="$store.commit('gallery/setShowGalleryOnMap', $event)"
        :checked="showGalleryOnMap"
        switch
      >
        {{ $t("showMedia") }}
      </b-form-checkbox>
    </b-row>
    <template v-if="tracks"
      ><b-row class="d-block px-1">
        <hr />
      </b-row>
      <b-row class="my-1">
        <label>{{ $t("tracks") }}</label>
        <b-form-select
          v-model="selectedTrackIds"
          :options="tracks"
          value-field="track_id"
          text-field="name"
          multiple
        ></b-form-select>
        <b-button
          @click="$store.commit('sections/setSelectedTracks', [])"
          variant="link"
          size="sm"
          class="text-decoration-none mt-1"
          ><i class="fas fa-times mr-1"></i>{{ $t("clear") }}</b-button
        >
      </b-row>
      <b-row v-if="trackRange" class="my-1 px-2">
        <div class="w-100">
          <tl-time-slider
            :min="trackRange[0]"
            :max="trackRange[1]"
            v-model="trackFilterRange"
          ></tl-time-slider>
        </div> </b-row
    ></template>
  </b-container>
</template>

<script>
import tlTimeSlider from "../components/tlTimeSlider";
import { DateTime } from "luxon";

export default {
  name: "tl-map-options",
  components: { "tl-time-slider": tlTimeSlider },
  methods: {},
  computed: {
    showGalleryOnMap() {
      return this.$store.state.gallery.showGalleryOnMap;
    },
    tracks() {
      return this.$store.state.sections.tracks;
    },
    selectedTrackIds: {
      get() {
        return this.$store.state.sections.selectedTracks;
      },
      set(value) {
        this.$store.commit("sections/setSelectedTracks", value);
      },
    },
    hideSections: {
      get() {
        return this.$store.state.hideSections;
      },
      set(value) {
        this.$store.commit("setHideSections", value);
      },
    },
    fullSectionOpacity: {
      get() {
        return this.$store.state.fullSectionOpacity;
      },
      set(value) {
        this.$store.commit("setFullSectionOpacity", value);
      },
    },
    selectedTracks() {
      return this.$store.getters["sections/getSelectedTracks"];
    },
    trackRange() {
      if (!this.selectedTracks) {
        return null;
      }

      if (this.selectedTracks.length > 1) {
        return null;
      }

      const features = this.selectedTracks[0].geojson.features;

      return [
        DateTime.fromISO(features[0].properties.time, {
          zone: features[0].properties.timezone,
        }),
        DateTime.fromISO(features[features.length - 1].properties.time, {
          zone: features[features.length - 1].properties.timezone,
        }),
      ];
    },
    trackFilterRange: {
      get() {
        return this.$store.state.sections.trackFilterRange;
      },
      set(value) {
        this.$store.commit("sections/setTrackFilterRange", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
