import Vue from "vue";
import paramsPt from "./paramsPt";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/storage";

export default {
  namespaced: true,
  modules: {
    pt: paramsPt,
  },
  state: () => ({
    section: new Section(),
    isLoading: false,
    trip: Trip(),
    isSavingMeta: false,
    trackerFile: null,
    isUploadingTrack: false,
    isEditing: false,
  }),
  mutations: {
    setSection(state, section) {
      state.section = section;
    },
    clearSection(state) {
      state.section = new Section();
    },
    setSectionMerge(state, section) {
      if (section.stops) {
        const firstStop = section.stops[0];
        const lastStop = section.stops[section.stops.length - 1];
        section.starttime = firstStop.departure || firstStop.plannedDeparture;
        section.endtime = lastStop.arrival || lastStop.plannedArrival;
        section.startpoint = {
          name: firstStop.name,
        };
        section.endpoint = {
          name: lastStop.name,
        };
      }

      state.section = Object.assign(new Section(), section);

      if (section.line_name) {
        state.section.ptm.category = section.line_name;
      }

      if (section.journey_number) {
        state.section.ptm.number = section.journey_number;
      }

      if (section.direction) {
        state.section.ptm.direction = section.direction;
      }

      if (section.vehicle && section.product) {
        state.section.ptm.mode = {
          mode: section.vehicle,
          product: section.product,
        };
      }

      if (section.operator) {
        state.section.ptm.operator = section.operator;
      }
    },
    setSectionProp(state, payload) {
      Vue.set(state.section, payload.key, payload.value);
    },
    setSectionPtmProp(state, payload) {
      Vue.set(state.section.ptm, payload.key, payload.value);
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setTrip(state, trip) {
      state.trip = trip;
    },
    setTripMerge(state, trip) {
      state.trip = Object.assign(Trip(), trip);
    },
    setTripProp(state, payload) {
      Vue.set(state.trip, payload.key, payload.value);
    },
    clearTrip(state) {
      state.trip = Trip();
    },
    setIsSavingMeta(state, isSavingMeta) {
      state.isSavingMeta = isSavingMeta;
    },
    setTrackerFile(state, trackerFile) {
      state.trackerFile = trackerFile;
    },
    setIsUploadingTrack(state, isUploadingTrack) {
      state.isUploadingTrack = isUploadingTrack;
    },
    setIsEditing(state, isEditing) {
      state.isEditing = isEditing;
    },
  },
  getters: {
    saveSectionEnabled(state, getters, rootState) {
      if (
        state.section.mode === "ptm" ||
        (state.section.mode === "pt" && state.isEditing)
      ) {
        return (
          state.section.ptm.stations.length > 1 &&
          state.section.ptm.category &&
          state.section.ptm.direction !== "" &&
          state.section.ptm.mode !== ""
        );
      } else if (state.section.mode == "pt") {
        return state.pt.selectedJourney != null;
      } else if (state.section.mode === "stay") {
        return state.section.coords != null;
      } else {
        return (
          state.section.startpoint.name != "" &&
          state.section.endpoint.name != "" &&
          rootState.router.route
        );
      }
    },
  },
  actions: {
    setTripMerge(context) {
      const trip = context.rootState.trips.selected;
      if (trip != null) {
        context.commit("setTripMerge", trip);
      }
    },
    async saveSection(context) {
      const fromStore = context.rootState.sections.fromStore;
      if (context.rootState.trips.selected !== null || fromStore) {
        context.commit("setIsLoading", true);
        const isEditing = context.state.isEditing;
        try {
          const params = { ...context.state.section };
          params.fromStore = fromStore;

          // set trip_id
          params.trip_id = context.rootState.trips.selected?.trip_id;

          if (isEditing) {
            params.section_id = context.rootState.sections.selected?.section_id;
          }

          // add route to params
          const mode = params.mode;
          const route = context.rootState.router.route;
          if (
            (mode == "walk" ||
              mode == "bike" ||
              mode == "car" ||
              mode == "ptm" ||
              mode == "flight" ||
              mode == "boat" ||
              (mode === "pt" && isEditing)) &&
            route
          ) {
            params.curve_section = JSON.stringify(route);
          }

          if (mode === "pt" && !isEditing) {
            // add hafas journey
            const journey = context.state.pt.selectedJourney;
            params.journey = journey !== null ? JSON.stringify(journey) : null;

            const overrideDate = context.state.pt.overrideDate;
            if (overrideDate) {
              params.overrideDate = overrideDate;
            }
          }

          // add file metadata
          const allow_read = context.rootState.trips.selected?.allow_read;

          params.metadata = {
            allow_read: allow_read != undefined ? allow_read : "none",
          };
          params.days =
            params.days && params.days.length != 0 ? params.days : null;

          delete params.calcDuration;
          delete params.duration;

          if (
            params.vehicleComposition &&
            params.vehicleComposition.length === 0
          ) {
            params.vehicleComposition = null;
          }

          if (!isEditing) {
            await firebase.functions().httpsCallable("addSection")(params);
          } else {
            await firebase.functions().httpsCallable("editSection")(params);
          }
        } catch (error) {
          console.error(error);
          context.dispatch("showError", error.toString(), { root: true });
          context.commit("setIsLoading", false);
          return;
        }
        context.commit("setIsLoading", false);
        context.commit("setIsLoading", true, { root: true });
        await context.dispatch(
          "sections/load",
          {
            clearTracks: false,
            fitBounds: false,
          },
          { root: true }
        );
        context.dispatch("resetSection");
        context.commit("setIsLoading", false, { root: true });
        context.commit("setResetMap", true, { root: true });
        context.commit(
          "setToast",
          {
            title: "info",
            tTitle: true,
            text: !isEditing ? "sectionAdded" : "sectionSaved",
            tText: true,
            variant: "primary",
          },
          { root: true }
        );
      }
    },
    resetSection(context, payload) {
      const section = new Section();
      if (!section.days) {
        section.days = [];
      }
      const list = context.rootState.sections.list;
      if (
        list != null &&
        list.length != 0 &&
        (!payload?.lastSectionId ||
          list.find((section) => section.section_id === payload.lastSectionId))
      ) {
        const lastSection = !payload?.lastSectionId
          ? list[list.length - 1]
          : list.find(
              (section) => section.section_id === payload.lastSectionId
            );

        if (payload?.lastSectionId) {
          section.pos = lastSection.pos + 1;
        }

        if (lastSection.days) {
          section.days = [lastSection.days[lastSection.days.length - 1]];
        }

        const firstStop = lastSection.stops[0];
        const lastStop = lastSection.stops[lastSection.stops.length - 1];
        if (lastStop.arrival || lastStop.plannedArrival) {
          section.starttime = lastStop.arrival || lastStop.plannedArrival;
          section.endtime = lastStop.arrival || lastStop.plannedArrival;
        } else if (firstStop.departure || firstStop.plannedDeparture) {
          section.starttime = firstStop.departure || firstStop.plannedDeparture;
          section.endtime = firstStop.departure || firstStop.plannedDeparture;
        }
        if (lastSection.mode === "pt") {
          section.hafas_profile = lastSection.hafas_profile;
        }
        if (lastStop.name) {
          if (
            lastSection.mode === "pt" &&
            lastSection.hafas_profile === "gmaps"
          ) {
            section.startpoint = {
              name: lastStop.name,
              place_id: lastStop.hafas_id,
            };
          } else if (lastSection.mode === "pt") {
            section.startpoint = {
              name: lastStop.name,
              id: lastStop.hafas_id,
              type: "stop",
            };
          } else {
            section.startpoint = {
              name: lastStop.name,
            };
          }
        }
        context.dispatch("pt/clear");
        context.commit("pt/setDatetime", section.starttime);
        context.commit("setSection", section);
      } else {
        context.commit("setSection", new Section());
      }
      context.commit("setIsEditing", false);
      context.dispatch("router/reset", null, { root: true });
      context.commit("pt/setVia", { name: "" });
    },
    async saveMeta(context) {
      const params = context.state.trip;
      const uid = context.rootState.user.uid;
      const db = firebase.firestore();
      if (params.name != "" && params.urlname != "") {
        context.commit("setIsSavingMeta", true);
        try {
          if (params.urlname !== context.rootState.trips.selected.urlname) {
            const tripsSnap = await db
              .collection("users")
              .doc(uid)
              .collection("trips")
              .where("urlname", "==", params.urlname)
              .get();
            if (!tripsSnap.empty) {
              context.commit(
                "setToast",
                {
                  title: "error",
                  tTitle: true,
                  text: "tripUrlnameError",
                  tText: true,
                  variant: "danger",
                },
                { root: true }
              );
              context.commit("setIsSavingMeta", false);
              return { status: 422 };
            }
          }
          // update trip meta
          const result = await firebase
            .functions()
            .httpsCallable("saveTripMeta")(params);
          context.commit("setIsSavingMeta", false);
          context.commit("setIsLoading", true, { root: true });
          context.commit(
            "setToast",
            {
              title: "info",
              tTitle: true,
              text: "tripDetailsSaved",
              tText: true,
              variant: "primary",
            },
            { root: true }
          );
          if (result.data.newUrlname) {
            context.dispatch(
              "toTrip",
              { urlname: result.data.urlname, toEdit: true },
              { root: true }
            );
          } else {
            await context.dispatch(
              "sections/load",
              { clearTracks: false, fitBounds: false },
              { root: true }
            );
          }
          context.commit("setIsLoading", false, { root: true });
        } catch (error) {
          console.error(error);
          context.dispatch("showError", error.toString(), { root: true });
        }
        context.commit("setIsSavingMeta", false);
      }
    },
    async uploadTrack(context) {
      if (
        context.rootGetters.isMyTrip &&
        context.rootState.trips.selected &&
        context.state.trackerFile
      ) {
        context.commit("setIsUploadingTrack", true);
        try {
          const db = firebase.firestore();
          const storage = firebase.storage().ref();
          const uid = context.rootState.user.uid;
          const trip_id = context.rootState.trips.selected.trip_id;
          const trackerFile = context.state.trackerFile;
          const trackDoc = db
            .collection("users")
            .doc(uid)
            .collection("trips")
            .doc(trip_id)
            .collection("tracks")
            .doc();
          await storage
            .child(
              `users/${uid}/trips/${trip_id}/tracks/${trackDoc.id}/${trackerFile.name}`
            )
            .put(trackerFile);
          const unsubscribe = trackDoc.onSnapshot(async (doc) => {
            if (doc.exists) {
              await context.dispatch("sections/loadTracks", null, {
                root: true,
              });
              context.commit("setIsUploadingTrack", false);
              unsubscribe();
            }
          });
          context.commit("setTrackerFile", null);
        } catch (error) {
          console.error(error);
          context.commit("setIsUploadingTrack", false);
        }
      }
    },
    setMode(context, mode) {
      context.commit("setSectionProp", { key: "mode", value: mode });
      if (!context.state.isEditing) {
        if (mode === "stay" || mode === "pt" || mode === "") {
          context.commit("router/setIsActive", false, { root: true });
        } else {
          context.commit("router/setIsActive", true, { root: true });
        }

        if (mode === "stay") {
          context.commit("router/setIsStay", true, { root: true });
        } else {
          context.commit("router/setIsStay", false, { root: true });
        }
      }
    },
    setTicketPriceSaved(context, value) {
      if (!context.state.section.withTickets) return;
      const withTickets = [
        {
          ticketId: context.state.section.withTickets[0].ticketId,
          ticketPriceSaved:
            value != null && value != "" ? parseFloat(value) : 0,
        },
      ];
      context.commit("setSectionProp", {
        key: "withTickets",
        value: withTickets,
      });
    },
    setTicketId(context, value) {
      if (!context.state.section.withTickets) return;
      const withTickets = [
        {
          ticketId: value,
          ticketPriceSaved:
            context.state.section.withTickets[0].ticketPriceSaved,
        },
      ];
      context.commit("setSectionProp", {
        key: "withTickets",
        value: withTickets,
      });
    },
  },
};

function Trip() {
  return {
    name: "",
    urlname: "",
    description: "",
    days: null,
    allow_read: "none",
    media: null,
  };
}

function Section() {
  this.mode = "";
  this.days = [];
  this.line_name = "";
  this.class_number = "";
  this.vehicle_number = "";
  this.ticket_type = "";
  this.ticketPrice = null;
  this.withTickets = null;
  this.ticket_class = 0;
  this.hafas_profile = "vao";
  this.coords = null;
  this.timetable_section = "";
  this.timetable_vehicle = "";
  this.startpoint = { name: "" };
  this.viapoints_coords = "";
  this.endpoint = { name: "" };
  this.starttime = "";
  this.endtime = "";
  this.duration = "";
  this.curve_section = "";
  this.curve_vehicle = "";
  this.points_section = "";
  this.points_vehicle = "";
  this.length = 0;
  this.description = "";
  this.pictures = "";
  this.gas_cost = 0;
  this.ptm = {
    stations: [],
    category: "",
    number: "",
    direction: "",
    mode: null,
    operator: "",
  };
  this.calcDuration = function () {
    this.duration = (new Date(this.endtime) - new Date(this.starttime)) / 1000;
  };
  this.vehicleComposition = null;
  this.pos = null;
}
