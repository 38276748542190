<template>
  <vue-slider
    v-if="range[0] >= minMinutes && range[1] <= maxMinutes"
    :min="minMinutes"
    :max="maxMinutes"
    :enableCross="false"
    v-model="range"
    :minRange="1"
    :tooltip-formatter="formatter"
  ></vue-slider>
</template>

<script>
import VueSlider from "vue-slider-component";
import { DateTime } from "luxon";

export default {
  name: "tl-time-slider",
  props: {
    min: DateTime,
    max: DateTime,
    value: Array,
  },
  components: {
    VueSlider,
  },
  data() {
    return {
      formatter: (v) =>
        DateTime.fromSeconds(v * 60, {
          zone: this.timezone,
        }).toFormat("HH:mm"),
    };
  },
  methods: {
    dateToMinutes(value, floor = true) {
      if (floor) {
        return Math.floor(value.toSeconds() / 60);
      } else {
        return Math.ceil(value.toSeconds() / 60);
      }
    },
  },
  computed: {
    minMinutes() {
      return this.dateToMinutes(this.min, true);
    },
    maxMinutes() {
      return this.dateToMinutes(this.max, false);
    },
    range: {
      get() {
        return this.value
          ? [
              this.dateToMinutes(this.value[0], true),
              this.dateToMinutes(this.value[1], false),
            ]
          : [this.minMinutes, this.maxMinutes];
      },
      set(value) {
        this.$emit("input", [
          DateTime.fromSeconds(value[0] * 60),
          DateTime.fromSeconds(value[1] * 60),
        ]);
      },
    },
    timezone() {
      return this.min ? this.min.toFormat("z") : null;
    },
  },
};
</script>

<style lang="scss">
@import "../style/bootstrap.scss";
/* Set the theme color of the component */
$themeColor: $primary;

/* import theme style */
@import "vue-slider-component/lib/theme/default.scss";
</style>
