import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import i18n from "./i18n";
import AsyncComputed from "vue-async-computed";
import store from "./store";
import "./style/bootstrap.scss";
import { BootstrapVue } from "bootstrap-vue";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import VueCookies from "vue-cookies";
import Meta from "vue-meta";
import frag from "vue-frag";

(function () {
  var src = "//cdn.jsdelivr.net/npm/eruda";
  if (
    !/eruda=true/.test(window.location) &&
    localStorage.getItem("active-eruda") != "true"
  )
    return;
  document.write("<scr" + 'ipt src="' + src + '"></scr' + "ipt>");
  document.write("<scr" + "ipt>eruda.init();</scr" + "ipt>");
})();
//window.$store = store;

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyC4IUUa4FSeQQsXUDQtq-4QlxGZIzGw33w",
  authDomain: "travellog-gaiswinkler.firebaseapp.com",
  databaseURL: "https://travellog-gaiswinkler.firebaseio.com",
  projectId: "travellog-gaiswinkler",
  storageBucket: "travellog-gaiswinkler.appspot.com",
  messagingSenderId: "897728947998",
  appId: "1:897728947998:web:c2ca57dfee941028f18b0f",
};
firebase.initializeApp(firebaseConfig);
//firebase.functions().useEmulator("localhost", 5001);

var authPromise = new Promise((resolve, reject) => {
  const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    unsubscribe();
    resolve(user);
  }, reject);
});

firebase.auth().onAuthStateChanged((user) => {
  authPromise = user;
  store.commit("setUser", user);
  if (user) {
    /*store.dispatch('refreshAccessToken').then((value) => {
      //
    });*/
  } else {
    //router.push('/');
  }
});

var tripDoc = {};
var lastUser;
var lastTrip;
var db = firebase.firestore();

window.prerenderReady = false;

router.beforeEach(async function (to, from, next) {
  store.commit("setIsLoading", true);
  if (to.name == "NotFound") {
    next();
  } else {
    next();
    var user;
    if (authPromise instanceof Promise) {
      user = await authPromise;
    } else {
      user = authPromise;
    }
    store.commit("router/setIsActive", false);

    if (
      from.name === "EditSection" &&
      from.params.section_id !== to.params.section_id &&
      store.state.params.isEditing
    ) {
      await store.dispatch("params/resetSection");
    }

    store.commit(
      "sections/setFromStore",
      to.matched && !!to.matched.find((route) => route.name === "Store")
    );

    if (
      to.params.user != undefined &&
      to.params.user != lastUser &&
      (!user || to.params.user != user.uid)
    ) {
      var usernameQuery = await db
        .collection("users")
        .where("username", "==", to.params.user)
        .get();
      if (!usernameQuery.empty && usernameQuery.size == 1) {
        store.commit("setUsername", to.params.user);
        var newUid = usernameQuery.docs[0].id;
        if (!user || newUid != user.uid) {
          store.commit("setOtherUser", { uid: newUid });
        } else {
          store.commit("setOtherUser", null);
        }
      } else {
        store.commit("setUsername", null);
        var uidQuery = await db.collection("users").doc(to.params.user).get();
        if (uidQuery.exists) {
          store.commit("setOtherUser", { uid: to.params.user });
        } else {
          if (user) {
            const userDoc = await db.collection("users").doc(user.uid).get();
            if (userDoc.exists && userDoc.data().username != undefined) {
              router.replace(`/user/${userDoc.data().username}`);
            } else {
              router.replace(`/user/${user.uid}`);
            }
          }
        }
      }
    } else if (to.params.user != lastUser) {
      store.commit("setUsername", null);
      store.commit("setOtherUser", null);
    }

    if (user && store.getters.getUser.uid == user.uid) {
      if (store.state.trips.list == null) {
        if (to.name === "User") {
          await store.dispatch("trips/load");
        } else {
          await store.dispatch("trips/load", { loadSections: false });
        }
      } else if (!store.state.trips.sectionsLoaded && to.name === "User") {
        await store.dispatch("trips/load");
      }
    }

    if (to.params.user != lastUser) {
      await Promise.all([
        store.dispatch("profile/load"),
        store.dispatch("tickets/load"),
      ]);
    }

    lastUser = to.params.user;

    /**
     * Check if msal authorization worked and generate tokens
     */
    if (to.name === "Msal") {
      const path = await store.dispatch("msal/getRefreshToken", {
        query: to.query,
      });

      if (!path) {
        return;
      }

      router.push(path);
      return;
    }

    /**
     * Start tracker polling
     */
    if (!store.state.tracker.listener) {
      store.dispatch("tracker/startPolling");
    }

    if (store.getters.getUser != null) {
      if (to.params.trip != undefined) {
        var tripObject = {};
        try {
          if (lastTrip != to.params.trip) {
            var tripQuery = db
              .collection("users")
              .doc(store.getters.getUser.uid)
              .collection("trips")
              .where("urlname", "==", to.params.trip);
            if (store.state.otherUser != null) {
              tripQuery = tripQuery.where("allow_read", "==", "all");
            }
            var tripQueryResult = await tripQuery.get();
            if (!tripQueryResult.empty && tripQueryResult.size == 1) {
              tripDoc = tripQueryResult.docs[0];
              tripObject.urlname = to.params.trip;
            } else {
              tripDoc = await db
                .collection("users")
                .doc(store.getters.getUser.uid)
                .collection("trips")
                .doc(to.params.trip)
                .get();
            }
          }
          if (tripDoc.exists) {
            if (
              store.state.trips.selected == null ||
              lastTrip != to.params.trip ||
              store.state.sections.list == null
            ) {
              tripObject.trip_id = tripDoc.id;
              store.commit("trips/setSelected", tripObject);
              await store.dispatch("sections/load");
            }
            if (
              store.getters.isMyTrip &&
              !store.state.sections.tracks &&
              to.path.split("/").includes("edit")
            ) {
              store.dispatch("sections/loadTracks");
            }
            if (to.name === "EditTripMeta") {
              store.dispatch("params/setTripMerge");
            }
            if (to.params.section_id != undefined) {
              var sections = store.state.sections.list;
              const section = sections.find((obj) => {
                return obj.section_id == to.params.section_id;
              });
              if (section) {
                store.commit("sections/setSelected", section);
              } else {
                router.replace(
                  `/user/${to.params.user}/trip/${to.params.trip}`
                );
              }
            } else {
              store.commit("sections/setSelected", null);
            }
            //
          } else {
            router.replace(`/user/${to.params.user}`);
          }
        } catch (error) {
          console.error(error);
          router.replace(`/user/${to.params.user}`);
        }
      } else if (store.state.sections.fromStore) {
        if (store.state.sections.list == null) {
          await store.dispatch("sections/load");
        }

        if (to.params.section_id != undefined) {
          var sectionsStore = store.state.sections.list;
          const section = sectionsStore.find((obj) => {
            return obj.section_id == to.params.section_id;
          });
          if (section) {
            store.commit("sections/setSelected", section);
          } else {
            router.replace(`/user/${to.params.user}/store`);
          }
        } else {
          store.commit("sections/setSelected", null);
        }
      } else {
        store.commit("trips/setSelected", null);
        store.dispatch("sections/clear");
      }
    } else {
      store.dispatch("trips/clear");
      store.dispatch("sections/clear");
    }

    lastTrip = to.params.trip;

    if (to.query.fullSectionOpacity) {
      store.commit(
        "setFullSectionOpacity",
        to.query.fullSectionOpacity === "true"
      );
    }

    store.commit("setIsLoading", false);
    window.prerenderReady = true;

    /*if (user) {
      if (to.params.user == user.uid) {
        if (store.state.trips.list == null) {
          store.dispatch('trips/load');
        }
        if (to.params.trip != undefined) {
          if (tripDoc.id != to.params.trip) {
            tripDoc = await db.collection("users").doc(user.uid).collection("trips").doc(to.params.trip).get();
          }
          if (tripDoc.exists) {
            var loadSections;
            if (store.state.trips.selected == null || store.state.trips.selected.trip_id != to.params.trip || store.state.sections.list == null) {
              store.commit('trips/setSelected', { trip_id: to.params.trip });
              loadSections = store.dispatch('sections/load');
            }
            if (to.params.section_id != undefined) {
              await loadSections;
              var sections = store.state.sections.list;
              if (sections.find(obj => {
                return obj.section_id == to.params.section_id
              }) != undefined) {
                next();
              } else {
                next(`/user/${user.uid}/trip/${to.params.trip}`);
              }
            } else {
              next();
            }
            next();
          } else {
            next(`/user/${user.uid}`);
          }
        } else {
          store.commit('trips/setSelected', null);
          store.dispatch('sections/clear');
          next();
        }
      } else {
        next(`/user/${user.uid}`);
      }
    }
    else if (to.path != '/') {
      if (to.name == "Trip") {
        try {
          var tripDoc2 = await db.collection("users").doc(to.params.user).collection("trips").doc(to.params.trip).get();
          if (tripDoc2.exists) {
            store.commit('setUser', { uid: to.params.user });
            store.commit('trips/setSelected', { trip_id: to.params.trip });
            store.dispatch('sections/load');
            next();
          } else {
            next('/');
          }
        } catch (error) {
          console.error(error);
          next('/');
        }
      } else {
        next('/');
      }
    } else {
      if (user) {
        next(`/user/${user.uid}`);
      } else {
        store.dispatch('trips/clear');
        store.dispatch('sections/clear');
        next();
      }
    }*/
  }
});

router.afterEach(function () {
  store.commit("refreshMap");
  store.commit("setLoading", false);
});

Vue.use(AsyncComputed);
Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(Meta);
Vue.directive("frag", frag);
//Vue.use(IconsPlugin);
//Vue.use(Skeleton);

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
