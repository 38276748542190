<template>
  <div>
    <b-row class="my-1">
      <slot></slot>
      <b-input-group class="tl-datetime">
        <b-form-input
          v-model="date"
          type="date"
          style="width: 35%"
        ></b-form-input>
        <b-form-input
          v-model="time"
          type="time"
          style="width: 23%"
        ></b-form-input>
        <b-form-select v-model="timezone" :options="timezones"></b-form-select>
      </b-input-group>
    </b-row>
    <b-row class="d-flex justify-content-end" v-if="!disableNow">
      <b-button
        variant="link"
        size="sm"
        class="text-decoration-none"
        @click="setNow"
        >{{ $t("now") }}</b-button
      ></b-row
    >
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "tl-datetime",
  props: ["value", "disableNow"],
  data() {
    return {
      date: "",
      time: "",
      timezone: "",
      timezones: [
        {
          value: "-12:00",
          text: "-12",
        },
        {
          value: "-11:00",
          text: "-11",
        },
        {
          value: "-10:00",
          text: "-10",
        },
        {
          value: "-09:30",
          text: "-9½",
        },
        {
          value: "-09:00",
          text: "-9",
        },
        {
          value: "-08:00",
          text: "-8",
        },
        {
          value: "-07:00",
          text: "-7",
        },
        {
          value: "-06:00",
          text: "-6",
        },
        {
          value: "-05:00",
          text: "-5",
        },
        {
          value: "-04:00",
          text: "-4",
        },
        {
          value: "-03:30",
          text: "-3½",
        },
        {
          value: "-03:00",
          text: "-3",
        },
        {
          value: "-02:00",
          text: "-2",
        },
        {
          value: "-01:00",
          text: "-1",
        },
        {
          value: "+00:00",
          text: "±0",
        },
        {
          value: "+01:00",
          text: "+1",
        },
        {
          value: "+02:00",
          text: "+2",
        },
        {
          value: "+03:00",
          text: "+3",
        },
        {
          value: "+03:30",
          text: "+3½",
        },
        {
          value: "+04:00",
          text: "+4",
        },
        {
          value: "+04:30",
          text: "+4½",
        },
        {
          value: "+05:00",
          text: "+5",
        },
        {
          value: "+05:30",
          text: "+5½",
        },
        {
          value: "+05:45",
          text: "+5¾",
        },
        {
          value: "+06:00",
          text: "+6",
        },
        {
          value: "+06:30",
          text: "+6½",
        },
        {
          value: "+07:00",
          text: "+7",
        },
        {
          value: "+08:00",
          text: "+8",
        },
        {
          value: "+08:45",
          text: "+8¾",
        },
        {
          value: "+09:00",
          text: "+9",
        },
        {
          value: "+09:30",
          text: "+9½",
        },
        {
          value: "+10:00",
          text: "+10",
        },
        {
          value: "+10:30",
          text: "+10½",
        },
        {
          value: "+11:00",
          text: "+11",
        },
        {
          value: "+12:00",
          text: "+12",
        },
        {
          value: "+12:45",
          text: "+12¾",
        },
        {
          value: "+13:00",
          text: "+13",
        },
        {
          value: "+14:00",
          text: "+14",
        },
      ],
    };
  },
  computed: {
    datetime() {
      return this.date != "" && this.time != "" && this.timezone
        ? DateTime.fromISO(this.date + "T" + this.time + this.timezone, {
            setZone: true,
          })
        : "";
    },
  },
  watch: {
    datetime: {
      handler: function (newVal) {
        this.$emit("input", newVal ? newVal.toISO() : "");
      },
      deep: true,
    },
    value(newVal) {
      this.setDatetime(newVal);
    },
  },
  methods: {
    setDatetime(newVal) {
      const newDateTime = DateTime.fromISO(newVal, {
        setZone: true,
      });
      if (newVal != "" && !newDateTime.equals(this.datetime)) {
        this.date = newDateTime.toFormat("yyyy-MM-dd");
        this.time = newDateTime.toFormat("HH:mm");
        this.timezone = newDateTime.toFormat("ZZ");
      } else if (newVal == "" && this.datetime != "") {
        this.date = "";
        this.time = "";
        this.timezone = DateTime.now().toFormat("ZZ");
      } else if (!newVal) {
        this.timezone = DateTime.now().toFormat("ZZ");
      }
    },
    setNow() {
      this.setDatetime(DateTime.local().toISO());
    },
  },
  mounted() {
    this.setDatetime(this.value);
  },
};
</script>

<style lang="scss" scoped>
.tl-datetime > * {
  font-size: 0.85rem;
}

::v-deep ::-webkit-calendar-picker-indicator {
  margin-inline-start: 0px !important;
}
</style>
