import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import axios from "axios";
import { DateTime } from "luxon";
import poly from "@mapbox/polyline";
import { getSectionColor } from "./sections";
import { formatLength } from "../lib/helpers";

export default {
  namespaced: true,
  state: () => ({
    list: null,
    selected: null,
    curve_user: null,
    fitBounds: false,
    sectionsLoaded: false,
  }),
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    addToList(state, list) {
      state.list = [...state.list, ...list];
    },
    setSelected(state, selected) {
      state.selected = selected;
    },
    addSelectedProp(state, payload) {
      if (state.selected != null) {
        Vue.set(state.selected, payload.key, payload.value);
      }
    },
    setCurveUser(state, curve_user) {
      state.curve_user = curve_user;
    },
    setFitBounds(state, fitBounds) {
      state.fitBounds = fitBounds;
    },
    resetSelectedInList(state) {
      Vue.set(
        state.list,
        state.list.findIndex(
          (value) => value.trip_id === state.selected.trip_id
        ),
        state.selected
      );
    },
    setSelectedProp(state, payload) {
      if (state.selected) {
        Vue.set(state.selected, payload.key, payload.value);
      }
    },
    setSectionsLoaded(state, sectionsLoaded) {
      state.sectionsLoaded = sectionsLoaded;
    },
  },
  getters: {
    getList(state) {
      return state.list;
    },
  },
  actions: {
    clear(context) {
      context.commit("setList", null);
      context.commit("setSelected", null);
      context.commit("setSectionsLoaded", false);
      context.dispatch("clearCurveUser");
    },
    clearCurveUser(context) {
      context.commit("setCurveUser", null);
    },
    async add(context, tripParams) {
      if (context.rootGetters["isLoggedIn"]) {
        try {
          const uid = context.rootState.user.uid;
          var db = firebase.firestore();

          const tripsSnap = await db
            .collection("users")
            .doc(uid)
            .collection("trips")
            .where("urlname", "==", tripParams.urlname)
            .get();
          if (!tripsSnap.empty) {
            context.commit(
              "setToast",
              {
                title: "error",
                tTitle: true,
                text: "tripUrlnameError",
                tText: true,
                variant: "danger",
              },
              { root: true }
            );
            return { status: 422 };
          }

          var ref = db.collection("users").doc(uid).collection("trips").doc();
          await ref.set({
            name: tripParams.name,
            urlname: tripParams.urlname,
            allow_read: tripParams.allow_read,
            starttime: new Date().toISOString(),
          });
          context.commit(
            "setToast",
            {
              title: "info",
              tTitle: true,
              text: "tripAdded",
              tText: true,
              variant: "primary",
            },
            { root: true }
          );
          context.dispatch(
            "toTrip",
            { urlname: tripParams.urlname, toEdit: true },
            { root: true }
          );
          return { status: 200 };
        } catch (error) {
          console.error(error);
          context.dispatch("showError", error.toString(), { root: true });
          return { status: 500 };
        }
      }
    },
    async load(context, { loadSections = true } = {}) {
      const uid = context.rootGetters.getUser.uid;
      context.dispatch("clear");
      context.dispatch("sections/clear", null, { root: true });

      var db = firebase.firestore();
      let tripsRef = db
        .collection("users")
        .doc(uid)
        .collection("trips")
        .orderBy("starttime", "desc");

      var snapshot = await tripsRef.get();

      if (!snapshot.empty) {
        const promises = [];
        const loadSectionsPromises = [];

        // eslint-disable-next-line no-inner-declarations
        async function getTrip(doc) {
          const trip_id = doc.id;

          var trip = {
            trip_id,
            name: doc.data().name,
            length: doc.data().length,
            description: doc.data().description,
            days: doc.data().days != undefined ? doc.data().days : null,
            urlname: doc.data().urlname,
            allow_read: doc.data().allow_read,
            starttime:
              doc.data().starttime != undefined ? doc.data().starttime : "",
            endtime: doc.data().endtime != undefined ? doc.data().endtime : "",
            countries: doc.data().countries,
            countriesHtml: getCountryFlags(doc.data().countries),
            ongoing: doc.data().ongoing,
            sections: null,
          };

          if (loadSections) {
            loadSectionsPromises.push(
              (async function () {
                const sectionsSnap = await doc.ref
                  .collection("sections")
                  .orderBy("pos")
                  .get();
                const storageRef = firebase.storage().ref();
                const sectionPromises = [];
                if (doc.data().name !== "Testreise") {
                  for (const sectionDoc of sectionsSnap.docs) {
                    if (sectionDoc.data().mode !== "stay") {
                      sectionPromises.push(
                        (async function () {
                          var sectionRef = storageRef.child(
                            "users/" +
                              uid +
                              "/trips/" +
                              doc.id +
                              "/sections/" +
                              sectionDoc.id +
                              "/polyline.txt"
                          );
                          let url = await sectionRef.getDownloadURL();
                          let response = await axios.get(url);
                          return {
                            polyline: poly.decode(response.data, 6),
                            color: getSectionColor(sectionDoc.data()),
                            ...sectionDoc.data(),
                          };
                        })()
                      );
                    }
                  }
                }
                const sections = await Promise.all(sectionPromises);
                Vue.set(trip, "sections", sections);
              })()
            );
          } else {
            context.commit("setSectionsLoaded", false);
          }

          if (doc.data().image) {
            trip.image = null;
            firebase
              .functions()
              .httpsCallable("getMediaItem")({
                uid,
                trip_id,
                media_id: doc.data().image.media_id,
              })
              .then((response) => {
                Vue.set(trip, "image", response.data);
              })
              .catch((error) => console.error(error));
          }

          if (trip.length) {
            trip.lengthText = formatLength(trip.length);
          } else {
            trip.lengthText = null;
          }

          trip.dateText = getDateText(trip.starttime, trip.endtime);

          return trip;
        }

        for (var doc of snapshot.docs) {
          promises.push(getTrip(doc));
        }
        context.commit("setList", await Promise.all(promises));
        if (loadSections) {
          Promise.all(loadSectionsPromises).then(() => {
            context.commit("setFitBounds", true);
            context.commit("setSectionsLoaded", true);
          });
        }
      }
    },
    async refreshDates(context) {
      if (
        context.rootGetters.isLoggedIn &&
        context.rootGetters.isMyTrip &&
        context.state.selected &&
        context.rootState.sections.list
      ) {
        var db = firebase.firestore();
        const uid = context.rootGetters.getUser.uid;
        const selected_trip = context.state.selected;
        const sections = context.rootState.sections.list;
        if (sections.length > 0) {
          const firstSection = sections[0];
          const lastSection = sections[sections.length - 1];

          const firstStop = firstSection.stops[0];
          const lastStop = lastSection.stops[lastSection.stops.length - 1];
          const starttime = firstStop.departure || firstStop.plannedDeparture;
          const endtime = lastStop.arrival || lastStop.plannedArrival;

          const tripRef = db
            .collection("users")
            .doc(uid)
            .collection("trips")
            .doc(selected_trip.trip_id);

          await tripRef.update({
            starttime: starttime ? starttime : "",
            endtime: endtime ? endtime : "",
          });

          context.commit("addSelectedProp", {
            key: "starttime",
            value: starttime ? starttime : "",
          });

          context.commit("addSelectedProp", {
            key: "endtime",
            value: endtime ? endtime : "",
          });

          context.commit("addSelectedProp", {
            key: "dateText",
            value: getDateText(selected_trip.starttime, selected_trip.endtime),
          });

          context.commit("resetSelectedInList");
        }
      }
    },
  },
};

export function getDateText(starttime, endtime) {
  let dateText = "";
  if (starttime && endtime) {
    const startdate = DateTime.fromISO(starttime, { setZone: true });
    const enddate = DateTime.fromISO(endtime, { setZone: true });
    if (startdate.year != enddate.year) {
      dateText =
        startdate.toFormat("dd.MM.yyyy") + "-" + enddate.toFormat("dd.MM.yyyy");
    } else if (startdate.month != enddate.month) {
      dateText =
        startdate.toFormat("dd.MM.") + "-" + enddate.toFormat("dd.MM.yyyy");
    } else if (startdate.day != enddate.day) {
      dateText =
        startdate.toFormat("dd.") + "-" + enddate.toFormat("dd.MM.yyyy");
    } else {
      dateText = startdate.toFormat("dd.MM.yyyy");
    }
  } else if (starttime) {
    dateText = DateTime.fromISO(starttime, { setZone: true }).toFormat(
      "dd.MM.yyyy"
    );
  } else if (endtime) {
    dateText = DateTime.fromISO(endtime, { setZone: true }).toFormat(
      "dd.MM.yyyy"
    );
  }
  return dateText;
}

// TODO: Add twitter license
export function getCountryFlags(countries) {
  let html = "";
  if (countries?.length > 1) {
    countries.forEach((country) => {
      html += `<img src="https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/${toCodePoint(
        country
      )}.svg" class="mr-1 emoji" alt="${country}" title="${country}" />`;
    });
  }
  return html;
}

function toCodePoint(country) {
  const unicodeSurrogates = country
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397));
  var r = [],
    c = 0,
    p = 0,
    i = 0;
  while (i < unicodeSurrogates.length) {
    c = unicodeSurrogates.charCodeAt(i++);
    if (p) {
      r.push((0x10000 + ((p - 0xd800) << 10) + (c - 0xdc00)).toString(16));
      p = 0;
    } else if (0xd800 <= c && c <= 0xdbff) {
      p = c;
    } else {
      r.push(c.toString(16));
    }
  }
  return r.join("-");
}

// async function getCurveUser(uid) {
//   var storageRef = firebase.storage().ref();
//   try {
//     var curve_userRef = storageRef.child(
//       "users/" + uid + "/curve_user.geojson"
//     );
//     let url = await curve_userRef.getDownloadURL();
//     let response = await axios.get(url);
//     return response.data;
//   } catch (e) {
//     return undefined;
//   }
// }
