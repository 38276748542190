import firebase from "firebase/app";
import "firebase/firestore";

export default {
  namespaced: true,
  state: () => ({
    listener: null,
    lastSeen: null,
    lastLocation: null,
    isLive: false,
    showLastLocation: false,
  }),
  mutations: {
    set(state, { lastSeen, lastLocation, isLive, showLastLocation }) {
      state.lastSeen = lastSeen;
      state.lastLocation = lastLocation;
      state.isLive = isLive;
      state.showLastLocation = showLastLocation;
    },
    reset(state) {
      state.listener = null;
      state.lastSeen = null;
      state.lastLocation = null;
      state.isLive = false;
      state.showLastLocation = false;
    },
    setListener(state, listener) {
      state.listener = listener;
    },
  },
  getters: {},
  actions: {
    async startPolling(context) {
      const uid = context.rootGetters.getUser?.uid;

      const db = firebase.firestore();
      const ref = db
        .collection("users")
        .doc(uid)
        .collection("data")
        .doc("tracker");
      let setListener = true;
      const listener = ref.onSnapshot(
        (doc) => {
          if (doc.data()) {
            context.commit("set", doc.data());
          }

          if (setListener) {
            context.commit("setListener", listener);
          }
          setListener = false;
        },
        () => {
          return;
        }
      );
    },
    stopPolling(context) {
      if (context.state.listener) {
        context.state.listener();
      }
      context.commit("reset");
    },
  },
};
