<template>
  <div>
    <b-modal id="tlGallery" scrollable :title="$t('gallery')" size="xl">
      <b-container fluid>
        <b-row cols="1" cols-sm="2" cols-md="4" cols-lg="6" class="px-2">
          <template v-if="!list || list.length == 0">
            <p>{{ $t("noEntries") }}</p>
          </template>
          <template v-else>
            <b-col v-for="item in list" :key="item.media_id">
              <b-modal
                scrollable
                :id="'tlGalleryItem_' + item.media_id"
                :title="item.fileName"
              >
                <b-img-lazy
                  v-if="item.fileType === 'photo'"
                  fluid
                  :src="item.url"
                  :alt="item.fileName"
                  :title="item.fileName"
                  blank-width="100%"
                  blank-height="300px"
                ></b-img-lazy>
                <b-embed
                  v-if="item.fileType === 'video'"
                  type="video"
                  controls
                  autoplay
                  :poster="item.thumbnailSmall"
                >
                  <source :src="item.url" :type="item.mimeType" />
                </b-embed>

                <template #modal-footer>
                  <b-container fluid>
                    <b-row>
                      <b-form-group class="d-flex align-items-center mb-0">
                        <b-form-radio-group
                          @input="setPermission(item, $event)"
                          :checked="item.allow_read"
                          :options="allowReadOptions"
                        ></b-form-radio-group>
                      </b-form-group>
                      <div style="flex: 1"></div>
                      <b-button
                        v-if="item.originalLocation"
                        variant="link"
                        class="text-decoration-none"
                        @click="resetLocation(item)"
                        >{{ $t("resetLocation") }}</b-button
                      >
                    </b-row>
                    <b-row class="d-flex align-items-center mt-2">
                      <b-button
                        :disabled="isDeleting"
                        v-b-modal="'deleteGalleryItem_' + item.media_id"
                        variant="danger"
                        ><i class="fas fa-trash"></i
                      ></b-button>
                      <b-modal
                        :id="'deleteGalleryItem_' + item.media_id"
                        :title="$t('deleteMediaItem')"
                      >
                        <b-container fluid>
                          <b-row class="mb-2 px-2">
                            <span
                              v-html="
                                $t('deleteDialogText', {
                                  name: item.fileName,
                                })
                              "
                            ></span>
                          </b-row>
                        </b-container>

                        <template #modal-footer>
                          <b-spinner
                            v-if="isDeleting"
                            variant="danger"
                            class="mr-2 ml-1"
                          ></b-spinner>
                          <b-button
                            variant="danger"
                            @click="deleteItem(item)"
                            :disabled="isDeleting"
                          >
                            {{ $t("confirm") }}
                          </b-button>
                        </template>
                      </b-modal>
                      <div style="flex: 1"></div>
                      <template
                        v-if="
                          (tripImage == null ||
                            tripImage.media_id != item.media_id) &&
                          item.fileType === 'photo'
                        "
                      >
                        <b-spinner
                          v-if="isSettingAsTripImage"
                          variant="primary"
                          class="mr-2 ml-1"
                        ></b-spinner>
                        <b-button
                          :disabled="isSettingAsTripImage"
                          variant="primary"
                          @click="
                            $store.dispatch('gallery/setAsTripImage', item)
                          "
                        >
                          {{ $t("setAsTripImage") }}
                        </b-button>
                      </template>
                    </b-row>
                  </b-container>
                </template>
              </b-modal>
              <b-button
                v-if="!item.isProcessing"
                v-b-modal="'tlGalleryItem_' + item.media_id"
                variant="link"
                class="p-0"
              >
                <b-img-lazy
                  thumbnail
                  fluid
                  class="tlGalleryEntry"
                  :src="item.thumbnailMedium"
                  :alt="item.fileName"
                  :title="item.fileName"
                  blank-width="100px"
                  blank-height="100px"
                ></b-img-lazy>
              </b-button>
              <b-skeleton-img
                v-else
                height="100px"
                width="100px"
              ></b-skeleton-img>
            </b-col>
          </template>
        </b-row>
      </b-container>

      <template #modal-footer>
        <b-container fluid>
          <!--<b-row class="mb-2">
            <b-form-file
              multiple
              :value="uploadFiles"
              @input="$store.commit('gallery/setUploadFiles', $event)"
              :placeholder="$t('uploadPlaceholder')"
              :drop-placeholder="$t('dropPlaceholder')"
              :browse-text="$t('browse')"
              accept="image/*,application/zip"
            ></b-form-file>
          </b-row>-->
          <b-row class="d-flex align-items-center">
            <b-form-group class="mb-0">
              <b-form-radio-group
                :options="allowReadOptions"
                :checked="allow_read"
                @input="$store.commit('gallery/setAllowRead', $event)"
              ></b-form-radio-group>
            </b-form-group>
            <div style="flex: 1"></div>
            <b-spinner
              v-if="isUploading"
              variant="primary"
              class="mr-2 ml-1"
            ></b-spinner>
            <b-button
              variant="primary"
              :disabled="isUploading"
              @click="$store.dispatch('gallery/pick')"
            >
              {{ $t("upload") }}
            </b-button>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "tlGallery",
  computed: {
    list() {
      return this.$store.state.gallery.list;
    },
    uploadFiles() {
      return this.$store.state.gallery.uploadFiles;
    },
    allow_read() {
      return this.$store.state.gallery.allow_read;
    },
    isUploading() {
      return this.$store.state.gallery.isUploading;
    },
    isDeleting() {
      return this.$store.state.gallery.isDeleting;
    },
    isSettingAsTripImage() {
      return this.$store.state.gallery.isSettingAsTripImage;
    },
    tripImage() {
      return this.$store.state.trips.selected != null
        ? this.$store.state.trips.selected.image
        : null;
    },
    allowReadOptions() {
      return [
        { value: "none", text: this.$t("private") },
        { value: "all", text: this.$t("public") },
      ];
    },
  },
  methods: {
    open() {
      this.$bvModal.show("tlGallery");
    },
    openItem(item) {
      this.$bvModal.show("tlGallery");
      this.$nextTick(() => {
        this.$bvModal.show("tlGalleryItem_" + item.media_id);
      });
    },
    async deleteItem(item) {
      await this.$store.dispatch("gallery/delete", [item]);
      this.$bvModal.hide("deleteGalleryItem_" + item.media_id);
      this.$bvModal.hide("tlGalleryItem_" + item.media_id);
    },
    async setPermission(item, allow_read) {
      await this.$store.dispatch("gallery/setPermissions", {
        items: [item],
        allow_read: allow_read,
      });
    },
    async resetLocation(item) {
      await this.$store.dispatch("gallery/resetLocation", item);
    },
  },
};
</script>

<style>
.tlGalleryEntry {
  height: 100px !important;
  aspect-ratio: 4/3;
  object-fit: cover;
}
</style>
