<template>
  <div ref="window" style="height: 100%; display: flex; flex-direction: column">
    <b-navbar class="tl-nav" toggleable="md" type="dark" variant="primary">
      <b-navbar-brand class="tl-title">
        <i class="tl-icon mr-2"></i>travellog
      </b-navbar-brand>
      <tl-typeahead
        :showOnFocus="true"
        class="searchbar d-none d-sm-block tl-typeahead"
        ref="searchbar"
        :data="trips"
        :serializer="(trip) => trip.name"
        :placeholder="isLoading ? $t('loading') : $t('searchTrip')"
        :value="searchbarValue"
        :inputClass="searchbarClass"
        @hit="toTrip"
        @focus="hideSearchbarValue(true)"
        @focusout="hideSearchbarValue(false)"
      >
        <template slot="suggestion" slot-scope="{ data }">
          <div class="d-flex align-items-center">
            <div style="height: 75px; width: 100px">
              <b-img-lazy
                v-if="data.image != undefined"
                :src="data.image.thumbnailMedium"
                style="height: 75px; width: 100px; object-fit: cover"
                blank-width="100"
                blank-height="75"
                :alt="data.name"
              ></b-img-lazy>
            </div>

            <div class="ml-4 flex-grow-1 py-2" style="height: 75px">
              <div class="w-100 h-50 d-flex align-items-center">
                <span class="w-100" style="font-weight: 500">{{
                  data.name
                }}</span>
              </div>
              <div class="w-100 h-50 d-flex align-items-center">
                <span
                  class="w-100 text-muted"
                  v-html="getMetaHtml(data)"
                ></span>
              </div>
            </div>
          </div>
        </template>
      </tl-typeahead>

      <b-navbar-nav class="ml-auto d-none d-sm-block">
        <b-dropdown
          right
          variant="link"
          toggle-class="text-decoration-none"
          id="settings-dropdown"
          no-caret
        >
          <template v-slot:button-content>
            <i class="fas fa-ellipsis-v" style="color: white"></i>
          </template>
          <b-dropdown-item
            v-if="$i18n.locale == 'en'"
            @click="changeLocale('de')"
            >German</b-dropdown-item
          >
          <b-dropdown-item
            v-if="$i18n.locale == 'de'"
            @click="changeLocale('en')"
            >English</b-dropdown-item
          >
          <b-dropdown-item
            href="https://www.jonasgaiswinkler.eu/impressum.html"
            >{{ $t("imprint") }}</b-dropdown-item
          >
        </b-dropdown>
      </b-navbar-nav>
      <div v-if="!isDesktop">
        <b-button v-show="showMain" variant="primary" @click="toMap">
          <i class="fas fa-map" key="icon-map"></i>
        </b-button>
        <b-button v-show="!showMain" variant="primary" @click="toMain">
          <i class="fas fa-list" key="icon-bars"></i>
        </b-button>
      </div>
    </b-navbar>

    <div
      v-show="isDesktop || showMain"
      class="tl-overlay overflow-hidden"
      id="tl-overlay"
      :style="{ height: overlayHeight + 'px' }"
    >
      <b-skeleton-wrapper :loading="isLoading" class="h-100">
        <template #loading>
          <div class="px-2 py-2">
            <b-skeleton width="80px" height="25px"></b-skeleton>
          </div>
          <b-card class="mx-1 shadow-sm">
            <b-row>
              <b-col cols="5"><b-skeleton-img></b-skeleton-img></b-col>
              <b-col cols="7"
                ><b-skeleton height="50px"></b-skeleton>
                <b-skeleton></b-skeleton>
                <b-skeleton></b-skeleton
              ></b-col>
            </b-row>
          </b-card>
          <b-container fluid class="h-100">
            <b-row class="h-100 mt-2">
              <b-col cols="1" class="d-flex justify-content-center px-1"
                ><b-skeleton height="100%" width="100%"></b-skeleton
              ></b-col>
              <b-col cols="11" class="px-2">
                <b-row class="px-3 mb-2" v-for="i in 8" :key="i">
                  <b-skeleton width="100%"></b-skeleton
                  ><b-skeleton width="100%" height="50px"></b-skeleton>
                  <b-skeleton width="100%"></b-skeleton></b-row
              ></b-col>
            </b-row>
          </b-container>
        </template>

        <router-view></router-view>
      </b-skeleton-wrapper>
    </div>

    <tl-map v-show="isDesktop || !showMain" ref="tlmap" class="tl-map"></tl-map>
    <div
      class="p-2"
      style="max-height: 35%"
      :style="{ overflow: isStation ? 'scroll' : 'inherit' }"
      key="router-mobile"
      v-if="routerIsActive && !isDesktop && !showMain"
    >
      <tl-router></tl-router>
    </div>
    <div
      v-if="routerIsActive && isDesktop"
      class="tl-router-overlay"
      :style="{ height: overlayHeight + 'px' }"
    >
      <div class="tl-router p-2">
        <tl-router></tl-router>
      </div>
    </div>
    <tl-gallery ref="tlGalleryGlobal"></tl-gallery>
  </div>
</template>

<script>
import tlMap from "../components/tlMap";
import tlTypeahead from "travellog-typeahead";
import tlRouter from "../components/tlRouter";
import tlGallery from "../components/tlGallery";

export default {
  name: "ui",
  components: {
    "tl-map": tlMap,
    tlTypeahead,
    "tl-router": tlRouter,
    "tl-gallery": tlGallery,
  },
  computed: {
    trips() {
      const list = this.$store.state.trips.list;
      return list != null ? list : [];
    },
    selected_trip() {
      return this.$store.state.trips.selected;
    },
    isDesktop() {
      this.backdoor;
      return this.isMounted && this.$refs.window != undefined
        ? this.$refs.window.clientWidth >= 768
        : true;
    },
    mainSidebar() {
      return this.$store.state.mainSidebar;
    },
    routerIsActive() {
      return this.$store.state.router.isActive;
    },
    toast() {
      return this.$store.state.toast;
    },
    isStation() {
      return this.$store.getters["router/getIsStation"];
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    resetMap() {
      return this.$store.state.resetMap;
    },
    showCountryFlags() {
      return this.$store.getters["profile/getShowCountryFlags"];
    },
  },
  watch: {
    selected_trip: {
      handler: function (newS) {
        if (newS != null && newS.name != undefined) {
          this.searchbarValue = newS.name;
          this.searchbarClass = "bold";
        } else {
          this.searchbarValue = "";
          this.searchbarClass = "";
        }
      },
      deep: true,
    },
    toast: {
      handler: function (toast) {
        if (toast != null) {
          const title =
            toast.tTitle === true ? this.$i18n.t(toast.title) : toast.title;
          const text =
            toast.tText === true ? this.$i18n.t(toast.text) : toast.text;
          this.$bvToast.toast(text, {
            title: title,
            autoHideDelay: 4000,
            variant: toast.variant,
            solid: true,
          });
          this.$store.commit("setToast", null);
        }
      },
      deep: true,
    },
    backdoor() {
      if (this.isMounted && this.$refs.window != undefined) {
        this.overlayHeight = this.$refs.window.clientHeight - 76;
      }
    },
    isDesktop() {
      this.$store.commit("setIsDesktop", this.isDesktop);
      this.$store.commit("setResetMap", !this.isDesktop);
      this.$nextTick(function () {
        setTimeout(() => {
          this.backdoor++;
          this.$refs.tlmap.refreshMap();
          this.$nextTick(function () {
            this.$store.commit("sections/setFitBounds", true);
          });
        }, 20);
      });
    },
  },
  data() {
    return {
      showMain: true,
      isMounted: false,
      backdoor: 0,
      overlayHeight: 0,
      searchbarValue: "",
      searchbarClass: "",
    };
  },
  methods: {
    toMap: function () {
      this.showMain = false;
      this.backdoor++;
      this.$nextTick(function () {
        this.$refs.tlmap.refreshMap();
        if (this.resetMap) {
          this.$store.commit("sections/setFitBounds", true);
          this.$store.commit("setResetMap", false);
        }
      });
    },
    toMain() {
      this.showMain = true;
      this.backdoor++;
    },
    toTrip(trip) {
      this.$store.dispatch("toTrip", trip);
    },
    openImpressum() {
      window.open("https://jonasgaiswinkler.eu/impressum.html", "_self");
    },
    showSnackbar(text) {
      this.$bvToast.toast(text, {
        title: `Titel`,
        solid: true,
      });
    },
    showSnackbarError(text) {
      this.$bvToast.toast(text, {
        title: `Titel`,
        variant: "danger",
        solid: true,
      });
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$cookies.set("locale", locale);
    },
    hideSearchbarValue(hidden) {
      this.searchbarValue = !hidden
        ? this.selected_trip
          ? this.selected_trip.name
          : ""
        : "";
      this.searchbarClass = !hidden ? "bold" : "";
    },
    getMetaHtml(trip) {
      let text = "";
      if (trip.dateText !== null) {
        text += trip.dateText;
        if (trip.lengthText !== null) {
          text += " • ";
        }
      }
      if (trip.lengthText !== null) {
        text += trip.lengthText;
      }
      if (this.showCountryFlags && trip.countriesHtml) {
        text += " • " + trip.countriesHtml;
      }
      return text;
    },
  },
  created() {
    if (this.$cookies.isKey("locale")) {
      this.changeLocale(this.$cookies.get("locale"));
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.$store.commit("setGalleryRef", this.$refs.tlGalleryGlobal);
      var vm = this;
      window.addEventListener("resize", function () {
        vm.backdoor++;
      });
      this.isMounted = true;
      this.backdoor++;
      this.$store.commit("setIsDesktop", this.isDesktop);
      this.$nextTick(function () {
        this.$refs.tlmap.refreshMap();
      });
    });
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.tl-map {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.tl-title {
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 768px) {
  .tl-overlay {
    width: 500px;
    background-color: white;
    position: absolute;
    z-index: $zOverlay;
    left: 10px;
    top: 66px;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0px 0px 6px 0px #00000033;
  }
}

@media screen and (max-width: 767px) {
  .tl-overlay {
    flex-grow: 1;
    height: calc(100% - 56px);
    padding: 5px;
  }
}

.tl-router-overlay {
  width: 300px;
  position: absolute;
  z-index: $zRouterOverlay;
  right: 10px;
  top: 66px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
}

.tl-router {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  pointer-events: auto;
  box-shadow: 0px 0px 6px 0px #00000033;
}

.searchbar {
  position: absolute;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  min-width: 200px;
  width: 35vw;
  z-index: $zSearchbar;

  .list-group-item {
    padding: 0 !important;
  }
}

.bold {
  font-weight: bold !important;
}

.tl-mobile {
  flex-grow: 1;
  height: calc(100% - 56px);
}

.tl-content-mobile {
  align-items: start;
  width: 100%;
  padding-top: 5px;
  overflow-y: auto;
}

.impressum {
  margin-top: 10px;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(calc(-100% - 10px), 0);
}
.slide-leave-to {
  transform: translate(calc(-100% - 10px), 0);
}

.tl-icon {
  display: inline-block;
  background-image: url("@/assets/logo-white.svg");
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  width: 1em;
  background-repeat: no-repeat;
}

.tl-nav {
  padding: 0.3rem 0.9rem !important;
  box-shadow: 0px 0px 6px 0px #00000066;
  z-index: $zNav;
}

#settings-dropdown > .dropdown-menu {
  z-index: $zSettings;
}
</style>
