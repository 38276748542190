<template>
  <b-col>
    <b-row class="mt-1">
      <label>{{ $t("days") }}</label>
    </b-row>
    <template v-if="days != null">
      <b-row v-for="(day, i) in days" class="mb-1" :key="i">
        <b-input-group>
          <b-form-input
            :value="day.day"
            @input="setDay(i, $event)"
            type="number"
            placeholder="#"
            min="0"
          ></b-form-input>
          <b-form-input
            :value="day.name"
            @input="setName(i, $event)"
            placeholder="Name"
            style="width: 60%"
          ></b-form-input
          ><b-input-group-append>
            <b-button variant="outline-danger" @click="remove(i)"
              ><i class="fas fa-times"></i
            ></b-button>
          </b-input-group-append>
        </b-input-group> </b-row
    ></template>
    <b-row class="mb-1 mt-2 d-flex align-items-center justify-content-end">
      <b-button @click="add" variant="link" class="text-decoration-none"
        ><i class="fas fa-plus mr-2"></i>{{ $t("newDay") }}</b-button
      >
      <slot></slot
    ></b-row>
  </b-col>
</template>

<script>
export default {
  name: "tl-days",
  props: {
    days: null,
  },
  computed: {},
  methods: {
    add() {
      const days = this.days != null ? this.days : [];
      days.push({
        day: days.length != 0 ? days[days.length - 1].day + 1 : 1,
        name: "",
      });
      this.$emit("input", days);
    },
    setDay(i, value) {
      const days = this.days;
      days[i].day = parseInt(value);
      this.$emit("input", days);
    },
    setName(i, value) {
      const days = this.days;
      days[i].name = value;
      this.$emit("input", days);
    },
    remove(i) {
      const days = this.days;
      if (days.length !== 1) {
        days.splice(i, 1);
        this.$emit("input", days);
      } else {
        this.$emit("input", null);
      }
    },
  },
};
</script>

<style scoped></style>
