<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div role="tablist" id="tablist">
    <template v-if="sectionsByDays" v-for="(day, j) in sectionsByDays">
      <template v-if="day.day !== null">
        <div :id="'day_' + day.day"></div>
        <div
          class="day"
          :id="'day_' + day.day + '_header'"
          v-b-toggle="'collapse-' + j"
        >
          <div
            class="center-h center-v"
            style="width: 100%; padding: 10px 15px; overflow: initial"
          >
            <span>
              <i
                >{{ $t("day") }} {{ day.day
                }}<span
                  v-if="
                    trip &&
                    trip.days.find((tripDay) => tripDay.day == day.day).name
                  "
                  >:
                  {{
                    trip.days.find((tripDay) => tripDay.day == day.day).name
                  }}</span
                ></i
              >
            </span>
            <div
              v-if="day.sections && day.sections.length > 0"
              class="day-arrow center-h center-v pr-2"
            >
              <i class="fas fa-chevron-down arrow-icon"></i>
            </div>
          </div>
        </div>
      </template>
      <b-collapse
        :id="'collapse-' + j"
        v-if="day.sections"
        :visible="day.day === null || singleSection"
      >
        <template v-for="(section, i) in day.sections">
          <div :id="'section_' + section.section_id"></div>
          <div
            @mouseover="hoverSection(section.section_id)"
            @mouseleave="hoverSection(null)"
            :key="getSectionKey(section)"
          >
            <template v-if="!section.onlyShow || section.onlyShow === 'top'">
              <div class="origindestination">
                <div class="center-h center-v" style="width: 8%">
                  <div v-if="i != 0" class="graph-line dotted"></div>
                  <div
                    class="graph-dot"
                    :style="getDotClass(section.stops[0], section.color)"
                  ></div>
                  <div
                    class="graph-line half bottom"
                    :style="getLineClass(section.stops[0], section.color)"
                  ></div>
                </div>
                <div
                  class="center-h"
                  :style="{ width: !singleSection ? '18%' : '25%' }"
                >
                  <span :title="getOriginTime(section, true)">{{
                    getOriginTime(section)
                  }}</span>
                  <span
                    class="ml-1 old-time"
                    :class="{
                      'line-through':
                        getOriginTime(section, false, true) !== '❌',
                    }"
                    :title="getOriginTime(section, true, true)"
                    >{{ getOriginTime(section, false, true) }}</span
                  >
                </div>
                <div
                  class="center-h"
                  :style="{ width: !singleSection ? '60%' : '53%' }"
                >
                  <span>{{ getOriginText(section) }}</span>
                </div>
                <div class="center-h end-v" style="width: 10%">
                  <span :title="getOriginPlatform(section)">{{
                    getOriginPlatform(section)
                  }}</span>
                </div>
                <div class="center-h" style="width: 4%"></div>
              </div>
              <div
                role="tab"
                class="tab"
                v-b-toggle="'accordion-' + j + '_' + i"
              >
                <div style="display: flex">
                  <div
                    class="center-h center-v"
                    style="flex: 0 0 8%; height: auto"
                  >
                    <div
                      class="graph-line"
                      :style="getLineClass(section.stops[0], section.color)"
                    ></div>
                  </div>
                  <div style="flex-grow: 1; min-width: 0">
                    <div class="w-100" style="display: flex">
                      <div
                        class="center-h"
                        style="flex-grow: 1; overflow: hidden"
                      >
                        <div style="padding-left: 10px; overflow: hidden">
                          <template v-if="showModeIcon(section)">
                            <div>
                              <tl-mode-icon
                                :mode="section.mode"
                                :key="section.mode"
                              ></tl-mode-icon
                              ><span
                                class="ml-2"
                                style="
                                  font-weight: bold;
                                  margin: 0;
                                  white-space: break-spaces;
                                "
                                >{{ section.description }}</span
                              >
                            </div></template
                          >
                          <span
                            style="
                              white-space: break-spaces;
                              word-wrap: break-word;
                            "
                            v-if="section.mode != 'stay'"
                            v-html="getDetailText(section)"
                          ></span>
                        </div>
                      </div>
                      <div
                        v-if="
                          getIntermediateStops(section).length > 0 ||
                          (section.description &&
                            (section.mode === 'pt' || section.mode === 'ptm'))
                        "
                        class="center-h center-v h-auto px-2"
                      >
                        <i class="fas fa-chevron-down arrow-icon"></i>
                      </div>
                    </div>
                    <div class="w-100 mt-1" v-if="section.vehicleComposition">
                      <tl-train-composition
                        :composition="section.vehicleComposition"
                        loadingPadding
                      ></tl-train-composition>
                    </div>
                  </div>
                </div>
              </div>
              <b-collapse
                :id="'accordion-' + j + '_' + i"
                accordion="my-accordion"
                role="tabpanel"
                style="position: relative"
                :visible="singleSection"
              >
                <div class="w-100 d-flex">
                  <div
                    class="center-h center-v"
                    style="flex: 0 0 8%; height: auto"
                  >
                    <div
                      class="graph-line"
                      :style="getLineClass(section.stops[0], section.color)"
                    ></div>
                  </div>
                  <div class="center-h" style="flex-grow: 1; overflow: hidden">
                    <div style="padding-left: 10px" class="w-100">
                      <div
                        class="w-100"
                        v-if="section.mode === 'pt' || section.mode === 'ptm'"
                      >
                        <span
                          v-if="section.description"
                          style="white-space: normal"
                          ><em>"{{ section.description }}"</em></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-for="stop in getIntermediateStops(section)"
                  class="intermediate"
                >
                  <div class="center-h center-v" style="width: 8%">
                    <div
                      class="graph-dot"
                      :style="getDotClass(stop, section.color)"
                    ></div>
                    <div
                      class="graph-line"
                      :style="getLineClass(stop, section.color, true)"
                    ></div>
                  </div>
                  <div
                    class="time"
                    :style="{ width: !singleSection ? '18%' : '25%' }"
                  >
                    <div>
                      <span :title="getSOArrivalTime(stop, true)">{{
                        getSOArrivalTime(stop)
                      }}</span>
                      <span
                        class="ml-1 old-time"
                        :class="{
                          'line-through':
                            getSOArrivalTime(stop, false, true) !== '❌',
                        }"
                        :title="getSOArrivalTime(stop, true, true)"
                        >{{ getSOArrivalTime(stop, false, true) }}</span
                      >
                    </div>
                    <div
                      v-if="
                        getSOArrivalTime(stop) != getSODepartureTime(stop) ||
                        getSODepartureTime(stop, false, true) !=
                          getSOArrivalTime(stop, false, true)
                      "
                    >
                      <span :title="getSODepartureTime(stop, true)">{{
                        getSODepartureTime(stop)
                      }}</span
                      ><span
                        class="ml-1 old-time"
                        :class="{
                          'line-through':
                            getSODepartureTime(stop, false, true) !== '❌',
                        }"
                        :title="getSODepartureTime(stop, true, true)"
                        >{{ getSODepartureTime(stop, false, true) }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="center-h"
                    :style="{ width: !singleSection ? '60%' : '53%' }"
                  >
                    <span>{{ stop.name }}</span>
                  </div>
                  <div class="center-h center-v" style="width: 10%">
                    <!-- Implement stopover platforms from timetable_vehicle
            <p style="margin-bottom: 0; margin-top: 5px;">{{  }}</p>
            <p style="margin-bottom: 5px;">{{ }}</p>
            -->
                  </div>
                  <div class="center-h" style="width: 4%"></div>
                </div>
              </b-collapse>
            </template>

            <div
              v-if="section.onlyShow === 'top'"
              style="width: 100%; height: 10px"
            >
              <div class="center-h center-v" style="width: 8%">
                <div
                  class="graph-line"
                  :style="getDaysLineClass(section.stops, section.color, 'top')"
                ></div>
              </div>
            </div>

            <div
              v-if="section.onlyShow === 'bottom'"
              style="width: 100%; height: 10px"
            >
              <div class="center-h center-v" style="width: 8%">
                <div
                  class="graph-line"
                  :style="
                    getDaysLineClass(section.stops, section.color, 'bottom')
                  "
                ></div>
              </div>
            </div>
            <template v-if="!section.onlyShow || section.onlyShow === 'bottom'">
              <div class="origindestination">
                <div class="center-h center-v" style="width: 8%">
                  <div
                    class="graph-line half top"
                    :style="
                      getLineClass(
                        section.stops[section.stops.length - 1],
                        section.color
                      )
                    "
                  ></div>
                  <div
                    class="graph-dot"
                    :style="
                      getDotClass(
                        section.stops[section.stops.length - 1],
                        section.color
                      )
                    "
                  ></div>
                </div>
                <div
                  class="center-h"
                  :style="{ width: !singleSection ? '18%' : '25%' }"
                >
                  <span :title="getDestinationTime(section, true)">{{
                    getDestinationTime(section)
                  }}</span>
                  <span
                    class="ml-1 old-time"
                    :class="{
                      'line-through':
                        getDestinationTime(section, false, true) !== '❌',
                    }"
                    :title="getDestinationTime(section, true, true)"
                    >{{ getDestinationTime(section, false, true) }}</span
                  >
                </div>
                <div
                  class="center-h"
                  :style="{ width: !singleSection ? '60%' : '53%' }"
                >
                  <span>{{ getDestinationText(section) }}</span>
                </div>
                <div class="center-h end-v" style="width: 10%">
                  <span :title="getDestinationPlatform(section)">{{
                    getDestinationPlatform(section)
                  }}</span>
                </div>
                <div class="center-h" style="width: 4%"></div>
              </div>
            </template>
          </div>
        </template>
      </b-collapse>
    </template>
  </div>
</template>

<script>
import { DateTime, Duration } from "luxon";
import tlModeIcon from "../components/tlModeIcon";
import tlTrainComposition from "../components/tlTrainComposition";
import { formatLength, formatEuros } from "../lib/helpers";

export default {
  name: "tl-sections",
  components: {
    "tl-mode-icon": tlModeIcon,
    "tl-train-composition": tlTrainComposition,
  },
  props: {
    sections: Array,
    trip: Object,
    singleSection: Boolean,
  },
  data() {
    return {
      index: undefined,
      now: DateTime.now(),
    };
  },
  created() {
    setInterval(() => {
      this.now = DateTime.now();
    }, 10000);
  },
  computed: {
    sectionsByDays() {
      const sections = this.sections;

      if (!sections) {
        return null;
      }

      if (!this.trip?.days) {
        return [
          {
            day: null,
            sections,
          },
        ];
      }

      const days = [];
      let currentDay;

      for (const section of sections) {
        if (!section.days) {
          if (!currentDay) {
            currentDay = {
              day: null,
              sections: [section],
            };
            continue;
          }

          if (currentDay.day === null) {
            currentDay.sections.push(section);
            continue;
          }

          days.push(currentDay);
          currentDay = {
            day: null,
            sections: [section],
          };
          continue;
        }

        if (currentDay && currentDay.day != section.days[0]) {
          days.push(currentDay);
        }

        if (section.days.length > 1) {
          for (const i in section.days) {
            if (i == 0) {
              if (currentDay && currentDay.day == section.days[0]) {
                currentDay.sections.push(
                  Object.assign({ onlyShow: "top" }, section)
                );
              } else {
                currentDay = {
                  day: parseInt(section.days[i]),
                  sections: [Object.assign({ onlyShow: "top" }, section)],
                };
              }

              days.push(currentDay);
              continue;
            }

            if (i == section.days.length - 1) {
              currentDay = {
                day: parseInt(section.days[i]),
                sections: [Object.assign({ onlyShow: "bottom" }, section)],
              };
              break;
            }

            currentDay = {
              day: parseInt(section.days[i]),
              sections: [],
            };
            days.push(currentDay);
          }
          continue;
        }

        if (currentDay && currentDay.day == section.days[0]) {
          currentDay.sections.push(section);
        } else {
          currentDay = {
            day: parseInt(section.days[0]),
            sections: [section],
          };
        }

        continue;
      }

      days.push(currentDay);

      return days;
    },
    fullSectionOpacity() {
      return this.$store.state.fullSectionOpacity;
    },
  },
  methods: {
    getSectionKey(section) {
      let key = section.section_id;

      if (section.onlyShow) {
        key += "_" + section.onlyShow;
      }

      return key;
    },
    expandDay(day) {
      if (this.sectionsByDays) {
        for (const j in this.sectionsByDays) {
          if (this.sectionsByDays[j].day == day) {
            this.$root.$emit("bv::toggle::collapse", "collapse-" + j);
          }
        }
      }
    },
    getOriginTime(section, withDate = false, showOld) {
      return getTime(section.stops[0], "departure", withDate, showOld);
    },
    getOriginText(section) {
      return section.stops[0].name;
    },
    getOriginPlatform(section) {
      if (section.mode == "pt" || section.mode == "ptm") {
        return (
          section.stops[0].plannedDeparturePlatform ||
          section.stops[0].departurePlatform
        );
      } else {
        return null;
      }
    },
    getDetailText(section) {
      var text = "";
      if (section.mode == "pt" || section.mode == "ptm") {
        text +=
          "<span style='font-weight: bold; margin-top: 5px; margin-bottom: 0;'>" +
          section.line_name +
          "&nbsp;<i class='fa'>&#xf061;</i>&nbsp;" +
          section.direction +
          "</span>";
      }

      let stats = "";

      if (section.duration) {
        stats +=
          "<span class='d-inline-block'>" +
          this.formatDuration(section) +
          "</span>";
      }

      if (section.length) {
        if (stats) stats += "<span class='mx-1'>•</span>";
        stats +=
          "<span class='d-inline-block'>" +
          formatLength(section.length) +
          "</span>";
      }

      if (section.ticketPrice) {
        if (stats) stats += "<span class='mx-1'>•</span>";
        stats +=
          "<span class='d-inline-block'>" +
          formatEuros(section.ticketPrice) +
          "</span>";
      }

      if (section.withTickets) {
        let ticketPriceSaved = 0;
        for (const ticket of section.withTickets) {
          ticketPriceSaved += ticket.ticketPriceSaved;
        }
        if (ticketPriceSaved) {
          if (stats) stats += "<span class='mx-1'>•</span>";
          stats +=
            "<span class='d-inline-block'>" +
            '<i class="fa-solid fa-piggy-bank mr-1"></i>' +
            formatEuros(ticketPriceSaved) +
            "</span>";
        }
      }

      if (stats) {
        if (text != "") text += "<br>";
        text +=
          "<span style='margin-top: 5px; margin-bottom: 5px; font-size: 0.95em'>" +
          stats +
          "</span>";
      }

      return text;
    },
    showModeIcon(section) {
      return section.mode != "pt" && section.mode != "ptm";
    },
    getSODepartureTime(stop, withDate = false, showOld) {
      return getTime(stop, "departure", withDate, showOld);
    },
    getSOArrivalTime(stop, withDate = false, showOld) {
      return getTime(stop, "arrival", withDate, showOld);
    },
    getDestinationTime(section, withDate = false, showOld) {
      return getTime(
        section.stops[section.stops.length - 1],
        "arrival",
        withDate,
        showOld
      );
    },
    getDestinationText(section) {
      return section.stops[section.stops.length - 1].name;
    },
    getDestinationPlatform(section) {
      if (section.mode == "pt" || section.mode == "ptm") {
        return (
          section.stops[section.stops.length - 1].plannedArrivalPlatform ||
          section.stops[section.stops.length - 1].arrivalPlatform
        );
      } else {
        return null;
      }
    },
    getIntermediateStops(section) {
      return section.stops.slice(1, section.stops.length - 1);
    },
    hoverSection(section_id) {
      this.$store.commit("sections/setHover", section_id);
    },
    getDotClass(stop, color = "var(--primary)") {
      if (!stop.arrival && !stop.plannedArrival) {
        if (!stop.departure && !stop.plannedDeparture) {
          return {
            background: color,
          };
        }

        const departure = DateTime.fromISO(
          stop.departure || stop.plannedDeparture
        );
        return {
          background:
            departure <= this.now || this.fullSectionOpacity
              ? color
              : "var(--light-gray)",
        };
      }

      const arrival = DateTime.fromISO(stop.arrival || stop.plannedArrival);
      return {
        background:
          arrival <= this.now || this.fullSectionOpacity
            ? color
            : "var(--light-gray)",
      };
    },
    getLineClass(stop, color = "var(--primary)", half = false) {
      if (!stop.arrival && !stop.plannedArrival) {
        if (!stop.departure && !stop.plannedDeparture) {
          return {
            background: color,
          };
        }

        const departure = DateTime.fromISO(
          stop.departure || stop.plannedDeparture
        );
        return {
          background:
            departure <= this.now || this.fullSectionOpacity
              ? color
              : "var(--light-gray)",
        };
      }

      const arrival = DateTime.fromISO(stop.arrival || stop.plannedArrival);

      if (!stop.departure && !stop.plannedDeparture) {
        return {
          background:
            arrival <= this.now || this.fullSectionOpacity
              ? color
              : "var(--light-gray)",
        };
      }

      const departure = DateTime.fromISO(
        stop.departure || stop.plannedDeparture
      );

      return {
        background:
          arrival > this.now && !this.fullSectionOpacity
            ? "var(--light-gray)"
            : departure < this.now || this.fullSectionOpacity || !half
            ? color
            : `linear-gradient(to bottom,${color} 0px,${color} 50%,${"var(--light-gray)"} 50%,${"var(--light-gray)"} 100%)`,
      };
    },
    getDaysLineClass(stops, color = "var(--primary)", position) {
      const stop = stops.length > 2 ? stops[stops.length - 2] : stops[0];
      const topColor = `linear-gradient(to bottom, ${color} 0px, white 100%)`;
      const bottomColor = `linear-gradient(to bottom, white 0px, ${color} 100%)`;
      const topGray = `linear-gradient(to bottom, ${"var(--light-gray)"} 0px, white 100%)`;
      const bottomGray = `linear-gradient(to bottom, white 0px, ${"var(--light-gray)"} 100%)`;

      let dateTime;

      if (!stop.departure && !stop.plannedDeparture) {
        if (!stop.arrival && !stop.plannedArrival) {
          return {
            background: position === "top" ? topColor : bottomColor,
          };
        }
        dateTime = DateTime.fromISO(stop.arrival || stop.plannedArrival);
      } else {
        dateTime = DateTime.fromISO(stop.departure || stop.plannedDeparture);
      }

      return {
        background:
          dateTime < this.now || this.fullSectionOpacity
            ? position === "top"
              ? topColor
              : bottomColor
            : position === "top"
            ? topGray
            : bottomGray,
      };
    },
    formatDuration(section) {
      if (section.duration == null) return;

      const duration = Duration.fromObject({
        seconds: section.duration,
      }).shiftTo("hours", "minutes");

      if (duration.hours >= 1) {
        return duration.toFormat("h:mm") + " h";
      } else {
        return duration.toFormat("m") + " min";
      }
    },
  },
};

function getTime(data, type, withDate, showOld) {
  var time;
  if (type == "departure" && (data.departure || data.plannedDeparture)) {
    if (showOld && data.cancelled && !data.departure) return "❌";
    let date = data.departure || data.plannedDeparture;
    if (
      showOld &&
      (!(data.departure && data.plannedDeparture) ||
        DateTime.fromISO(data.departure, { setZone: true }).equals(
          DateTime.fromISO(data.plannedDeparture, { setZone: true })
        ))
    ) {
      return "";
    }
    if (showOld) {
      date = data.plannedDeparture;
    }
    time = DateTime.fromISO(date, { setZone: true });
  } else if (type == "arrival" && (data.arrival || data.plannedArrival)) {
    if (showOld && data.cancelled && !data.arrival) return "❌";
    let date = data.arrival || data.plannedArrival;
    if (
      showOld &&
      (!(data.arrival && data.plannedArrival) ||
        DateTime.fromISO(data.arrival, { setZone: true }).equals(
          DateTime.fromISO(data.plannedArrival, { setZone: true })
        ))
    ) {
      return "";
    }
    if (showOld) {
      date = data.plannedArrival;
    }
    time = DateTime.fromISO(date, { setZone: true });
  } else {
    if (!showOld) return "—";
    return "";
  }

  if (withDate) {
    return time.toFormat("EEE, dd.MM.yyyy HH:mm");
  } else {
    return time.toFormat("HH:mm");
  }
}
</script>
<style lang="scss" scoped>
@import "../style/bootstrap.scss";
span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.center-h {
  float: left;
  align-items: center;
  display: flex;
  position: relative;
  height: 100%;
}

.center-v {
  justify-content: center;
}

.end-v {
  justify-content: flex-end;
}

.origindestination {
  height: 30px;
  width: 100%;
}

.old-time {
  font-size: 85%;
  overflow: initial;
}

.line-through {
  text-decoration: line-through;
}

.day {
  width: 100%;
  font-size: 1.15em;
  color: grey;
  overflow: initial;
  display: flex;
  background-color: white;
  z-index: $zDay;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

.day-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.arrow-icon {
  transition: transform 0.25s linear;
}

.collapsed > div > div > div > div > .arrow-icon {
  transform: rotate(0deg);
}

.collapsed > div > div > div > div > .arrow-icon {
  transform: rotate(0deg);
}

:not(.collapsed) > div > div > .arrow-icon {
  transform: rotate(180deg);
}

:not(.collapsed) > div > div > .arrow-icon {
  transform: rotate(180deg);
}

.tab {
  position: relative;
  padding: 0;
  outline: none;
  cursor: pointer;
}

.intermediate {
  height: 50px;
  width: 100%;
}

.intermediate .time {
  flex-direction: column;
  display: flex;
  float: left;
  height: 100%;
  justify-content: center;
}

.graph-dot {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  z-index: $zGraphDot;
}

.graph-line {
  width: 4px;
  height: 100%;
  position: absolute;
}

.half {
  height: 15px !important;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

.dotted {
  border-left-style: dotted;
  border-width: 4px;
  border-color: grey !important;
  top: -15px;
  height: 30px !important;
}

.gradient-top {
  background: linear-gradient(to bottom, $primary 0px, white 100%);
}

.gradient-bottom {
  background: linear-gradient(to bottom, white 0px, $primary 100%);
}

.bg-gray {
  background-color: $gray-500;
}

.bg-gray-half {
  background: linear-gradient(
    to bottom,
    $primary 0px,
    $primary 50%,
    $gray-500 50%,
    $gray-500 100%
  );
}
</style>
