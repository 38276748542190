import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import axios from "axios";
import poly from "@mapbox/polyline";
import router from "@/router";
import { getDateText, getCountryFlags } from "./trips";
import { DateTime } from "luxon";
import { formatLength } from "../lib/helpers";

export default {
  namespaced: true,
  state: () => ({
    list: null,
    selected: null,
    curve_trip: null,
    isDeleting: false,
    hover: null,
    tracks: null,
    selectedTracks: [],
    filterTrack: true,
    trackFilterRange: null,
    trackFilterPolygonActive: false,
    trackFilterPolygon: null,
    fitBounds: false,
    fitSection: false,
    shouldScroll: true,
    fromStore: false,
    startAfter: null,
    isLoadingNext: false,
  }),
  mutations: {
    setList(state, list) {
      Vue.set(state, "list", list);
    },
    setSelected(state, selected) {
      state.selected = selected;
    },
    setCurveTrip(state, curve_trip) {
      state.curve_trip = curve_trip;
    },
    setIsDeleting(state, isDeleting) {
      state.isDeleting = isDeleting;
    },
    setHover(state, hover) {
      state.hover = hover;
    },
    setTracks(state, tracks) {
      state.tracks = tracks;
    },
    setSelectedTracks(state, selectedTracks) {
      state.trackFilterRange = null;
      state.selectedTracks = selectedTracks;
    },
    setFilterTrack(state, filterTrack) {
      state.filterTrack = filterTrack;
    },
    setTrackFilterRange(state, trackFilterRange) {
      state.trackFilterRange = trackFilterRange;
    },
    setTrackFilterPolygonActive(state, trackFilterPolygonActive) {
      state.trackFilterPolygonActive = trackFilterPolygonActive;
    },
    setTrackFilterPolygon(state, trackFilterPolygon) {
      state.trackFilterPolygon = trackFilterPolygon;
    },
    setFitBounds(state, fitBounds) {
      state.fitBounds = fitBounds;
    },
    setFitSection(state, fitSection) {
      state.fitSection = fitSection;
    },
    setSectionProp(state, { section_id, key, value }) {
      if (state.list) {
        const i = state.list.findIndex(
          (item) => item.section_id === section_id
        );
        if (i != -1) {
          const section = state.list[i];
          section[key] = value;
          Vue.set(state.list, i, section);
        }
      }
    },
    setShouldScroll(state, shouldScroll) {
      state.shouldScroll = shouldScroll;
    },
    setFromStore(state, fromStore) {
      state.fromStore = fromStore;
    },
    setStartAfter(state, startAfter) {
      state.startAfter = startAfter;
    },
    setIsLoadingNext(state, isLoadingNext) {
      state.isLoadingNext = isLoadingNext;
    },
  },
  getters: {
    getSelectedTracks(state) {
      const tracks = state.tracks;
      const selectedTracks = state.selectedTracks;
      if (tracks && selectedTracks.length > 0) {
        return tracks.filter((track) =>
          selectedTracks.includes(track.track_id)
        );
      } else {
        return null;
      }
    },
  },
  actions: {
    clear(context, payload) {
      context.commit("setList", null);
      context.commit("setSelected", null);
      context.dispatch("clearCurveTrip");
      const clearTracks = payload?.clearTracks ?? true;
      if (clearTracks) {
        context.commit("setTracks", null);
        context.commit("setSelectedTracks", []);
      }
      context.commit("setStartAfter", null);
      context.commit("setIsLoadingNext", false);
    },
    clearCurveTrip(context) {
      context.commit("setCurveTrip", null);
    },
    async load(context, payload) {
      const fitBounds = payload?.fitBounds ?? true;
      const clearTracks = payload?.clearTracks ?? true;
      const loadNext =
        (payload?.loadNext ?? false) && context.state.startAfter != null;
      const uid = context.rootGetters.getUser.uid;
      const selected_trip = context.rootState.trips.selected;
      const fromStore = context.state.fromStore;
      if (selected_trip !== null || fromStore) {
        if (!loadNext) {
          context.dispatch("clear", { clearTracks });
          context.commit("params/clearTrip", null, { root: true });
        }

        const db = firebase.firestore();

        if (!fromStore) {
          var tripDoc = await db
            .collection("users")
            .doc(uid)
            .collection("trips")
            .doc(selected_trip.trip_id)
            .get();

          const trip = {
            trip_id: tripDoc.id,
            name: tripDoc.data().name,
            length: tripDoc.data().length,
            lengthText: formatLength(tripDoc.data().length),
            description: tripDoc.data().description,
            days: tripDoc.data().days != undefined ? tripDoc.data().days : null,
            urlname: tripDoc.data().urlname,
            allow_read: tripDoc.data().allow_read,
            starttime:
              tripDoc.data().starttime != undefined
                ? tripDoc.data().starttime
                : "",
            endtime:
              tripDoc.data().endtime != undefined ? tripDoc.data().endtime : "",
            countries: tripDoc.data().countries,
            countriesHtml: getCountryFlags(tripDoc.data().countries),
            dateText: getDateText(
              tripDoc.data().starttime,
              tripDoc.data().endtime
            ),
            ongoing: tripDoc.data().ongoing,
          };

          context.commit("trips/setSelected", trip, { root: true });
          context.dispatch("gallery/load", null, { root: true }).then(() => {
            if (tripDoc.data().image) {
              const image = context.rootState.gallery.list?.find(
                (item) => item.media_id === tripDoc.data().image.media_id
              );
              context.commit(
                "trips/setSelectedProp",
                {
                  key: "image",
                  value: image,
                },
                { root: true }
              );
            }
          });
        }

        context.dispatch("clearCurveTrip");

        let sectionsRef = !fromStore
          ? db
              .collection("users")
              .doc(uid)
              .collection("trips")
              .doc(selected_trip.trip_id)
              .collection("sections")
              .orderBy("pos")
          : db
              .collection("users")
              .doc(uid)
              .collection("store")
              .orderBy("startTime", "desc");

        if (fromStore) {
          sectionsRef = !loadNext
            ? sectionsRef.limit(11)
            : sectionsRef.startAfter(context.state.startAfter).limit(11);
        }

        let snapshot = await sectionsRef.get();
        const docs = snapshot.docs;
        if (fromStore) {
          context.commit(
            "setStartAfter",
            !snapshot.empty && docs.length == 11 ? docs[docs.length - 2] : null
          );
          docs.pop();
        }
        var promises = [];
        for (var doc of docs) {
          promises.push(
            docToSection(
              doc,
              uid,
              selected_trip?.trip_id,
              context.rootState.access_token,
              fromStore
            )
          );
        }
        var sections = await Promise.all(promises);
        if (fromStore) {
          sections.reverse();
          if (loadNext) {
            sections = sections.concat(context.state.list);
          }
        }
        context.commit("setFitBounds", fitBounds);
        if (!fromStore) {
          var media = [];
          for (var section of sections) {
            if (section.media != undefined) {
              media = media.concat(section.media);
            }
          }
          context.commit(
            "trips/addSelectedProp",
            { key: "media", value: media },
            { root: true }
          );
        }

        context.dispatch("params/setTripMerge", null, { root: true });
        context.commit("setList", sections);
        if (router.currentRoute.params.section_id) {
          const section = sections.find((obj) => {
            return obj.section_id == router.currentRoute.params.section_id;
          });
          if (section) {
            context.commit("setSelected", section);
          }
        }
        context.dispatch("params/resetSection", null, { root: true });
        context.commit("setResetMap", true, { root: true });
      }
    },
    async loadNext(context) {
      context.commit("setIsLoadingNext", true);
      try {
        await context.dispatch("load", {
          loadNext: true,
          fitBounds: false,
          clearTracks: false,
        });
      } catch (error) {
        console.error(error);
        context.dispatch("showError", error.toString(), { root: true });
      }
      context.commit("setIsLoadingNext", false);
    },
    async delete(context, section_id) {
      const trip = context.rootState.trips.selected;
      const fromStore = context.state.fromStore;
      if ((trip != null || fromStore) && section_id != undefined) {
        context.commit("setIsDeleting", true);
        try {
          const deleteObject = firebase
            .functions()
            .httpsCallable("deleteObject");
          var result = await deleteObject({
            type: "section",
            fromStore: fromStore,
            trip_id: trip?.trip_id,
            section_id: section_id,
          });
          if (result.data.status == 200) {
            if (!fromStore) {
              context.dispatch("toTrip", { toEdit: true }, { root: true });
            } else {
              router.replace({ name: "StoreMeta" });
            }
            context.commit("setIsLoading", true, { root: true });
            await context.dispatch("load", {
              clearTracks: false,
              fitBounds: false,
            });
            context.commit("setIsLoading", false, { root: true });
            context.commit(
              "setToast",
              {
                title: "info",
                tTitle: true,
                text: "sectionDeleted",
                tText: true,
                variant: "primary",
              },
              { root: true }
            );
          } else {
            throw new Error("Error in deleteSection");
          }
        } catch (error) {
          console.error(error);
          context.dispatch("showError", error.toString(), { root: true });
        }
        context.commit("setIsDeleting", false);
      }
    },
    async loadTracks(context) {
      const uid = context.rootGetters.getUser.uid;
      const selected_trip = context.rootState.trips.selected;
      if (selected_trip !== null && context.rootGetters.isMyTrip) {
        const trip_id = selected_trip.trip_id;
        const db = firebase.firestore();
        const trackSnap = await db
          .collection("users")
          .doc(uid)
          .collection("trips")
          .doc(trip_id)
          .collection("tracks")
          .orderBy("timestamp")
          .get();
        if (!trackSnap.empty) {
          const promises = [];
          for (const trackDoc of trackSnap.docs) {
            promises.push(
              (async function () {
                var storageRef = firebase.storage().ref();
                var trackRef = storageRef.child(
                  "users/" +
                    uid +
                    "/trips/" +
                    trip_id +
                    "/tracks/" +
                    trackDoc.id +
                    "/track.geojson"
                );
                let url = await trackRef.getDownloadURL();
                let response = await axios.get(url);
                return {
                  track_id: trackDoc.id,
                  name: trackDoc.data().name,
                  timestamp: trackDoc.data().timestamp.toDate(),
                  geojson: response.data,
                };
              })()
            );
          }
          const tracks = await Promise.all(promises);
          context.commit("setTracks", tracks);
        } else {
          context.commit("setTracks", null);
        }
      }
    },
    async reorder(context, sections) {
      const uid = context.rootGetters.getUser.uid;
      const selected_trip = context.rootState.trips.selected;
      const fromStore = context.state.fromStore;
      if (
        context.rootGetters.isLoggedIn &&
        (fromStore ||
          (selected_trip !== null && context.rootGetters.isMyTrip)) &&
        sections?.length > 0
      ) {
        context.commit("setList", sections);

        const db = firebase.firestore();
        var batch = db.batch();
        for (var i in sections) {
          var sectionsRef = !fromStore
            ? db
                .collection("users")
                .doc(uid)
                .collection("trips")
                .doc(selected_trip.trip_id)
                .collection("sections")
            : db.collection("users").doc(uid).collection("store");
          var sectionRef = sectionsRef.doc(sections[i].section_id);
          batch.update(sectionRef, { pos: parseInt(i) });
        }
        const promises = [];
        promises.push(batch.commit());
        if (!fromStore) {
          promises.push(
            context.dispatch("trips/refreshDates", null, { root: true })
          );
        }

        await Promise.all(promises);
        context.commit(
          "setToast",
          {
            title: "info",
            tTitle: true,
            text: "sectionsReordered",
            tText: true,
            variant: "primary",
          },
          { root: true }
        );
      }
    },
    edit(context) {
      //const uid = context.rootGetters.getUser.uid;
      const trip_id = context.rootState.trips.selected?.trip_id;
      const section_id = router.currentRoute.params?.section_id;
      const fromStore = context.state.fromStore;

      if (
        !context.rootGetters.isLoggedIn ||
        (!context.rootGetters.isMyTrip && !fromStore)
      ) {
        console.error("Edit Section: No permission!");
        return;
      }

      if (
        (router.currentRoute.name !== "EditSection" &&
          router.currentRoute.name !== "EditSectionStore") ||
        !section_id ||
        (!trip_id && !fromStore)
      ) {
        console.error(
          "Edit Section: Method can only be accessed from page 'EditSection'"
        );
        return;
      }

      const section = context.state.list.find(
        (item) => item.section_id === section_id
      );

      if (!section.days) {
        section.days = [];
      }

      context.commit("params/setSectionMerge", section, { root: true });
      if (section.ticketPrice != null) {
        context.commit("params/pt/setAddPrice", true, { root: true });
        context.commit("params/pt/setSelectedPrice", "manual", { root: true });
      }
      context.commit("params/setIsEditing", true, { root: true });
      if (section.mode !== "stay") {
        const markers = getMarkers(section);
        context.dispatch("router/setMarkers", markers, { root: true });
        context.dispatch("router/route", null, { root: true });
        context.commit("router/setIsActive", true, { root: true });
        context.commit("setFitSection", section_id);
      } else {
        context.commit("router/setIsStay", true, { root: true });
      }
    },
    async update(context, { section_id }) {
      const uid = context.rootGetters.getUser.uid;
      const trip_id = context.rootState.trips.selected?.trip_id;

      if (!uid || !trip_id || !section_id) {
        console.error("Error: uid, trip_id or section_id missing!");
        return;
      }

      const section = context.state.list.find(
        (section) => section.section_id === section_id
      );

      if (
        section.mode !== "pt" ||
        !section?.hafasTripId ||
        (section?.lastUpdated &&
          DateTime.now().diff(DateTime.fromISO(section.lastUpdated), "minutes")
            .minutes < 2)
      ) {
        return;
      }

      try {
        context.commit("setShouldScroll", false);
        context.commit("setSectionProp", {
          section_id,
          key: "lastUpdated",
          value: DateTime.now().toISO(),
        });

        const updateSection = firebase
          .functions()
          .httpsCallable("updateSection");
        var result = await updateSection({
          uid,
          trip_id,
          section_id,
        });

        if (result.data?.lastUpdated) {
          context.commit("setShouldScroll", false);
          context.commit("setSectionProp", {
            section_id,
            key: "lastUpdated",
            value: result.data.lastUpdated,
          });
        }

        if (result.data?.stops) {
          context.commit("setShouldScroll", false);

          context.commit("setSectionProp", {
            section_id,
            key: "stops",
            value: result.data.stops,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};

async function docToSection(doc, uid, trip_id, access_token, fromStore) {
  var section;
  var sectionName;
  var data = doc.data();
  const stops = data.stops;
  if (data.mode == "pt" || data.mode == "ptm") {
    sectionName =
      data.line_name +
      " " +
      stops[0].name +
      " — " +
      stops[stops.length - 1].name;
  } else {
    sectionName =
      data.description != ""
        ? data.description
        : stops[0].name + " — " + stops[stops.length - 1].name;
  }
  section = {
    section_id: doc.id,
    display_name: sectionName,
    color: getSectionColor(data),
    ...data,
  };

  if (data.mode !== "stay") {
    section.polyline = poly.decode(
      await getSectionFile(uid, trip_id, doc.id, "/polyline.txt", fromStore),
      6
    );
  }

  if (section.vehicleComposition) {
    var storageRef = firebase.storage().ref();
    var ref = !fromStore
      ? storageRef.child(
          "users/" +
            uid +
            "/trips/" +
            trip_id +
            "/sections/" +
            doc.id +
            "/vehicleComposition/"
        )
      : storageRef.child(
          "users/" + uid + "/store/" + doc.id + "/vehicleComposition/"
        );
    const files = await ref.listAll();
    const vehiclePromises = [];
    for (const i in section.vehicleComposition) {
      if (i >= files.items.length) break;
      vehiclePromises.push(
        getSectionFileUrl(
          uid,
          trip_id,
          doc.id,
          "/vehicleComposition/" + i + ".gif",
          fromStore
        )
      );
    }
    const urls = await Promise.all(vehiclePromises);
    section.vehicleComposition.map((vehicle, index) => {
      vehicle.url = urls[index];
    });
  }

  // if (data.mode == "pt" || data.mode == "ptm") {
  //     var timetable_section = await getTimetableSection(
  //         uid,
  //         trip_id,
  //         doc.id
  //     );
  //     if (timetable_section !== undefined) {
  //         section.timetable_section = timetable_section;
  //     }
  // }
  return section;
}

async function getSectionFile(uid, trip_id, section_id, file_name, fromStore) {
  const url = await getSectionFileUrl(
    uid,
    trip_id,
    section_id,
    file_name,
    fromStore
  );
  try {
    let response = await axios.get(url);
    return response.data;
  } catch (e) {
    return undefined;
  }
}

async function getSectionFileUrl(
  uid,
  trip_id,
  section_id,
  file_name,
  fromStore
) {
  var storageRef = firebase.storage().ref();
  try {
    var ref = !fromStore
      ? storageRef.child(
          "users/" +
            uid +
            "/trips/" +
            trip_id +
            "/sections/" +
            section_id +
            file_name
        )
      : storageRef.child("users/" + uid + "/store/" + section_id + file_name);
    let url = await ref.getDownloadURL();
    return url;
  } catch (e) {
    return undefined;
  }
}

function getMarkers(section) {
  if (section.mode === "stay") {
    console.error("getMarkers: Called function with section with mode 'stay'!");
    return;
  }
  const polyline = section.polyline;
  const markers = [];
  let currentCoords = [];

  const getMarker = function (index, stop, coords) {
    const point = polyline[index];
    const marker = {
      lat: point[0],
      lng: point[1],
      mode: "inherit",
      isStation: !!stop,
      ignoreMarkers: false,
      station: {
        name: stop?.name ?? "",
        arrival: stop?.arrival ?? "",
        plannedArrival: stop?.plannedArrival ?? "",
        platformArrival: stop?.platformArrival ?? "",
        departure: stop?.departure ?? "",
        plannedDeparture: stop?.plannedDeparture ?? "",
        platformDeparture: stop?.platformDeparture ?? "",
        hafas_id: stop?.hafas_id ?? null,
      },
      feature: {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: coords,
        },
        properties: {},
      },
    };
    return marker;
  };

  if (section.mode === "pt" || section.mode === "ptm") {
    markers.push(getMarker(0, section.stops[0]));

    for (const index in polyline) {
      const point = polyline[index];
      currentCoords.push([point[1], point[0]]);

      if (index != 0) {
        const stop = section.stops.find((stop) => stop.point == index);
        if (stop) {
          markers.push(getMarker(index, stop, currentCoords));
          currentCoords = [[point[1], point[0]]];
        }
      }
    }
    return markers;
  } else {
    markers.push(getMarker(0));
    markers.push(
      getMarker(
        polyline.length - 1,
        false,
        polyline.map((point) => [point[1], point[0]])
      )
    );
    return markers;
  }
}

export function getSectionColor(section) {
  if (section.mode == "pt" || section.mode == "ptm") {
    const product = section.product;
    const vehicle = section.vehicle;
    if (product == "tram") {
      return "tomato";
    } else if (
      product == "subway" ||
      product == "metro" ||
      product == "u-bahn"
    ) {
      return "royalblue";
    } else if (vehicle == "train") {
      return "dimgrey";
    } else if (vehicle == "bus") {
      return "mediumseagreen";
    } else if (vehicle == "watercraft") {
      return "deepskyblue";
    } else {
      return "var(--primary)";
    }
  } else if (section.mode == "walk") {
    return "gold";
  } else if (section.mode == "bike") {
    return "orange";
  } else if (section.mode == "car") {
    return "#ba8758";
  } else if (section.mode == "flight") {
    return "silver";
  } else if (section.mode == "boat") {
    return "deepskyblue";
  } else {
    return "var(--primary)";
  }
}
