<template>
  <div v-frag>
    <b-row class="my-1 px-1">
      <label>{{ $t("sectionHafasProfile") }}</label>
      <b-form-select
        :value="params.hafas_profile"
        @input="setHafasProfile($event)"
        :options="hafasProfiles"
      ></b-form-select>
    </b-row>
    <b-row class="my-1 px-1">
      <b-form-radio
        v-model="arrivalDeparture"
        name="arrival"
        value="arrival"
        class="mr-3"
        >{{ $t("arrival") }}</b-form-radio
      >
      <b-form-radio
        v-model="arrivalDeparture"
        name="departure"
        value="departure"
        >{{ $t("departure") }}</b-form-radio
      >
    </b-row>
    <tl-datetime class="px-1" v-model="datetime"></tl-datetime>
    <b-row class="my-1 px-1">
      <label>{{ $t("sectionStartpointName") }}</label>
      <tl-typeahead
        class="tl-typeahead"
        ref="startpoint"
        :data="startpoint_stops"
        :serializer="(stop) => stop.name"
        :value="params.startpoint.name"
        @input="
          cancelFindStartpoint();
          findStartpointStops($event);
        "
        @search="clearInput('startpoint', $event)"
        @hit="setParam('startpoint', $event)"
        showAllResults
        disableSort
        style="width: 100%"
      >
        <template v-if="isLoadingStartpoint" slot="append">
          <div class="append-spinner">
            <b-spinner
              class="typeahead-spinner"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
      </tl-typeahead>
    </b-row>
    <b-row class="my-1 px-1" v-if="params.hafas_profile != 'gmaps'">
      <label>{{ $t("via") }}</label>
      <tl-typeahead
        class="tl-typeahead"
        ref="via"
        :data="via_stops"
        :serializer="(stop) => stop.name"
        :value="via.name"
        @input="
          cancelFindVia();
          findViaStops($event);
        "
        @search="clearInput('via', $event)"
        @hit="setVia($event)"
        showAllResults
        disableSort
        style="width: 100%"
      >
        <template v-if="isLoadingVia" slot="append">
          <div class="append-spinner">
            <b-spinner
              class="typeahead-spinner"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
      </tl-typeahead>
    </b-row>
    <b-row class="my-1 px-1">
      <label>{{ $t("sectionEndpointName") }}</label>
      <tl-typeahead
        class="tl-typeahead"
        ref="endpoint"
        :data="endpoint_stops"
        :serializer="(stop) => stop.name"
        :value="params.endpoint.name"
        @input="
          cancelFindEndpoint();
          findEndpointStops($event);
        "
        @search="clearInput('endpoint', $event)"
        @hit="setParam('endpoint', $event)"
        showAllResults
        disableSort
        style="width: 100%"
      >
        <template v-if="isLoadingEndpoint" slot="append">
          <div class="append-spinner">
            <b-spinner
              class="typeahead-spinner"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
      </tl-typeahead>
    </b-row>
    <b-row class="my-1 px-1">
      <label>{{ $t("sectionDescription") }}</label>
      <b-form-input
        :value="params.description"
        @change="setParam('description', $event)"
      ></b-form-input>
    </b-row>
    <b-row
      class="my-1 px-1 py-2"
      style="cursor: pointer"
      v-b-toggle="'accordion-additional-options'"
    >
      <label class="flex-grow-1" style="cursor: inherit"
        >{{ $t("additionalOptions")
        }}<b-badge
          :variant="
            selectedAdditionalOptionsAmount == 0 ? 'secondary' : 'primary'
          "
          class="ml-1"
          >{{ selectedAdditionalOptionsAmount }}</b-badge
        ></label
      >
      <div class="center-h center-v h-auto px-2">
        <i class="fas fa-chevron-down additional-options-arrow"></i>
      </div> </b-row
    ><b-row>
      <b-collapse
        id="accordion-additional-options"
        accordion="accordion-additional-options"
        class="position-relative w-100"
      >
        <b-container fluid
          ><b-row class="my-1 px-1">
            <label>{{ $t("overrideDate") }}</label>
            <b-form-input
              type="date"
              v-model="overrideDate"
            ></b-form-input></b-row
          ><tl-with-tickets></tl-with-tickets></b-container
      ></b-collapse>
    </b-row>

    <b-row class="my-1 align-end px-1">
      <b-spinner
        v-if="isLoadingJourneys"
        variant="primary"
        label="Spinning"
      ></b-spinner>
      <b-button
        variant="primary"
        class="ml-2"
        @click="findJourneys"
        :disabled="!findJourneysEnabled"
        >{{ $t("search") }}</b-button
      >
    </b-row>
    <b-row class="my-1 px-1" id="addSectionJourneys">
      <tl-journey-picker
        v-if="journeys != null"
        :journeys="journeys"
        @selected="setSelectedJourney"
        :selectedJourney="selectedJourney"
      ></tl-journey-picker>
    </b-row>
    <div id="addSectionPriceFinder">
      <tl-price-finder
        v-if="selectedJourney"
        @scroll="scrollToPriceFinder"
      ></tl-price-finder>
    </div>
    <div id="vehicleCompositionEditor">
      <tl-vehicle-composition-editor
        v-if="selectedJourney"
        @scroll="scrollTo('vehicleCompositionEditor')"
      ></tl-vehicle-composition-editor>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import VueScrollTo from "vue-scrollto";
import tlTypeahead from "travellog-typeahead";
import tlJourneyPicker from "../components/tlJourneyPicker";
import tlPriceFinder from "../components/tlPriceFinder";
import tlWithTickets from "../components/tlWithTickets";
import tlDatetime from "../components/tlDatetime";
import tlVehicleCompositionEditor from "../components/tlVehicleCompositionEditor";
import { profiles } from "../lib/hafas-profiles";

export default {
  components: {
    tlTypeahead,
    "tl-journey-picker": tlJourneyPicker,
    "tl-price-finder": tlPriceFinder,
    "tl-with-tickets": tlWithTickets,
    "tl-datetime": tlDatetime,
    "tl-vehicle-composition-editor": tlVehicleCompositionEditor,
  },
  computed: {
    params() {
      return this.$store.state.params.section;
    },
    findJourneysEnabled() {
      return (
        this.params.datetime != "" &&
        this.params.hafas_profile != "" &&
        (this.params.startpoint.id != undefined ||
          this.params.startpoint.place_id != undefined) &&
        (this.params.endpoint.id != undefined ||
          this.params.endpoint.place_id != undefined)
      );
    },
    selectedJourney() {
      return this.$store.state.params.pt.selectedJourney;
    },
    via() {
      return this.$store.state.params.pt.via;
    },
    startpoint_stops() {
      return this.$store.state.params.pt.startpointStops;
    },
    via_stops() {
      return this.$store.state.params.pt.viaStops;
    },
    endpoint_stops() {
      return this.$store.state.params.pt.endpointStops;
    },
    isLoadingStartpoint() {
      return this.$store.state.params.pt.isLoadingStartpoint;
    },
    isLoadingVia() {
      return this.$store.state.params.pt.isLoadingVia;
    },
    isLoadingEndpoint() {
      return this.$store.state.params.pt.isLoadingEndpoint;
    },
    isLoadingJourneys() {
      return this.$store.state.params.pt.isLoadingJourneys;
    },
    journeys() {
      return this.$store.state.params.pt.journeys;
    },
    earlierRef() {
      return this.$store.state.params.pt.earlierRef;
    },
    laterRef() {
      return this.$store.state.params.pt.laterRef;
    },
    hafasProfiles() {
      return [{ value: "gmaps", text: "Google Maps" }, ...profiles];
    },
    arrivalDeparture: {
      get() {
        return this.$store.state.params.pt.arrivalDeparture;
      },
      set(value) {
        this.$store.commit("params/pt/setArrivalDeparture", value);
      },
    },
    datetime: {
      get() {
        return this.$store.state.params.pt.datetime;
      },
      set(value) {
        this.$store.commit("params/pt/setDatetime", value);
      },
    },
    overrideDate: {
      get() {
        return this.$store.state.params.pt.overrideDate;
      },
      set(value) {
        this.$store.commit("params/pt/setOverrideDate", value);
      },
    },
    selectedAdditionalOptionsAmount() {
      let amount = 0;
      if (this.overrideDate) {
        amount++;
      }
      if (this.params.withTickets) {
        amount++;
      }
      return amount;
    },
  },
  methods: {
    setParam(key, value) {
      this.$store.commit("params/setSectionProp", { key: key, value: value });
    },
    setVia(value) {
      this.$store.commit("params/pt/setVia", value);
    },
    setSelectedJourney(journey) {
      if (
        this.selectedJourney == null ||
        journey == null ||
        this.selectedJourney.refreshToken != journey.refreshToken
      ) {
        this.$store.commit("params/pt/setSelectedJourney", journey);
        this.scrollToPriceFinder();
      } else {
        this.$store.commit("params/pt/setSelectedJourney", null);
      }
      this.$store.commit("params/setSectionProp", {
        key: "vehicleComposition",
        value: null,
      });
    },
    clearData() {
      this.$store.dispatch("params/pt/clear");
    },
    clearInput(key, value) {
      if (value === "") {
        if (key === "via") {
          this.setVia({ name: "" });
        } else {
          this.setParam(key, { name: "" });
        }
      }
    },
    setHafasProfile(event) {
      this.clearData();
      this.setParam("hafas_profile", event);
      this.findStartpointStops(this.params.startpoint.name, true);
      this.findViaStops(this.via.name, true);
      this.findEndpointStops(this.params.endpoint.name, true);
    },
    findStartpointStops: debounce(async function (name, ignoreSame = false) {
      this.$store.dispatch("params/pt/findStartpointStops", {
        name,
        ignoreSame,
      });
    }, 1000),
    cancelFindStartpoint: function () {
      this.$store.dispatch("params/pt/cancelFindStartpoint");
    },
    findViaStops: debounce(async function (name, ignoreSame = false) {
      this.$store.dispatch("params/pt/findViaStops", {
        name,
        ignoreSame,
      });
    }, 1000),
    cancelFindVia: function () {
      this.$store.dispatch("params/pt/cancelFindVia");
    },
    findEndpointStops: debounce(async function (name, ignoreSame = false) {
      this.$store.dispatch("params/pt/findEndpointStops", {
        name,
        ignoreSame,
      });
    }, 1000),
    cancelFindEndpoint: function () {
      this.$store.dispatch("params/pt/cancelFindEndpoint");
    },
    findJourneys: async function () {
      await this.$store.dispatch("params/pt/findJourneys");
      this.scrollToJourneys();
    },
    scrollToJourneys() {
      VueScrollTo.scrollTo("#addSectionJourneys", 500, {
        container: "#addSectionContainer",
      });
    },
    scrollToPriceFinder() {
      VueScrollTo.scrollTo("#addSectionPriceFinder", 500, {
        container: "#addSectionContainer",
      });
    },
    scrollTo(id) {
      VueScrollTo.scrollTo("#" + id, 500, {
        container: "#addSectionContainer",
      });
    },
  },
};
</script>

<style scoped>
label {
  margin: 0;
}

.align-end {
  display: flex;
  justify-content: flex-end;
}

.append-spinner {
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.typeahead-spinner {
  height: 25px;
  width: 25px;
}

.my-1 {
  align-items: center;
}

::v-deep .input-group:not(.tl-datetime) > .form-control:not(:last-child) {
  border-radius: 0.25rem !important;
}

.additional-options-arrow {
  transition: transform 0.25s linear;
}

.collapsed > div > .additional-options-arrow {
  transform: rotate(0deg);
}

:not(.collapsed) > div > .additional-options-arrow {
  transform: rotate(180deg);
}
</style>
