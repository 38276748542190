<template>
  <span>
    <span v-show="mode == 'stay'"><i class="fas fa-map-marker-alt"></i> </span>
    <span v-show="mode == 'walk'">
      <i class="fas fa-walking"></i>
    </span>
    <span v-show="mode == 'bike'">
      <i class="fas fa-bicycle"></i>
    </span>
    <span v-show="mode == 'car'"> <i class="fas fa-car"></i></span>
    <span v-show="mode == 'pt'"> <i class="fas fa-subway"></i></span>
    <span v-show="mode == 'ptm'">
      <i class="fas fa-subway"></i>
      <i class="fas fa-wrench ml-1"></i
    ></span>
    <span v-show="mode == 'flight'"> <i class="fas fa-plane"></i></span>
    <span v-show="mode == 'boat'"> <i class="fas fa-ship"></i></span>
    <span v-if="showName" class="ml-2">{{ $t(mode) }}</span>
  </span>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "",
    },
    showName: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
