<template>
  <b-button-group vertical style="width: 100%">
    <b-button @click="setMode('stay')">
      <tl-mode-icon mode="stay" :showName="true"></tl-mode-icon>
    </b-button>
    <b-button @click="setMode('walk')">
      <tl-mode-icon mode="walk" :showName="true"></tl-mode-icon>
    </b-button>
    <b-button @click="setMode('bike')">
      <tl-mode-icon mode="bike" :showName="true"></tl-mode-icon>
    </b-button>
    <b-button @click="setMode('car')">
      <tl-mode-icon mode="car" :showName="true"></tl-mode-icon>
    </b-button>
    <b-button @click="setMode('pt')">
      <tl-mode-icon mode="pt" :showName="true"></tl-mode-icon>
    </b-button>
    <b-button @click="setMode('ptm')">
      <tl-mode-icon mode="ptm" :showName="true"></tl-mode-icon>
    </b-button>
    <b-button @click="setMode('flight')">
      <tl-mode-icon mode="flight" :showName="true"></tl-mode-icon>
    </b-button>
    <b-button @click="setMode('boat')">
      <tl-mode-icon mode="boat" :showName="true"></tl-mode-icon>
    </b-button>
  </b-button-group>
</template>

<script>
import tlModeIcon from "../components/tlModeIcon";

export default {
  name: "tl-mode-picker",
  components: {
    "tl-mode-icon": tlModeIcon,
  },
  methods: {
    setMode(mode) {
      this.$store.dispatch("params/setMode", mode);
    },
  },
};
</script>

<style></style>
