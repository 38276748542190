import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
import UI from "../views/UI";
import User from "../views/User";
import Trip from "../views/Trip";
import EditTrip from "../views/EditTrip";
import EditTripMeta from "../views/EditTripMeta";
import AddSection from "../views/AddSection";
import EditSection from "../views/EditSection";
import Section from "../views/Section";
import NotFound from "../views/NotFound";
import Tracker from "../views/Tracker";
import OdRedirect from "../views/OdRedirect";
import Msal from "../views/Msal";
import StoreMeta from "../views/StoreMeta";
import Settings from "../views/Settings";

Vue.use(VueRouter);
const mode = "history";
const base = "/";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/user/:user",
    component: UI,
    children: [
      {
        path: "",
        name: "User",
        component: User,
      },
      {
        path: "trip/:trip/day/:day?",
        name: "Trip",
        component: Trip,
        alias: "trip/:trip",
      },
      {
        path: "trip/:trip/edit",
        component: EditTrip,
        children: [
          {
            path: "",
            name: "EditTripMeta",
            component: EditTripMeta,
          },
          {
            path: "add",
            name: "AddSection",
            component: AddSection,
          },
          {
            path: ":section_id",
            name: "EditSection",
            component: EditSection,
          },
        ],
      },
      {
        path: "trip/:trip/tracker",
        component: Tracker,
        name: "Tracker",
      },
      {
        path: "trip/:trip/section/:section_id",
        name: "Section",
        component: Section,
      },
      {
        path: "store",
        component: EditTrip,
        name: "Store",
        children: [
          {
            path: "",
            name: "StoreMeta",
            component: StoreMeta,
          },
          {
            path: "add",
            name: "AddSectionStore",
            component: AddSection,
          },
          {
            path: ":section_id",
            name: "EditSectionStore",
            component: EditSection,
          },
        ],
      },
      {
        path: "settings",
        component: Settings,
        name: "Settings",
      },
    ],
  },
  {
    path: "/odRedirect",
    name: "OdRedirect",
    component: OdRedirect,
  },
  {
    path: "/msal",
    name: "Msal",
    component: Msal,
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode,
  routes,
  base,
});

export default router;
