<template>
  <b-container fluid style="overflow-y: auto; height: 100%">
    <tl-profile class="mt-1"></tl-profile>
    <b-row class="mt-2 mb-2 pl-3 pr-1 justify-content-between">
      <h3>{{ $t("trips") }}</h3>
      <b-button v-b-modal.addTrip variant="link" class="text-decoration-none"
        ><i class="fas fa-plus mr-2"></i
        >{{ $t("addTripDialogTitle") }}</b-button
      >

      <b-modal id="addTrip" :title="$t('addTripDialogTitle')">
        <b-container fluid>
          <b-row class="mb-2 px-2">
            <label>{{ $t("tripName") }}</label>
            <b-form-input v-model="tripParams.name"></b-form-input>
          </b-row>
          <b-row class="mb-2 px-2">
            <label>{{ $t("tripUrlname") }}</label>
            <b-form-input
              v-model="tripParams.urlname"
              :state="validUrlname"
              aria-describedby="input-urlname-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-urlname-feedback">
              {{ $t("tripUrlnameChars") }}
            </b-form-invalid-feedback>
          </b-row>
          <b-row class="px-2">
            <b-form-group :label="$t('allowRead')" class="mb-0">
              <b-form-radio-group
                :options="allowReadOptions"
                v-model="tripParams.allow_read"
              ></b-form-radio-group>
            </b-form-group>
          </b-row>
        </b-container>

        <template #modal-footer>
          <b-spinner
            v-if="isLoading"
            variant="primary"
            class="mr-2 ml-1"
          ></b-spinner>
          <b-button
            variant="primary"
            @click="addTrip"
            :disabled="addTripDisabled || isLoading"
          >
            {{ $t("add") }}
          </b-button>
        </template>
      </b-modal>
    </b-row>
    <tl-trips :trips="trips" class="px-1"></tl-trips>

    <b-row class="my-1 px-1 justify-content-end">
      <b-button @click="$store.dispatch('msal/authorize')">
        Authorize MSAL
      </b-button></b-row
    >
    <b-row class="my-1 px-2">
      <b-button
        @click="$store.dispatch('completeUser')"
        :disabled="isCompletingUser"
        >{{ $t("completeUser") }}</b-button
      >
      <b-spinner
        v-if="isCompletingUser"
        variant="primary"
        class="mr-1 ml-2"
      ></b-spinner>
    </b-row>
  </b-container>
</template>

<script>
import tlTrips from "../components/tlTrips";
import tlProfile from "../components/tlProfile";

export default {
  components: {
    "tl-trips": tlTrips,
    "tl-profile": tlProfile,
  },
  data() {
    return {
      tripParams: {
        name: "",
        urlname: "",
        allow_read: "none",
      },
      isLoading: false,
    };
  },
  computed: {
    trips() {
      return this.$store.state.trips.list;
    },
    allowReadOptions() {
      return [
        { value: "none", text: this.$t("private") },
        { value: "all", text: this.$t("public") },
      ];
    },
    validUrlname() {
      return /^[a-z0-9-]*$/.test(this.tripParams.urlname) ? null : false;
    },
    addTripDisabled() {
      return (
        this.tripParams.name == "" ||
        this.tripParams.urlname == "" ||
        this.validUrlname === false
      );
    },
    isCompletingUser() {
      return this.$store.state.isCompletingUser;
    },
  },
  methods: {
    async addTrip() {
      if (!this.addTripDisabled) {
        this.isLoading = true;
        const result = await this.$store.dispatch("trips/add", this.tripParams);
        if (result.status == 200) {
          this.tripParams = {
            name: "",
            urlname: "",
            allow_read: "none",
          };
          this.$bvModal.hide("addTrip");
        }
        this.isLoading = false;
      }
    },
  },
  watch: {
    "tripParams.name": function (newVal) {
      this.tripParams.urlname = newVal
        .toLowerCase()
        .replaceAll(" ", "-")
        .replaceAll("ü", "ue")
        .replaceAll("ä", "ae")
        .replaceAll("ö", "oe")
        .replace(/[^a-z0-9-]/g, "");
    },
  },
};
</script>

<style></style>
