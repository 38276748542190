export const profiles = [
  { value: "oebb", text: "ÖBB Scotty", path: "hafas-client/p/oebb" },
  { value: "vao", text: "VAO", path: "hafas-client/p/vor" },
  { value: "db", text: "DB", path: "hafas-client/p/db" },
  { value: "bls", text: "BLS", path: "hafas-client/p/bls" },
  { value: "vbb", text: "VBB", path: "hafas-client/p/vbb" },
  { value: "sncb", text: "SNCB/NMBS", path: "hafas-client/p/sncb" },
  {
    value: "mobiliteit-lu",
    text: "Mobilitéitszentral (Luxembourg)",
    path: "hafas-client/p/mobiliteit-lu",
  },
  { value: "cfl", text: "CFL", path: "hafas-client/p/cfl" },
  {
    value: "rejseplanen",
    text: "Rejseplanen in Denmark",
    path: "./lib/rejseplanen",
  },
  {
    value: "irish-rail",
    text: "Iarnród Éireann/Irish Rail",
    path: "./lib/irish-rail",
  },
];

export function getProfilePath(value) {
  const profile = profiles.find((p) => p.value === value);
  return profile ? profile.path : null;
}
