<template>
  <div
    v-if="composition"
    class="pb-1 d-flex align-items-end train-composition"
    style="overflow: scroll"
  >
    <div v-for="(coach, i) in composition">
      <img
        v-if="coach.url || coach.image"
        :src="getSrc(coach)"
        :ref="'coach_' + i"
        style="image-rendering: pixelated"
        @load="resizeImage('coach_' + i)"
      />
    </div>
  </div>
  <div :class="['w-100', loadingPadding ? 'pr-5' : '']" v-else>
    <b-skeleton></b-skeleton>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";

export default {
  name: "tl-train-composition",
  props: {
    composition: Array,
    loadingPadding: Boolean,
  },
  components: {},
  data() {
    return {
      coaches: null,
      loadedCoaches: 0,
    };
  },
  methods: {
    async getComposition() {
      if (!this.composition && this.composition.length == 0) return;

      try {
        const response = await firebase
          .functions()
          .httpsCallable("getTrainComposition")({
          composition: this.composition,
        });
        this.loadedCoaches = 0;

        this.coaches = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    resizeImage(key) {
      this.$nextTick(() => {
        const ref = this.$refs[key];
        ref[0].width = ref[0].naturalWidth * 0.85;
        this.loadedCoaches++;
      });
    },
    getSrc(coach) {
      return coach.url ?? "data:image/gif;base64," + coach.image;
    },
  },
  computed: {},
  mounted() {
    //this.getComposition();
  },
  watch: {
    loadedCoaches() {
      if (this.coaches && this.coaches.length === this.loadedCoaches) {
        this.$emit("loaded");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.train-composition::-webkit-scrollbar {
  display: none;
}
</style>
