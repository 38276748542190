<template>
  <div>
    <b-row class="my-1 px-1 align-items-center">
      <b-form-checkbox @input="setAddPrice" :checked="addPrice" switch>
        {{ $t("addPrice") }}
      </b-form-checkbox>
      <b-spinner
        v-if="isLoadingPrices"
        class="ml-2"
        small
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <b-row v-if="addPrice" class="my-1 px-1"
      ><b-list-group class="accordion">
        <b-list-group-item
          button
          @click="setSelectedPrice(true)"
          :active="isSelected('manual')"
        >
          <div class="d-flex">
            <div class="d-flex">
              <span class="align-self-center">{{ $t("manual") }}</span>
            </div>
            <div class="flex-grow-1 ml-2">
              <b-input-group append="€">
                <b-form-input
                  :disabled="!isSelected('manual')"
                  class="text-right"
                  style="
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                  "
                  type="number"
                  :step="0.01"
                  v-model="ticketPrice"
                ></b-form-input>
              </b-input-group>
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item
          button
          v-if="!prices && mode !== 'stay'"
          @click="findPrices"
          :disabled="isLoadingPrices || !findPricesEnabled"
        >
          <div class="d-flex">
            <div class="d-flex">
              <span class="align-self-center">{{ $t("findPrices") }}</span>
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item
          v-if="prices"
          button
          v-for="(price, i) in prices"
          @click="setSelectedPrice(false, i, price.price)"
          :active="isSelected(i)"
        >
          <div class="d-flex">
            <span class="flex-grow-1">{{ price.title }}</span>
            <span class="price">{{ formatPrice(price.price) }}</span>
          </div>

          <b-collapse
            :visible="isSelected(i)"
            accordion="accordion-price"
            role="tabpanel"
          >
            <div class="w-100 mt-1">
              <span class="small">{{ formatRoute(price.connection) }}</span>
            </div>
            <div class="w-100 mt-1">
              <span class="small">{{ formatVehicles(price.connection) }}</span>
            </div>
          </b-collapse>
        </b-list-group-item>
      </b-list-group></b-row
    >
  </div>
</template>

<script>
export default {
  name: "tl-price-finder",
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {
    async setAddPrice(addPrice) {
      if (addPrice) {
        this.$store.commit("params/pt/setAddPrice", true);
        this.setSelectedPrice(true);
        this.$emit("scroll");
      } else {
        this.$store.dispatch("params/pt/disablePrice");
      }
    },
    async findPrices() {
      await this.$store.dispatch("params/pt/findPrices");
      this.$emit("scroll");
    },
    setSelectedPrice(manual, i = 0, price = null) {
      if (manual && this.selectedPrice == "manual") {
        return;
      }
      this.$store.commit("params/pt/setSelectedPrice", manual ? "manual" : i);
      this.$store.commit("params/setSectionProp", {
        key: "ticketPrice",
        value: manual ? 0 : price,
      });
      if (this.$store.state.params.section.withTickets) {
        this.$store.dispatch("params/setTicketPriceSaved", manual ? 0 : price);
      }
    },
    isSelected(i) {
      return this.selectedPrice != null ? this.selectedPrice == i : false;
    },
    formatPrice(price) {
      return (
        (Math.round(price * 100) / 100).toFixed(2).replace(".", ",") + " €"
      );
    },
    formatRoute(connection) {
      let text = connection[0]?.from?.name;
      for (const leg of connection) {
        text += ` - ${leg?.to?.name}`;
      }
      return text;
    },
    formatVehicles(connection) {
      let text = "";
      for (const i in connection) {
        const leg = connection[i];
        if (i > 0) {
          text += " - ";
        }
        text += leg.category?.name;
      }
      return text;
    },
  },
  computed: {
    /** @returns {any} */
    addPrice() {
      return this.$store.state.params.pt.addPrice;
    },
    /** @returns {any} */
    isLoadingPrices() {
      return this.$store.state.params.pt.isLoadingPrices;
    },
    /** @returns {any} */
    prices() {
      return this.$store.state.params.pt.prices;
    },
    /** @returns {any} */
    selectedPrice() {
      return this.$store.state.params.pt.selectedPrice;
    },
    ticketPrice: {
      /** @returns {any} */
      get() {
        return this.selectedPrice == "manual"
          ? this.$store.state.params.section.ticketPrice
          : null;
      },
      /** @param {any} value */
      set(value) {
        this.$store.commit("params/setSectionProp", {
          key: "ticketPrice",
          value: value != null && value != "" ? parseFloat(value) : null,
        });
        if (this.$store.state.params.section.withTickets) {
          this.$store.dispatch("params/setTicketPriceSaved", value);
        }
      },
    },
    /** @returns {boolean} */
    findPricesEnabled() {
      return this.$store.getters["params/pt/findPricesEnabled"];
    },
    /** @returns {string} */
    mode() {
      return this.$store.state.params.section?.mode;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-group {
  width: 100% !important;
}

span.small {
  display: block;
  line-height: 1.3;
}

.price {
  white-space: nowrap;
  align-self: center;
}
</style>
