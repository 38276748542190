<template>
  <div>
    <b-container fluid>
      <b-row class="my-1 mb-2 align-items-center">
        <h5 class="m-0">
          <i class="fas fa-route"></i
          ><span class="ml-2">{{ $t("routing") }}</span>
        </h5>
        <b-spinner
          style="height: 1.5em; width: 1.5em"
          v-show="isRouting"
          variant="primary"
          class="ml-2"
        ></b-spinner>
      </b-row>
      <b-row v-if="markers.length === 0" class="my-1">
        <span>{{ $t("placeFirstMarker") }} </span>
      </b-row>
      <b-row
        v-if="selectedMarker === null && markers.length !== 0"
        class="my-1"
      >
        <span>{{ $t("noMarkerSelected") }} </span>
      </b-row>
      <template
        v-if="
          selectedMarker !== null && markers.length > 1 && selectedMarker !== 0
        "
      >
        <b-row class="my-1">
          <b-input-group>
            <b-form-radio-group
              :checked="routeModeButtons"
              @input="setRouteMode($event)"
              button-variant="outline-primary"
              buttons
            >
              <b-form-radio value="direct"
                ><i class="fas fa-draw-polygon"></i></b-form-radio
              ><b-form-radio value="walk"
                ><i class="fas fa-walking"></i
              ></b-form-radio>
              <b-form-radio value="car"
                ><i class="fas fa-car"></i
              ></b-form-radio>
              <b-form-radio class="last-radio" value="rail"
                ><i class="fas fa-subway"></i
              ></b-form-radio>
            </b-form-radio-group>
            <b-form-select
              :options="brouterProfiles"
              :value="routeModeSelect"
              @input="setRouteMode($event)"
            ></b-form-select>
          </b-input-group>
        </b-row>
        <b-row
          class="my-2"
          v-if="selectedMarkerData && selectedMarkerData.mode !== 'inherit'"
        >
          <b-button
            @click="setRouteModeAll()"
            size="sm"
            class="text-decoration-none"
            variant="link"
            >{{ $t("applyModeToAllMarkers") }}</b-button
          >
        </b-row>
        <b-row
          class="my-2"
          v-if="selectedMarkerData && selectedMarkerData.mode === 'direct'"
        >
          <b-form-checkbox switch v-model="ignoreMarkers">{{
            $t("ignoreMarkers")
          }}</b-form-checkbox>
        </b-row>
      </template>
      <template
        v-if="
          selectedMarker !== null &&
          (params.mode === 'ptm' || params.mode === 'pt') &&
          markers.length > 0
        "
      >
        <b-row class="mt-2 mb-1">
          <b-form-checkbox switch v-model="isStation">{{
            $t("station")
          }}</b-form-checkbox>
        </b-row>
        <template v-if="isStation">
          <b-row class="my-1">
            <label>{{ $t("stationName") }}</label>
            <b-form-input v-model="stationName"></b-form-input>
          </b-row>
          <tl-datetime v-model="arrival">
            <label>{{ $t("arrival") }}</label>
          </tl-datetime>
          <tl-datetime v-model="plannedArrival">
            <label>{{ $t("plannedArrival") }}</label>
          </tl-datetime>
          <tl-datetime v-model="departure">
            <label>{{ $t("departure") }}</label>
          </tl-datetime>
          <tl-datetime v-model="plannedDeparture">
            <label>{{ $t("plannedDeparture") }}</label>
          </tl-datetime>
          <b-row class="my-1">
            <div class="left-50">
              <label>{{ $t("platformArrival") }}</label>
            </div>
            <div class="left-50">
              <label>{{ $t("platformDeparture") }}</label>
            </div>
            <b-input-group>
              <b-form-input v-model="platformArrival"></b-form-input>
              <b-form-input v-model="platformDeparture"></b-form-input>
            </b-input-group>
          </b-row>
        </template>
      </template>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import tlDatetime from "./tlDatetime";

export default {
  name: "tl-router",
  components: { "tl-datetime": tlDatetime },
  data() {
    return {
      datetime: "",
    };
  },
  computed: {
    routeMode() {
      return this.$store.getters["router/getMode"];
    },
    routeModeButtons() {
      if (
        this.routeMode === "direct" ||
        this.routeMode === "walk" ||
        this.routeMode === "car" ||
        this.routeMode === "rail"
      ) {
        return this.routeMode;
      } else {
        return null;
      }
    },
    routeModeSelect() {
      if (
        this.routeMode === "direct" ||
        this.routeMode === "walk" ||
        this.routeMode === "car" ||
        this.routeMode === "rail"
      ) {
        return "";
      } else {
        return this.routeMode;
      }
    },
    params() {
      return this.$store.state.params.section;
    },
    markers() {
      return this.$store.state.router.markers;
    },
    selectedMarker() {
      return this.$store.state.router.selectedMarker;
    },
    selectedMarkerData() {
      if (this.selectedMarker && this.markers) {
        return this.markers[this.selectedMarker];
      }

      return null;
    },
    isStation: {
      get() {
        return this.$store.getters["router/getIsStation"];
      },
      set(value) {
        this.$store.dispatch("router/setIsStation", value);
      },
    },
    ignoreMarkers: {
      get() {
        return this.$store.getters["router/getIgnoreMarkers"];
      },
      set(value) {
        this.$store.dispatch("router/setIgnoreMarkers", value);
      },
    },
    station() {
      return this.$store.getters["router/getStation"];
    },
    stationName: {
      get() {
        return this.station.name;
      },
      set(value) {
        this.$store.dispatch("router/setStationProp", {
          key: "name",
          value: value,
        });
      },
    },
    arrival: {
      get() {
        return this.station.arrival;
      },
      set(value) {
        this.$store.dispatch("router/setStationProp", {
          key: "arrival",
          value: value,
        });
      },
    },
    plannedArrival: {
      get() {
        return this.station.plannedArrival;
      },
      set(value) {
        this.$store.dispatch("router/setStationProp", {
          key: "plannedArrival",
          value: value,
        });
      },
    },
    departure: {
      get() {
        return this.station.departure;
      },
      set(value) {
        this.$store.dispatch("router/setStationProp", {
          key: "departure",
          value: value,
        });
      },
    },
    plannedDeparture: {
      get() {
        return this.station.plannedDeparture;
      },
      set(value) {
        this.$store.dispatch("router/setStationProp", {
          key: "plannedDeparture",
          value: value,
        });
      },
    },
    platformArrival: {
      get() {
        return this.station.platformArrival;
      },
      set(value) {
        this.$store.dispatch("router/setStationProp", {
          key: "platformArrival",
          value: value,
        });
      },
    },
    platformDeparture: {
      get() {
        return this.station.platformDeparture;
      },
      set(value) {
        this.$store.dispatch("router/setStationProp", {
          key: "platformDeparture",
          value: value,
        });
      },
    },
    isRouting() {
      return this.$store.state.router.isRouting;
    },
  },
  methods: {
    setRouteMode(mode) {
      if (mode) {
        this.$store.dispatch("router/setMode", mode);
      }
    },
    setRouteModeAll() {
      this.$store.dispatch("router/setModeAll");
    },
  },
  asyncComputed: {
    brouterProfiles: {
      async get() {
        var subwayProfile = await loadBRouterProfile("subway");
        var tramProfile = await loadBRouterProfile("tram");
        var narrowGaugeProfile = await loadBRouterProfile("narrow_gauge");
        var lightRailProfile = await loadBRouterProfile("light_rail");
        var otherRailProfile = await loadBRouterProfile("other_rail");
        return [
          { text: this.$t("bike"), value: "bike" },
          { text: this.$t("shortest"), value: "shortest" },
          { text: this.$t("metro"), value: subwayProfile },
          { text: this.$t("tram"), value: tramProfile },
          { text: this.$t("narrowGauge"), value: narrowGaugeProfile },
          { text: this.$t("lightRail"), value: lightRailProfile },
          { text: this.$t("otherRailways"), value: otherRailProfile },
        ];
      },
      default() {
        return [{ text: this.$t("bike"), value: "bike" }];
      },
      watch: ["$i18n.locale"],
    },
  },
};

async function loadBRouterProfile(name) {
  var profile = await (
    await fetch(process.env.BASE_URL + "brouter-profiles/" + name + ".brf")
  ).text();
  var response = await axios({
    method: "post",
    url: "https://brouter.de/brouter/profile",
    data: profile,
    headers: {
      "Content-Type": "text/plain;charset=UTF-8",
      Accept: "*/*",
    },
  });
  return response.data.profileid;
}
</script>

<style scoped>
.left-50 {
  width: 50%;
  float: left;
}

.fa-walking,
.fa-car,
.fa-subway,
.fa-draw-polygon {
  width: 16px;
}

.last-radio {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
