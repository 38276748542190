import Vue from "vue";
import Axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    isActive: false,
    markers: [],
    selectedMarker: null,
    route: null,
    isRouting: false,
    isStay: false,
    hoverMarker: null,
  }),
  mutations: {
    setIsActive(state, isActive) {
      state.isActive = isActive;
    },
    setMarkers(state, markers) {
      state.markers = markers;
    },
    addMarker(state, payload) {
      const markersLength = state.markers.length;
      const lastMode = state.markers[markersLength - 1]?.mode;
      state.markers.push({
        lat: payload.marker.lat,
        lng: payload.marker.lng,
        mode:
          markersLength != 0 && lastMode !== "inherit" ? lastMode : "direct",
        isStation: false,
        station: payload.station ?? Station(),
        ignoreMarkers: true,
      });
      state.selectedMarker = state.markers.length - 1;
    },
    addMarkerAt(state, payload) {
      const currentMode = state.markers[payload.index]?.mode || "direct";
      let coord = { lat: payload.marker.lat, lng: payload.marker.lng };
      if (currentMode === "inherit") {
        const closestPoint = state.route.features.find(
          (item) => item.properties?.marker_id == payload.index
        ).geometry.coordinates[payload.closestPoint];
        coord = {
          lat: closestPoint[1],
          lng: closestPoint[0],
        };
      }

      let feature;

      if (currentMode === "inherit") {
        let routeSection = JSON.parse(
          JSON.stringify(state.markers[payload.index].feature)
        );

        const coords = routeSection.geometry.coordinates;

        const firstCoordinates = coords.slice(0, payload.closestPoint + 1);

        const secondCoordinates = coords.slice(
          payload.closestPoint,
          coords.length
        );

        const firstSection = JSON.parse(JSON.stringify(routeSection));
        firstSection.geometry.coordinates = firstCoordinates;
        feature = firstSection;

        const secondSection = JSON.parse(JSON.stringify(routeSection));
        secondSection.geometry.coordinates = secondCoordinates;
        state.markers[payload.index].feature = secondSection;

        state.hoverMarker = null;
      }
      state.markers.splice(payload.index, 0, {
        ...coord,
        mode: currentMode,
        isStation: false,
        ignoreMarkers: true,
        station: payload.station ?? Station(),
        feature,
      });
      state.selectedMarker = parseInt(payload.index);
    },
    removeMarker(state, index) {
      state.markers.splice(index, 1);
      if (state.selectedMarker > index) {
        state.selectedMarker--;
      } else if (state.selectedMarker == index) {
        state.selectedMarker = null;
      }
    },
    moveMarker(state, payload) {
      state.markers[payload.index].lat = payload.lat;
      state.markers[payload.index].lng = payload.lng;
    },
    clearMarkers(state) {
      state.markers = [];
    },
    setMode(state, mode) {
      if (state.selectedMarker !== null) {
        state.markers[state.selectedMarker].mode = mode;
      }
    },
    setModeAll(state) {
      if (state.selectedMarker !== null) {
        state.markers = state.markers.map((marker) => {
          return { ...marker, mode: state.markers[state.selectedMarker].mode };
        });
      }
    },
    setIsStation(state, isStation) {
      if (state.selectedMarker !== null) {
        state.markers[state.selectedMarker].isStation = isStation;
      }
    },
    setIgnoreMarkers(state, ignoreMarkers) {
      if (state.selectedMarker !== null) {
        state.markers[state.selectedMarker].ignoreMarkers = ignoreMarkers;
      }
    },
    setStationProp(state, payload) {
      if (state.selectedMarker !== null) {
        Vue.set(
          state.markers[state.selectedMarker].station,
          payload.key,
          payload.value
        );
      }
    },
    setSelectedMarker(state, selectedMarker) {
      state.selectedMarker = selectedMarker;
    },
    setRoute(state, route) {
      state.route = route;
    },
    setIsRouting(state, isRouting) {
      state.isRouting = isRouting;
    },
    setIsStay(state, isStay) {
      state.isStay = isStay;
    },
    setHoverMarker(state, hoverMarker) {
      state.hoverMarker = hoverMarker;
    },
  },
  getters: {
    getMode(state) {
      if (state.markers.length != 0 && state.selectedMarker !== null) {
        return state.markers[state.selectedMarker].mode;
      } else {
        return null;
      }
    },
    getIsStation(state) {
      if (state.markers.length != 0 && state.selectedMarker !== null) {
        return state.markers[state.selectedMarker].isStation;
      } else {
        return null;
      }
    },
    getIgnoreMarkers(state) {
      if (state.markers.length != 0 && state.selectedMarker !== null) {
        return state.markers[state.selectedMarker].ignoreMarkers;
      } else {
        return null;
      }
    },
    getStation(state) {
      if (state.markers.length != 0 && state.selectedMarker !== null) {
        return state.markers[state.selectedMarker].station;
      } else {
        return null;
      }
    },
    getMappedRoute(state) {
      const route = state.route;
      if (!route) {
        return null;
      }

      return route.features.map((feature) => {
        return {
          coords: feature.geometry.coordinates.map((coord) => [
            coord[1],
            coord[0],
          ]),
          marker_id: feature.properties.marker_id,
        };
      });
    },
  },
  actions: {
    setMarkers(context, payload) {
      context.commit("setMarkers", payload);
      context.dispatch("setPtm");
      context.commit("setSelectedMarker", null);
      //context.dispatch("route");
    },
    addMarker(context, payload) {
      context.commit("addMarker", payload);
      context.dispatch("route");
    },
    addMarkerAt(context, payload) {
      context.commit("addMarkerAt", payload);
      context.dispatch("route");
    },
    removeMarker(context, index) {
      context.commit("removeMarker", index);
      context.dispatch("route");
      context.dispatch("setPtm");
    },
    moveMarker(context, payload) {
      context.commit("moveMarker", payload);
      context.dispatch("route");
      context.dispatch("setPtm");
    },
    clearMarkers(context) {
      context.commit("clearMarkers");
      context.dispatch("route");
      context.dispatch("setPtm");
    },
    reset(context) {
      context.dispatch("clearMarkers");
      context.commit("setSelectedMarker", null);
      context.commit("setIsActive", false);
      context.commit("setIsStay", false);
    },
    setMode(context, mode) {
      context.commit("setMode", mode);
      context.dispatch("route");
    },
    setModeAll(context) {
      context.commit("setModeAll");
      context.dispatch("route");
    },
    setIsStation(context, isStation) {
      context.commit("setIsStation", isStation);
      context.dispatch("setPtm");
    },
    setIgnoreMarkers(context, ignoreMarkers) {
      context.commit("setIgnoreMarkers", ignoreMarkers);
      context.dispatch("route");
    },
    setStationProp(context, payload) {
      context.commit("setStationProp", payload);
      context.dispatch("setPtm");
    },
    async route(context) {
      const markers = context.state.markers;
      if (markers.length > 1) {
        try {
          context.commit("setIsRouting", true);
          // array of route section
          const routePromises = [];

          // loop through route markers
          for (const i in markers) {
            // current station
            const marker = markers[i];

            if (i != 0) {
              // route sections between two markers
              routePromises.push(
                routeSection(markers[i - 1], marker, marker.mode, i)
              );
            }
          }

          // wait until all sections are routed
          const features = await Promise.all(routePromises);

          // correct direct features with ignoreMarkers = true
          for (let featureIndex in features) {
            featureIndex = parseInt(featureIndex);
            const feature = features[featureIndex];
            if (feature.properties?.ignoreMarkers) {
              let firstCoords = feature.geometry.coordinates[0];
              if (features[featureIndex - 1]) {
                firstCoords =
                  features[featureIndex - 1].geometry.coordinates[
                    features[featureIndex - 1].geometry.coordinates.length - 1
                  ];
              }
              let secondCoords = feature.geometry.coordinates[1];
              if (features[featureIndex + 1]) {
                secondCoords =
                  features[featureIndex + 1].geometry.coordinates[0];
              }
              features[featureIndex].geometry = {
                type: "LineString",
                coordinates: [firstCoords, secondCoords],
              };
            }
          }

          context.commit("setRoute", Route(features));
        } catch (error) {
          console.error(error);
          if (error.response != undefined && error.response.status === 400) {
            context.commit(
              "setToast",
              {
                title: "error",
                tTitle: true,
                text: "noRouteFound",
                tText: true,
                variant: "danger",
              },
              { root: true }
            );
          } else {
            context.commit(
              "setToast",
              {
                title: "error",
                tTitle: true,
                text: error.toString(),
                variant: "danger",
              },
              { root: true }
            );
          }
        }
      } else {
        context.commit("setRoute", null);
      }
      context.commit("setIsRouting", false);
    },
    setPtm(context) {
      const markers = context.state.markers;
      if (markers.length > 1) {
        // array of stations
        const stations = [];
        // loop through route markers
        for (const i in markers) {
          // current station
          const marker = markers[i];
          // add station to stations
          if (marker.isStation) {
            stations.push({
              ...marker.station,
              lat: marker.lat,
              lng: marker.lng,
              marker_id: i,
            });
          }
        }

        context.commit(
          "params/setSectionPtmProp",
          {
            key: "stations",
            value: stations,
          },
          { root: true }
        );
      } else {
        context.commit(
          "params/setSectionPtmProp",
          {
            key: "stations",
            value: [],
          },
          { root: true }
        );
      }
    },
  },
};

// routes section between two points and returns geojson feature
async function routeSection(from, to, mode, id) {
  var feature;
  if (from != undefined && to != undefined && mode != undefined) {
    if (mode === "direct") {
      // if mode is 'direct', return straight line between points
      feature = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [from["lng"], from["lat"]],
            [to["lng"], to["lat"]],
          ],
        },
        properties: {
          ignoreMarkers: to.ignoreMarkers,
        },
      };
    } else if (mode === "inherit") {
      feature = to.feature;
    } else {
      var profile;
      if (mode === "walk") {
        profile = "hiking-beta";
      } else if (mode == "bike") {
        profile = "trekking";
      } else if (mode == "car") {
        profile = "car-fast";
      } else if (mode === "rail") {
        profile = "rail";
      } else {
        profile = mode;
      }
      var lonlats =
        from["lng"] + "," + from["lat"] + "|" + to["lng"] + "," + to["lat"];
      var url = `https://brouter.de/brouter?lonlats=${lonlats}&nogos=&profile=${profile}&alternativeidx=0&format=geojson`;
      const { data } = await Axios.get(url);
      feature = data.features[0];
      delete feature.properties;
    }
    feature.properties = { ...feature.properties, marker_id: id };
  }
  return feature;
}

export function Station() {
  return {
    name: "",
    arrival: "",
    plannedArrival: "",
    platformArrival: "",
    departure: "",
    plannedDeparture: "",
    platformDeparture: "",
  };
}

function Route(features) {
  return {
    type: "FeatureCollection",
    features: features,
  };
}
